import { Deserializable } from './deserializable';
import { Task } from '@Mesh/core/models/task';

export class UserPoint implements Deserializable {
  id?: number;
  date?: string;
  user_points?: number;
  points?: number;
  user_rating?: number;
  rating?: number;
  user_score?: number;
  score?: number;
  source_type?: string;
  source_id?: number;
  source_name: string;
  unit?: string;
  timestamp?: number;
  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
