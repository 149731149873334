export class ChatPager {
    page?: number;
    skip?: number;
    take?: number;
}

export interface DialogByClient {
    totalAddress: number
    totalSupport: number
    totalTask: number
    totalUnread: number
    clientSapId: number
    client: DialogClient
}

export interface DialogClient {
    clientSapId: number
    clientName: string
    inn: string
    kpp: string
    ogrn: string
    urAdr: string
    addressCount: any
}

export interface DialogUserParams {
    $limit: number;
    $skip: number;
    type?: string,
    $sort?: any,
    clientSapId?: number;
    onlyUnread?: number; // 0, 1
    taskStatuses?: string;
}

export interface DialogByClientParams {
    $limit: number;
    $skip: number;
    clientName?: string
}

export enum TypeDialog {
    SUPPORT = 'support',
    TASK_COMMENTS = 'task-comments'
}


export interface UserDialog {
    chat_id: number;
    type: string;
    typeId: number;
    addressSapId: number;
    total: number;
    totalUnread: number;
    totalSupport?: number;
    taskId: number;
    message: any;
    task: any;
    taskStep: any;
    outlet: any;
    settings: UserDialogSettings
}

export interface UserDialogSettings {
    muted: number
    pinned: number
    blocked: number
}



