import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TypeDialog, UserDialog } from '../../../../chat';
import { User } from '../../../../../../../core/models/user';

@Component({
  selector: 'iql-outlet-last-message',
  templateUrl: './outlet-last-message.component.html',
  styleUrls: ['./outlet-last-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutletLastMessageComponent implements OnInit {
  @Input() userDialog: UserDialog;
  @Input() currentUser: User;
  TypeDialog = TypeDialog;

  constructor() { }

  ngOnInit() {
    console.log(this.currentUser);
  }

  get getReplyUser() {
    if (this.currentUser) {
      const filtered = [this.userDialog.message.user, this.userDialog.message.replyUser].filter(user =>user.id !== this.currentUser.id);
      return filtered.length ? filtered[0] : this.currentUser;
    }
  };
}
