import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective; 
  @Input() isShowMode = false;
  @Input() showProduct: Subject<any>;
  product: any;

  productForm: FormGroup;
  subcriptions: Subscription[] = [];

  constructor() { }

  ngOnInit() {
    
    let sub = this.showProduct.subscribe(product => {
      this.product = product;
      this.productForm = new FormGroup({
        quantity: new FormControl(this.product.quantity),
        size: new FormControl('0'),
        brandFamilyName: new FormControl(this.product.brandFamilyName),
        categoryName: new FormControl(this.product.categoryName)
      })
      this.mdSlideUp.show()
    });
    this.subcriptions.push(sub);

  }

  ngOnDestroy() {
    this.subcriptions.forEach(s => s.unsubscribe());
  }

  onHide() {
    this.productForm.reset();
  }

}
