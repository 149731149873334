import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApplicationSettings from '../../reducers/startup/applications-settings.reducers';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { City, Region } from '@Mesh/core/models/location';
import { orderBy } from 'lodash-es';

export const getApplicationSettingsState = createFeatureSelector<fromApplicationSettings.State>('applicationSettings');

export const getCategoriesLoadingStatus = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getCategoriesLoadingStatus
);
export const getCategoriesValue = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getCategoriesValue
);
export const getCategoriesMainLoadingStatus = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getCategoriesMainLoadingStatus
);
export const getCategoriesMainValue = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getCategoriesMainValue
);
export const getPlansLoadingStatus = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getPlansLoadingStatus
);
export const getPlansValue = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getPlansValue
);

export const getApplicationSettingsLoadingStatus = createSelector(
  getApplicationSettingsState, settingsState =>
    settingsState.categories.loadingStatus === LoadingStatus.Loaded &&
    settingsState.plans.loadingStatus === LoadingStatus.Loaded
);

export const getCitiesLoadingStatus = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getCitiesLoadingStatus
);
export const getCitiesValue = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getCitiesValue
);
export const getRegionsLoadingStatus = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getRegionsLoadingStatus
);
export const getRegionsValue = createSelector(
  getApplicationSettingsState,
  fromApplicationSettings.getRegionsValue
);

export const selectRegions = createSelector(
  getRegionsValue,
  regions =>  orderBy(regions.map((region, index) => (new Region(index, region.regionName, region.regionCode))), 'regionCode') as Region[]
);

export const selectCities = createSelector(
  getCitiesValue,
  cities => orderBy(cities.map((city, index) => (new City(index, city.cityName, city.regionCode))), 'cityName') as City[]
);
