import { Client } from './client';

export interface Task {
    id: number;
    name: string;
    cost: number;
    active: boolean;
    imageUrl: string;
    dateStart: string;
    dateEnd: string;
    step: TaskStep[];
    dataOfCreation: string;
    comment: string;
    progressStatus?: string;
    clientSapId?: number;
    progress: number;
    reward: string;
}

export interface TaskData {
    content: Task[];
    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        },
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    number: number;
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    size: number;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
}

export interface ITaskAssignment {
    clientsOutletsToAdd: { clientSapId: number, addressSapId: number, segmentName?: string, dateStart?: string, dateEnd?: string }[];
    clientsOutletsToRemove: { clientSapId: number, addressSapId: number, segmentName?: string, dateStart?: string, dateEnd?: string }[];
}

export class ITaskOutlet {
    addressSapId: number;
}
export interface CriteriaClientTask {
    clientSapId: number;
    addressSapId: number;
    notOverdue: boolean;
    taskStatuses: string[];
    onlyActual: boolean;
    startDate?: string;
    endDate?: string;
    pageNumber: number;
    pageSize: number;
}
export class TaskInfo {
    id?: number;
    name: string;
    description: string;
    cost: number;
    active: boolean;
    imageUrl: string;
    dateStart: string;
    dateEnd: string;
    step: TaskStep[];
    dataOfCreation: string;
    autoAssignment: boolean;
    autoAssignmentType: CreateGoalAutoAssignType;
    autoAssignmentDayCount: number;
    recommendedOrderConfigId?: number;
    comment: string;
    equipmentQuantity: number;
    strategies: Array<string>;

    constructor(
        active = false,
        autoAssignment = false,
        autoAssignmentType = null,
        autoAssignmentDayCount = null,
        dateStart = new Date().toISOString(),
        dateEnd = new Date().toISOString(),
        dataOfCreation = new Date().toISOString(),
        description = '',
        step = [],
        equipmentQuantity = EquipmentType.QUANTITY_40,
        strategies = [CountingStrategiesType.Q1],

    ) {
        this.id = null;
        this.active = active;
        this.cost = 500;
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
        this.imageUrl = '';
        this.name = '';
        this.step = step;
        this.dataOfCreation = dataOfCreation;
        this.comment = '';
        this.description = description;
        this.autoAssignment = autoAssignment;
        this.autoAssignmentType = autoAssignmentType;
        this.autoAssignmentDayCount = autoAssignmentDayCount;
        this.equipmentQuantity = equipmentQuantity;
        this.strategies = strategies;
    }
}

export class TaskStep {
    constructor(taskId = 0) {
        this.id = null;
        this.name = '';
        this.cost = 0;
        this.taskId = taskId;
        this.step = 0;
        this.status = undefined;
        this.imageUrls = [];
        this.videoUrls = [];
        this.description = '';
        this.comment = '';
        this.progressStatus = undefined;
        this.question = undefined;
        this.reply = [];
        this.requiredCountMedia = 1;
        this.type = { name: StepType.sendPhoto };
        this.additionalType = null;
        this.recommendedOrderConfigId = undefined;
        this.needModeration = false;
        this.enableRecommendedOrder = false;
        this.userBonusStrategy = CreateGoalBonusStrategyType.disable;
        this.userBonusValue = null;
    }

    id?: number;
    name: string;
    cost: number;
    taskId: number;
    step: number;
    status: string;
    progressStatus: string;
    imageUrls: AttachmentDetails[];
    videoUrls: AttachmentDetails[];
    description: string;
    comment: string;
    question: string;
    reply: string[];
    requiredCountMedia: number;
    type: {
        name: StepType;
    };
    additionalType?: string;
    recommendedOrderConfigId: number;
    needModeration: boolean;
    enableRecommendedOrder: boolean;
    userBonusStrategy: CreateGoalBonusStrategyType;
    userBonusValue: number;
}

export class AttachmentDetails {
    id?: number;
    url: string;
    sort: number;
}

export class TaskStepStatusResponse {
    id: number;
    taskId: number;
    clientSapId: number;
    addressSapId: number;
    stepId: number;
    status: string;
    images: string[];
    acceptedImages: string[];
    comment: string;
    answer: boolean;

    updatedAt: Date;
}

export class TaskClientData {
    content: Client[];
    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        },
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    number: number;
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    size: number;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
}
export interface TaskClient {
    clientSapId: number
    addressSapId: number
    status: string
    cost: number
    id: number
    taskId: number
    active: boolean
    name: string
    imageUrl: string
    dateStart: string
    dateEnd: string
    dateOfCreation: string
    comment: string
    step: TaskStep[]
    countStep: number
    notOverdue: boolean
    taskStatus: string
}
export class TaskProgress {
    taskId: number;
    taskOutletClientId: number;
    addressSapId: number;
    clientSapId: number;
    name: string;
    stepCount: number;

    expired: boolean;
    preformed: boolean;

    statusCounts: IStatusInfo[];

    lastSettingCheckingOrApprovedStatusAt: string;
}

export interface IStatusInfo {
    status: TaskStatusApi;
    stepCount: number;
}

export interface IStockInfoResponse {
    materialId: number;
    quantity: number;
    registerAt: string;
    addressSapId: number;
}

export interface IStockInfo {
    planName: string;
    date: string;

    productsInfo: {
        materialId: number;
        imageUrl: string;
        quantity: number;
        unit: string;
    }[];
}

export enum TaskStatusApi {
    new = 'NEW',
    accepted = 'ACCEPTED',
    notAccepted = 'NOT_ACCEPTED',
    checking = 'CHECKING'
}

export enum TaskStatus {
    NEW = 'NEW',
    ACCEPTED = 'ACCEPTED',
    NOT_ACCEPTED = 'NOT_ACCEPTED',
    CHECKING = 'CHECKING',
    PROGRESS = 'PROGRESS',
    UNKNOWN = 'UNKNOWN'
}

export enum TaskStepStatus {
    NEW = 'NEW',
    ACCEPTED = 'ACCEPTED',
    NOT_ACCEPTED = 'NOT_ACCEPTED',
    CHECKING = 'CHECKING',
    UNKNOWN = 'UNKNOWN'
}

export const TaskStatusMap = {
    [TaskStatus.NEW]: {
        type: TaskStatus.NEW,
        title: 'Новый',
        color: '#FFD600'
    },
    [TaskStatus.ACCEPTED]: {
        type: TaskStatus.ACCEPTED,
        title: 'Выполненно',
        color: '#00D0B3'
    },
    [TaskStatus.NOT_ACCEPTED]: {
        type: TaskStatus.NOT_ACCEPTED,
        title: 'Не выполнено',
        color: '#FF5254'
    },
    [TaskStatus.PROGRESS]: {
        type: TaskStatus.PROGRESS,
        title: 'В процессе',
        color: '#166FFF'
    },
    [TaskStatus.CHECKING]: {
        type: TaskStatus.CHECKING,
        title: 'На проверке',
        color: '#FF8C4D'
    },
    [TaskStatus.UNKNOWN]: {
        type: TaskStatus.UNKNOWN,
        title: 'Неизветсно',
        color: '#FF5254'
    },
};

export const TaskStepStatusMap = {
    [TaskStepStatus.NEW]: {
        type: TaskStepStatus.NEW,
        title: 'Новый',
        color: '#166FFF'
    },
    [TaskStepStatus.ACCEPTED]: {
        type: TaskStepStatus.ACCEPTED,
        title: 'Выполненно',
        color: '#00D0B3'
    },
    [TaskStepStatus.CHECKING]: {
        type: TaskStepStatus.CHECKING,
        title: 'Провалено',
        color: '#FF5254'
    },
    [TaskStepStatus.NOT_ACCEPTED]: {
        type: TaskStepStatus.NOT_ACCEPTED,
        title: 'Провалено',
        color: '#FF5254'
    },
    [TaskStatus.UNKNOWN]: {
        type: TaskStatus.UNKNOWN,
        title: 'Неизветсно',
        color: '#FF5254'
    },
};

export enum StepType {
    sendPhoto = 'SEND_PHOTO',
    giveAnswer = 'GIVE_ARBITRARY_ANSWER',
    selectOption = 'SELECT_OPTION',
    calculateStock = 'CALCULATE_REST_OF_PRODUCT',
    photoMonitoring = 'PHOTO_MONITORING',
    autoRecommendedOrder = 'AUTO_RECOMMENDED_ORDER'
}
export const StepTypeMap = {
    // [StepType.questioning]: {
    //     title: 'Анкетирование',
    //     type: StepType.questioning,
    //     image: 'assets/img/task-step/questionnaire.png',
    //     icon: 'assets/img/task-step/icons/anketa-icon.svg',
    //     color: '#00D0B3',
    // },
    // [StepType.freeForm]: {
    //     title: 'Свободная форма',
    //     type: StepType.freeForm,
    //     image: 'assets/img/task-step/freeform-specification.png',
    //     icon: 'assets/img/task-step/icons/file-icon.svg',
    //     color: '#8E44AD',
    // },
    [StepType.calculateStock]: {
        title: 'Остатки',
        type: StepType.calculateStock,
        image: 'assets/img/task-step/leftovers.png',
        icon: 'assets/img/task-step/icons/boxes-icon.svg',
        color: '#FFA84C',
    },
    [StepType.photoMonitoring]: {
        title: 'Фото-мониторинг стеллажей',
        type: StepType.photoMonitoring,
        image: 'assets/img/task-step/photo-monitoring.png',
        icon: 'assets/img/task-step/icons/photo-monitoring-icon.svg',
        color: '#0066FF',
    },
    [StepType.sendPhoto]: {
        title: 'Сделать фото',
        type: StepType.sendPhoto,
        image: 'assets/img/task-step/make-photo.png',
        icon: 'assets/img/task-step/icons/photo-icon.svg',
        color: '#FF6852',
    },
    [StepType.autoRecommendedOrder]: {
        title: 'Авто-заказ',
        type: StepType.autoRecommendedOrder,
        image: 'assets/img/task-step/auto-order.png',
        icon: 'assets/img/task-step/icons/order-icon.svg',
        color: '#2ECC71',
    },
    // [StepType.makeOrder]: {
    //     title: 'Сделать заказ',
    //     type: StepType.makeOrder,
    //     image: 'assets/img/task-step/auto-order.png',
    //     icon: 'assets/img/task-step/icons/order-icon.svg',
    //     color: '#2ECC71',
    // },
    // [StepType.training]: {
    //     title: 'Обучение',
    //     type: StepType.training,
    //     image: 'assets/img/task-step/questionnaire.png',
    //     icon: 'assets/img/task-step/icons/education-icon.svg',
    //     color: '#00E0FF',
    // },
    // [StepType.locationConfirmation]: {
    //     title: 'Подтверждение местоположения',
    //     type: StepType.locationConfirmation,
    //     image: 'assets/img/task-step/location-confirmation.png',
    //     icon: 'assets/img/task-step/icons/photo-icon.svg',
    //     color: '#0ABC31',
    // },
    // [StepType.tmcTransfer]: {
    //     title: 'Перемещение ТМЦ',
    //     type: StepType.tmcTransfer,
    //     image: 'assets/img/task-step/location-confirmation.png',
    //     icon: 'assets/img/task-step/icons/arrows-icon.svg',
    //     color: '#FF5254',
    // },
    // [StepType.equipmentCheck]: {
    //     title: 'Проверка оборудования',
    //     type: StepType.equipmentCheck,
    //     image: 'assets/img/task-step/location-confirmation.png',
    //     icon: 'assets/img/task-step/icons/rack-icon.svg',
    //     color: '#3DC1D3',
    // },
    // [StepType.equipmentRequisition]: {
    //     title: 'Заявка по оборудованию',
    //     type: StepType.equipmentCheck,
    //     image: 'assets/img/task-step/location-confirmation.png',
    //     icon: 'assets/img/task-step/icons/application-icon.svg',
    //     color: '#FFD952',
    // },
    unknown: {
        title: 'Неизвестный тип',
        type: 'unknown',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
};

export enum AdditionalSendPhotoType {
    photoCalculateRestOfProduct = 'PHOTO_CALCULATE_REST_OF_PRODUCT',
    photoCheck = 'PHOTO_CHECK',
    photoSign = 'PHOTO_SIGN',
    photoAddress = 'PHOTO_ADDRESS',
    photoFacade = 'PHOTO_FACADE',
    photoEquip = 'PHOTO_EQUIP',
}

export const SendPhotoTypeMap = {
    [AdditionalSendPhotoType.photoCalculateRestOfProduct]: {
        type: AdditionalSendPhotoType.photoCalculateRestOfProduct,
        title: 'Фото остатков',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoCheck]: {
        type: AdditionalSendPhotoType.photoCheck,
        title: 'Фото чека',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoSign]: {
        type: AdditionalSendPhotoType.photoSign,
        title: 'Фото режимной выставки',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoAddress]: {
        type: AdditionalSendPhotoType.photoAddress,
        title: 'Фото адреса',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoFacade]: {
        type: AdditionalSendPhotoType.photoFacade,
        title: 'Фото фасада',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoEquip]: {
        type: AdditionalSendPhotoType.photoEquip,
        title: 'Фото оборудования',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
};

export enum CreateGoalAutoAssignType {
    beforeVisit = 'BEFORE_VISIT'
}

export enum CreateGoalBonusStrategyType {
    fixed = 'FIXED',
    dynamic = 'DYNAMIC',
    disable = 'DISABLE',
}

export enum AdditionalType {
    PHOTO_CALCULATE_REST_OF_PRODUCT = 'PHOTO_CALCULATE_REST_OF_PRODUCT', // Фото вид сверху
    PHOTO_FRONTAL = 'PHOTO_FRONTAL', // Фото витрины
    PHOTO_CHECK = 'PHOTO_CHECK',
    PHOTO_SIGN = 'PHOTO_SIGN',
    PHOTO_ADDRESS = 'PHOTO_ADDRESS',
    PHOTO_FACADE = 'PHOTO_FACADE',
    PHOTO_EQUIP = 'PHOTO_EQUIP',
}

export enum EquipmentType {
    QUANTITY_40 = 40,
    QUANTITY_60 = 60,
}

export enum CountingStrategiesType {
    Q1 = 'Q1', // Количество слотов по производителям
    A2 = 'A2', // Соблюдение матрицы по товарам
    S3 = 'S3' // Поддержание минимального запаса
}

export interface TaskStepResultPayload {
    stepId: number
    taskOutletClientId: number
    comment: string
}
