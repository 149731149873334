import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { SALEPLAN_IMAGES_URL } from '@Env/environment';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { debounceTime, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { Product } from '@Mesh/core/models/product';
import { ProductRecognitionImage } from '../models/recognition';
import { RecognitionMapItem } from './product-recognition-map/product-recognition-map.component';
import { ProductService } from '../../../../core/services/api/saleplan/product.service';
import { ProductsFilter } from '../../../../core/models/internal/products-filter';
import { removeEmptyParams } from '../../../helpers/remove-empty-params';

@Component({
    selector: 'iql-product-recognition',
    templateUrl: './product-recognition.component.html',
    styleUrls: ['./product-recognition.component.scss'],
    animations: [
        trigger('productRecognitionLeft', [
            state(
                'open',
                style({
                    opacity: '*',
                    right: '46vw',
                })
            ),
            state(
                'closed',
                style({
                    opacity: 0,
                    right: '0',
                })
            ),
            transition('open => closed', [animate('0.7s')]),
            transition('closed => open', [animate('0.5s')]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRecognitionComponent implements OnInit, AfterViewInit, OnDestroy {
    recognizeToggle = false;
    allCigarettes: Product[] = [];
    recognizedCigarettes: Product[] = [];
    unrecognizedCigarettes = [];
    activeItem: Product;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    cropperInfo: ImageCroppedEvent;
    indicated = [];
    @ViewChild('productListShowcaseImg', { static: false })
    productListShowcaseImg: any;

    @ViewChild('backgroundImage', { static: false })
    backgroundImage: ElementRef;

    @ViewChild('imageCropper', { read: ElementRef, static: false }) imageCropper: ElementRef<HTMLElement>;

    previewWidth = 650;
    previewHeight = window.innerHeight;
    acceptedPositions = [];
    IMAGES_URL = SALEPLAN_IMAGES_URL;
    isProductSelected = false;
    selectedProduct;
    isLoading = false;
    destroy$ = new Subject();
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
    private dataSub$: Subscription;
    sourceImageUrl: string;
    brandIcons = {
        PMI: 'pmi.svg',
        JTI: 'jti.svg',
        ITG: 'itg.svg',
        DT: 'dt.svg',
    };
    selectRecognizedProducts$: Observable<ProductRecognitionImage>;
    showViewImage = false;
    showProductCart: boolean;
    activeRecognitionCoord: RecognitionMapItem;

    productList: Product[];
    private pageToLoadNext = 0;
    private params: Partial<ProductsFilter> = {
        pageSize: 20,
        addressSapId: this.chatService.addressSapId,
    };
    loading = false;
    recognitionImage: ProductRecognitionImage;

    constructor(
        public chatService: ChatService,
        private productService: ProductService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.chatService.recognizedProducts$.pipe(takeUntil(this.destroy$)).subscribe(findImage => {
            // if (findImage) {
            // this.showViewImage = false;
            this.recognitionImage = findImage;
            this.cdr.markForCheck();
            const materialIds = findImage?.coords.map(cord => cord.materialId);
            const addressSapId = this.chatService.addressSapId;
            this.productService.getProduct({
                materialIds,
                addressSapId,
                pageSize: materialIds?.length || 40,
                ignoreStock: true,
            })
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.recognizedCigarettes = data.content;
                });
            // }
        });
        this.loadProducts();
    }

    getImageUrl(item: any): string {
        const imageUrl = item.productInfo?.imageUrl || item.url;
        if (imageUrl) {
            return this.IMAGES_URL + '/300/' + imageUrl;
        }
    }

    getBrandImageUrl(event: Event, item: any): void {
        (event.target as HTMLImageElement).src = '/assets/img/plans/' +
            (this.brandIcons[item.manufacturerNameId]
                ? this.brandIcons[item.manufacturerNameId]
                : 'top_marki.svg');
    }

    ngAfterViewInit(): void {
        if (this.searchInput && this.searchInput.nativeElement) {
            this.dataSub$ = fromEvent(this.searchInput.nativeElement, 'input')
                .pipe(
                    map(
                        (event: KeyboardEvent) =>
                            (event.target as HTMLInputElement).value
                    )
                )
                .subscribe((value) => this.onSearch(value));
        }
        // if(this.imageCropper?.nativeElement && this.backgroundImage?.nativeElement) {
        //     this.imageCropper.nativeElement.style.height = this.backgroundImage.nativeElement.style.height + 'px';
        //     this.imageCropper.nativeElement.style.width = this.backgroundImage.nativeElement.style.width  + 'px';
        // }
    }

    onSearch(value: string): void {
        // if (value) {
        //     this.productList = this.allCigarettes.filter(
        //         (cigarette) =>
        //             cigarette.materialName
        //                 .toLowerCase()
        //                 .includes(value.toLowerCase()) ||
        //             (cigarette.manufacturerName &&
        //                 cigarette.manufacturerName.toLowerCase() ===
        //                 value.toLowerCase())
        //     );
        // } else {
        //     this.productList = [...this.allCigarettes];
        // }
        if (value) {
            this.params = {
                pageSize: 20,
                addressSapId: this.chatService.addressSapId,
                materialName: value,
            };
        } else {
            this.params = {
                addressSapId: this.chatService.addressSapId,
                pageSize: 20
            };
        }
        this.productList = [];
        this.pageToLoadNext = 0;
        this.loadProducts();
    }

    recognize(item: Product): void {
        this.isProductSelected = true;
        this.recognizeToggle = true;
        this.activeItem = item;
        this.selectedProduct = item;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
        this.cropperInfo = event;
        console.log(this.cropperInfo);
    }

    donePosition(): void {
        this.recognizeToggle = false;
        this.isProductSelected = false;
        this.indicated.push(this.activeItem.id);
        this.recognizedCigarettes.push(this.selectedProduct);
        this.productList = this.productList.filter(
            (cigarette) => cigarette.id !== this.selectedProduct.id
        );
    }

    acceptPosition(): void {
        this.isLoading = true;

        const coordinates = {
            image: this.activeItem.url,
            xmin: this.cropperInfo.imagePosition.x1,
            ymin: this.cropperInfo.imagePosition.y1,
            xmax: this.cropperInfo.imagePosition.x2,
            ymax: this.cropperInfo.imagePosition.y2,
            materialId: this.activeItem.materialId,
            name: this.activeItem.materialName,
        };

        this.chatService.acceptRecognition(coordinates).subscribe((data) => {
            this.isLoading = false;
            // this.calcAcceptedPositionSize();
        });
    }

    // calcAcceptedPositionSize(): void {
    //     this.acceptedPositions.push({
    //         name: this.activeItem.materialName,
    //         width:
    //             this.cropperInfo.cropperPosition.x2 -
    //             this.cropperInfo.cropperPosition.x1,
    //         height:
    //             this.cropperInfo.cropperPosition.y2 -
    //             this.cropperInfo.cropperPosition.y1,
    //         top: this.cropperInfo.cropperPosition.y1 + 4,
    //         left: this.cropperInfo.cropperPosition.x1 + 4,
    //     });
    // }

    positionMissing(): void {
        this.recognizeToggle = false;
        this.isProductSelected = false;
        this.productList = this.productList.filter(
            (cigarette) => cigarette.id !== this.selectedProduct.id
        );
        this.unrecognizedCigarettes.push(this.selectedProduct);
    }

    zoomOut(): void {
        // this.scale -= 0.1;
        // this.transform = {
        //   ...this.transform,
        //   scale: this.scale,
        // };
    }

    zoomIn(): void {
        // this.scale += 0.1;
        // this.transform = {
        //   ...this.transform,
        //   scale: this.scale,
        // };
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    closeRecognition(): void {
        this.chatService.closeRecognition();
    }

    toggleViewImage(): void {
        this.showViewImage = !this.showViewImage;
        this.onClosedProductCart();
    }

    onShowProductCart(element: RecognitionMapItem): void {
        this.activeRecognitionCoord = element;
        this.activeItem = this.recognizedCigarettes.find(item => item.materialId === element.materialId);
        if (!this.activeItem) {
            this.productService.getProduct({
                materialIds: [element.materialId],
                addressSapId: this.chatService.addressSapId,
                pageSize: 1,
                ignoreStock: true,
            })
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.activeItem = data.content[0];
                    this.cdr.markForCheck();
                });
        }
        this.showProductCart = true;
        this.cdr.markForCheck();
    }

    onRemoveCoordinate(): void {
        this.chatService.recognizedProducts$.pipe(
            switchMap(recognize => this.chatService.removeRecognitionResultCoord(recognize.id, this.activeRecognitionCoord.coordId)
                .pipe(map(() => ({
                    ...recognize,
                    coords: recognize.coords.filter(item => item.id !== this.activeRecognitionCoord.coordId)
                })))),
            takeUntil(this.destroy$)
        )
            .subscribe((data) => {
                this.chatService.setRecognizedProducts(data)
                this.onClosedProductCart();
            });
    }

    onClosedProductCart(): void {
        this.showProductCart = false;
    }

    loadProducts(): void {
        if (this.loading) {
            return;
        }
        this.loading = true;

        if (this.pageToLoadNext > 1) {
            this.params = { ...this.params, ...{ page: this.pageToLoadNext } };
        }

        this.productService.getProduct(this.params)
            .pipe(takeUntil(this.destroy$))
            .subscribe(address => {
                this.productList = this.productList ? [...this.productList, ...address.content] : address.content;
                this.loading = false;
                this.pageToLoadNext++;
                this.cdr.markForCheck();
            });
    }

    onScroll(): void {
        this.loadProducts();
    }

}
