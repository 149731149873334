import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogDistributorModalComponent } from './catalog-distributor-modal/catalog-distributor-modal.component';
import { CatalogUnitModalComponent } from './catalog-unit-modal/catalog-unit-modal.component';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [CatalogUnitModalComponent, CatalogDistributorModalComponent],
  exports: [CatalogUnitModalComponent, CatalogDistributorModalComponent],
  entryComponents: [CatalogUnitModalComponent, CatalogDistributorModalComponent],
  imports: [CommonModule, FormsModule, ModalModule.forRoot()],
})
export class CatalogModalModule {}
