import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { IMAGES_URL } from '../../../../../../environments/environment';
import { ProductRecognitionCoord, ProductRecognitionImage } from '../../models/recognition';
export interface RecognitionMapItem {
  name: string;
  coordId: number;
  materialId: number;
  width: number;
  height: number;
  top: number;
  left: number;
}

@Component({
  selector: 'iql-product-recognition-map',
  templateUrl: './product-recognition-map.component.html',
  styleUrls: ['./product-recognition-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductRecognitionMapComponent implements OnInit, OnChanges {
  @ViewChild('backgroundImage', { static: false }) backgroundImage: ElementRef;
  @Input() recognitionImage: ProductRecognitionImage;
  @Output() clickedProduct: EventEmitter<RecognitionMapItem> = new EventEmitter();

  acceptedPositions: RecognitionMapItem[];
  IMAGES_URL = IMAGES_URL;
  oldDimensionsImage: { height: number; width: number; };
  urlImage: string;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.recognitionImage) {
      this.urlImage = IMAGES_URL + this.recognitionImage.originalImage;
      this.cdr.detectChanges();
      this.getImgSize(this.urlImage).subscribe(oldDimensions => {
        this.oldDimensionsImage = oldDimensions;
        const newDimensions = (this.backgroundImage.nativeElement as HTMLElement).getBoundingClientRect();
        this.acceptedPositions = this.getPositionSize(this.recognitionImage.coords, { oldDimensions, newDimensions });
        this.cdr.detectChanges();
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.recognitionImage) {
      const newDimensions = (this.backgroundImage.nativeElement as HTMLElement).getBoundingClientRect();
      this.acceptedPositions = this.getPositionSize(this.recognitionImage.coords,
        {
          oldDimensions: this.oldDimensionsImage,
          newDimensions
        });
      this.cdr.markForCheck();
    }
  }

  onClickPosition(item: RecognitionMapItem): void {
    this.clickedProduct.emit(item);
  }

  private getPositionSize(imagesCoord: ProductRecognitionCoord[], dimensions: { oldDimensions, newDimensions }): RecognitionMapItem[] {
    const { oldDimensions, newDimensions } = dimensions;
    return imagesCoord.map(item => {
      const xmin = this.getNewCoord(item.xmin, oldDimensions.width, newDimensions.width);
      const xmax = this.getNewCoord(item.xmax, oldDimensions.width, newDimensions.width);
      const ymin = this.getNewCoord(item.ymin, oldDimensions.height, newDimensions.height);
      const ymax = this.getNewCoord(item.ymax, oldDimensions.height, newDimensions.height);
      return {
        name: item.name,
        coordId: item.id,
        materialId: item.materialId,
        width: xmax - xmin,
        height: ymax - ymin,
        top: ymin,
        left: xmin,
      };
    });
  }

  private getNewCoord(coordValue: number, oldWidth: number, newWidth: number): number {
    return coordValue * newWidth / oldWidth;
  }

  private getImgSize(imgSrc: string): Observable<{ height: number, width: number }> {
    const img = new Image();
    img.src = imgSrc;
    return new Observable((observer): void => {
      if (imgSrc) {
        img.onload = (): void => {
          observer.next({
            height: img.height,
            width: img.width,
          });
          observer.complete();
        };
        img.onerror = (e): void => {
          observer.error(e);
        };
      } else {
        observer.next({
          height: 0,
          width: 0,
        });
        observer.complete();
      }
    });
  }
}
