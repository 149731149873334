import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DatatableComponent, DataTablePagerComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { IMAGE_URL, SALEPLAN_IMAGES_URL } from '@Env/environment';
import { faEdit, faEye, faPaperPlane, faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ITableInputData, ITableType } from '@Mesh/shared/components/table-v2/models/table';
import { TableColumn, TableColumnType } from '@Mesh/shared/components/table-v2/models/columns';
import { Manufacturer } from '@Mesh/core/models/manufacturer';

@Component({
  selector: 'iql-multi-table',
  templateUrl: './multi-table.component.html',
  styleUrls: ['./multi-table.component.scss']
})
export class MultiTableComponent implements OnChanges {
  readonly url = IMAGE_URL;
  readonly basePath = SALEPLAN_IMAGES_URL;
  categoryId: number;
  brandId: number;
  outletId: number;

  icons = {
      faEye: faEye,
      faEdit: faEdit,
      faTrash: faTrashAlt,
      faPaperPlane: faPaperPlane,
      faSave: faSave,
      faChevronRight: faChevronRight,
      faChevronDown: faChevronDown
  };

  @Input() data: ITableInputData;
  @Input() inMemoryPaging = false;
  @Input() columns: TableColumn[];
  @Input() tableType: ITableType;
  @Input() changings: { row: any, key: string }[] = [];
  @Input() clientSapId = 0;
  @Input() showActivateButton = false;
  @Input() allowGrouping = false;
  @Input() groupingKey: string;
  @Input() planName: string;

  @Output() filterProducts = new EventEmitter<number>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() pushChangings = new Subject<{ row: any, key: string }[]>();
  @Output() innerButtonClick = new Subject<{ row: any, buttonName: string }>();
  @Output() onSend = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onShow = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onActivate = new EventEmitter<any>();
  @Output() onSelectRows = new EventEmitter<any>();
  @Output() onHistory = new EventEmitter<any>();
  @Output() onBasket = new EventEmitter<any>();

  limit = 10;
  tableRowData: any[];
  selected = [];
  scrollBarHorizontal = (window.innerWidth < 1250);
  columnModeSetting = 'force';
  TableType = ITableType;
  TableColumnType = TableColumnType;
  selectedMessage = 'выбрано';
  totalMessage = 'всего';
  rowCount = 0;
  pageSize = 0;
  pageCoef = 0;

  @ViewChild('table', { static: false }) table: DatatableComponent;
  @ViewChild('pager', { static: false }) pager: DataTablePagerComponent;

  constructor() {
      window.onresize = () => this.scrollBarHorizontal = window.innerWidth < 2250;
  }

  private getBrandName(brandId: number): string {
      const brand = this.data.brandList.find(b => b.id === brandId);
      return brand ? brand.brandName : '';
  }
  private getClientName(clientSapId: number): string {
      const client = this.data.clients.content.find(c => c.clientSapId === clientSapId);
      return client ? client.clientName : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
        if (changes.clientSapId && this.tableRowData) {
            this.tableRowData.forEach(r => {
                r.clientSapId = this.clientSapId ? this.clientSapId : 0;
            });
        }
        this.tableRowData = [];
        this.rowCount = 0;
        this.pageSize = 0;
        this.pageCoef = 0;
        this.pager.page = 0;
        if (changes.data && this.data.manufacturers && this.tableType === ITableType.producers) {
            const expandBrands = [];
            this.data.manufacturers.forEach((m: Manufacturer) => {
                m.brands.forEach(b => {
                    b['manufacturerName'] = m.name;
                    b['manufacturerIcon'] = m.image;
                    expandBrands.push(b);
                });

            });
            this.tableRowData = [...expandBrands];
            this.tableRowData.forEach(r => {
                r.name = r.brandInfo && r.brandInfo.name ? r.brandInfo.name : r.brandName;
                r.icon = r.brandInfo && r.brandInfo.icon ? r.brandInfo.icon : '';
            });

        // this.tableRowData = [...this.data.brands.content.filter(b => !!b.brandName.length)];
        // if (this.data.categories) {
        //   const categories = this.data.categories;
        //   this.tableRowData.forEach(r => {
        //     r.name = r.brandInfo && r.brandInfo.name ? r.brandInfo.name : r.brandName;
        //     r.icon = r.brandInfo && r.brandInfo.name ? r.brandInfo.icon : '';
        //     const categoryName = categories.find(c => c.id === r.categoryId)?.categoryName;
        //     r.categoryName = categoryName;
        //     if (this.data.progress) {
        //         const progress = this.data.progress.find(p => p.brandId === r.id);
        //         if (progress) {
        //             r.progress = { current: progress.countOutletTaskSuccess, target: progress.countOutletTask };
        //         }
        //     }
        //   })
        // }
        // if (this.data.plans) {
        //     const plans = this.data.plans.map(p => {
        //         p['active'] = true;
        //     })
        //     this.tableRowData = [...this.data.plans, ...this.tableRowData]
        // }
      }
  }

  onSelect(rows: any[]): void {
      this.onSelectRows.emit(rows);
  }

  onFooterPage(): void {
      //this.pageCoef = (this.pager.page - 1) * 10;
      this.filterProducts.next(this.pager.page - 1);
  }

  onSelectAllClick(event: any): void {
      event.target.checked ? this.selected = [...this.tableRowData] : this.selected = [];
  }

  valueChanged(data: { row: any, key: string }): void {
      if (data.key === 'requestCount') {
          data.row.isBasketEnabled = true;
      }
      data.row.changed = true;
      const isAlreadyChanged = this.changings.find(c => c.row.materialId === data.row.materialId && c.key === data.key);
      if (!isAlreadyChanged) {
          this.changings.push(data);
      }
  }

  onDataChange(row: any): void {
      row.changed = false;
      this.onSend.emit(row);
  }

  onDataEdit(row: any): void {
      this.onEdit.emit(row);
  }

  onDataShow(row: any): void {
      this.onShow.emit(row);
  }

  onDataDelete(row: any): void {
      this.onDelete.emit(row);
  }

  onDataActivate(row: any): void {
      this.onActivate.emit(row);
  }

  getRowClass(row: any): string {
      return row.className
          ? row.className
          : !row.active
              ? 'deactivated'
              : '';
  }

  onInnerButtonClick(row: any, buttonName: string): void {
      this.innerButtonClick.next({ row, buttonName });
  }

  onRowClick(el: any): void {
      if (el.type === 'click') {
          this.rowClicked.emit(el.row);
      }
  }

  onBasketClick(row: any): void {
      this.onBasket.emit(row);
      row.isBasketEnabled = false;
  }

  onHistoryClick(row: any): void {
      this.onHistory.emit(row);
  }

  getGroupRowHeight(group: any, rowHeight: boolean): any {

    return {
        height: group.length * 40 + 'px',
        width: '100%'
      };
  }

  toggleExpandGroup(group: any): void {
      this.table.groupHeader.toggleExpandGroup(group);
  }

  onDetailToggle(event: any): void { }
}
