import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UsersSyncService {
  private trigger = new Subject<void>();

  public triggerObservable = this.trigger.asObservable();

  constructor() { }

  public triggerGetUsers() {
    this.trigger.next();
  }
}