export enum GoalType {
  NEW = 'new',
  EXISTED = 'existed',
}

export enum GoalIdType {
  val = 'val',
  top24SaleVolume = 'top24SaleVolume',
  top24Material = 'top24Material',
  orderAmount = 'orderAmount',
  driveList = 'driveList',
  amount = 'amount',
}

export enum GoalIdTypeLabels {
  val = 'Val',
  top24SaleVolume = 'Top24SaleVolume',
  top24Material = 'Top24Material',
  orderAmount = 'OrderAmount',
  driveList = 'DriveList',
  amount = 'Amount',
}

export enum ScoringMechanicsType {
  BY_SECTOR = 'byProduct',
  BY_PRODUCT = 'bySector',
}

export enum CoefficientValueType {
  ITEM = 'item',
  PERCENT = 'percent',
  BONUS = 'bonus',
}

export enum TargetUnitType {
  ITEM = 'item',
  PRICE = 'price',
}

export enum BonusStrategy {
  FIXED_BONUS_STRATEGY = 'FIXED_BONUS_STRATEGY',
  DYNAMIC_BONUS_STRATEGY = 'DYNAMIC_BONUS_STRATEGY',
}

export enum CoefficientType {
  GOLD = 'Gold',
  SILVER = 'Silver',
  IRON = 'Iron',
}

export interface IMultipliers {
  addressSegment: CoefficientType;
  percentageStep: number;
  multiplier: number;
}
export interface BonusConfigItem {
  id?: number;
  materialId?: number;
  sectorId?: string;
  quant?: number;
}

export interface AddressSegmentBonus {
  id?: number;
  type: CoefficientType;
  value: number;
}
export interface INewGoal {
  name: string;
  description: string;
  step: number;
  strategy: string;
  priorityId: number;
  goldBonus: number;
  silverBonus: number;
  ironBonus: number;
  external: boolean;
  material: boolean;
  money: boolean;
  withVat: boolean;
  image: string;
  addressSegmentBonuses: AddressSegmentBonus[];
  multipliers: IMultipliers[];
  userBonusStrategyConfig: {
    userBonusStrategy: BonusStrategy;
    value: number;
  };
  bonusConfigItems: BonusConfigItem[];
  shortName: string;
  planId: number;
  categoryId: number;
}
