import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OpenedResume, OpenedResumeUpdatePayload, Reason } from './chat-hr-opened-resume';
import { HR_URL } from '@Env/environment';
import { IMeta } from '@Mesh/core/models/pagination';


@Injectable({
  providedIn: 'root'
})
export class ChatHrOpenedResumeApiService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  getOpenedUsers(statusId: string, page: number): Observable<{ data: Array<OpenedResume>, meta: IMeta }> {
    const params = new URLSearchParams();
    params.set('status_id', statusId);
    params.set('has_resume', '1');
    if (page !== undefined) {
      params.set('page', page.toString());
    }
    return this.http.get<{ data: Array<OpenedResume>, meta: IMeta }>(`${HR_URL}/user/search?${params.toString()}`)
      .pipe(
        map((response) => {
          return {
            data: response.data,
            meta: response.meta
          };
        }),
        catchError((error) => {
          console.error('An error occurred while fetching opened resume:', error);
          return of(null);
        })
      );
  }

  getRefusalReasons(): Observable<Reason[]> {
    return this.http.get<any>(`${HR_URL}/refusal-reasons`)
      .pipe(
        map((res: any) => res.data),
        catchError((error) => {
          console.error('Ошибка при получении данных:', error);
          return of([] as Array<any>);
        })
      );
  }

  updateOpenedUser(userId: number, payload: OpenedResumeUpdatePayload): Observable<any> {
    const { refusalReasons, ...otherPayload } = payload;
    const updatedPayload = {
      ...otherPayload,
      ...(refusalReasons ? { refusal_reasons: refusalReasons } : null)
    };
    const url = `${HR_URL}/admin/user/${userId}`;
    return this.http.patch(url, updatedPayload);
  }
}
