import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UserAvatarComponent } from './user-avatar.component';
@NgModule({
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
  ],
  declarations: [UserAvatarComponent],
  exports: [UserAvatarComponent]
})
export class UserAvatarModule { }
