import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IUserData, IUserInfo } from '../../../models/user';
import { ACADEMY_URL, AUTH_URL, HR_URL } from '@Env/environment';
import { LoginInfo } from '@Mesh/core/models/loginInfo';
import * as moment from 'moment';
import { catchError, share } from 'rxjs/operators';
import { AcademyAuth } from '../../../models/academy-auth';

@Injectable({ providedIn: 'root' })
export class AuthService {
  protected token$: BehaviorSubject<string> = new BehaviorSubject(null);

  private get httpOptions(): Object {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return { headers } as Object;
  }

  constructor(private http: HttpClient) {
    this.publishStoredToken();
  }

  get isLoggedIn(): boolean {
    const accessTokenExpires = (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.accessTokenExpires;
    const refreshTokenExpires = (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.refreshTokenExpires;

    return accessTokenExpires && (moment.utc(accessTokenExpires).diff(moment.utc()) >= 5 * 1000 || moment.utc(refreshTokenExpires).diff(moment.utc()) >= 5 * 1000);
  }

  get isPreviewMode(): boolean {
    return !!JSON.parse(localStorage.getItem('isPreview'));
  }

  get user(): IUserData {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.user;
  }

  get accessToken(): string {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.accessToken;
  }

  get token(): string {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.accessToken;
  }

  get refreshToken(): string {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.refreshToken;
  }

  tokenChange(): Observable<string> {
    return this.token$
      .pipe(share());
  }

  publishStoredToken(): void {
    this.token$.next(this.token);
  }

  getToken(loginInfo: LoginInfo): Observable<IUserInfo> {
    return this.http.post(`${AUTH_URL}`, loginInfo, this.httpOptions).map((userInfo: any) => {
      return {
        accessToken: userInfo.access_token,
        refreshToken: userInfo.refresh_token,
        accessTokenExpires: userInfo.accessTokenExpires,
        refreshTokenExpires: userInfo.refreshTokenExpires,
        user: userInfo.user
      } as IUserInfo;
    });
  }

  updateTokens(): Observable<IUserInfo> {
    return this.http.post(`${AUTH_URL}/refresh-token`, { refreshToken: this.refreshToken }, this.httpOptions).map((userInfo: any) => {
      const result = {
        accessToken: userInfo.access_token,
        refreshToken: userInfo.refresh_token,
        accessTokenExpires: userInfo.accessTokenExpires,
        refreshTokenExpires: userInfo.refreshTokenExpires,
        user: userInfo.user
      } as IUserInfo;

      console.log(result);
      return result;
    });
  }

  getTokenAcademy(token: string): Observable<AcademyAuth> {
    return this.http.post<AcademyAuth>(`${ACADEMY_URL}/tokens/convert-from-retail`, { token })
      .pipe(catchError(err => of(null)));
  }

  getTokenHR(token: string): Observable<AcademyAuth> {
    return this.http.post<AcademyAuth>(`${HR_URL}/tokens/convert-from-retail`, { token })
      .pipe(catchError(err => of(null)));
  }

  logout(): void {
    localStorage.clear();
    window.location.assign('');
  }
}
