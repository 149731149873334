import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChatHrVisitDataEditComponent } from "./chat-hr-visit-data-edit/chat-hr-visit-data-edit.component";
import { ChatHrVisitDataLogComponent } from "./chat-hr-visit-data-log/chat-hr-visit-data-log.component";
import { ChatHrVisitDataResumeComponent } from "./chat-hr-visit-data-resume/chat-hr-visit-data-resume.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoadingModule } from "../../../../directives/loading/loading.module";
import { CustomSelectModule } from "../../../../components/custom-select/custom-select.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { VisitDataLogItemComponent } from "./chat-hr-visit-data-log/visit-data-log-item/visit-data-log-item.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { DateTransformPipe } from "./pipes/date-transform.pipe";
import { VisitDataLogTabsComponent } from "./chat-hr-visit-data-log/visit-data-log-tabs/visit-data-log-tabs.component";
import { SwitchCheckedModule } from "@Mesh/shared/components/switch/switch-checked.module";
import { VisitDataUserMoneyItemComponent } from "./chat-hr-visit-data-log/visit-data-user-money-item/visit-data-user-money-item.component";
import { VisitDataTaskPaymentItemComponent } from "./chat-hr-visit-data-log/visit-data-task-payment-item/visit-data-task-payment-item.component";
import { UserDataEditComponent } from "./chat-hr-visit-data-edit/user-data-edit/user-data-edit.component";
import { DraggableScrollModule } from "@Mesh/shared/directives/draggable-scroll/draggable-scroll.module";
import { ChatHrCandidatDocumentsComponent } from "../chat-hr-visit-data/chat-hr-candidat-documents/chat-hr-candidat-documents.component";
import { HatHrCandidatDocumentsTabsComponent } from './chat-hr-candidat-documents/hat-hr-candidat-documents-tabs/hat-hr-candidat-documents-tabs.component';
import { CandidatDocumentsPageItemComponent } from './chat-hr-candidat-documents/candidat-documents-page-item/candidat-documents-page-item.component';
import { DocumentWrapperDraggDirective } from './chat-hr-candidat-documents/document-wrapper-dragg.directive';

@NgModule({
  declarations: [
    ChatHrVisitDataEditComponent,
    ChatHrVisitDataLogComponent,
    ChatHrVisitDataResumeComponent,
    ChatHrCandidatDocumentsComponent,
    VisitDataLogItemComponent,
    DateTransformPipe,
    VisitDataLogTabsComponent,
    VisitDataUserMoneyItemComponent,
    VisitDataTaskPaymentItemComponent,
    UserDataEditComponent,
    HatHrCandidatDocumentsTabsComponent,
    CandidatDocumentsPageItemComponent,
    DocumentWrapperDraggDirective,
  ],
  exports: [
    ChatHrVisitDataEditComponent,
    ChatHrVisitDataLogComponent,
    ChatHrVisitDataResumeComponent,
    ChatHrCandidatDocumentsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    LoadingModule,
    BsDatepickerModule.forRoot(),
    CustomSelectModule,
    SwitchCheckedModule,
    DraggableScrollModule
  ],
})
export class ChatHrVisitDataModule {}
