import { User } from "./user"

export interface VisitDialog {
  type: string
  typeId: number
  id: number
  label: string
  visit: Visit
  message: Message
  attached: Attached
  likes: number
  liked: boolean
  countUnread: number
}

export interface Visit {
  id: number
  userId: number
  user?: User
  vacancyId: number
  statusId: number
  visitDate: string
  taskPayment: number
  paymentBalance: number
  paymentStatus: any
  createdAt: string
  updatedAt: string
  vacancy: Vacancy
}

export interface Vacancy {
  id: number
  isParttime: boolean
  name: string
  positionCategoryId: number
  positionId: number
  regionId: number
  bristolHeadId: number
  bristolDivisionId: any
  cityId: number
  districtId: any
  address: string
  createdAt: string
  updatedAt: string
  city: City
}

export interface City {
  id?: number
  name: string
  createdAt?: string
  updatedAt?: string
}

export interface Message {
  id: number
  text: string
  userId: number
  replyUserId: any
  replyMessageId: any
  type: string
  typeId: number
  readAt: any
  editedAt: any
  createdAt: string
  updatedAt: string
  moderatedAt: any
  attached: Attached
  likes: number
  liked: boolean
}

export interface Attached {
  messages: any[]
  uploads: any[]
}

export interface VisitDialogParams {
  $limit: number
  $skip: number
  statusIds?: number[]
  statusId?: number
  visitDate?: string
  cityId?: number
  userId?: number
}

export enum VisitStatus {
  NEW_VISIT = 0, // Новый визит
  VISIT_TIME_CONFIRMED = 1, // Время визита подтверждено
  REJECTED = 2, // Отклоненный
  FAILED_TO_VERIFY = 3, // Не удалось подтвердить
  CANDIDATE_CONFIRMED_VISIT = 4, // Кандидат подтвердил визит
  CANDIDATE_DID_NOT_SHOW_UP = 5, // Кандидат не явился
  PERFECT_VISIT = 6 // Совершенный визит
}

export const VisitStatusMap = {
  [VisitStatus.NEW_VISIT]: {
    type: VisitStatus.NEW_VISIT,
    title: 'Новый',
    color: '#FBC531',
    fullname: 'Новый визит'
  },
  [VisitStatus.VISIT_TIME_CONFIRMED]: {
    type: VisitStatus.VISIT_TIME_CONFIRMED,
    title: 'Запись подтв.',
    color: '#2ECC71',
    fullname: 'Время визита подтверждено'
  },
  [VisitStatus.REJECTED]: {
    type: VisitStatus.REJECTED,
    title: 'Отклоненный',
    color: '#F64E60',
    fullname: 'Отклонено'
  },
  [VisitStatus.FAILED_TO_VERIFY]: {
    type: VisitStatus.FAILED_TO_VERIFY,
    title: 'Запись не подтв.',
    color: '#F64E60',
    fullname: 'Не удалось подтвердить'
  },
  [VisitStatus.CANDIDATE_CONFIRMED_VISIT]: {
    type: VisitStatus.CANDIDATE_CONFIRMED_VISIT,
    title: 'Визит подтв.',
    color: '#2ECC71',
    fullname: 'Кандидат подтвердил визит'
  },
  [VisitStatus.CANDIDATE_DID_NOT_SHOW_UP]: {
    type: VisitStatus.CANDIDATE_DID_NOT_SHOW_UP,
    title: 'Не явился',
    color: '#F64E60',
    fullname: 'Кандидат не явился'
  },
  [VisitStatus.PERFECT_VISIT]: {
    type: VisitStatus.PERFECT_VISIT,
    title: 'Визит совершен',
    color: '#2ECC71',
    fullname: 'Совершенный визит'
  },
  ['UNKNOWN']: {
    type: 'UNKNOWN',
    title: 'Неизвестный',
    color: '#F64E60',
    fullname: 'Неизвестный'
  },
}

export interface VisitCity {
  id: number;
  name: string;
}

export interface VisitDataItem {
  id: number;
  created_at: string;
  status_id: number;
  user: User;
  user_id: number;
  vacancy: Vacancy;
  vacancy_id: number;
  visit_date: string;
}

export interface VisitJournalEvent {
  id: 0;
  status_id: VisitStatus;
  status_name: string;
  comment: string;
  user_vacancy_id: number;
  created_at: string;
  updated_at: string;
}

export interface UserMoneyRecord {
  id: number;
  user_money: number;
  money: number;
  reason: string;
  created_at: string;
}

export interface TaskPaymentRecord {
  id: number;
  user_vacancy_id: number;
  amount: number;
  payment_balance: number;
  status: number;
  statusName: string;
  created_at: string;
}
