import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatHrOpenedResumeItemComponent } from './chat-hr-opened-resume-list/chat-hr-opened-resume-item/chat-hr-opened-resume-item.component';
import { ChatHrOpenedResumeTabsComponent } from './chat-hr-opened-resume-list/chat-hr-opened-resume-tabs/chat-hr-opened-resume-tabs.component';
import { ChatHrOpenedResumeListComponent } from './chat-hr-opened-resume-list/chat-hr-opened-resume-list.component';
import { ChatHrOpenedResumeDataEditComponent } from './chat-hr-opened-resume-data/chat-hr-opened-resume-data-edit/chat-hr-opened-resume-data-edit.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomSelectModule } from '@Mesh/shared/components/custom-select/custom-select.module';
import { MultiSelectDropdownModule } from '@Mesh/shared/components/multi-select-dropdown/multi-select-dropdown.module';
import { CustomMultiselectModule } from '@Mesh/shared/components/custom-multiselect/custom-multiselect.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ResumeViewComponent } from './chat-hr-opened-resume-data/chat-hr-opened-resume-data-edit/resume-view/resume-view.component';
import { LoadingModule } from '@Mesh/shared/directives/loading/loading.module';
import { DraggableScrollModule } from '@Mesh/shared/directives/draggable-scroll/draggable-scroll.module';



@NgModule({
  declarations: [
    ChatHrOpenedResumeItemComponent,
    ChatHrOpenedResumeTabsComponent,
    ChatHrOpenedResumeListComponent,
    ChatHrOpenedResumeListComponent,
    ChatHrOpenedResumeDataEditComponent,
    ResumeViewComponent
  ],
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    CustomSelectModule,
    MultiSelectDropdownModule,
    CustomMultiselectModule,
    InfiniteScrollModule,
    LoadingModule,
    DraggableScrollModule
  ],
  exports: [
    ChatHrOpenedResumeListComponent,
    ChatHrOpenedResumeDataEditComponent
  ]
})
export class ChatHrOpenedResumeModule { }
