import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Comment } from '../../../../chatAcademy/models/comment';
import { User } from '../../../../chatAcademy/models/user';
@Component({
  selector: 'iql-chat-training-item',
  templateUrl: './chat-training-item.component.html',
  styleUrls: ['./chat-training-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTrainingItemComponent implements OnInit {
  @Input() chatDialog: Comment;
  @Input() currentUser: User;
  @Output() openedDialog = new EventEmitter<any>();

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  get oneMark() {
    let { readAt, createdAt } = this.message;
    return !createdAt || !readAt;
  }

  get twoMarks() {
    let { readAt, createdAt } = this.message;
    return createdAt && readAt;
  }

  get message() {
    return this.chatDialog.message;
  }

  get user() {
    let {
      replyUser,
      user
    } = this.message;
    return replyUser?.id !== this.currentUser.id ? replyUser || user : user || replyUser;
  }

  get mine() {
    let {
      user
    } = this.message;
    return user?.id === this.currentUser.id;
  }

  get isTaskChatDialog() {
    return this.chatDialog.type === 'task-comments';
  }

  get onlyImages() {
    return this.chatDialog.message.text === '' && this.chatDialog.message.attached.uploads.filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif'].indexOf(item.ext) !== -1).length > 0;
  }

  onOpenDialog(): void {
    this.openedDialog.emit()
  }
}
