import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { User } from '../../../../chatHR/models/user';
import { Visit, VisitDialog, VisitStatusMap } from '../../../../chatHR/models/visit-dialog';
import moment from 'moment';

@Component({
  selector: 'iql-chat-hr-visit-item',
  templateUrl: './chat-hr-visit-item.component.html',
  styleUrls: ['./chat-hr-visit-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrVisitItemComponent implements OnInit {
  @Input() visitDialog: VisitDialog;
  @Input() currentUser: User;
  @Output() openedDialog = new EventEmitter<any>();
  @Output() showVisitInfo: EventEmitter<ChatHrVisitItemComponent> = new EventEmitter();

  @ViewChild('infoCandidate', { static: false }) infoCandidate: ElementRef;

  isAnimating = false;
  isShowInfo: boolean;
  visitStatusMap = VisitStatusMap;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  onShowInfo(event: Event) {
    if (!this.isAnimating) {
      this.showVisitInfo.emit(this);
      this.isAnimating = true;
    }
  }

  showInfo() {
    this.isShowInfo = true;
    this.cdr.markForCheck();
  }

  onClosedInfo() {
    if (!this.isAnimating) {
      this.isShowInfo = false;
      this.cdr.markForCheck();
    }
  }

  onOpenDialog(): void {
    this.openedDialog.emit()
  }

  onTransitionEnd(event: Event) {
    this.isAnimating = false;
    setTimeout(() => {
      const isHovered = this.isElementHovered(this.infoCandidate.nativeElement);
      if (!isHovered) {
        this.isShowInfo = false;
        this.cdr.markForCheck();
      }
    }, 10);
  }

  private isElementHovered(element: HTMLElement): boolean {
    const hoveredElements = document.querySelectorAll(':hover');
    return Array.from(hoveredElements).includes(element);
  }


  get visitStatus(): any {
    return this.visitDialog && this.visitDialog.visit?.statusId
      && this.visitStatusMap[this.visitDialog.visit.statusId]
      ? this.visitStatusMap[this.visitDialog.visit.statusId] : this.visitStatusMap['UNKNOWN'];
  }

  get visit(): Visit {
    return this.visitDialog && this.visitDialog.visit;
  }

  get formattedDateOrTime(): string {
    const currentDate = moment();
    const date = moment(this.visitDialog?.message?.createdAt);

    if (currentDate.isSame(date, 'day')) {
      return date.format('HH:mm');
    } else {
      return date.format('DD.MM.YYYY');
    }
  }
}
