import { ChatSource } from "./chat-hr-main-page/chat-hr-main-page.component"

export interface ChatHrMainPageTab {
  title: string
  key: TabsKey
  statusIds: number[]
  source: ChatSource
}

export interface ChatHrMainPageFilterData {
  cityId?: number,
  visitDate?: string,
  statusId?: number,
  name?: string,
  userId?: number
}

export enum TabsKey {
  ALL_USERS_CHAT = 'AllUsersChat',
  CONFIRMED = 'Confirmed',
  NO_SHOW ='NoShow',
  COMPLETED = 'Completed',
  ARCHIVE = 'Archive',
  OPENED = 'Opened',
}