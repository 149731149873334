import { Component, OnInit, ViewChild, Input, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { IMAGE_URL, IMAGES_URL } from '@Env/environment';
import { Subject } from 'rxjs';
import { OrderHistoryInfo } from '@Mesh/core/models/APImodels/saleplan/orders';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Moment } from 'moment';
import moment from 'moment';

interface OrderHistoryBasketInfo extends OrderHistoryInfo {
  expanded: boolean;
}
@Component({
  selector: 'iql-basket-dialog',
  templateUrl: './basket-dialog.component.html',
  styleUrls: ['./basket-dialog.component.scss']
})

export class BasketDialogComponent implements OnInit, OnChanges, OnDestroy {
  readonly url = IMAGE_URL;
  readonly basePath = IMAGES_URL;

  @ViewChild(DaterangepickerDirective, { static: false }) dateRangePickerDirective: DaterangepickerDirective;
  dateRange: { startDate: moment.Moment, endDate: moment.Moment } = { startDate: undefined, endDate: undefined };

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  @Input() showEvent: boolean;
  @Input() orders: OrderHistoryInfo[];

  @Output() updateOrderInfo = new EventEmitter<number>();
  @Output() dateChange = new EventEmitter<{ startDate: Moment, endDate: Moment }>();
  LoadingStatus = LoadingStatus;
  unsubscribe$ = new Subject<any>();
  ordersData: OrderHistoryBasketInfo[] = [];
  sum = 0;

  constructor() { }

  ngOnInit(): void {
    this.dateRange.startDate = moment.utc().startOf('day');
    this.dateRange.endDate = moment.utc().endOf('day');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showEvent && changes.showEvent.currentValue !== undefined) {
      this.mdSlideUp.show();
    }

    if (changes.orders) {
      const orders: OrderHistoryBasketInfo[] = JSON.parse(JSON.stringify(changes.orders.currentValue));
      this.ordersData = orders?.map<OrderHistoryBasketInfo>(o => ({...o, expanded: this.ordersData?.find(od => od.docNumber === o.docNumber)?.expanded})) ?? [];
      this.sum = this.ordersData?.reduce((s, e) => s + e.cost, 0) ?? 0;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  closeModal(): void {
    this.mdSlideUp.hide();
  }

  onExpandOrder(order: OrderHistoryBasketInfo): void {
    order.expanded = !order.expanded;
    this.updateOrderInfo.emit(order.docNumber);
  }

  onDateChanged(date: { startDate: Moment, endDate: Moment }): void {
    this.dateChange.emit(date);
  }

}
