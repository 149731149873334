import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { SALEPLAN_URL, USER_URL } from '@Env/environment';
import { EnableOutletGoal, OutletGoalsModel } from '@Mesh/core/models/APImodels/saleplan/saleplans';
import { Outlet, OutletData, OutletFilterCriteria, OutletSalesOrg } from '@Mesh/core/models/outlet';

@Injectable({
  providedIn: 'root'
})

export class OutletService {

  constructor(private readonly http: HttpClient) { }

  outletGoalOnOff(data: EnableOutletGoal): Observable<{}> {
    return this.http.post<boolean>(`${SALEPLAN_URL}/sale-plan/disable`, data);
  }

  getAllOutletGoals(): Observable<OutletGoalsModel> {
    // const data = {
    //   'offset': 0,
    //   'sort': {
    //     'sorted': true,
    //     'unsorted': true,
    //     'empty': true
    //   },
    //   'pageNumber': 0,
    //   'pageSize': 0,
    //   'paged': true,
    //   'unpaged': true
    // };
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });


    return this.http.get<OutletGoalsModel>(`${SALEPLAN_URL}/sale-plan/disable`, { headers: reqHeader });
  }

  getOutlet(addressSapId: string): Observable<Outlet> {
    return this.http.get<Outlet>(`${USER_URL}/address/${addressSapId}?withUsers=false`);
  }

  getOutlets(param: OutletFilterCriteria): Observable<OutletData> {
    let params = new HttpParams();
    if (param.pageNumber) {
      params = params.append('page', `${param.pageNumber}`);
    }
    if (param.pageSize) {
      params = params.append('size', `${param.pageSize}`);
    }
    if (param.clientSapId) {
      params = params.append('clientSapId', `${param.clientSapId}`);
    }
    if (param.addressNamePattern) {
      params = params.append('addressNamePattern', `${param.addressNamePattern}`);
    }
    if (param.segmentName) {
      params = params.append('segmentName', `${param.segmentName}`);
    }
    if (param.ids) {
      params = params.append('ids', `${param.ids}`);
    }
    // if (param.extendedFormat) {
    //   params = params.append('extendedFormat', `${param.extendedFormat}`);
    params = params.append('extendedFormat', `true`);
    // }
    if (param.onlyActive) {
      params = params.append('onlyActive', `${param.onlyActive}`);
    }
    if (param.sortByStreet) {
      params = params.append('sortByStreet', `${param.sortByStreet}`);
    }
    if (param.sort) {
      params = params.append('sort', `${param.sort}`);
    }
    if (param.direction) {
      params = params.append('direction', `${param.direction}`);
    }
    return this.http.get<OutletData>(`${USER_URL}/address`, { params });
  }

  findAllSales(): Observable<OutletSalesOrg> {
    return this.http.get<OutletSalesOrg>(`${USER_URL}/address/sales-orgs`);
  }
}
