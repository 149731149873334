import { User } from '../models/user';
import { ChatLikesService } from './chat-likes.service';
import { ChatSettingsService } from './chat-settings.service';
import { FeathersService } from './feathers.service';
import { MessagesService } from './messages.service';
import { SoundService, Sounds } from './sound.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { ChatMessage } from '../models/chat-message';
import { AcademyUserStateService } from '../../../../core/services/api/auth/academy-user-state.service';
import { DialogUserParams } from '../models/chat';


export class ChatPager {
  page?: number;
  skip?: number;
  take?: number;
}
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  updateDialogs = new EventEmitter();
  userChatSubscription$: Subject<Observable<ChatMessage[]>> = new Subject;

  constructor(
    private feathers: FeathersService,
    private academyUserStateService: AcademyUserStateService,
    private messagesService: MessagesService,
    private chatSettings: ChatSettingsService,
    private chatLikesService: ChatLikesService,
    private soundService: SoundService,
  ) {

  }
  user: User;

  getUser(userId) {
    return this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
      .service('users')
      .watch({
        idField: 'id',
        listStrategy: 'never'
      })
      .get(userId);

  }

  getTaskUser(id: number) {
    return this.feathers
      .service('task-user')
      .get(id);
  }

  getModule(type: string, id: number) {
    return this.feathers
      .service('users-chat/module/' + type)
      .get(id);
  }

  getUsers({
    page = 0,
    omit_ids = [],
    searchQuery = null
  }) {
    let limit = 20;

    let query = {
      /* $sort: {
        name: 1
      }, */
      // $select: ['id', 'name', 'patronymic', 'positionId', 'birthPlace', 'avatarId', 'surname'],
      // readed: false
      $limit: limit,
      $skip: page * limit,
      name: {
        $ne: ""
      },
      /* id: {
        $nin: omit_ids,
      }, */
      /* dismissalDate: {
        $in: [ null ]
      } */

    };
    if (searchQuery) {
      query['$like'] = searchQuery;
    }

    return this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
      .service('users')
      .watch({
        idField: 'id',
        listStrategy: 'never'
      })
      .find({
        query
      });

  }

  getUsersDialogs(params: DialogUserParams): Observable<{ total: number; data: any[] }> {
    return this.feathers.service('users-chat/list')
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({ query: params })
      .pipe(
        catchError(err => {
          console.error(`users-chat/list return error: ${err.message}`);
          return of({ total: 0, data: [] });
        }),
        map(({ total, data }) => ({ total, data })
        ));
  }


  getChatCommunity({
    type
  }) {

    return new Observable(observer => {
      let community;
      this.feathers.service('messages/:type/:typeId').on('created', msg => {
        if (community && community.type === type && community.info.id === msg.typeId) {
          community.messages.pop();
          community.messages.unshift(msg);
          observer.next(community = {
            ...community
          });
        }
      });

      this.academyUserStateService.onChangeUser()
        .pipe(switchMap(user => {
          this.user = user;

          if (!this.user[type]) return of(null);
          return this.getCommunity({
            type,
            type_id: user[type].id,
            limit: 2
          }).pipe(
            map(messages => ({
              type,
              info: user[type],
              messages
            }),
              first(),
              // catchError(err => of(null))
            )
          );
        })).subscribe(_community => {
          community = _community;
          observer.next(community);
        });
    });


  }


  getCommunity({
    type,
    type_id,
    page = 0,
    limit = 100
  }: any) {

    return this.feathers
      .service(`messages/${type}/${type_id}`)
      .watch({
        idField: 'id',
        listStrategy: 'never'
      })
      .find({
        query: {
          $limit: limit,
          $skip: page * limit,
          // participant: user_id
          $sort: {
            createdAt: -1
          }
        }
      }).pipe(
        map(({ data }) => data)
      );
  }

  getAvailableDialogs({
    page = 0,
    take = 10,
    skip = 0
  }: ChatPager = {}): Observable<{ total: number, data: any[] }> {
    const $limit = take;
    const $skip = skip;
    this.messagesService.findChats({ type: 'all', $limit, $skip });
    return this.messagesService.subjects.getObs('users-chat/list');
  }

  togglePin(dialog, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.togglePin(dialog, type, typeId, flag);
  }

  toggleSound(dialog, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.toggleSound(dialog, type, typeId, flag);
  }

  removeDialog(dialog, type: string, typeId: number): any {
    return this.chatSettings.removeDialog(dialog, type, typeId);
  }

  toggleLike(type: string, typeId: number, messageId: number) {
    this.soundService.play(Sounds.CHAT_MESSAGE_LIKED);
    this.chatLikesService.toggleLike(type, typeId, messageId);
  }


}
