import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener, Input
} from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  pressing: boolean;
  longPressing: boolean;
  timeout: any;
  interval: any;

  @Input('appLongPress') pressTime: number = 500;

  @Output()
  onPress = new EventEmitter();

  @Output()
  onLongPress = new EventEmitter();

  @Output()
  onLongPressing = new EventEmitter();

  @Output()
  onEndLongPress = new EventEmitter();

  @HostBinding('class.press')
  get press() {
    return this.pressing;
  }

  @HostBinding('class.longpress')
  get longPress() {
    return this.longPressing;
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    this.pressing = true;
    this.longPressing = false;
    this.onPress.emit(event);
    this.timeout = setTimeout(() => {
      this.longPressing = true;
      this.onLongPress.emit(event);
      this.interval = setInterval(() => {
        this.onLongPressing.emit(event);
      }, 50);
    }, this.pressTime);
  }

  @HostListener('touchend')
  @HostListener('mouseup')
  @HostListener('mouseleave')
  endPress() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
    this.longPressing = false;
    this.pressing = false;
    this.onEndLongPress.emit();
  }
}
