import { Bonus, Strategy } from './bonus';

export interface SalePlan {
  planName: string;
  quant: number;
  plan_id: number;
  image?: string;
  planId: number;
  planProgress?: PlanProgress;
  progressColor?: number;
  predictColor?: number;
  disable?: SalePlanEnable;
  disableOn?: boolean;
  bonus?: Bonus;
  predictBonus?: Bonus;
}

export const TargetNamesRus = {
  [Strategy.val]: 'пач.',
  [Strategy.driveList]: 'пач.',
  [Strategy.amount]: 'руб.',
  [Strategy.orderAmount]: 'руб.',
  [Strategy.top24SaleVolume]: 'пач.',
  [Strategy.top24Material]: 'sku'
};

export interface SalePlanEnable {
  id: number;
  planId: number;
  strategy: string;
  startAt: string;
  finishAt: string;
  addressSapId: number;
  clientSapId: number;
}

export interface PlanProgress {
  totalElement: number;
  totalQuant: number;
  totalAmount: number;
  addressSapId: number;
  planName: string;
  planId: number;
  requireQuant: number;
}

export interface PlanProgressMaterial {
  planId: number;
  planName: string;
  addressSapId: number;
  requiredElement: number;
  delivQuantity: number;
  requiredQuantity: number;
  achievedQuantity: number;
  delivAmount: number;
  delivNetAmount: number;
  achievedElement: number;
  successPlan: boolean;
}

export interface PlanProgressConfig {
  addressSapId: number;
  delivAmount: number;
  delivNetAmount: number;
  delivQount: number;
  isMoney: boolean;
  planId: number;
  planName: string;
  quantRequire: number;
  totalElement: number;
  progressColor?: number;
}

export interface EnableOutletGoal {
  clientSapId?: number;
  addressSapId: number;
  planId: number;
  startAt: string;
  finishAt: string;
}

export interface OutletGoalsModel {
  offset: 0;
  'sort': {
    'sorted': true,
    'unsorted': true,
    'empty': true
  };
  'pageNumber': 0;
  'pageSize': 0;
  'paged': true;
  'unpaged': true;
}
