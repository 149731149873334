import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { IUserData } from '../../../models/user';

@Component({
  selector: 'iql-user-header-info',
  templateUrl: './user-header-info.component.html',
  styleUrls: ['./user-header-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserHeaderInfoComponent implements OnInit {
  @Input() user: IUserData;
  @Input() userRoleType: string;
  @Output() logout = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  onClickLogout(): void {
    this.logout.emit();
  }
}
