import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iql-custom-multiselect',
  templateUrl: './custom-multiselect.component.html',
  styleUrls: ['./custom-multiselect.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomMultiselectComponent),
    multi: true
  }]
})
export class CustomMultiselectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  value: string[];
  @Input()
  set optionData(options: { [key: string]: string }[]) {
    this.options = options || [];
    if (this.value) {
      this.patchData(this.value);
    }
    this.cdr.markForCheck();
  }
  @Input() idKey: string = 'id';
  @Input() nameKey: string = 'name';
  @Input() placeholder = '―';
  @Input() showSearch: boolean = false;
  @Output() outputData: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() scrolledBottom: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchChange: EventEmitter<any> = new EventEmitter<any>();
  options: { [key: string]: string }[] = [];
  selectedItems: { [key: string]: string } = {};
  dropdownActive: boolean = false;
  disabled: boolean = false;
  searchQuery: FormControl = new FormControl(null);
  private unsubscribe$ = new Subject<void>();

  onChange = (value: any) => { };
  onTouched = () => { };

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.searchQuery.valueChanges
      .pipe(debounceTime(700),
        // distinctUntilChanged(),
        takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.searchChange.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleHandle(event?: Event): void {
    if (this.disabled) { return; }
    // if (event) {
    //   event.stopPropagation();
    // }
    this.dropdownActive = !this.dropdownActive;
  }

  dropdownClosed(): void {
    this.dropdownActive = false;
    this.searchQuery.reset(null, { emitEvent: false });
  }

  dropdownOpen(): void {
    this.dropdownActive = true;
  }

  select(data: { [key: string]: string }): void {
    if (this.selectedItems[data[this.idKey]]) {
      delete this.selectedItems[data[this.idKey]];
      this.selectData();
      return;
    }
    this.selectedItems[data[this.idKey]] = data[this.nameKey];
    this.selectData();
    this.searchQuery.reset(null, { emitEvent: false });
    // this.updateLayoutWithMultipleMode();
  }

  onRemoveSelect(data: { key: string }, event: Event) {
    event.stopPropagation();
    if (this.selectedItems[data.key]) {
      delete this.selectedItems[data.key];
      this.selectData();
    }
  }

  delete(): void {
    this.selectedItems = {};
    this.outputData.emit([]);
    this.updateValue([]);
  }

  selectData(): void {
    const data = Object.keys(this.selectedItems);
    this.outputData.emit(data);
    this.updateValue(data);

    // this.dropdownClosed();
  }

  writeValue(outsideValue: string[]): void {
    this.value = outsideValue
    if (outsideValue) {
      this.patchData(outsideValue);
    }
    this.updateValue(outsideValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateValue(insideValue: string[]): void {
    this.onChange(insideValue);
    this.onTouched();
  }

  private patchData(selectedValue: string[]): void {
    if (this.options.length) {
      if (!Array.isArray(selectedValue)) {
        const option = this.options.find(item => String(item[this.idKey]) === String(selectedValue));
        if (!!option) {
          this.selectedItems = {
            [option[this.idKey]]: option[this.nameKey]
          };
        }
      } else {
        selectedValue
          .forEach(item => {
            const option = this.options.find(
              (opt) => String(opt[this.idKey]) === String(item)
            );
            if (option) {
              this.selectedItems[option[this.idKey]] = option[this.nameKey];
            }
          });
      }
    }
  }

  get isEmptySelectedItems() {
    return (this.selectedItems && (Object.keys(this.selectedItems).length === 0));
  }

  onScroll(): void {
    this.scrolledBottom.emit();
  }

  onSearchClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.dropdownActive) {
      this.dropdownActive = true;
      this.cdr.markForCheck();
    }
  }
}

