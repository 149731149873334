import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

export interface VisitDataLogTab {
  title: string
  key: LogTabsKey
}

export enum LogTabsKey {
  EVENTS = 'Events',
  TASK_PAYMENT = 'TaskPayment',
  WORK_PAYMENT = 'WorkPayment',
}

@Component({
  selector: 'iql-visit-data-log-tabs',
  templateUrl: './visit-data-log-tabs.component.html',
  styleUrls: ['./visit-data-log-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitDataLogTabsComponent {
  @Output() changeTab = new EventEmitter<LogTabsKey>();

  tabsList: VisitDataLogTab[] =
    [
      {
        title: 'Оплата за тестовое задание',
        key: LogTabsKey.TASK_PAYMENT
      },
      {
        title: 'Выплаты за рабочие смены',
        key: LogTabsKey.WORK_PAYMENT
      },
    ];

    currentTabKey: LogTabsKey = LogTabsKey.EVENTS;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  onClickTab(selectedTab: VisitDataLogTab): void {
    if (this.currentTabKey === selectedTab.key) {
      this.currentTabKey = LogTabsKey.EVENTS;
    } else {
      this.currentTabKey = selectedTab.key;
    }

    this.changeTab.emit(this.currentTabKey);
    this.cdr.markForCheck();
  }

}
