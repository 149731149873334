import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'iql-chat-dialog-tab',
    templateUrl: './chat-dialog-tab.component.html',
    styleUrls: ['./chat-dialog-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatDialogTabComponent implements OnInit {
    // tslint:disable-next-line:no-input-rename
    @Input('tabTitle') title: string;

    @Input() active = false;

    @Input() tabTooltip: string;

    @Input() countMessages: number;

    @Input() id: number;

    @Input() type: string;

    @Input() route: string;

    constructor() { }

    ngOnInit(): void { }
}
