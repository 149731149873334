import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { ChatType } from '../chat-type';

@Injectable({
  providedIn: 'root'
})
export class ChatTypeSwitchService {
  defaulCount = {
    [ChatType.EMPLOYEE_TRAINING]: 0,
    [ChatType.SALES_AGENTS]: 0,
    [ChatType.SALES_POINTS]: 0,
    [ChatType.RESPONSES_JOB]: 0
  }
  private chatTypeState$ = new BehaviorSubject<ChatType>(ChatType.SALES_POINTS);
  private countNewMessage$ = new BehaviorSubject<{ [x: string]: number }>(this.defaulCount);

  constructor() { }

  setChatType(count: ChatType): void {
    this.chatTypeState$.next(count);
  }

  onChangeChatType(): Observable<ChatType> {
    return this.chatTypeState$.pipe(share<ChatType>());
  }

  setNotifications(type: ChatType, count: number): void {
    const rawCount = this.countNewMessage$.getValue();
    const data = {
      ...rawCount,
      [type]: count,
    }
    this.countNewMessage$.next(data);
  }

  onChangeNotifications(): Observable<{ [x: string]: number; }> {
    return this.countNewMessage$.pipe(share());
  }
}
