import { UserMoneyRecord } from '@Mesh/shared/modules/chatHR/models/visit-dialog';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'iql-visit-data-user-money-item',
  templateUrl: './visit-data-user-money-item.component.html',
  styleUrls: ['./visit-data-user-money-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitDataUserMoneyItemComponent {
  @Input() item: UserMoneyRecord;

}
