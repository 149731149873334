import { Comment } from '../models/comment';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import * as RecordRTC from 'recordrtc';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, flatMap, takeUntil } from 'rxjs/operators';
import { ChatMessageQuote } from '../models/chat-message-quote';
import { User } from '../models/user';
import { ChatService } from './../service/chat.service';
import { MessagesService } from './../service/messages.service';
import { SoundService } from './../service/sound.service';
import { ChatDialogService, ChatDialogType, ITypingUser, ModuleTypes, ResponseType } from './chat-dialog.service';
import { AcademyUserStateService } from '../../../../core/services/api/auth/academy-user-state.service';
import { HRUserStateService } from '../../../../core/services/api/auth/hr-user-state.service';
import { ChatHrVisitApiService } from '../../chat/chat-hr/chat-hr-visit-data/chat-hr-visit.service';
import { VisitDataItem, VisitDialog } from '../models/visit-dialog';
import { ChatUserInfo } from '../models/chat-user-info';

declare var StereoAudioRecorder: any;
export const FORWARDED_MESSAGE = `«Пересланное сообщение»`;

interface VisitDialogInfo {
  dialog: VisitDialog,
  type?: ChatDialogType,
  info: any, messages: any[]
}

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDialogComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  u$ = new Subject;

  // _record: User | { type: ChatDialogType, info: any, messages: any[] };
  _record: ChatUserInfo | VisitDialogInfo;
  _dialog: { type: string, typeId: any, task?: any, module?: any };
  @ViewChild(NgxDropzoneComponent, { static: false }) dropzone: NgxDropzoneComponent;
  @ViewChildren('chatList', { read: ElementRef }) chatListRefs: QueryList<ElementRef>;

  _scrollNew = 0;
  firstNotLoaded = false;
  stream;
  record$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dialog$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: -1
  };

  get scrollNew() {
    return this._scrollNew;
  }

  set scrollNew(value) {
    if (this.isUserNearBottom() || value === 0) {
      this._scrollNew = value;
    }
  }

  @Input()
  set record(value) {
    this._record = value;
    this.record$.next(value);
  }

  get record() {
    return this._record;
  }

  @Input()
  set dialog(value) {
    this._dialog = value;
    this.dialog$.next(value);
  }

  get dialog() {
    return this._dialog;
  }

  @Output('onBack') onBack = new EventEmitter();
  @Output('onForward') onForward = new EventEmitter();
  _searchQuery: string = null;
  communitiesSwiperIndex = 0;
  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    // spaceBetween: 16,
    slidesPerView: 'auto',
    freeMode: true,
    preloadImages: true,
    pagination: false,
    centeredSlides: false,
  };
  guild$;
  alliance$;
  clan$;
  shop$;
  scrolledToBottom = false;
  loading;
  recording;
  newMessageIndex = -1;
  @ViewChildren(SwiperDirective) swiperViewes: QueryList<SwiperDirective>;
  @ViewChild(SwiperDirective, { static: false }) swiper: SwiperDirective;


  spinner = SPINNER;
  LOADER_ID = 'chat-dialog-loader';
  current_user: User;
  messages$;
  dialogs$;
  dialogs;
  messages;
  lastIndex;
  page = 1;
  loadOnScroll = false;
  loadImage = false;
  loadMessagesCount = {};
  @ViewChildren('messages') messagesQuery: QueryList<any>;
  @ViewChild('messagesWrapper', { static: false }) messagesWrapper: PerfectScrollbarDirective;
  @ViewChild('messagesWrapper', { static: false }) infiniteScrollEl: ElementRef;
  @ViewChild(InfiniteScrollDirective, { static: false }) infiniteScroll: InfiniteScrollDirective;
  @ViewChild('messageInput', { static: false }) messageInput: ElementRef;
  @ViewChild('chatHistoryWrapper', { static: false }) chatHistoryWrapper: ElementRef;
  private _stickyHeader: boolean = false;
  recorder;

  userMessage;
  editingMessage: Comment = null;
  quotingMessage: Comment = null;
  forwardingMessage: Comment = null;
  dzIsHidden = true;
  first = true;
  hide = true;
  typingUsers$;
  allTypingUsers: ITypingUser[] = [];
  currentTypingUsers: ITypingUser[] = [];
  visitDataItem: VisitDataItem;
  dataForDialogVisitHeader: any;

  get stickyHeader(): boolean {
    return this._stickyHeader;
  }

  get searchQuery() {
    return this._searchQuery;
  }

  set searchQuery(value) {
    this._searchQuery = value;
  }

  set stickyHeader(value: boolean) {
    this._stickyHeader = value;
  }

  get chatStatus() {
    switch (this.chatType) {
      case 'user':
        return this.chatRecord?.status?.online ? 'Онлайн' : 'Офлайн';
      default:
        return '';
    }
  }

  typingSubject = new Subject<string>();

  onKeyUp($event) {
    // this.soundService.play(Sounds.CHAT_TEXT_TYPING);
    this.typingSubject.next($event.target.value);
  }

  emitTypingEvent(textValue) {
    if (this.dialog && this.current_user && textValue !== '') {
      const message = this.messages.length ? this.messages[0] : {};
      const getReplyUserId = () => {
        const filtered = [message.userId, message.replyUserId].filter(id => id !== this.current_user.id);
        return filtered.length ? filtered[0] : this.current_user.id;
      };
      const typingData: ITypingUser = {
        type: this.dialog.type,
        typeId: this.dialog.typeId,
        replyUserId: getReplyUserId(),
        fullname: this.current_user.full_name
      };
      this.chatDialogService.setTypingStatus(typingData);
    }
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      if (this.messageInput && this.messageInput.nativeElement && !this.isTouchDevice()) {
        this.messageInput.nativeElement.focus();
      }
    });
    this.swiperViewes.changes.pipe(
      takeUntil(this.u$)
    ).subscribe(() => {
      this.swiperViewes.forEach(item => item.update());
      //this.swiper.update();
    });
    // console.log(this.infiniteScrollEl);
    // this.scrollWithDelay();
    // this.messagesQuery.changes.pipe(first()).subscribe(this.scrollWithDelay.bind(this));

    this.typingSubject
      .pipe(takeUntil(this.u$), debounceTime(2200), distinctUntilChanged())
      .subscribe(textValue => this.emitTypingEvent(textValue));
  }

  get isEdge() {
    return navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
  }

  get audios() {
    return this.files.filter(file => file.name.indexOf('audio') !== -1);
  }

  get others() {
    return this.files.filter(file => file.name.indexOf('audio') === -1);
  }

  get isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  getExt(file) {
    return file.name.split(".").pop();
  }

  getUrl(file) {
    return URL.createObjectURL(file);
  }

  async startAudioRecord() {
    navigator.mediaDevices.getUserMedia({
      audio: true,
    }).then(stream => {
      this.stream = stream;
    });
    if (this.stream) {
      const options: any = {
        type: 'audio',
        mimeType: 'audio/wav',
        numberOfAudioChannels: this.isEdge ? 1 : 2,
        checkForInactiveTracks: true,
        bufferSize: 16384
      };
      if (this.isSafari || this.isEdge) {
        options.recorderType = RecordRTC.StereoAudioRecorder;
      }

      if (navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
        options.sampleRate = 48000; // or 44100 or remove this line for default
      }

      if (this.isSafari) {
        options.sampleRate = 44100;
        options.bufferSize = 4096;
        options.numberOfAudioChannels = 2;
      }

      if (this.recorder) {
        this.recorder.destroy();
        this.recorder = null;
      }
      this.recorder = new RecordRTC.RecordRTCPromisesHandler(this.stream, options);
      console.log(this.recorder);
      await this.recorder.startRecording();
      this.recording = true;
      this.cdr.detectChanges();
    }
  }

  async stopAudioRecord() {
    if (this.recorder) {
      await this.recorder.stopRecording();
      const blob = await this.recorder.getBlob();
      if (this.recorder) {
        this.recorder.destroy();
        this.recorder = null;
      }
      blob.name = `audio${this.files.length + 1}`;
      console.log('blob', blob);
      this.files.push(blob);
      this.recording = false;
      this.cdr.detectChanges();
    }
  }

  scrollWithDelay() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  imagesLoaded(id) {
    this.loadMessagesCount[id] = true;
    console.log('firstTry:' + this.first, { ...this.chatListRefs });
    if (this.messages[this.messages.length - 1].id === id && this.isUserNearBottom()) {
      if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
        this.scrollToBottom();
      }
    }
    if (this.first) {
      console.log('first:' + this.first, this.loadMessagesCount, this.messages);
      if (Object.keys(this.loadMessagesCount).length === this.messages.length) {
        if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
          this.scrollToBottom();
          this.first = false;
        } else {
          this.firstNotLoaded = true;
        }
      }
    }

    console.log('scroll:' + this.scrollNew);
    if (this.scrollNew > 0 && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      console.log('scrollToBottom');
      this.scrollToBottom();
      this.scrollNew--;
    }
    /*if (this.lastIndex && this.chatListRefs && this.lastIndex !== (this.chatListRefs.length - 1) && this.chatListRefs.toArray()[this.lastIndex]) {
      console.log('scrollToIndex:' + this.lastIndex);
      this.scrollToIndex(this.lastIndex);
      this.lastIndex = null;
      this.loadOnScroll = false;
    }*/
  }

  scrollToBottom() {
    try {
      // this.messagesWrapper.nativeElement.scrollIntoView(true);
      console.log(this.chatListRefs);
      if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
        console.log(this.chatListRefs.last);
        this.zone.runOutsideAngular(() => {
          //this.chatListRefs.last.nativeElement.scrollIntoView({block: 'end', behavior: 'auto', inline: 'start'});
        });
        this.hide = false;
        this.cdr.detectChanges();
      }
      /*const el = this.messagesWrapper.nativeElement as Element;
      el.scrollTop = el.scrollHeight;*/
      // window.scrollTo(0, 1);
    } catch (err) {
    }
  }


  isMobileSafari() {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  edit(message: Comment) {
    this.editingMessage = message;
    this.quotingMessage = null;
    this.userMessage = message.text;
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.check();
  }

  remove(message: Comment) {
    this.ngxService.startLoader(this.LOADER_ID);
    this.chatDialogService.removeMessage(message)
      // @ts-ignore
      .finally(() => this.ngxService.stopLoader(this.LOADER_ID));
  }

  forward(message: Comment) {
    this.forwardingMessage = message;
    this.check();
  }

  forwardTo(record: User | { type: ChatDialogType, info: any, messages: any[] }, dialog: Comment) {

    let id = null,
      [mine_id, their_id] = dialog?.participants || [];
    //id = typeof their_id !== 'undefined' ? their_id : user.id;

    if (!this.forwardingMessage) return;

    const text = FORWARDED_MESSAGE;

    /*if (this.forwardingMessage.text === FORWARDED_MESSAGE) {
      text = this.forwardingMessage.attached.messages[0].message.text;
    }*/

    const type = record.hasOwnProperty('type') ? 'messages' : 'users-chat';

    this.chatDialogService.forwardMessage({
      text,
      record,
      dialog,
      type,
      attached: {
        messages: [this.forwardingMessage],
        uploads: []
      }
    }).pipe(
      first()
    ).subscribe(() => {
      this.forwardingMessage = null;
      this.ngxService.stopLoader(this.LOADER_ID);
      //this.chatService.updateDialogs.emit();
      this.onForward.emit(record);
      this.check();
    });
  }

  onTouch($event) {
    this.zone.runOutsideAngular(() => {
      if (this.messageInput) {
        this.messageInput.nativeElement.blur();
      }
    });
  }

  quote(message: Comment) {
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.quotingMessage = message;
    this.editingMessage = null;
    this.userMessage = '';
    this.check();
    console.log(this.quotingMessage);
  }

  scrollToQuoted(quote: ChatMessageQuote) {
    // TODO: provide scroll to quoted message and highlight it
  }

  get chatRecord() {
    if (this.record.hasOwnProperty('type')) {
      // @ts-ignore
      return this.record.info;
    } else {
      return this.record;
    }
  }

  scrollToIndex(index) {
    if (this.chatListRefs) {
      const arrChatList = this.chatListRefs.toArray();
      if (index === (arrChatList.length - 1)) return false;
      if (arrChatList[index]) {
        this.zone.runOutsideAngular(() => {
          //this.messagesWrapper.nativeElement.scrollTop = arrChatList[index].nativeElement.offsetTop;
        });
        return true;
      }
    }
  }

  ngAfterViewChecked(): void {
    console.log('parent - 3');
    if (this.scrollNew > 0 && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      console.log('scrollToBottom');
      this.scrollNew--;
    }
    if (this.firstNotLoaded && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      this.firstNotLoaded = false;
    }
    if (this.lastIndex && this.chatListRefs && this.messagesWrapper) {
      if (this.scrollToIndex(this.lastIndex)) {
        this.lastIndex = null;
        this.loadOnScroll = false;
      }
    }
  }

  private isUserNearBottom(): boolean {
    /* if (this.messageInput) {
       const threshold = 150;
       const position = this.messagesWrapper.directiveRef.nativeElement.scrollTop;
       const height = this.messagesWrapper.nativeElement.scrollHeight - this.messagesWrapper.nativeElement.offsetHeight;
       console.log('position', position);
       console.log('height', height);
       console.log('isUserNearBottom', (position + threshold) > height);
       return (position / height) > 0.85;
     }*/
    return true;
  }

  onScroll($event) {
    if (this.isTouchDevice()) {
      this.zone.runOutsideAngular(() => {
        // this.messageInput.nativeElement.blur();
      });
    }
  }

  onScrollTop($event) {
    console.log($event, 'event scro;;');
    //console.log(this.messagesWrapper.scrollHeight);
    // rework this func
    this.scrolledToBottom = false;
    console.log("!this.loadOnScroll", !this.loadOnScroll);
    if (!this.loadOnScroll) {
      console.log("!this.loadOnScroll", !this.loadOnScroll);
      this.loadOnScroll = true;
      this.chatDialogService.getMessages({ page: this.page++ });
    }
  }

  get chatType(): ChatDialogType {
    // @ts-ignore 
    return this.record.type ? this.record.type : 'user';
  }

  nameClick() {
    if (this.chatType === 'user') {
      this.router.navigate([`/user/${this.chatRecord.id}`]);
    }
  }

  ngOnDestroy() {
    this.u$.next();
    this.u$.unsubscribe();
  }

  trackMessagesBy(i, { id, user, replyUser }) {
    return `${id}-from-${user.id}-to-${replyUser ? replyUser.id : i}`;
  }

  constructor(
    private chatDialogService: ChatDialogService,
    private messagesService: MessagesService,
    private chatService: ChatService,
    private hrUserStateService: HRUserStateService,
    private cdr: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private soundService: SoundService,
    private zone: NgZone,
    private router: Router,
    private chatHrVisitService: ChatHrVisitApiService,
  ) {
  }

  ngOnInit() {
    this.getCurrentVisit();

    // tslint:disable-next-line: no-unused-expression
    async () => {
      const notifications = await this.chatDialogService.findNotifications();
    };
    this.lastIndex = null;
    this.loadOnScroll = false;
    this.scrollNew = 0;
    this.first = true;

    this.loading = true;
    this.hide = true;
    this.check();

    // this.messagesService.subjects['users-chat/list']
    //   .pipe(takeUntil(this.u$))
    //   .subscribe((dialogs) => {
    //     // this.ngxService.stopLoader('messages-loader');
    //     this.zone.run(() => {
    //       this.dialogs = dialogs;
    //       this.cdr.markForCheck();
    //     });
    //   });

    this.hrUserStateService.onChangeUser().pipe(
      takeUntil(this.u$),
      first()
    ).subscribe(user => {
      this.current_user = user;
      this.typingUsers$ = this.chatDialogService.activateTypingStatus();
      this.typingUsers$.pipe(takeUntil(this.u$)).subscribe(({ type, data }) => {
        if (type === ResponseType.TYPING && this.allTypingUsers.indexOf(data) === -1 && data.replyUserId !== this.current_user.id) {
          this.allTypingUsers.push(data);
          this.filterTypingUsers();
          setTimeout(() => {
            this.allTypingUsers.splice(this.allTypingUsers.indexOf(data), 1);
            this.currentTypingUsers.splice(this.currentTypingUsers.indexOf(data), 1);
            this.check();
          }, 2000);
        }
        this.check();
      });

      const filterFunc = ((value) => !!value);

      this.record$.pipe(
        takeUntil(this.u$),
        filter(filterFunc),
        distinctUntilChanged(),
        flatMap((value): Observable<any> => {
          return this.dialog$;
        }),
        flatMap((value): Observable<any> => {
          return this.chatDialogService.activateChat({
            record: this.record,
            current_user: this.current_user,
            dialog: this.dialog
          });
        })
      ).subscribe(({ messages, type, lastIndex }) => {

        if (messages) {
          this.loadMessagesCount = {};
          this.messages = [...messages];
          this.loading = false;
          if (messages && messages.length === 0) {
            this.hide = false;
          }
          if (type === ResponseType.CREATED || type === ResponseType.REMOVED || type === ResponseType.PATCHED) {
            this.scrollNew = 2;
          }

          console.log(lastIndex);

          if (lastIndex) {
            this.lastIndex = lastIndex;
          }
          this.loadOnScroll = false;
          this.cdr.detectChanges();
        }
      });
    });
  }

  filterTypingUsers() {
    const checkDialog = (type, typeId) => (type === this.dialog?.type && typeId === this.dialog?.typeId);
    const filteredByChat = this.allTypingUsers.filter(({ type, typeId }) => checkDialog(type, typeId));
    this.currentTypingUsers = filteredByChat.splice(0, 3);
  }

  messageLoaded(message) {
    //this.loading = false;
    //this.ngxService.stopLoader('messages-loader');
  }

  check() {
    this.cdr.markForCheck();
  }

  log(...args) {
    console.log(...args);
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  removeUploads: any[] = [];

  addToRemoveUpload(upload, id) {
    if (this.editingMessage) {
      this.editingMessage.attached.uploads.splice(id, 1);
      this.removeUploads.push(upload);
    }
  }

  removeUpload() {
    if (this.editingMessage) {
      //this.editingMessage.attached.uploads.splice(id, 1);
      this.removeUploads.forEach(upload => {
        if (upload.id && upload.typeId) {
          this.chatDialogService.removeUpload(upload);
        } else {
          this.files.splice(this.files.indexOf(upload), 1);
        }
      });
    }
  }

  closeEditingMode() {
    this.editingMessage = null;
    this.userMessage = '';
  }

  sendMessage($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (
      (!this.userMessage && this.files.length === 0 && !this.editingMessage)
      ||
      (!this.userMessage && this.files.length === 0 && this.editingMessage && this.editingMessage.attached?.uploads?.length === 0)
    ) {
      console.log('edit');
      return;
    }
    ;
    // this.ngxService.startLoader(this.LOADER_ID);

    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.blur();
    });

    this.first = true;

    switch (true) {
      case !!this.editingMessage:
        // tslint:disable-next-line: no-unused-expression
        const msg = this.editingMessage;
        const msgId = this.editingMessage.id;
        if (msg.text !== this.userMessage || this.files.length || this.removeUploads.length > 0) {
          this.removeUpload();
          console.log('edit 1');
          this.chatDialogService.editMessage({
            ...msg,
            text: this.userMessage
          }).then((response) => {
            const tempFiles = [...this.files];
            const imgFiles = tempFiles.filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif'].indexOf(item.name.split('.').pop()) !== -1);
            this.files = [];

            if (tempFiles.length) {
              /*const placeholders = [];
              imgFiles.forEach(item => {
                console.log(item);
                const url = URL.createObjectURL(item);
                console.log(url);
                placeholders.push({
                  name: item.name,
                  ext: item.name.split('.').pop(),
                  url,
                  original: url,
                });
              });
              this.chatDialogService.updateUploads(msgId, placeholders);*/

              this.chatDialogService.uploadMedia({ uploads: tempFiles, type: this.dialog?.type }).subscribe(uploads => {
                console.log('uploads:', uploads);
                if (uploads.length > 0) {
                  const uploadsList = [...uploads];
                  this.chatDialogService.service.patch(msg.id, { text: msg.text, attached: { uploads: uploadsList } }).then(() => {
                    //this.scrollNew = 2;
                  });
                }
                //this.scrollToBottom();
              });
            }
            // this.ngxService.stopLoader(this.LOADER_ID);
            /*this.zone.runOutsideAngular(() => {
              this.messageInput.nativeElement.blur();
            });*/
            this.check();
          });
        }
        this.editingMessage = null;
        this.userMessage = '';
        break;

      default:
        if (!this.userMessage && this.files.length > 0) {
          this.userMessage = this.files.length === 1 ? 'Прикреплённый файл.' : 'Прикреплённые файлы.';
        }
        const attached = {
          messages: this.quotingMessage ? [this.quotingMessage] : [],
          uploads: []
        };
        this.chatDialogService.sendMessage({
          text: this.userMessage,
          type: this.dialog?.type,
          attached
        }).pipe(
          first()
        ).subscribe(async (response) => {
          this.userMessage = '';
          this.quotingMessage = null;
          const tempFiles = [...this.files];
          console.log('tempFiles:', tempFiles);
          const imgFiles = tempFiles.filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif'].indexOf(item.name.split('.').pop()) !== -1);
          this.files = [];
          const msg = await response;
          if (tempFiles.length > 0) {

            /*const placeholders = [];
            console.log('msgid:', msg.id, imgFiles);
            imgFiles.forEach(item => {
              console.log(item);
              const url = URL.createObjectURL(item);
              console.log(url);
              placeholders.push({
                name: item.name,
                ext: item.name.split('.').pop(),
                url,
                original: url,
              });
            });
            this.chatDialogService.updateUploads(msg.id, placeholders);*/

            this.chatDialogService.uploadMedia({ uploads: tempFiles, type: this.dialog?.type }).subscribe(uploads => {
              console.log('uploads:', uploads);
              if (uploads.length > 0) {
                this.chatDialogService.service.patch(msg.id, { attached: { uploads } }).then(() => {
                  // this.scrollNew = 2;
                  this.scrollToBottom();
                });
              }
            });
          }
          // else {
          //   this.chatDialogService.updateUploads(msg.id, placeholders);
          // }
          if (this.dialog?.type === 'task-comments' || ModuleTypes.indexOf(this.dialog?.type) !== -1) {
            const resetType = async () => {
              if (msg) {
                const { user, replyUser } = msg;
                const type = `users-chat_${[user.id, replyUser.id].sort().join('-')}`;
                this.dialog.type = type;
                delete this.dialog.task;
                delete this.dialog.module;
              }
            };
            resetType();
          }
          // this.ngxService.stopLoader(this.LOADER_ID);
          /*this.zone.runOutsideAngular(() => {
            this.messageInput.nativeElement.blur();
          });*/
          this.check();
          this.scrollWithDelay();
        });

        break;
    }


    // this.chatHistoryWrapper.nativeElement.scrollTop = this.chatHistoryWrapper.nativeElement.scrollHeight;
  }

  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  trackByMessageId(index: number, message: any) {
    return message.id
  }

  onReadMessages(): void {
    console.log('read!!!')
    if (this.messages && this.messages.length) {
      const readedMessages = this.messages.filter(message => message.userId !== this.current_user.id && !message.readAt);
      readedMessages.forEach(message =>
        this.chatDialogService.markIsReadMessage(message));
    }
  }

  getCurrentVisit() {
    if ((this.record as VisitDialogInfo).dialog?.visit) {
      const visitId = (this.record as VisitDialogInfo).dialog.visit.id;
      if (visitId) {
        this.getVisit(visitId);
      }
    }
  }

  getVisit(userId: number) {
    this.chatHrVisitService.getVisitsByVisitId(userId)
      .pipe(takeUntil(this.u$))
      .subscribe(
        data => {
          this.visitDataItem = data;
          this.cdr.markForCheck();
        },
        error => console.error(error)
      );
  }

}
