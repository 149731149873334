import {
  BonusConfigCategory, BonusConfiguration, BonusConfigurationSearchParams,
  BonusAddChildConfigurationPayload, BonusChildrenConfigurationSearchParams,
} from '../../../models/bonus/bonus-configuration';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_URL } from '@Env/environment';
import { Observable, of } from 'rxjs';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { BonusOutlets } from '@Mesh/core/models/bonus/bonus-outlets';
import { BonusConfigurationRuleRequest } from '@Mesh/core/models/bonus/bonus-configuration-rule-request';
import { GoalIdType, INewGoal } from '../../../../pages/goals/components/create-goals/createGoalTypes';
import { objectToParams } from '../../../../shared/helpers/object.helpers';

@Injectable({
  providedIn: 'root'
})
export class BonusService {
  readonly connectionString = `${SALEPLAN_URL}/v1/bonus`;

  constructor(private readonly http: HttpClient) {
  }

  getBonus({ addressSapId, planIds, date }: { addressSapId: number, planIds: number[], date?: string }): Observable<Bonus[]> {
    if (!addressSapId) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.append('addressSapId', `${addressSapId}`);
    params = params.append('showOffPlan', 'true');
    if (date) {
      params = params.append('bonusDate', `${date}`);
    }
    if (planIds) {
      planIds.forEach(p => params = params.append('planIds', `${p}`));
    }
    return this.http.get<Bonus[]>(`${this.connectionString}`, { params });
  }

  getBonusConfiguration(criteria: BonusConfigurationSearchParams = {}): Observable<BonusConfiguration[]> {
    const params = objectToParams(criteria);
    return this.http.get<BonusConfiguration[]>(`${this.connectionString}/configuration`, { params });
  }

  getBonusConfigurationById(bonusConfigId: number): Observable<BonusConfiguration> {
    return this.http.get<BonusConfiguration>(`${this.connectionString}/configuration/${bonusConfigId}`);
  }


  getBonusConfigCategory(): Observable<BonusConfigCategory[]> {
    return of(
      [
        {
          id: null,
          name: 'Все'
        },
        {
          id: 1,
          name: 'Табак'
        },
        {
          id: 2,
          name: 'RRP'
        },
        {
          id: 3,
          name: 'Напитки'
        },
        {
          id: 4,
          name: 'Другое'
        },
      ] as BonusConfigCategory[]
    );
  }

  getPredictBonus({ addressSapId, planIds }: { addressSapId: number, planIds: number[], date?: string }): Observable<Bonus[]> {
    if (!addressSapId) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.append('showOffPlan', 'true');
    params = params.append('addressSapId', `${addressSapId}`);

    if (planIds) {
      planIds.forEach(p => params = params.append('planIds', `${p}`));
    }
    return this.http.get<Bonus[]>(`${this.connectionString}/predict`, { params });
  }

  getPredictBonusClient(clientSapId: number): Observable<BonusClient> {
    const params = new HttpParams().append('clientSapId', `${clientSapId}`);
    return this.http.get<BonusClient>(`${this.connectionString}/client/predict`, { params });
  }

  getBonusOutlets(addressSapId: number): Observable<BonusOutlets[]> {
    const params = new HttpParams().append('addressSapId', `${addressSapId}`);
    return this.http.get<BonusOutlets[]>(`${this.connectionString}`, { params });
  }

  getPredictBonusOutlets(addressSapId: number): Observable<BonusOutlets[]> {
    const params = new HttpParams().append('addressSapId', `${addressSapId}`);
    return this.http.get<BonusOutlets[]>(`${this.connectionString}/predict`, { params });
  }

  toggleBonusConfigurationRule(request: BonusConfigurationRuleRequest[]): Observable<any> {
    return this.http.post<any>(`${this.connectionString}/configuration/rules`, request);
  }

  createBonusConfigurationByType(strategy: GoalIdType, data: INewGoal): Observable<BonusConfiguration> {
    return this.http.post<BonusConfiguration>(`${this.connectionString}/configuration/${strategy}`, data);
  }

  getChildrensBonusConfiguration(planId: number, criteria: Partial<BonusChildrenConfigurationSearchParams> = {}): Observable<BonusConfiguration[]> {
    const params = objectToParams(criteria);
    return this.http.get<BonusConfiguration[]>(`${this.connectionString}/configuration/hierarchy/${planId}/child`, { params });
  }

  addChildBonusConfiguration(planId: number, payload: BonusAddChildConfigurationPayload): Observable<any> {
    return this.http.post<any>(`${this.connectionString}/configuration/hierarchy/${planId}/child`, payload);
  }

  removeChildBonusConfiguration(planId: number, payload: BonusAddChildConfigurationPayload): Observable<any> {
    return this.http.request('delete', `${this.connectionString}/configuration/hierarchy/${planId}/child`, { body: payload });
  }
}