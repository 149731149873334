import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { SALEPLAN_URL, USER_URL } from '@Env/environment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IClientsOutletsResponse } from '@Mesh/core/models/lookup';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  readonly connectionString = `${USER_URL}/address`;

  constructor(private readonly http: HttpClient) {
  }

  getClientsOutletsLookup(clientSapId: number): Observable<IClientsOutletsResponse[]> {
    const options: any = {
      size: 9999,
      clientSapId,
      extendedFormat: true
    };
    return this.http.get(`${this.connectionString}`, { params: <HttpParams>options }).map((result: any) => {
      return result.content.map(o => {
        return {
          clientSapId: o.clientSapId,
          addressSapId: o.addressSapId,
          street: o.street,
          house: o.house,
          segmentName: o.segmentName
        };
      });
    });
  }

  getClientsOutletsLookupByClientSapIds(clientSapIds: number[]): Observable<IClientsOutletsResponse[]> {
    const obs = [];
    clientSapIds.forEach(id => obs.push(this.http.get(`${this.connectionString}?extendedFormat=true&clientSapId=${id}`)));
    return forkJoin(obs).pipe(
      map((ress: any[]) => {
          const arr = [];
          ress.forEach(res => {
              res.content.forEach(o => {
                arr.push( {
                  clientSapId: o.clientSapId,
                  addressSapId: o.addressSapId,
                  street: o.street,
                  house: o.house,
                  segmentName: o.segmentName
                });
              });
          });
          return arr;
        }
      ));
    }

  getClientsOutletLookup(addressSapId: number): Observable<IClientsOutletsResponse> {
    return this.http.get<IClientsOutletsResponse>(`${this.connectionString}/${addressSapId}`);
  }
}
