import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { detailBody } from '../../../../../@pages/animations/collapse-animation';
import { DialogByClient, DialogUserParams, UserDialog } from '../../chat';
import { ChatService } from '../../chat.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeathersService } from '../../../../../core/services/chat/feathers.service';
import { User } from '../../../../../core/models/user';

@Component({
  selector: 'iql-chat-client-item',
  templateUrl: './chat-client-item.component.html',
  styleUrls: ['./chat-client-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [detailBody],
})
export class ChatClientItemComponent implements OnInit {
  @Input() dialogClient: DialogByClient;
  @Output() openedDialog = new EventEmitter<any>();
  @Output() collapsedGroup = new EventEmitter<any>();

  collapsed: boolean = true;
  dialogsList: any[];
  loading: boolean = false;
  limitList = 15;
  params: DialogUserParams = {
    $limit: this.limitList,
    $skip: 0,
  };
  private ngOnDestroy$: Subject<null> = new Subject<null>();
  currentUser$: Observable<User>;

  constructor(
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private element: ElementRef,
    private feathersService: FeathersService,
  ) { }

  ngOnInit() {
    this.currentUser$ = this.feathersService.currentUserSubject;
  }

  get state(): string {
    return this.collapsed ? 'collapsed' : 'expanded';
  }

  onToggle(): void {
    this.collapsed = !this.collapsed;
    if (!this.collapsed) {
      this.dialogsList = [];
      this.params = {
        $limit: this.limitList,
        $skip: 0,
        clientSapId: this.dialogClient.clientSapId
      };
      this.getClientDialogs();
      this.collapsedGroup.emit(this.element.nativeElement);
    }
  }

  getClientDialogs(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.chatService.getUsertDialogs(this.params)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(listDialogs => {
        this.dialogsList = this.dialogsList ? [...this.dialogsList, ...listDialogs.data] : listDialogs.data;
        this.loading = false;
        this.cdr.markForCheck();
        this.params = {
          $limit: this.limitList,
          $skip: this.dialogsList ? this.dialogsList.length : 0,
          clientSapId: this.dialogClient.clientSapId
        };
      });
  }

  onScroll(): void {
    this.getClientDialogs();
  }

  onOpenDialog(dialog: UserDialog): void {
    this.openedDialog.emit(dialog)
  }
}
