import { CriteriaClientTask, ITaskOutlet, TaskClient, TaskStepResultPayload } from './../../../models/task';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IStockInfoResponse, StepType, TaskData, TaskInfo, TaskProgress, TaskStep, TaskStepStatusResponse } from '../../../models/task';
import { catchError } from 'rxjs/operators';
import { SALEPLAN_URL, TASK_URL } from '@Env/environment';
import { ProductsData } from '@Mesh/core/models/product';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { IClientsQueryParams, IQueryParams } from '@Mesh/core/models/query-params';
import { BonusConfiguration } from '@Mesh/core/models/bonus/bonus-configuration';
import { IResponse } from '@Mesh/core/models/IResponse';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { RecognitionResult } from '../../../models/recognition-result';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private get contentTypeJson(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  private get httpOptions(): Object {
    return { headers: this.contentTypeJson } as Object;
  }

  constructor(private http: HttpClient) {
  }

  readonly baseUrl = `${TASK_URL}/task`;

  getClientTasks(criteria: Partial<CriteriaClientTask> = {}): Observable<IResponse<TaskClient[]>> {
    const params = objectToParams(criteria);
    return this.http.get<IResponse<TaskClient[]>>(`${TASK_URL}/client-task`, { params });
  }

  getClientTasksOverview(addressSapIds: number[]): Observable<TaskData> {
    let params = new HttpParams();

    if (addressSapIds) {
      params = params.append('addressSapIds', `${addressSapIds}`);
    }
    return this.http.get<TaskData>(`${TASK_URL}/client-task/overview`, {
      params,
      headers: this.contentTypeJson
    });
  }

  getTasks(taskInfo: { clientSapId?: number, pageNumber?: number, brandId?: number, addressSapId?: number, showOnlyIsSuccess?: boolean }): Observable<TaskData> {
    let params = new HttpParams();

    if (taskInfo.brandId) {
      params = params.append('brandId', `${taskInfo.brandId}`);
    }
    if (taskInfo.clientSapId) {
      params = params.append('clientSapId', `${taskInfo.clientSapId}`);
    }
    if (taskInfo.pageNumber) {
      params = params.append('pageNumber', `${taskInfo.pageNumber}`);
    }
    if (taskInfo.addressSapId) {
      params = params.append('addressSapId', `${taskInfo.addressSapId}`);
    }
    if (taskInfo.showOnlyIsSuccess) {
      params = params.append('showOnlyIsSuccess', `${taskInfo.showOnlyIsSuccess}`);
    }
    return this.http.get<TaskData>(`${this.baseUrl}`, {
      params,
      headers: this.contentTypeJson
    });
  }

  getStepsStatuses(taskOutletClientId: number, stepId: number[]): Observable<any> {
    let params = new HttpParams().append('taskOutletClientId', `${taskOutletClientId}`);
    stepId.forEach(s => params = params.append('stepIds', `${s}`));

    return this.http.get<any>(`${this.baseUrl}/success`, {
      params,
      headers: this.contentTypeJson
    }).pipe(
      catchError(() => {

        return of([]);
      })
    );
  }

  getTaskOutletsByClientId(taskId: number, clientId: number): Observable<number[]> {
    const params = new HttpParams().append('taskId', `${taskId}`).append('clientSapId', `${clientId}`);

    return this.http.get<number[]>(`${this.baseUrl}/get/addressSapId`, {
      params,
      headers: this.contentTypeJson
    });
  }

  getTaskOutletIds(taskId: number): Observable<any[]> {
    const params = new HttpParams().append('taskId', `${taskId}`);

    return this.http.get<any[]>(`${this.baseUrl}/client/outlet`, {
      params,
      headers: this.contentTypeJson
    });
  }

  getTaskById(taskId: number): Observable<TaskInfo> {
    return this.http.get<TaskInfo>(`${this.baseUrl}/${taskId}`, this.httpOptions);
  }

  getTasksProgress(taskId: number, clientIds: number[]): Observable<TaskProgress[]> {
    let params = new HttpParams().append('taskId', `${taskId}`);

    clientIds.forEach(c => params = params.append('clientSapIds', `${c}`));

    return this.http.get<TaskProgress[]>(`${this.baseUrl}/progress/clients`, {
      params,
      headers: this.contentTypeJson
    });
  }

  //endregion

  //region Post/Put/Delete methods
  updateTask(taskInfo: Partial<TaskInfo>): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${taskInfo.id}`, taskInfo, this.httpOptions);
  }

  createTask(taskInfo: TaskInfo): Observable<any> {
    delete taskInfo.id;
    return this.http.put<any>(`${this.baseUrl}/create`, taskInfo, this.httpOptions);
  }

  patchTask(taskInfo: Partial<TaskInfo>, id: number): Observable<any> {
    return this.http.patch<any>(`${this.baseUrl}/${id}`, taskInfo, this.httpOptions);
  }

  proceedWithStep(taskStep: TaskStepStatusResponse, stepType: StepType): Observable<any> {
    switch (stepType) {
      case StepType.calculateStock: {
        break;
      }

      case StepType.selectOption: {
        break;
      }

      case StepType.sendPhoto: {
        break;
      }

      case StepType.giveAnswer: {
        break;
      }

      case StepType.photoMonitoring: {
        break;
      }
    }

    return of();
  }


  addTaskClientsOutlets(body: { clientSapId: number, addressSapId: number, segmentName?: string, dateStart?: string, dateEnd?: string }[], taskId: number): Observable<any> {
    const data = body.map(i => {
      return {
        clientSapId: i.clientSapId,
        addressSapId: i.addressSapId,
        dateStart: i.dateStart,
        dateEnd: i.dateEnd,
      };
    });

    return this.http.post<any>(`${this.baseUrl}/${taskId}/client/outlet`, data, this.httpOptions);
  }

  addTaskOutlet(taskId: number, body: ITaskOutlet[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}-address/${taskId}`, body, this.httpOptions);
  }

  addTaskOutletsFromCsv(file: any, taskId: number): Observable<any> {
    //TODO: figure out how to pass it
    return this.http.post<any>(`${this.baseUrl}/add/client/csv?taskId=${taskId}`, { file }, this.httpOptions);
  }

  deleteTaskClientsOutlets(body: { clientSapId: number, addressSapId: number, dateStart?: string, dateEnd?: string }[], taskId: number): Observable<any> {
    const options = this.httpOptions;
    options['body'] = body;

    return this.http.delete<any>(`${this.baseUrl}/${taskId}/client/outlet`, options);
  }

  //endregion

  //endregion

  //region TaskStep methods

  updateStep(step: Partial<TaskStep>, stepId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/step/${stepId}`, step, this.httpOptions);
  }

  cancelStepResult(payload: TaskStepResultPayload): Observable<TaskStep> {
    return this.http.put<TaskStep>(`${TASK_URL}/task/success/cancel-result`, payload);
  }

  acceptStepResult(payload: TaskStepResultPayload): Observable<TaskStep> {
    return this.http.put<TaskStep>(`${TASK_URL}/task/success/accept-result`, payload);
  }

  createStep(step: TaskStep, taskId: number): Observable<any> {
    delete step.id;
    return this.http.put<any>(`${this.baseUrl}/step/create`, { ...step, taskId }, this.httpOptions).pipe(
      catchError(e => {
        console.log(e);
        return of({});
      })
    );
  }

  deleteStep(stepId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/step${stepId}`, this.httpOptions).pipe(
      catchError(e => {
        console.log(e);
        return of({});
      })
    );
  }

  //endregion

  getPlans(): Observable<{ planName: string, plan_id: number }[]> {
    return this.http.get<{ planName: string, plan_id: number }[]>(`${SALEPLAN_URL}/v1/sale-plan/plans?addressSapId=800587944`, this.httpOptions);
  }

  getStockInfo(outletId: number, planId: number, date: string): Observable<IStockInfoResponse[]> {
    return this.http.get<IStockInfoResponse[]>(`${SALEPLAN_URL}/v1/leftover/plan?addressSapId=${outletId}&planId=${planId}&registerAt=${date}`, this.httpOptions);
  }

  getProductsInfo(outletId: number, recommendedOrderConfigId: number): Observable<ProductsData> {
    return this.http.get<ProductsData>(`${SALEPLAN_URL}/v1/product?addressSapId=${outletId}&recommendedOrderConfigId=${recommendedOrderConfigId}&pageSize=999`, this.httpOptions);
  }

  getPredictBonusClient(clientSapId: number): Observable<BonusClient> {
    return this.http.get<BonusClient>(`${TASK_URL}/bonus/${clientSapId}/predict`);
  }

  getOrdersRecommendedConfigs(params: Partial<IClientsQueryParams>): Observable<IResponse<BonusConfiguration[]>> {
    return this.http.get<IResponse<BonusConfiguration[]>>(`${SALEPLAN_URL}/v1/orders/configuration/recommended`, { params: <HttpParams>params });
  }

  getRecognitionResult(taskSuccessId: number): Observable<RecognitionResult> {
    return this.http.get<RecognitionResult>(`${TASK_URL}/recognition-result/by-task-success-id/${taskSuccessId}`);
  }
}
