import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ChatDialogType } from '../chat-dialog/chat-dialog.service';
import { ChatService } from '../../chatAcademy/service/chat.service';
import { ChatDialogService } from '../../chatAcademy/chat-dialog/chat-dialog.service';
import { AcademyUserStateService } from '../../../../core/services/api/auth/academy-user-state.service';
import { User } from '../../chatAcademy/models/user';

@Component({
  selector: 'iql-chat-training',
  templateUrl: './chat-training.component.html',
  styleUrls: ['./chat-training.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTrainingComponent implements OnInit {
  @Output() changedType = new EventEmitter<string>();
  record: { dialog?: any; type?: ChatDialogType; info?: any; };
  dialog: any;
  currentUser: User;

  constructor(
    private chatService: ChatService,
    private chatDialogService: ChatDialogService,
    private cdr: ChangeDetectorRef,
    private academyUserStateService: AcademyUserStateService
  ) { }

  ngOnInit() {
    this.academyUserStateService.onChangeUser().
      subscribe(user => {
        this.currentUser = user;
        this.chatDialogService.current_user = user;
        this.cdr.markForCheck();
      })
  }

  onChangeTypeChat(type: string): void {
    this.changedType.emit(type);
  }

  openChat(record: { dialog?: any, type?: ChatDialogType, info?}): void {
    if (record.type) {
      this.record = record;
    } else {
      let { id: current_user_id } = this.currentUser,
        { id: reply_user_id = current_user_id } = record.dialog.message.replyUser || {};
      this.record = current_user_id !== reply_user_id ? record.dialog.message.replyUser : record.dialog.message.user;
      this.dialog = record.dialog;
    }
    this.cdr.detectChanges();
  }

  closedDialog(): void {
    this.record = null;
    this.dialog = null;
  }
}
