export const environment = {
  production: false,
  translation: {
    supportedLanguages: ['ru', 'en'],
    default: 'ru',
    languages: {
      ru: 'assets/translations/ru.json',
      en: 'assets/translations/en.json',
    }
  }
};

export const FIREBASE = {
  apiKey: "AIzaSyBIaurZB8ATPTt6Gy-xJuswZBN7Yi6Uk54",
  authDomain: "sprint2-164b2.firebaseapp.com",
  databaseURL: "https://sprint2-164b2.firebaseio.com",
  projectId: "sprint2-164b2",
  storageBucket: "sprint2-164b2.appspot.com",
  messagingSenderId: "89950498120",
  appId: "1:89950498120:web:786d9dcdabac727d2c977d",
  measurementId: "G-6VMT7WZHEX"
};

export const SALEPLAN_URL = 'https://saleplan.stage.retail.iql.ru/api';
export const TASK_URL = 'https://task.stage.retail.iql.ru/api/v1';
export const USER_URL = 'https://user.stage.retail.iql.ru/api/v1';
export const MESSAGING_URL = 'https://notifications.stage.retail.iql.ru/api/v1';
export const NODE_URL = 'https://chat.stage.retail.iql.ru';
export const BASE_URL = 'https://sprint2.avaha.ru';
export const SALEPLAN_IMAGES_URL = 'https://cdn.stage.retail.iql.ru';
export const CLOUD_URL = 'https://cloud.stage.retail.iql.ru/api/v1';
export const CLOUD_UPLOAD_URL = 'https://cloud.stage.retail.iql.ru/api/v1/content/image';
export const CLOUD_UPLOAD_DOC_URL = 'https://cloud.stage.retail.iql.ru/api/v1/content/doc';
export const IMAGE_CLOUD_URL = 'https://static.cloud.stage.retail.iql.ru';
export const MESSAGING_WEBSOCKET_URL = 'wss://notifications.stage.retail.iql.ru/iq-messaging/socket/v1/';
export const IMAGES_URL = IMAGE_CLOUD_URL;
export const ACADEMY_URL = 'https://api.bristol-academy.tech.iql.ru/api';
export const ACADEMY_CHAT_URL = 'https://chat.bristol-academy.tech.iql.ru';
export const HR_URL = 'https://api.hr.tech.iql.ru/api';
export const HR_CHAT_URL = 'https://chat.hr.tech.iql.ru';

export const API_URL = BASE_URL + '/api/v1';
export const IMAGE_URL = API_URL + '/upload/image';
export const AUTH_URL = USER_URL + '/token';
