import { Deserializable } from './deserializable';

export class Grade implements Deserializable {
  id?: number;
  order?: number;
  name?: string;
  rating?: number;
  task_id?: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

