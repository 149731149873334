import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, share } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { User } from '../../../../shared/modules/chatHR/models/user';
import { AcademyAuth } from '../../../models/academy-auth';

@Injectable({
  providedIn: 'root'
})
export class HRUserStateService {
  protected HRUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  protected HRUserToken$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private authService: AuthService
  ) {
  }

  onChangeToken(): Observable<any> {
    return this.HRUserToken$
      .pipe(
        filter(value => !!value),
        share());
  }

  onChangeUser(): Observable<User> {
    return this.HRUser$
      .pipe(share());
  }

  publishCurrentHRUser(): void {
    this.authService.tokenChange()
      .pipe(
        concatMap(token => {
          if (token) {
            return this.authService.getTokenHR(token)
              .pipe(catchError((err) => of({ data: null, token: null })));
          } else {
            return of({ data: null, token: null });
          }
        }),
        catchError((err) => of({ data: null, token: null })))
      .subscribe((tokenUser: AcademyAuth) => {
        if (tokenUser) {
          this.HRUser$.next(tokenUser.data)
          this.HRUserToken$.next(tokenUser.token);
        }
      });
  }
}
