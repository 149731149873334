import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from '../../reducers';
import { catchError, map, switchMap, switchMapTo, withLatestFrom } from 'rxjs/operators';
import * as fromActions from '../../actions/startup/application-settings.actions';
import { getCategoriesLoadingStatus, getCategoriesMainLoadingStatus, getCitiesLoadingStatus, getPlansLoadingStatus, getRegionsLoadingStatus } from '../../selectors/startup/application-settings.selectors';
import { LoadingStatus } from '../../../core/models/external/loadable';
import { Router } from '@angular/router';
import { ApplicationSettingsService } from '@Mesh/core/services/api/startup/application-settings.service';

@Injectable()
export class ApplicationSettingsEffects {
  constructor(
    private readonly _store: Store<State>,
    private readonly _actions$: Actions,
    private readonly applicationSettingService: ApplicationSettingsService,
    private readonly router: Router
  ) {
  }

  loadApplicationSettings$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadApplicationSettings),
      switchMapTo([
        fromActions.loadCategories(),
        fromActions.loadPlans(),
        fromActions.loadCities({}),
        fromActions.loadRegions({}),
        fromActions.loadCategoriesMain()
      ])
    )
  );

  loadCategories$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadCategories),
      withLatestFrom(this._store.pipe(select(getCategoriesLoadingStatus))),
      switchMap(([, loadingStatus]) => {
        if (loadingStatus === LoadingStatus.Loading) {
          return this.applicationSettingService.getCategoriesTree().pipe(
            map(categories => fromActions.categoriesLoaded({ categories }))
          );
        }
        return [];
      }),
      catchError(error => {
        return [];
      })
    )
  );

  loadCategoriesMain$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadCategoriesMain),
      withLatestFrom(this._store.pipe(select(getCategoriesMainLoadingStatus))),
      switchMap(([, loadingStatus]) => {
        if (loadingStatus === LoadingStatus.Loading) {
          return this.applicationSettingService.getCategoriesMain().pipe(
            map(categories => fromActions.categoriesMainLoaded({ categories }))
          );
        }
        return [];
      }),
      catchError(error => {
        return [];
      })
    )
  );

  loadPlans$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadPlans),
      withLatestFrom(this._store.pipe(select(getPlansLoadingStatus))),
      switchMap(([, loadingStatus]) => {
        if (loadingStatus === LoadingStatus.Loading) {
          return this.applicationSettingService.getPlans().pipe(
            map(plans => fromActions.plansLoaded({ plans }))
          );
        }
        return [];
      })
    )
  );
  citiesLoading$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadCities),
      withLatestFrom(this._store.pipe(select(getCitiesLoadingStatus))),
      switchMap(([query, loadingStatus]) => {
        if (loadingStatus === LoadingStatus.Loading) {
          return this.applicationSettingService.getCities(query).pipe(
            map(res => fromActions.citiesLoaded({ cities: res.content }))
          );
        }
        return [];
      })
    )
  );
  regionsLoading$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadRegions),
      withLatestFrom(this._store.pipe(select(getRegionsLoadingStatus))),
      switchMap(([query, loadingStatus]) => {
        if (loadingStatus === LoadingStatus.Loading) {
          return this.applicationSettingService.getRegions(query).pipe(
            map(res => fromActions.regionsLoaded({ regions: res.content }))
          );
        }
        return [];
      })
    )
  );


}
