import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from "@angular/core";
import { HRDocumentType } from "../documents-type.enum";

@Component({
  selector: "iql-hat-hr-candidat-documents-tabs",
  templateUrl: "./hat-hr-candidat-documents-tabs.component.html",
  styleUrls: ["./hat-hr-candidat-documents-tabs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HatHrCandidatDocumentsTabsComponent implements OnInit {
  @Output() changeTab = new EventEmitter<any>();

  tabsList = [
    {
      entity: HRDocumentType.PassportFirstPage,
      label: "Паспорт. Первая страница",
      icon: "icon-passport-first-page",
    },
    {
      entity: HRDocumentType.PassportOtherPages,
      label: "Паспорт (страница с пропиской)",
      icon: "icon-passport-other-pages",
    },
    {
      entity: HRDocumentType.PreviousPassports,
      label: "Паспорт (19 страницы паспорта)",
      icon: "icon-passport-other-pages",
    },
    { entity: HRDocumentType.SNILS, label: "СНИЛС", icon: "icon-snils" },
    { entity: HRDocumentType.INN, label: "ИНН", icon: "icon-inn" },
    {
      entity: HRDocumentType.OtherDocuments,
      label: "Прочие документы",
      icon: "icon-other-documents",
    },
  ];
  currentTab = HRDocumentType.PassportFirstPage;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const initialTab = this.tabsList.find((tab) => tab.entity === this.currentTab);
    if (initialTab) {
      this.changeTab.emit(initialTab);
    }
  }

  onChangeTab(tab: any): void {
    this.currentTab = tab.entity;
    this.changeTab.emit(tab.entity);
    this.cdr.markForCheck();
  }
}
