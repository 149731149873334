import { Deserializable } from '@Mesh/core/models/deserializable';
import { Permission } from '../../pages/employees/types/permission.type';
import { Client } from './client';

interface AdditionsType {
  id: number | null;
  name: string | null;
}

export class User implements Deserializable {
  id?: number;
  clientId?: number;
  role?: string;
  username?: string;
  status?: any;
  type?: string;
  name: string;
  surname: string;
  avatar?: string;
  division?: AdditionsType;
  position?: AdditionsType;
  client?: Client;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  get short_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name.charAt(0));
    }
    if (this.surname) {
      result.push(this.surname.charAt(0));
    }
    return result.length ? result.join(' ') : '';
  }

  get full_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name);
    }
    if (this.surname) {
      result.push(this.surname);
    }
    return result.length ? result.join(' ') : '';
  }
}

export interface IUserInfo {
  accessTokenExpires: number;
  refreshTokenExpires: number;
  accessToken: string;
  refreshToken: string;
  user: IUserData;
}

interface ClientInfo {
  clientSapId: number;
  clientName: string;
  inn: string;
  kpp: string;
  ogrn: string;
  urAdr: string;
  addressCount: number | null;
}

export interface IUserData {
  username: string | null;
  avatar: string | null;
  email: string | null;
  possibleClientSapId: number | null;
  possibleEmail: string | null;
  possiblePhone: string | null;
  client: Client;
  permissions: string[];
  addresses: any[] | null;
  language: string;
  id: number;
  agentId: number | null;
  name: string;
  phone: string;
  status: string;
  type: string;
  position: string;
}


export interface UsersSearchParams {
  clientSapId: number
  addressSapId: number[]
  userIds: number[]
  namePattern: string
  phonePattern: string
  extendedFormat: boolean
  withPermissions: boolean
  status: string[]
  type: string[]
  shouldMaskOwnerPhone: boolean
  size: number
  page: number
  sort: string
  direction: string
}


export interface IUser {
  username: string
  avatar: string
  email: string
  possibleClientSapId: string
  possibleEmail: string
  possiblePhone: string
  client: Client
  permissions: Permission[]
  addresses: any
  language: string
  id: number
  agentId: number
  name: string
  phone: string
  status: string
  type: string
  position: string
}

export enum UserStatusType {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  INACTIVE = 'INACTIVE'
}

