import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskProgress } from '@Mesh/core/models/task';
import { populateTaskProgressEntity } from '@Mesh/store/actions/task/task-progress.actions';

export const taskProgressFeatureKey = 'tasksProgress';

export interface State extends EntityState<TaskProgress> {
}

export const adapter: EntityAdapter<TaskProgress> = createEntityAdapter<TaskProgress>({
  selectId: taskProgress => taskProgress.addressSapId
});

export const initialState: State = adapter.getInitialState();

const taskProgressReducer = createReducer(
  initialState,
  on(populateTaskProgressEntity, (state, { tasksProgress }) => adapter.addAll(tasksProgress, state))
);

export function reducer(state: State | undefined, action: Action) {
  return taskProgressReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
export const _selectTaskProgressIds = selectIds;
export const _selectTaskProgressEntities = selectEntities;
export const _selectAllTaskProgress = selectAll;
