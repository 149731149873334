import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { OpenedResume, OpenedResumeStatus, OpenedResumeStatusInfo, OpenedResumeStatusMap } from '../../chat-hr-opened-resume';

@Component({
  selector: 'iql-chat-hr-opened-resume-item',
  templateUrl: './chat-hr-opened-resume-item.component.html',
  styleUrls: ['./chat-hr-opened-resume-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrOpenedResumeItemComponent {
  @Input() resume: OpenedResume;
  @Output() showResume = new EventEmitter()

  OpenedResumeStatus = OpenedResumeStatus;
  OpenedResumeStatusMap = OpenedResumeStatusMap;

  get userStatus(): OpenedResumeStatusInfo {
    return this.resume && this.resume.status_id !== undefined && this.resume.status_id !== null
      && this.OpenedResumeStatusMap[this.resume.status_id]
      ? this.OpenedResumeStatusMap[this.resume.status_id] : this.OpenedResumeStatusMap['UNKNOWN'];
  }

  onShowResume() {
    this.showResume.emit(this.resume);
  }
}
