import { ProductQuantity, ProductsData, ProductPricelist, ProductSalePlan, Product } from '@Mesh/core/models/product';
import { OutletActiveOrdersData, OutletActiveOrders } from '@Mesh/core/models/outletActiveOrders';
import { OutletCatalog, OutletCatalogData } from '@Mesh/core/models/outletCatalog';
import { TaskClientData, TaskData, TaskProgress } from '@Mesh/core/models/task';
import { OrderDocInfoDetailed, OrderHistoryInfo, OrdersHistory } from '@Mesh/core/models/APImodels/saleplan/orders';
import { OutletGoals, OutletGoalsData } from '@Mesh/core/models/outletGoals';
import { Employees, EmployeesData } from '@Mesh/core/models/employees';
import { DocumentsData, Document } from '@Mesh/core/models/document';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { PlanConfig, PlanConfigItem } from '@Mesh/core/models/plan';
import { Brand, BrandData, Plan } from '@Mesh/core/models/brand';
import { Client, ClientsData } from '@Mesh/core/models/client';
import { Outlet, OutletData } from '@Mesh/core/models/outlet';
import { Manufacturer } from '@Mesh/core/models/manufacturer';
import { ClientsLookup } from '@Mesh/core/models/lookup';
import { Category } from '@Mesh/core/models/category';
import { Progress } from '@Mesh/core/models/progress';
import { Dictionary } from '@ngrx/entity';
import { TableColumn } from '@Mesh/shared/components/table-v2/models/columns';
import { BonusConfiguration } from '@Mesh/core/models/bonus/bonus-configuration';

export enum ITableType {
  products = 'products',
  catalog = 'catalog',
  orders = 'orders',
  ordersInfo = 'ordersInfo',
  autoOrders = 'autoOrders',
  orderReports = 'orderReports',
  ordersHistory = 'ordersHistory',
  orderDocInfoDetailed = 'orderDocInfoDetailed',
  ordersState = 'ordersState',
  clients = 'clients',
  outlets = 'outlets',
  documents = 'documents',
  employees = 'employees',
  outletCatalog = 'outletCatalog',
  producers = 'producers',
  tasks = 'tasksList',
  tasksHistory = 'tasksHistory',
  task = 'task',
  taskClients = 'taskClients',
  motivationProgramRules = 'motivationProgramRules',
  manufacturerProducts = 'manufacturerProducts',
  taskOutlets = 'taskOutlets',
  taskOverview = 'taskOverview',
  producerTasks = 'producerTasks',
  producerClients = 'producerClients',
  producerOutlets = 'producerOutlets',
  planConfigs = 'planConfigs',
  planCatalog = 'planCatalog',
  outletActiveOrders = 'outletActiveOrders',
  bonusConfigs = 'bonusConfigs',
  goals = 'goals'
}

export interface ITableInputData {
  products?: ProductsData;
  productsWithOrders?: Product[];
  tasks?: TaskData;
  taskProgress?: Dictionary<TaskProgress>;
  taskClients?: TaskClientData;
  clients?: ClientsData;
  clientLookups?: ClientsLookup[];
  outlets?: Outlet[];
  outletData?: OutletData;
  documents?: Document[];
  documentsData?: DocumentsData;
  outletActiveOrders?: OutletActiveOrders[];
  outletActiveOrdersData?: OutletActiveOrdersData;
  outletCatalog?: OutletCatalog[];
  outletCatalogData?: OutletCatalogData;
  outletGoals?: OutletGoals[];
  outletGoalsData?: OutletGoalsData;
  employees?: Employees[];
  employeesData?: EmployeesData;
  quantity?: ProductQuantity[];
  salePlan?: ProductSalePlan[];
  categories?: Category[];
  brands?: BrandData;
  brandList?: Brand[];
  brandId?: number;
  taskId?: number;
  outletId?: number;
  clientSapId?: number;
  routeParams?: any;
  client?: Client;
  pricelist?: ProductPricelist[];
  selectedRowId?: number | string;
  progress?: Progress[];
  plans?: Plan[];
  planConfigs?: PlanConfig[];
  bonusConfigs?: BonusConfiguration[];
  planConfigItems?: PlanConfigItem[];
  manufacturers?: Manufacturer[];
  orders?: any;
  docHistories?: OrderHistoryInfo[];
  datailedData?: OrderDocInfoDetailed[];
  ordersHistory?: OrdersHistory;
  loadingStatus?: LoadingStatus;
  details?: any[];
}

export interface ITable {
    type: ITableType;
    columns: TableColumn[];
}
