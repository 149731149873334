import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { ChatHrOpenedResumeDataService } from '../chat-hr-opened-resume-data.service';
import { ChatHrOpenedResumeApiService } from '../../chat-hr-opened-resume.service';
import { OpenedResume, OpenedResumeStatusInfo, OpenedResumeStatusMap, OpenedResumeUpdatePayload, Reason } from '../../chat-hr-opened-resume';
import { Subject } from 'rxjs';
import { UsersSyncService } from '../../opened-users-sync.service';


@Component({
  selector: 'iql-chat-hr-opened-resume-data-edit',
  templateUrl: './chat-hr-opened-resume-data-edit.component.html',
  styleUrls: ['./chat-hr-opened-resume-data-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrOpenedResumeDataEditComponent implements OnInit, OnDestroy {
  @Input() data: OpenedResume;

  spinner = SPINNER;
  userForm: FormGroup;
  reasons: Reason[];
  statuses: OpenedResumeStatusInfo[]
  statusesMap: OpenedResumeStatusInfo[] = Object.values(OpenedResumeStatusMap);
  schedules = [];
  employments = [];
  positions = [];
  cities = [];
  districts = [];
  private isSubmitting = false;
  private ngOnDestroy$: Subject<null> = new Subject<null>();
  view: 'worksheet' | 'resume' = 'worksheet';
  isLoading = false;

  constructor(
    private loaderService: NgxUiLoaderService,
    private fb: FormBuilder,
    private chatHrOpenedResumeDataService: ChatHrOpenedResumeDataService,
    private apiService: ChatHrOpenedResumeApiService,
    private cdr: ChangeDetectorRef,
    private usersSyncservice: UsersSyncService
  ) {
    this.userForm = this.fb.group({
      status: [''],
      reasons: [[]],
      comment: [''],
    });
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.getRefusalReasons();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  switchView() {
    this.view = this.view === 'worksheet' ? 'resume' : 'worksheet';
  }

  patchFormValue() {
    this.userForm.patchValue({
      status: this.data.status_id.toString(),
      reasons: this.data.refusalReasons.map(reason => reason.name),
      comment: this.data.comment,
    });
    this.cdr.markForCheck();
  }

  onSubmit() {
    if (this.userForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.loaderService.startLoader('edit-resume');
      const formValues = this.userForm.value;
      let payload: OpenedResumeUpdatePayload = {};

      if (formValues.status) {
        payload.status_id = formValues.status;
      }
      if (formValues.reasons.length > 0) {
        payload.refusalReasons = formValues.reasons;
      }
      if (formValues.comment) {
        payload.comment = formValues.comment;
      }
      this.apiService.updateOpenedUser(this.data.id, payload)
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe({
          next: () => {
            this.usersSyncservice.triggerGetUsers();
            this.loaderService.stopLoader('edit-resume');
            this.isSubmitting = false;
          },
          error: err => {
            this.loaderService.stopLoader('edit-resume');
            console.error('Updating user error', err);
            this.isSubmitting = false;
          }
        });
    }
  }

  onClosed() {
    this.chatHrOpenedResumeDataService.closeOpenedResumeData();
  }

  isControlInvalid(controlName: string): boolean {
    if (!this.userForm) {
      return;
    }
    const control = this.userForm.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  getRefusalReasons(): void {
    this.apiService.getRefusalReasons()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe({
        next:(data) => {
          this.reasons = data;
          this.patchFormValue();
          this.isLoading = false;
          this.cdr.markForCheck();
        },
        error: err => {
          console.error('Error fetching refusal reasons',err);
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

}
