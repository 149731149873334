import { ITaskOutlet } from './../../../core/models/task';
import { Injectable } from '@angular/core';
import * as fromActions from '../../actions/task/tasks.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { TasksService } from '@Mesh/core/services/api/task/tasks.service';
import { combineLatest, forkJoin, of } from 'rxjs';
import { StepType, TaskStep } from '@Mesh/core/models/task';
import {DataService} from '@Mesh/core/services/api/deprecated/data.service';
import { GetErrorType } from '@Mesh/core/models/APPmodels/errors';

@Injectable()
export class TasksEffects {
    constructor(private readonly _actions$: Actions, private readonly tasksService: TasksService, private readonly dataService: DataService) {
    }

    tasksLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadTasks),
            switchMap(props => this.tasksService.getTasks(props).pipe(
                map(tasks => fromActions.tasksLoaded({ tasks })),
                catchError(err => ([fromActions.tasksLoadError()]))
            ))
        )
    );

  getClientsForOutlets$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadAllClientsOutlets),
            switchMap(({ params, reset }) => this.dataService.getClientsForOutlets(params).pipe(
                map(data => {
                  return fromActions.loadAllClientsOutletsLoaded({ data: data.content, reset });
                }),
                catchError(err => ([fromActions.tasksLoadError()]))
            ))
        )
    );

    completedTasksLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadCompletedTasks),
            switchMap(props => this.tasksService.getTasks(props).pipe(
                map(tasks => fromActions.completedTasksLoaded({ tasks })),
                catchError(err => ([fromActions.completedTasksLoadError()]))
            ))
        )
    );

    createTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createTask),
            mergeMap(({ task, taskAssignment }) => forkJoin(this.tasksService.createTask(task), of(task), of(taskAssignment))),
            map(([{ id }, task, taskAssignment]) => fromActions.createSteps({
                steps: task.step,
                autoAssignment: task.autoAssignment,
                taskId: id,
                assignment: taskAssignment,
                actionOnSuccess: () => fromActions.taskCreated({ task: { ...task, id } })
            })),
            catchError(err => {
                console.log(err);
                return [fromActions.taskUpdateError()];
            })
        )
    );

    updateTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.updateTask),
            mergeMap(({ task, taskAssignment }) => forkJoin(this.tasksService.updateTask(task), of(task), of(taskAssignment))),
            map(([{ id }, task, taskAssignment]) => fromActions.createSteps({
                steps: task.step,
                taskId: id,
                assignment: taskAssignment,
                actionOnSuccess: () => fromActions.taskUpdated({ task })
            })),
            catchError(err => {
                console.log(err);
                return [fromActions.taskUpdateError()];
            })
        )
    );

    createSteps$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createSteps),
            mergeMap(({ steps, taskId, assignment, actionOnSuccess, autoAssignment }) => {
                const stepsCreation = [];
              steps.forEach(s => s.id ? stepsCreation.push(this.tasksService.updateStep(s, s.id)) : stepsCreation.push(this.tasksService.createStep(s, taskId)));
                return combineLatest(stepsCreation).mergeMap((taskSteps: TaskStep[]) => [
                    fromActions.stepsCreated({ steps: taskSteps, taskId }),
                    fromActions.addTaskOutlet({
                      taskId,
                      assignment,
                      autoAssignment,
                      actionOnSuccess,
                      taskOutlets: assignment.clientsOutletsToAdd.map(({addressSapId, clientSapId}) => ({ addressSapId, clientSapId } as ITaskOutlet))
                    }),
                ]);
            }),
            catchError(error => {
                console.log(error);
                return [fromActions.stepUpdateError({ error })];
            })
        )
    );

    addTaskOutlet$ = createEffect(
      () => this._actions$.pipe(
          ofType(fromActions.addTaskOutlet),
          mergeMap(({ assignment, taskOutlets, taskId, actionOnSuccess, autoAssignment }) => this.tasksService.addTaskOutlet(taskId, taskOutlets).pipe(
                mapTo(
                  autoAssignment ? actionOnSuccess() : fromActions.updateTaskClientsOutlets({ assignment, taskId, actionOnSuccess })
                ),
                catchError(err => ([fromActions.taskUpdateError()]))
              )
          )
      )
  );

    updateTaskClientsOutletsNew$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.updateTaskClientsOutlets),
            mergeMap(({ type, assignment, taskId, actionOnSuccess }) => {
                if (!assignment.clientsOutletsToRemove?.length && !assignment.clientsOutletsToAdd?.length) {
                    return of(fromActions.taskClientsOutletsUpdated());
                }

                const addRequest = !assignment.clientsOutletsToAdd.length ? undefined : this.tasksService.addTaskClientsOutlets(assignment.clientsOutletsToAdd, taskId);
                const removeRequest = !assignment.clientsOutletsToRemove.length ? undefined : this.tasksService.deleteTaskClientsOutlets(assignment.clientsOutletsToRemove, taskId);
                const requests = !!addRequest ? [addRequest] : [];
                if (!!removeRequest) {
                    requests.push(removeRequest);
                }
                return combineLatest(requests).pipe(
                    mergeMap(() => [fromActions.taskClientsOutletsUpdated(), actionOnSuccess()]),
                    catchError(err => {
                        return [fromActions.taskUpdateError()];
                    })
                );
            }))
    );

    patchTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.patchTask),
            mergeMap(({ task, id }) => this.tasksService.patchTask(task, id).pipe(
                mapTo(fromActions.taskPatched({ task, id })),
                catchError(err => ([fromActions.taskUpdateError()]))
                )
            )
        )
    );

    stepLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadStepStatuses),
            switchMap(props => {
              console.trace(props);
              return this.tasksService.getStepsStatuses(props.taskOutletClientId, props.stepId)
            }),
            map(steps => fromActions.stepStatusesLoaded({ steps }))
        )
    );

    proceedWithStep$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.proceedWithStep),
            switchMap(props => this.tasksService.proceedWithStep(props.taskStep, StepType.calculateStock).pipe(
                map(() => fromActions.proceedWithStepSucceeded()),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadCurrentTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadTask),
            switchMap(({ taskId }) => this.tasksService.getTaskById(taskId).pipe(
                map(task => fromActions.taskLoaded({ task })),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadTaskClientOutlets$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadTask),
            switchMap(({ taskId }) => this.tasksService.getTaskById(taskId).pipe(
                map(task => fromActions.taskLoaded({ task })),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadPlanNames$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadPlanNames),
            switchMap(() => this.tasksService.getPlans().pipe(
                map(planNames => fromActions.planNamesLoaded({ planNames })),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadPlanStock$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadPlanStock),
            mergeMap(({ outletId, recommendedOrderConfigId, date }) => {
                return combineLatest(this.tasksService.getStockInfo(outletId, recommendedOrderConfigId, date), this.tasksService.getProductsInfo(outletId, recommendedOrderConfigId)).map(([stockInfo, productsInfo]) => fromActions.planStockLoaded({
                    stockInfo,
                    productsInfo: productsInfo.content
                }));
            }),
            catchError((error) => {
                console.log(error);
                return [fromActions.planStockError()];
            })
        )
    );

  loadPredictBonusClient = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.taskBonusAction.loadPredictBonusClient),
      switchMap(props => this.tasksService.getPredictBonusClient(props.clientSapId).pipe(
        map(data => fromActions.taskBonusAction.loadPredictBonusClientSuccess({data})),
        catchError(err => [fromActions.taskBonusAction.loadPredictBonusClientError({error: GetErrorType(err)})])
      ))
    )
  );
}
