import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTasks from '../../reducers/task/tasks.reducers';

export const getTasksState = createFeatureSelector<fromTasks.State>('tasks');

export const getTasks = createSelector(
    getTasksState,
    fromTasks.getTasks
);

export const getCurrentTask = createSelector(
    getTasksState,
    fromTasks.getCurrentTask
);

export const getClients = createSelector(
    getTasksState,
    fromTasks.getTasksClients
);

export const getTasksLoading = createSelector(
    getTasksState,
    fromTasks.tasksLoading
);

export const getStepStatuses = createSelector(
    getTasksState,
    fromTasks.getStepStatuses
);

export const getIsUpdating = createSelector(
    getTasksState,
    fromTasks.getIsUpdating
);

export const getPlanNames = createSelector(
    getTasksState,
    fromTasks.getPlanNames
);

export const selectTaskPostingStatus = createSelector(
    getTasksState,
    fromTasks.getTaskPostingStatus
);

export const selectTasksLoadingStatus = createSelector(
    getTasksState,
    fromTasks.getTasksLoadingStatus
);

export const selectStepsPostingStatus = createSelector(
    getTasksState,
    fromTasks.getStepsPostingStatus
);

export const selectPlanStock = createSelector(
    getTasksState,
    fromTasks.getPlanStock
);

export const taskBonusSelectors = {
  selectPredictBonusClient: createSelector(getTasksState, fromTasks.getPredictBonusClient),
  selectPredictBonusClientLoadingStatus: createSelector(getTasksState, fromTasks.getPredictBonusClientLoadingStatus),
};
