import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/startup/application-settings.actions';
import { generateToSet, Loadable, LoadingStatus } from '@Mesh/core/models/external/loadable';
import { Plan } from '@Mesh/core/models/brand';
import { orderBy } from 'lodash-es';
import { City, Region } from '@Mesh/core/models/location';
import { CategoryMain } from '@Mesh/core/models/tree';

export interface State {
  categories: Loadable<Array<CategoryMain>>;
  categoriesMain: Loadable<Array<CategoryMain>>;
  plans: Loadable<Array<Plan>>;
  cities: Loadable<Array<City>>;
  regions: Loadable<Array<Region>>;
}

export const initialState: State = {
  categories: new Loadable([], LoadingStatus.NotLoaded),
  categoriesMain: new Loadable([], LoadingStatus.NotLoaded),
  plans: new Loadable([], LoadingStatus.NotLoaded),
  cities: new Loadable([], LoadingStatus.NotLoaded),
  regions: new Loadable([], LoadingStatus.NotLoaded)
};

const applicationSettingsReducer = createReducer(
  initialState,
  on(fromActions.loadCategories, state => {
    if (state.categories.loadingStatus === LoadingStatus.NotLoaded || state.categories.loadingStatus === LoadingStatus.Error) {
      return { ...state, categories: new Loadable([], LoadingStatus.Loading) };
    }
    return { ...state };
  }),
  on(fromActions.categoriesLoaded, (state, { categories }) => {
    const ctg = new Loadable(categories, LoadingStatus.Loaded);
    return { ...state, categories: ctg };
  }),
  on(fromActions.loadCategoriesMain, state => {
    if (state.categoriesMain.loadingStatus === LoadingStatus.NotLoaded || state.categoriesMain.loadingStatus === LoadingStatus.Error) {
      return { ...state, categoriesMain: new Loadable([], LoadingStatus.Loading) };
    }
    return { ...state };
  }),
  on(fromActions.categoriesMainLoaded, (state, { categories }) => {
    const ctg = new Loadable(categories, LoadingStatus.Loaded);
    return { ...state, categoriesMain: ctg };
  }),
  on(fromActions.loadPlans, state => {
    if (state.plans.loadingStatus === LoadingStatus.NotLoaded || state.plans.loadingStatus === LoadingStatus.Error) {
      return { ...state, plans: new Loadable([], LoadingStatus.Loading) };
    }
    return { ...state };
  }),
  on(fromActions.plansLoaded, (state, { plans }) => ({ ...state, plans: new Loadable(plans, LoadingStatus.Loaded) })),
  on(fromActions.loadCities, (state, query) => (
    {
      ...state,
      cities: (state.cities.loadingStatus === LoadingStatus.NotLoaded
        || state.cities.loadingStatus === LoadingStatus.Error
        || state.cities.query !== query) ?
        new Loadable(query?.regionCode && !query?.page ? [] : state.cities.value, LoadingStatus.Loading, query) :
        state.cities
    })
  ),
  on(fromActions.citiesLoaded, (state, { cities }) => (
    {
      ...state,
      cities: state.cities.loadingStatus === LoadingStatus.Loading ?
        {...state.cities, value: generateToSet( state.cities.value.concat( cities ), 'cityName' ), loadingStatus: LoadingStatus.Loaded } :
        state.cities
    })
  ),
  on(fromActions.loadRegions, (state, query) => (
    {
      ...state,
      regions: (state.regions.loadingStatus === LoadingStatus.NotLoaded
        || state.regions.loadingStatus === LoadingStatus.Error
        || state.regions.query !== query) ?
        {...state.regions, loadingStatus: LoadingStatus.Loading, query: query } :
        {...state.regions}
    })
  ),
  on(fromActions.regionsLoaded, (state, { regions }) => (
    {
      ...state,
      regions: state.regions.loadingStatus === LoadingStatus.Loading ?
        {...state.regions, value: generateToSet(state.regions.value.concat(regions), 'regionName'), loadingStatus: LoadingStatus.Loaded} :
        state.regions
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return applicationSettingsReducer(state, action);
}

export const getCategoriesLoadingStatus = (state: State) => state.categories.loadingStatus;
export const getCategoriesValue = (state: State) => orderBy(state.categories.value, 'categoryName');
export const getCategoriesMainLoadingStatus = (state: State) => state.categoriesMain.loadingStatus;
export const getCategoriesMainValue = (state: State) => orderBy(state.categoriesMain.value, 'name');
export const getPlansLoadingStatus = (state: State) => state.plans.loadingStatus;
export const getPlansValue = (state: State) => orderBy(state.plans.value, 'name');
export const getCitiesLoadingStatus = (state: State) => state.cities.loadingStatus;
export const getCitiesValue = (state: State) => orderBy(state.cities.value, 'cityName');
export const getRegionsLoadingStatus = (state: State) => state.regions.loadingStatus;
export const getRegionsValue = (state: State) => orderBy(state.regions.value, 'regionName');

