import { createAction, props } from '@ngrx/store';
import { Outlet, OutletFilters, OutletData, OutletFilterCriteria } from '@Mesh/core/models/outlet';
import { TaskProgress } from '@Mesh/core/models/task';

enum OutletActionTypes {
    LoadOutlets = '[Outlets] Load Outlets',
    PopulateOutlets = '[Outlets] Populate Outlet Entity',
    SetSelectedOutlet = '[Outlets] Set SelectedOutlet',
    SetSelectedTaskOutletClient = '[Outlets] Set SelectedTaskOutletCLient',
    SetOutletFilters = '[Outlets] Set Outlet Filters',
    LoadOutletList = '[Outlets] Get Outlet list',
    OutletListLoaded = '[Outlets] Outlet list loaded',
    LoadClientOutletIds = '[Outlets] Load client selected outlets',
    LoadTaskOutletIds = '[Outlets] Load task selected outlet Ids',
    SetSelectedOutlets = '[Outlets] Set selected outlets',
}

export const getOutlets = createAction(OutletActionTypes.LoadOutlets, props<{ clientSapId?: number, pageSize?: number }>());
export const getClientOutletIds = createAction(OutletActionTypes.LoadClientOutletIds, props<{ taskId: number; clientId: number }>());
export const getTaskOutletIds = createAction(OutletActionTypes.LoadTaskOutletIds, props<{ taskId: number }>());
export const populateOutletEntity = createAction(OutletActionTypes.PopulateOutlets, props<{ outlets: Outlet[] }>());
export const setSelectedOutlet = createAction(OutletActionTypes.SetSelectedOutlet, props<{ id: number }>());
export const setSelectedTaskOutletClient = createAction(OutletActionTypes.SetSelectedTaskOutletClient, props<{ taskProgress: TaskProgress }>());
export const setSelectedOutlets = createAction(OutletActionTypes.SetSelectedOutlets, props<{ ids: number[] }>());
export const setOutletFilters = createAction(OutletActionTypes.SetOutletFilters, props<{ filters: OutletFilters }>());
export const loadOutletList = createAction(OutletActionTypes.LoadOutletList, props<OutletFilterCriteria>());
export const outletListLoaded = createAction(OutletActionTypes.OutletListLoaded, props<{ outletData: OutletData }>());
