import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatDynamicService {

  private adItemSubject: BehaviorSubject<AdItem | null> = new BehaviorSubject<AdItem | null>(null);

  createDynamicItem(component: Type<any>, data: any): void {
    const adItem = new AdItem(component, data);
    this.adItemSubject.next(adItem);
  }

  removeDynamicItem() {
    this.adItemSubject.next(null);
  }

  getDynamicItemObservable(): Observable<AdItem | null> {
    return this.adItemSubject.asObservable();
  }
}


export class AdItem {
  constructor(public component: Type<any>, public data: any) { }
}
