import { HRDocumentType } from "./documents-type.enum";
import {
  PassportFirstPagePayload,
  PassportOtherPagesPayload,
  SNILSPayload,
  INNPayload,
  OtherDocumentsPayload,
  DocInfoItem,
  DocumentPayload,
} from "./chat-hr-candidat-documents";

export class HRDocumentMapper {
  static mapPassportFirstPage(payload: PassportFirstPagePayload): DocInfoItem[] {
    return [
      { key: "series", label: "Серия", value: payload.series, progress: 100, isEditing: false },
      { key: "number", label: "Номер", value: payload.number, progress: 100, isEditing: false },
      { key: "issued_by", label: "Паспорт выдан", value: payload.issued_by, progress: 100, isEditing: false },
      { key: "issue_date", label: "Дата выдачи", value: payload.issue_date, progress: 100, isEditing: false },
      { key: "subdivision", label: "Код подразделения", value: payload.subdivision, progress: 100, isEditing: false },
      { key: "surname", label: "Фамилия", value: payload.surname, progress: 100, isEditing: false },
      { key: "name", label: "Имя", value: payload.name, progress: 100, isEditing: false },
      { key: "middle_name", label: "Отчество", value: payload.middle_name, progress: 100, isEditing: false },
      { key: "gender", label: "Пол", value: payload.gender.toUpperCase(), progress: 100, isEditing: false },
      { key: "birth_date", label: "Дата рождения", value: payload.birth_date, progress: 100, isEditing: false },
      { key: "birth_place", label: "Место рождения", value: payload.birth_place, progress: 100, isEditing: false },
    ];
  }

  static mapPassportOtherPages(payload: PassportOtherPagesPayload): DocInfoItem[] {
    return [
      { key: "processed_text", label: "Текст", value: payload.processed_text, progress: 100, isEditing: false },
    ];
  }

  static mapPreviousPassports(payload: any): DocInfoItem[] {
    return [
      { key: "series", label: "Серия", value: payload.series, progress: 100, isEditing: false },
      { key: "number", label: "Номер", value: payload.number, progress: 100, isEditing: false },
      { key: "issued_by", label: "Паспорт выдан", value: payload.issued_by, progress: 100, isEditing: false },
      { key: "issue_date", label: "Дата выдачи", value: payload.issue_date, progress: 100, isEditing: false },
    ];
  }

  static mapSNILS(payload: SNILSPayload): DocInfoItem[] {
    return [{ key: "fullText", label: "СНИЛС", value: payload.fullText, progress: 100, isEditing: false }];
  }

  static mapINN(payload: INNPayload): DocInfoItem[] {
    return [{ key: "fullText", label: "ИНН", value: payload.fullText, progress: 100, isEditing: false }];
  }

  static mapOtherDocuments(payload: OtherDocumentsPayload): DocInfoItem[] {
    return [
      { key: "number", label: "Номер документа", value: payload.number, progress: 100, isEditing: false },
      { key: "issue_date", label: "Дата выдачи", value: payload.issue_date, progress: 100, isEditing: false },
      { key: "issued_by", label: "Выдан", value: payload.issued_by, progress: 100, isEditing: false },
    ];
  }

  static mapPayloadToDocInfo(payload: DocumentPayload, typeId: HRDocumentType): DocInfoItem[] {
    switch (typeId) {
      case HRDocumentType.PassportFirstPage:
        return this.mapPassportFirstPage(payload as PassportFirstPagePayload);
      case HRDocumentType.PassportOtherPages:
        return this.mapPassportOtherPages(payload as PassportOtherPagesPayload);
      case HRDocumentType.PreviousPassports:
        return []
      case HRDocumentType.SNILS:
        return this.mapSNILS(payload as SNILSPayload);
      case HRDocumentType.INN:
        return this.mapINN(payload as INNPayload);
      case HRDocumentType.OtherDocuments:
        return this.mapOtherDocuments(payload as OtherDocumentsPayload);
      default:
        return [];
    }
  }
}
