import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Education, Gender, Resume } from '@Mesh/core/models/resume';
import { ChatHrService } from '../../../../chat-hr.service';
import { ChatHrVisitApiService } from '../../../../chat-hr-visit-data/chat-hr-visit.service';

@Component({
  selector: 'iql-resume-view',
  templateUrl: './resume-view.component.html',
  styleUrls: ['./resume-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResumeViewComponent implements OnInit, OnDestroy {
  @Input() userId: number;

  public currentResume: Resume;
  public resumeList: Resume[];
  private ngOnDestroy$ = new Subject<void>();
  public isLoading = true;
  private error;
  public Gender = Gender;
  public Education = Education;

  constructor(
    private chatHrService: ChatHrService,
    private chatHrVisitApiService: ChatHrVisitApiService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.getResumeList(this.userId)
  }

  private getResumeList(userId: number) {
    this.chatHrVisitApiService.getResume(userId)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe({
        next: (responce) => {
          this.resumeList = responce;
          if (this.resumeList && this.resumeList.length) {
            this.currentResume = this.resumeList[0];
          }
          this.isLoading = false;
          this.cdr.markForCheck();
        },
        error: (err) => {
          console.error('Error fetching resume');
          this.error = err;
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

  public onClosed(): void {
    this.chatHrService.setShowVisitData({
      visitDataItem: null,
      entity: 'viewResume',
      open: false
    });
  }

  public onChangeResume(sourceId: string) {
    this.currentResume = this.resumeList.find(item => item.source_id === sourceId);
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }
}
