export class Product {
    materialId: number;
    materialName: string;
    id?: number;
    parentMaterialId: string;
    parentMaterialName: string;
    brandFamilyName: string;
    brandId: number;
    sectorName: string;
    categoryName: string;
    categoryId: number;
    quant: number;
    materialGroupName: string;
    url: string;
    priceList: Price[];
    productInfo: ProductInfo;
    order: Order[];
    orderHistories: Order[];
    stock: Stock[]
    orderFrozen: Order[];
    salePlans: ProductSalePlan[];
    quantities: number[];
    salePlanQuant: number;
    needToSalePlan: number;
    manufacturerName?: string;
    groupedStock: any;
    isFavorite: boolean;
  materialGroupNameId: any;
  planIds: number[];
}
export interface GroupedStock {
    salesOrgSapId: string;
    plantId: string;
    stockId: string;
    channel: string;
    priceType: string;
    distributorCode: string;
    distributorName: string;
    stock: Stock[];
}
export class Order {
    addressSapId: number;
    clientSapId: number;
    materialId: number;
    id: number;
    quant: number;

    createdAt?: string;
    updatedAt?: string;
    sendToSap?: boolean;

    delivDate?: string;
    docDate?: string;
    docNumber?: number;
    expiryDate?: string;
    mrc?: number;
    priceList?: Price[];
    rub?: number;

    totalPrice?: number;
    price?: number;
    name?: string;
    icon?: string;
    active?: boolean;
    measureUnit?: string;
    quantity?: number;
    bonus?: number;
    plan?: number;
    historyCount?: number;
    progressCount?: number;
    totalCount?: number;
    readonly?: boolean;
    needToSalePlan?: number;

    materialIds?: number[];
}

export class ProductSalePlan {
    id: number;
    planId: number;
    planName: string;
    dateFrom: string;
    dateTo: string;
    addressSapId: number;
    materialId: number;
    quant: number;
    clientSapId: number;
    planLeftQuantity: number;
    planOverQuantity: number;
    planAchieved: boolean;
}

export interface Stock {
    id: number;
    materialId: number;
    mrc: number;
    quantityMaterial: number;
    plantId: string;
    stockId: string;
    gtd: string;
    baseNdsPrice: number;
    baseUnit: string;
    ndsPrice: number;
    baseQuantUnit: number;
    unit: string;
    prices: Price[];
    discountPrice: number;
    discountNdsPrice: number;
    salesOrgSapId: string;
    distributorCode: string;
    distributorName: string;
}

export class ProductMedia {
    materialId: number;
    sort: number;
    url: string;

    '3D'?: boolean;
    id?: number;
    image?: boolean;
    matUrls?: boolean;
    presentation?: boolean;
    video?: boolean;
}

export class Reward {
    description: string;
    materialId: number;
    name: string;
    year: number;
}

export class ProductInfo {
    id?: number;
    description: string;
    name: string;
    imageUrl: string;
    since: number;
    country: string;
    packages: string;
    active?: boolean;
}

export class ProductQuantity {
    addressSapId: number;
    clientSapId: number;
    id: number;
    materialId: number;
    quantity: number;
}

export interface Price {
    id: number;
    materialId: number;
    measureUnit: string;
    mrc: string;
    ndsPerc: string;
    priceName: string;
    priceRub: string;
    priceRubWithNds: string;
    sectorId: string;
    dateFrom: number;
    dateTo: number;
}

export class ProductPricelist {
    materialId: number;
    priceList: Price[];
}

export interface ProductsData {
    content: Product[];
    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        };
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    number: number;
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    size: number;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
    isPlan?: boolean;
}

export interface PlanProgress {
    id: number;
    salePlanQuant: number;
    needToSalePlan: number;
    exceedSalePlan: number;
}


export interface ProductCriteria {
    materialName?: string;
    materialIds?: number[];
    addressSapId: number;
    clientSapId: number;
    materialGroupNameId?: string;
    manufacturerNameId?: string;
    categoryNameId?: string;
    brandNameId?: string;
    brandFamilyNameId?: string;
    productTypeNameId?: string;
    planId?: string;
    leftoverAt?: string;
    ignoreStock?: boolean;
    sort?: string;
    pageNumber: number;
    pageSize: number;
    onlyFavorites?: boolean;
  }