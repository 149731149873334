import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TableColumn, TableColumnType } from './models/columns';
import { DatatableComponent, DataTablePagerComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { ITableInputData, ITableType } from './models/table';
import { IMAGE_URL, SALEPLAN_IMAGES_URL } from '@Env/environment';
import { faEdit, faEye, faPaperPlane, faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import * as moment from 'moment';
import { Order } from '@Mesh/core/models/product';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { TaskStatusApi } from '@Mesh/core/models/task';
import { RowDetailTemplateDirective } from '@Mesh/shared/components/table-v2/elements/row-detail-template/row-detail-template.directive';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { statusColor } from '@Mesh/pages/employees/consts/status-color';
import { userStatus } from '@Mesh/pages/employees/consts/status';
import { permissionIcons } from '@Mesh/pages/employees/consts/permission-icons';
import { buttonTexts } from '@Mesh/pages/employees/consts/button-texts';
import { permissionsTexts } from '@Mesh/pages/employees/consts/permissions-texts';
import { IMAGE_CLOUD_URL } from '@Env/environment';
import { forEach, uniq } from 'lodash-es';
import { CoefficientType } from '@Mesh/pages/goals/components/create-goals/createGoalTypes';

@Component({
  selector: 'iql-table-v2',
  templateUrl: './table-v2.component.html',
  styleUrls: ['./table-v2.component.scss'],
})
export class TableV2Component implements OnChanges {
  readonly url = IMAGE_URL;
  readonly basePath = SALEPLAN_IMAGES_URL;
  readonly cloudUrl = IMAGE_CLOUD_URL;
  categoryId: number;
  brandId: number;
  outletId: number;
  CoefficientType = CoefficientType;

  icons = {
    faEye: faEye,
    faEdit: faEdit,
    faTrash: faTrashAlt,
    faPaperPlane: faPaperPlane,
    faSave: faSave,
    faArrowAltCircleDown: faArrowAltCircleDown,
    // faCatalog: faCatalog
  };

  statusImages = {
    inProgress: '',
    picMonitoring: '',
  };

  classes = {
    'Новый заказ': '#FFD600',
    'Заказ принят': '#166FFF',
    'Заказ доставлен': '#58E400',
    'Заказ отклонен': '#FF5254',
    'В процессе выполнения': '#3BDBC5',
    'На модерации': '#FF00A0',
    Выполнено: '#53D37A',
    Провалено: '#FF4857',
  };

  LoadingStatus = LoadingStatus;

  // input

  @Input() data: ITableInputData;
  @Input() inMemoryPaging = false;
  @Input() witFooter = true;
  @Input() tableClass = '';
  @Input() columns: TableColumn[];
  @Input() tableType: ITableType;
  @Input() changings: { row: any; key: string }[] = [];
  @Input() clientSapId = 0;
  @Input() showActivateButton = false;

  // output

  @Output() innerButtonClick = new Subject<{ row: any; buttonName: string }>();
  @Output() pushChangings = new Subject<{ row: any; key: string }[]>();
  @Output() filterProducts = new EventEmitter<number>();
  @Output() onSelectRows = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() onSend = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onShow = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onActivate = new EventEmitter<any>();
  @Output() onHistory = new EventEmitter<any>();
  @Output() onBasket = new EventEmitter<any>();
  @Output() onTextBtnClick = new EventEmitter<{ columnName: string; data: any }>();
  @Output() onCatalogBtnClick = new EventEmitter<any>();
  @Output() onViewDetailsClick = new EventEmitter<{ columnName: string; data: any }>();
  @Output() onSort = new EventEmitter<{ columnName: string; dir: string }>();
  @Output() changedUserPlan = new EventEmitter<{ row: any; userPlan: boolean }>();
  @Output() onExpand = new EventEmitter<any>();

  @ContentChild(RowDetailTemplateDirective, { read: TemplateRef, static: true }) rowDetailTemplate: TemplateRef<any>;

  // table
  limit = 10;
  tableRowData: any[];
  selected = [];
  scrollBarHorizontal = window.innerWidth < 1250;
  columnModeSetting = 'force';
  TableType = ITableType;
  TableColumnType = TableColumnType;
  selectedMessage = 'выбрано';
  totalMessage = 'всего';
  rowCount = 0;
  pageSize = 0;
  pageCoef = 0;

  tableStatus: LoadingStatus;

  statusColors = statusColor;
  userStatuses = userStatus;
  permissionIcons = permissionIcons;
  buttonTexts = buttonTexts;
  permissionTexts = permissionsTexts;

  @ViewChild('table', { static: false }) table: DatatableComponent;
  @ViewChild('pager', { static: false }) pager: DataTablePagerComponent;

  constructor() {
    window.onresize = () => (this.scrollBarHorizontal = window.innerWidth < 1850);
  }

  private getBrandName(brandId: number): string {
    const brand = this.data.brandList?.find((b) => b.id === brandId);
    return brand ? brand.brandName : '';
  }

  private getClientName(clientSapId: number): string {
    const client = this.data.clients.content.find((c) => c.clientSapId === clientSapId);
    return client ? client.clientName : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientSapId && this.tableRowData) {
      this.tableRowData.forEach((r) => {
        r.clientSapId = this.clientSapId ? this.clientSapId : 0;
      });
    }
    if (changes.data) {
      this.tableStatus = this.data?.loadingStatus;
    }
    if (changes.data && this.tableType === ITableType.catalog) {
      if (this.data?.products?.content) {
        this.limit = 9999;
        this.tableRowData = [...this.data.products.content];
        this.tableRowData.forEach((r) => {
          r.active = true;
          if (r.readonly === undefined) {
            r.readonly = true;
          }
          r.icon = r.url ? `/${r.url}` : r.productInfo && r.productInfo.imageUrl ? r.productInfo.imageUrl : '';
          r.shortName = r.productInfo && r.productInfo.name ? r.productInfo.name : r.materialName;
          r.isBasketEnabled = false; // basket state
          // прайс
          if (r.priceList && r.priceList.length) {
            r.priceList = r.priceList[0];
            r.priceRubWithNds = r.priceList.priceRubWithNds;
            r.measureUnit = r.priceList.measureUnit;
            r.mrc = r.priceList.mrc;
            r.measureUnit && r.measureUnit === 'PAK' ? (r.measureUnit = 'Блок') : r.measureUnit?.toString();
          }
          // план
          if (r.salePlans && r.salePlans.length) {
            r.salePlans = r.salePlans[0];
            r.goes = [r.salePlans.dateFrom, r.salePlans.dateTo];
          }
          r.requestCount = r.amountOrderFrozen + r.amountHistory;
          r.totalCount = r.requestCount > r.salePlanQuant ? r.salePlanQuant : r.requestCount;
          // количество
          if (this.data.quantity) {
            const quantity = this.data.quantity ? this.data.quantity.find((q) => q.materialId === r.materialId) : null;
            quantity ? (r.quantity = quantity.quantity) : (r.quantity = 0);
          }
        });
        this.rowCount = this.data.products.totalElements;
        if (this.pager) {
          this.pager.page = 0;
        }
        // this.pageSize = this.data.products.size;
        // this.pageCoef = this.data.products.number * 10;
      } else {
        this.tableRowData = [];
        this.rowCount = 0;
        this.pageSize = 0;
        this.pageCoef = 0;
        if (this.pager) {
          this.pager.page = 0;
        }
      }
    }
    if (changes.data && this.tableType === ITableType.planCatalog) {
      if (this.data?.products?.content) {
        this.limit = 10;
        this.tableRowData = [...this.data.products.content];
        this.tableRowData.forEach((r) => {
          r.active = true;
          r.shortName = r.productInfo && r.productInfo.name ? r.productInfo.name : r.materialName;
          r.bonus = r.bonus || 0;
          r.quant = r.quant || 0;
        });
        this.rowCount = this.data.products.totalElements;
        this.pageSize = this.data.products.size;
        this.pageCoef = this.data.products.number * 10;
        if (this.pager) {
          this.pager.page = 0;
        }
      }
      if (!this.data.products && this.data.planConfigItems && this.data.planConfigItems.length) {
        const rows = [];
        this.data.planConfigItems.forEach((pci) =>
          rows.unshift({
            ...pci,
            active: true,
            shortName: pci.materialId,
            bonus: 0,
          })
        );
        this.tableRowData = [...rows];
        this.rowCount = this.data.planConfigItems.length;
        this.pageSize = 10;
        this.pageCoef = 0;
      }
    }

    if (changes.data && this.tableType === ITableType.goals) {
      if (this.data?.products?.content) {
        this.limit = 10;
        this.tableRowData = [...this.data.products.content];
        this.tableRowData.forEach((r) => {
          r.active = true;
          r.icon = r.url ? `/${r.url}` : r.productInfo && r.productInfo.imageUrl ? r.productInfo.imageUrl : '';
          r.shortName = r.productInfo && r.productInfo.name ? r.productInfo.name : r.materialName;
          r.isBasketEnabled = true;
          if (r.stock && r.stock.length) {
            r.stockQuantity = 0;
            for (const stock of r.stock) {
              r.stockQuantity += stock.quantityMaterial * stock.baseQuantUnit;
            }

            r.stockPricePerPieceMrc = r.stock[0].mrc;
            r.stockPricePerPiece = r.stock[0].basePrice;
            r.stock.forEach((s) => {
              s.stockUnit = `${s.unit} (${s.baseQuantUnit}${r.stock[0].baseUnit})`;
            });

            r.stockUnits = uniq([...r.stock.map((s) => s.stockUnit), r.stock[0].baseUnit]);
            r.stockUnit = r.stockUnits[0];

            const selectedStockInfo = r.stock.find((s) => s.stockUnit === r.stockUnit);
            r.stockPricePerUnit = !selectedStockInfo
              ? r.stock[0].basePrice
              : selectedStockInfo.basePrice * selectedStockInfo.baseQuantUnit;
          }
        });
        this.rowCount = this.data.products.totalElements;
        this.pageSize = this.data.products.size;
        this.pageCoef = this.data.products.number * 10;
        if (this.pager) {
          this.pager.page = 0;
        }
      } else {
        this.tableRowData = [];
        this.rowCount = 0;
        this.pageSize = 0;
        this.pageCoef = 0;
        if (this.pager) {
          this.pager.page = 0;
        }
      }
    }

    if (changes.data && this.tableType === ITableType.orders) {
      this.limit = 100;
      if (this.data?.docHistories?.length) {
        this.tableRowData = [...this.data.docHistories];
        if (this.data.selectedRowId) {
          const row = this.tableRowData?.find((t) => t.docNumber === this.data.selectedRowId);
          if (row) {
            this.table.rowDetail.toggleExpandRow(row);
          }
          return;
        }
        this.tableRowData.forEach((r) => {
          r.active = true;
          r.name = r.docNumber ? `Заказ#${r.docNumber}` : 'Заказ в обработке';
          r.status = r.docDate ? `Обработан` : 'Нет данных';
        });
      }
    }
    if (changes.data && this.tableType === ITableType.ordersInfo) {
      this.limit = 50;
      if (this.data.orders && this.data.orders.content) {
        this.tableRowData = [...this.data.orders.content];
        this.tableRowData.forEach((r) => {
          const product = r.product;
          r.shortName = product.materialName;
          r.icon = product.url
            ? `/${product.url}`
            : product.productInfo && product.productInfo.imageUrl
            ? product.productInfo.imageUrl
            : '';
          r.active = true;
        });
      }
    }
    if (changes.data && this.tableType === ITableType.ordersHistory) {
      this.limit = 50;
      if (this.data?.ordersHistory?.content) {
        this.tableRowData = [...this.data.ordersHistory.content];
        this.tableRowData.forEach((r) => {
          const product = r;
          r.name = product.docNumber;
          r.status =
            product.status === 0
              ? 'Новый'
              : product.status === 1
              ? 'Принят'
              : product.status === 2
              ? 'Исполнен'
              : product.status === 3
              ? 'Отменен'
              : '';
          r.date = product.docDateTime;
          r.address = product.address;
          r.price = product.cost;
          r.skuCount = product.countProduct;
          r.icon = product.icon;
          r.active = product.active;
        });
      }
      if (this.pager) {
        this.rowCount = this.data.ordersHistory.totalElements;
        this.pageSize = this.data.ordersHistory.size;
        this.pageCoef = this.data.ordersHistory.number * 10;
        this.pager.page = this.data.ordersHistory.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.ordersState) {
      if (this.data?.productsWithOrders?.length && this.data?.routeParams) {
        const products = this.data.productsWithOrders;
        const dateId = +this.data.routeParams.dateId;
        let orders = products
          .map((p) => {
            p.orderFrozen.forEach((pof) => {
              //pof.totalPrice = p.priceList && p.priceList.length && pof.quant ? +(+p.priceList[0].priceRubWithNds * pof.quant).toFixed(2) : 0;
              pof.price = p.priceList && p.priceList.length ? +(+p.priceList[0].priceRubWithNds).toFixed(2) : 0;
              pof.name = p.materialName;
              pof.mrc = p.priceList && p.priceList.length ? +p.priceList[0].mrc : 0;
              pof.icon = p.url ? `/${p.url}` : p.productInfo && p.productInfo.imageUrl ? p.productInfo.imageUrl : '';
              pof.active = true;
              pof.measureUnit = p.priceList && p.priceList.length ? p.priceList[0].measureUnit : '';
              pof.measureUnit && pof.measureUnit === 'PAK' ? (pof.measureUnit = 'Блок') : pof.measureUnit?.toString();
              pof.quantity = p.quantities && p.quantities.length ? p.quantities[0] : 0;
              pof.bonus = 0;
              pof.plan = +p?.salePlanQuant;
              pof.needToSalePlan = +p?.needToSalePlan;
              if (pof.readonly === undefined) {
                pof.readonly = true;
              }
              // история
              if (p.orderHistories && p.orderHistories.length) {
                pof.historyCount = 0;
                p.orderHistories.forEach((o: Order) => {
                  pof.historyCount += o.quant ? o.quant : 0;
                });
              }
              // прогресс
              pof.historyCount = pof.historyCount ? pof.historyCount : 0;
              pof.quant = pof.quant ? pof.quant : 0;
              pof.progressCount = pof.historyCount + pof.quant;
              pof.totalPrice = pof.price * pof.quant;
            });
            return p.orderFrozen;
          })
          .filter((o) => o.length)
          .reduce((sum, o) => sum.concat(o), []);
        //фильтр ордеров с неподходящей датой
        //const date = new Date(dateId).getDate();
        //orders = orders.filter(o =>  new Date(Date.parse(o.createdAt)).getDate() === date);
        // orders = orders.reduce((sum, el) => {
        //     if (sum && sum.length) {
        //         if (sum[sum.length - 1].name === el.name) {
        //             sum[sum.length - 1].quant += el.quant;
        //             sum[sum.length - 1].totalPrice += el.totalPrice;
        //             return sum
        //         } else {
        //             sum.push(el);
        //             return sum
        //         }
        //     } else {
        //         return [el]
        //     }
        // }, [] as Order[])
        // фильтр по id
        orders = orders.filter((o) => o.id === dateId);
        this.tableRowData = [...orders];
      }
      if (this.data.brandId && this.tableRowData && this.tableRowData.length) {
        this.tableRowData.forEach((r) => {
          r.brandId = this.data.brandId;
        });
      }
    }
    if (
      changes.data &&
      (this.tableType === ITableType.tasks ||
        this.tableType === ITableType.producerTasks ||
        this.tableType === ITableType.tasksHistory) &&
      this.data?.tasks
    ) {
      this.tableRowData = [...this.data.tasks.content];
      this.brandId = this.data.brandId;
      this.tableRowData.forEach((r) => {
        r.changed = false;
        r.brandId = this.brandId ? this.brandId : '';
        r.clientSapId = this.clientSapId ? this.clientSapId : 0;
        if (this.data.progress) {
          const progress = this.data.progress.find((p) => p.taskId === r.id);
          if (progress) {
            r.progress = { current: progress.countOutletTaskSuccess, target: progress.countOutletTask };
          }
        }
      });
      this.rowCount = this.data.tasks.totalElements;
      this.pageSize = this.data.tasks.size;
      this.pageCoef = this.data.tasks.number * 10;
      if (this.pager) {
        this.pager.page = this.data.tasks.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.producers && this.data?.brands) {
      this.tableRowData = [...this.data.brands.content];
      this.tableRowData.forEach((r) => {
        r.shortName = r.brandInfo && r.brandInfo.name ? r.brandInfo.name : r.brandName;
        r.icon = r.brandInfo && r.brandInfo.name ? r.brandInfo.icon : '';
        if (this.data.progress) {
          const progress = this.data.progress.find((p) => p.brandId === r.id);
          if (progress) {
            r.progress = { current: progress.countOutletTaskSuccess, target: progress.countOutletTask };
          }
        }
      });

      if (this.pager) {
        this.rowCount = this.data.brands.totalElements;
        this.pageSize = this.data.brands.size;
        this.pageCoef = this.data.brands.number * 10;
        this.pager.page = this.data.brands.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.taskOverview && this.data?.outlets) {
      this.tableRowData = [];
      for (const outlet of this.data.outlets) {
        let row: any;

        if (this.data.taskProgress && this.data.taskProgress[outlet.addressSapId]) {
          const taskInfo = this.data.taskProgress[outlet.addressSapId];

          const newStatusCount = taskInfo.statusCounts.find((s) => s.status === TaskStatusApi.new).stepCount;
          const acceptedStatusCount = taskInfo.statusCounts.find((s) => s.status === TaskStatusApi.accepted).stepCount;
          const declinedStatusCount = taskInfo.statusCounts.find(
            (s) => s.status === TaskStatusApi.notAccepted
          ).stepCount;
          const moderationStatusCount = taskInfo.statusCounts.find(
            (s) => s.status === TaskStatusApi.checking
          ).stepCount;

          const isNew = newStatusCount + declinedStatusCount === taskInfo.stepCount;
          const isAccepted = acceptedStatusCount === taskInfo.stepCount;
          const isOnModeration =
            moderationStatusCount > 0 && acceptedStatusCount + moderationStatusCount === taskInfo.stepCount;

          row = { ...outlet, taskInfo: taskInfo };

          if (isOnModeration && taskInfo.lastSettingCheckingOrApprovedStatusAt) {
            row.countDown =
              10 * 60 - moment.utc().diff(moment.utc(taskInfo.lastSettingCheckingOrApprovedStatusAt), 'seconds');

            row.countDown = row.countDown >= 10 * 60 ? undefined : row.countDown < 0 ? undefined : row.countDown;

            row.countDownConfig = { leftTime: row.countDown };
          }

          row.className = isOnModeration ? 'not-finished' : isNew ? 'new' : isAccepted ? 'succeed' : 'new';
        }

        if (row && outlet.addressSapId === this.data.selectedRowId) {
          row.className = 'selected';
        }

        if (row) {
          this.tableRowData.push(row);
        }
      }
      this.pageSize = 10;
      this.rowCount = this.tableRowData.length;
    }
    if (changes.data && this.tableType === ITableType.clients && this.data?.clients) {
      this.tableRowData = [...this.data.clients.content];
      if (this.pager) {
        this.rowCount = this.data.clients.totalElements;
        this.pageSize = this.data.clients.size;
        this.pageCoef = this.data.clients.number * 10;
        this.pager.page = this.data.clients.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.producerClients && this.data?.taskClients) {
      this.tableRowData = [...this.data.taskClients.content];
      if (this.data.routeParams) {
        this.tableRowData.forEach((r) => {
          r.taskId = this.data.routeParams.taskId;
          r.brandId = this.data.routeParams.brandId;
          r.active = true;
        });
      }
      if (this.data.progress) {
        this.tableRowData.forEach((r) => {
          const progress = this.data.progress.find((p) => p.clientSapId === r.clientSapId);
          if (progress) {
            r.progress = { current: progress.countOutletTaskSuccess, target: progress.countOutletTask };
          }
        });
      }
      if (this.pager) {
        this.rowCount = this.data.taskClients.totalElements;
        this.pageSize = this.data.taskClients.size;
        this.pageCoef = this.data.taskClients.number * 10;
        this.pager.page = this.data.taskClients.number + 1;
      }
    }
    if (
      changes.data &&
      (this.tableType === ITableType.producerOutlets || this.tableType === ITableType.outlets) &&
      this.data?.outletData
    ) {
      this.tableRowData = [...this.data.outletData.content];
      if (this.data.routeParams) {
        this.tableRowData.forEach((r) => {
          r.taskId = this.data.routeParams.taskId;
          r.brandId = this.data.routeParams.brandId;
          r.clientSapId = this.data.routeParams.clientSapId;
        });
      }
      if (this.pager) {
        this.rowCount = this.data.outletData.totalElements;
        this.pageSize = this.data.outletData.size;
        this.pageCoef = this.data.outletData.number * 10;
        this.pager.page = this.data.outletData.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.documents && this.data?.documentsData) {
      this.tableRowData = [...this.data.documentsData.content];
      if (this.pager) {
        this.rowCount = this.data.documentsData.totalElements;
        this.pageSize = this.data.documentsData.size;
        this.pageCoef = this.data.documentsData.number * 10;
        this.pager.page = this.data.documentsData.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.outletCatalog && this.data?.outletCatalogData) {
      this.tableRowData = [...this.data.outletCatalogData.content];
      if (this.pager) {
        this.rowCount = this.data.outletCatalogData.totalElements;
        this.pageSize = this.data.outletCatalogData.size;
        this.pageCoef = this.data.outletCatalogData.number * 10;
        this.pager.page = this.data.outletCatalogData.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.outletActiveOrders && this.data?.outletActiveOrdersData) {
      this.tableRowData = [...this.data.outletActiveOrdersData.content];
      this.tableRowData.forEach((r) => {
        const product = r;
        r.name = product.docNumber;
        r.status = product.status === 0 ? 'Новый' : product.status === 1 ? 'Принят' : '';
        r.date = product.docDateTime;
        r.address = product.address;
        r.price = product.cost;
        r.skuCount = product.countProduct;
        r.icon = product.icon;
        r.active = product.active;
      });
      if (this.pager) {
        this.rowCount = this.data.outletActiveOrdersData.totalElements;
        this.pageSize = this.data.outletActiveOrdersData.size;
        this.pageCoef = this.data.outletActiveOrdersData.number * 10;
        this.pager.page = this.data.outletActiveOrdersData.number + 1;
      }
    }
    if (changes.data && this.tableType === ITableType.planConfigs && this.data?.planConfigs) {
      this.tableRowData = [...this.data.planConfigs];
      this.limit = 500;
      this.tableRowData.forEach((r) => {
        r.active = true;
      });
    }
    if (changes.data && this.tableType === ITableType.bonusConfigs && this.data?.bonusConfigs) {
      this.tableRowData = [...this.data.bonusConfigs];
      this.limit = 500;
      this.tableRowData.forEach((r) => {
        r.active = true;
      });
    }
    if (changes.data && this.data?.details) {
      this.tableRowData = [...this.data.details];
      if (this.pager) {
        this.rowCount = this.data.details.length;
        this.pageSize = this.data.details.length;
        this.pageCoef = this.data.details.length;
        this.pager.page = 1;
      }
    }
  }

  onSelect(rows: any[]): void {
    this.onSelectRows.emit(rows);
  }

  onFooterPage(): void {
    //this.pageCoef = (this.pager.page - 1) * 10;
    this.filterProducts.next(this.pager.page - 1);
  }

  onSelectAllClick(event: any): void {
    event.target.checked ? (this.selected = [...this.tableRowData]) : (this.selected = []);
  }

  valueChanged(data: { row: any; key: string }): void {
    data.row.changed = true;
    const isAlreadyChanged = this.changings.find((c) => c.row.materialId === data.row.materialId && c.key === data.key);
    if (!isAlreadyChanged) {
      this.changings.push(data);
    }
  }

  onDataChange(row: any): void {
    row.changed = false;
    this.onSend.emit(row);
  }

  onDataEdit(row: any): void {
    this.onEdit.emit(row);
  }

  onDataShow(row: any): void {
    this.onShow.emit(row);
  }

  onDataDelete(row: any): void {
    this.onDelete.emit(row);
  }

  onDataActivate(row: any): void {
    this.onActivate.emit(row);
  }

  getRowClass(row: any): string {
    return row.className ? row.className : `${!row.active ? 'deactivated' : ''} ${row.succseed ? 'succseed' : ''}`;
  }

  onInnerButtonClick(row: any, buttonName: string): void {
    this.innerButtonClick.next({ row, buttonName });
  }

  onRowClick(el: any): void {
    if (el.type === 'click') {
      if (this.tableType === 'clients' && el.column.name === 'Документы') {
        this.rowClicked.emit({
          ...el.row,
          colName: 'Документы',
        });
        return;
      }
      this.rowClicked.emit(el.row);
    }
  }

  onSortClick(evt: any): void {
    if (evt?.sorts && evt.sorts.length > 0) {
      const sort = evt.sorts[0];
      this.onSort.emit({ columnName: sort.prop, dir: sort.dir });
    }
  }

  onTextButtonClick(columnName: string, row: any): void {
    this.onTextBtnClick.emit({ columnName: columnName, data: row });
  }

  onViewClick(columnName: string, row: any): void {
    this.onViewDetailsClick.emit({ columnName: columnName, data: row });
  }

  onCatalogButtonClick(row: any): void {
    this.onCatalogBtnClick.emit(row);
  }

  onHistoryClick(row: any): void {
    this.onHistory.emit(row);
  }

  makeRowReadonly(row: any): void {
    row.readonly = true;
  }

  makeRowEditable(row: any): void {
    row.readonly = false;
  }

  onDetailToggle(event: any): void {}

  toggleExpandRow(row: any, expandedValue: any): void {
    this.table.rowDetail.toggleExpandRow(row);
    if (!expandedValue) {
      this.onExpand.emit(row);
    }
  }

  toggleText(row: any): void {
    row.value = !row.value;
  }

  onChangeUserPlan(row: any, userPlan: boolean) {
    this.changedUserPlan.emit({ row, userPlan });
  }
}
