import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/api/auth/auth.service';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { State } from '@Mesh/store/reducers';
import { Store } from '@ngrx/store';
import { refreshTokenSuccess } from '@Mesh/store/actions/auth/auth.actions';
import { IUserInfo } from '@Mesh/core/models/user';
import { HRUserStateService } from '../services/api/auth/hr-user-state.service';
import { HR_URL } from '@Env/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private store: Store<State>,
    private hRUserStateService: HRUserStateService,
  ) {
  }

  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authenticatedRequest = request;
    const token = this.authService.accessToken;

    // if (token != null && !request.url.endsWith('api/v1/refresh-token') && (!request.url.endsWith('api/v1/token') || request.url.indexOf('notification') !== -1) && !request.url.endsWith('upload-media')) {
    //   authenticatedRequest = this.addTokenHeader(request, token);
    // }

    const isRefreshTokenEndpoint = request.url.endsWith('api/v1/refresh-token');
    const isTokenEndpoint = request.url.endsWith('api/v1/token');
    const isNotificationInURL = request.url.indexOf('notification') !== -1;
    const isUploadMediaEndpoint = request.url.endsWith('upload-media');
    const isApiHr = request.url.indexOf(HR_URL) !== -1;
    const isTokensRequest = request.url.indexOf('tokens') !== -1;

    const shouldAddToken = token != null
      && !isRefreshTokenEndpoint
      && (!isTokenEndpoint || isNotificationInURL)
      && !isUploadMediaEndpoint
      && !isApiHr;

    if (shouldAddToken) {
      authenticatedRequest = this.addTokenHeader(request, token);
    }

    if (isApiHr && !isTokensRequest) {
      return this.addTokenFromHRUser(request).pipe(
        switchMap(authenticatedRequest => {
          authenticatedRequest = this.addContentTypeJson(authenticatedRequest);
          return next.handle(authenticatedRequest);
        }),
        catchError(error => {
          if (error.status === 401) {
            console.error("Unauthorized error:", error);
          }
          return throwError(error);
        })
      );
    }

    authenticatedRequest = this.addContentTypeJson(authenticatedRequest);

    return next.handle(authenticatedRequest).pipe(
      catchError(error => {
        if (error.status === 401) {
          return this.handleUnauthorizedError(authenticatedRequest, next);
        }

        return throwError(error);
      }));
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authService.updateTokens().pipe(
        switchMap((userInfo: IUserInfo) => {
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          this.store.dispatch(refreshTokenSuccess({ userInfo: userInfo }));
          this.isRefreshing = false;

          this.accessTokenSubject.next(userInfo.accessToken);

          return next.handle(this.addTokenHeader(request, userInfo.accessToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;

          this.authService.logout();
          return throwError(err);
        })
      );
    }

    return this.accessTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private addContentTypeJson(request: HttpRequest<any>): any {
    if (!request.url.endsWith('upload-media')
      && !request.url.endsWith('api/v1/content/image')
      && !request.url.endsWith('api/v1/content/doc')) {
      return request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    return request.clone({ headers: request.headers.delete('Content-Type') });
  }

  private addTokenFromHRUser(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.hRUserStateService.onChangeToken().pipe(
      take(1),
      map(token => {
        return token ? request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        }) : request;
      })
    );
  }

}
