import { HR_URL } from '@Env/environment';
import { User } from '@Mesh/shared/modules/chatHR/models/user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class ChatHrMainPageFiltersUserSearchService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  searchUser(name: string,page:number): Observable<User[]> {
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('page', page.toString());
    // params = params.append('per_page', '15');
    // params = params.append('has_resume', '1');

    return this.http.get<User[]>(`${HR_URL}/user/search`, { params })
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error finding user', err);
          return of([] as Array<User>);
        })
      );
  }

  searchUserByPhone(phone: string,page:number):  Observable<User[]>  {
    let params = new HttpParams();
    params = params.append('phone', phone);
    params = params.append('page', page.toString());
    // params = params.append('per_page', '15');
    // params = params.append('has_resume', '1');

    return this.http.get<User[]>(`${HR_URL}/user/search`, { params })
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error finding user by phone', err);
          return of([] as Array<User>);
        })
      );
  }

}
