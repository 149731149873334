import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatHrComponent } from './chat-hr.component';
import { ChatHrMainPageComponent } from './chat-hr-main-page/chat-hr-main-page.component';
import { ChatHrItemComponent } from './chat-hr-main-page/chat-hr-item/chat-hr-item.component';
import { ChatClientMainPageFilterModule } from '../chat-client-main-page/chat-client-main-page-filter/chat-client-main-page-filter.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChatTypeSwitchModule } from '../chat-type-switch/chat-type-switch.module';
import { ChatHRDialogModule } from '../../chatHR/chat.module';
import { LoadingModule } from '../../../directives/loading/loading.module';
import { ChatHrMainPageFiltersComponent } from './chat-hr-main-page/chat-hr-main-page-filters/chat-hr-main-page-filters.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PgSelectModule } from '../../../../@pages/components/select/select.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatHrMainPageTabsComponent } from './chat-hr-main-page/chat-hr-main-page-tabs/chat-hr-main-page-tabs.component';
import { ChatHrVisitItemComponent } from './chat-hr-main-page/chat-hr-visit-item/chat-hr-visit-item.component';
import { ChatHrInfoCandidateComponent } from './chat-hr-main-page/chat-hr-info-candidate/chat-hr-info-candidate.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CustomSelectModule } from '@Mesh/shared/components/custom-select/custom-select.module';
import { MultiSelectDropdownModule } from '@Mesh/shared/components/multi-select-dropdown/multi-select-dropdown.module';
import { CustomMultiselectModule } from '@Mesh/shared/components/custom-multiselect/custom-multiselect.module';
import { ChatHrMainPageFiltersUserSearchComponent } from './chat-hr-main-page/chat-hr-main-page-filters/chat-hr-main-page-filters-user-search/chat-hr-main-page-filters-user-search.component';
import { ChatHrOpenedResumeModule } from './chat-hr-opened-resume/chat-hr-opened-resume.module';
import { DraggableScrollModule } from '@Mesh/shared/directives/draggable-scroll/draggable-scroll.module';



@NgModule({
  declarations: [
    ChatHrComponent,
    ChatHrMainPageComponent,
    ChatHrItemComponent,
    ChatHrMainPageFiltersComponent,
    ChatHrMainPageTabsComponent,
    ChatHrVisitItemComponent,
    ChatHrInfoCandidateComponent,
    ChatHrMainPageFiltersUserSearchComponent,
  ],
  exports: [
    ChatHrComponent,
  ],
  imports: [
    CommonModule,
    ChatClientMainPageFilterModule,
    ChatTypeSwitchModule,
    InfiniteScrollModule,
    LoadingModule,
    ChatHRDialogModule,
    BsDatepickerModule.forRoot(),
    PgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    NgxUiLoaderModule,
    CustomSelectModule,
    MultiSelectDropdownModule,
    CustomMultiselectModule,
    ChatHrOpenedResumeModule,
    DraggableScrollModule
  ]
})
export class ChatHrModule { }
