import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, OnDestroy, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ChatHrVisitApiService } from '../../chat-hr-visit-data/chat-hr-visit.service';
import moment from 'moment';
import { ChatDialogService } from '@Mesh/shared/modules/chatHR/chat-dialog/chat-dialog.service';
import { VisitCity, VisitStatusMap } from '../../../../chatHR/models/visit-dialog';

@Component({
  selector: 'iql-chat-hr-main-page-filters',
  templateUrl: './chat-hr-main-page-filters.component.html',
  styleUrls: ['./chat-hr-main-page-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrMainPageFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isDisabledFilters: boolean;
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();

  statuses: any[];

  isParttime = [
    { label: 'Тип занятости', value: null },
    { label: 'Полная занятость', value: 0 },
    { label: 'Частичная занятость', value: 1 }
  ];
  cities: VisitCity[] = [];
  filterForm: FormGroup;
  private ngOnDestroy$: Subject<null> = new Subject<null>();
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private fb: FormBuilder,
    private chatHrVisitService: ChatHrVisitApiService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.statuses = this.setStausesList();
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-app',
        locale: 'ru',
        dateInputFormat: 'DD.MM.YYYY',
        showWeekNumbers: false
      }
    );
    this.initForm();
    this.getCities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('isDisabledFilters' in changes) {
      if (this.filterForm) {
        this.filterForm.reset();
        if (this.isDisabledFilters) {
          this.filterForm.disable();
        } else {
          this.filterForm.enable();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  initForm(): void {
    this.filterForm = this.fb.group({
      cityId: null,
      visitDate: null,
      statusId: null,
      userId: null,
      isParttime: null,
    });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe((value) => {
        this.resetFieldsWithNullValue();
        if (value.visitDate) {
          const formattedDate = moment(value.visitDate).format('YYYY-MM-DD');
          value.visitDate = formattedDate;
        }
        this.filterChanged.emit(value);
      });
  }

  getCities(): void {
    this.chatHrVisitService.getVisitCities()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(
        (data) => {
          this.cities = data;
          this.cdr.markForCheck();
        },
        (error) => {
          console.error('Ошибка при получении данных:', error);
        }
      );
  }

  resetFieldsWithNullValue(): void {
    const controls = this.filterForm.controls;
    Object.keys(controls).forEach(controlName => {
      if (controls[controlName].value === null) {
        controls[controlName].setValue(new Date(), { emitEvent: false });
        controls[controlName].reset(null, { emitEvent: false });
      }
    });
  }
  private setStausesList() {
    const statusMapList = Object.values(VisitStatusMap).filter(item => item.type !== 'UNKNOWN');
    return [
      { title: 'Статус визита', type: null },
      ...statusMapList
    ];
  }
}
