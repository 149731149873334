import { FeathersService } from './feathers.service';
import { MessagesService } from './messages.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ChatSettingsService {

  settings$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private feathers: FeathersService,
              private messagesService: MessagesService
  ) {
  }

  async updateOrCreate(type, typeId, settings) {
    const results = await this.service.find({query: {type, typeId}});
    if (results.length) {
      for (const row of results) {
        return this.service.patch(row.id, settings);
      }
    } else {
      return this.service.create({type, typeId, ...settings});
    }
  }

  async removeDialogByType(type: string) {
    const results = await this.service.find({query: {type}});
    if (results.length) {
        const deletedResults = [];
        for (const row of results) {
            return this.service.remove(row.id);
        }
    } else {
        throw new Error('Dialog with the specified type not found');
    }
}

  get service() {
    return this.feathers.service('chats-settings');
  }

  togglePin(dialog, type: string, typeId: number, flag: boolean): any {
    this.messagesService.togglePinned(dialog, flag);
    return this.updateOrCreate(type, typeId, {pinned: flag ? 1 : 0})
  }

  toggleSound(dialog, type: string, typeId: number, flag: boolean): any {
    this.messagesService.toggleSound(dialog, flag);
    return this.updateOrCreate(type, typeId, {muted: flag ? 1 : 0});
  }

  removeDialog(dialog, type: string, typeId: number): any {
    this.messagesService.removeDialog(dialog);
    return this.updateOrCreate(type, typeId, {deletedAt: moment().format('YYYY-MM-DD HH:mm:ss[Z]')});
  }

  archiveDialog(type: string, typeId: number): Promise<any> {
    const settings = { archived: 1 };
    return this.updateOrCreate(type, typeId, settings);
  }

  unArchiveDialog(type:string):Promise<any> {
    return this.removeDialogByType(type);
  }

}
