import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './store/reducers';
import { init } from './store/actions/auth/auth.actions';
import * as moment from 'moment';
import { LanguageService } from './core/services/chat/language.service';
import { AcademyUserStateService } from './core/services/api/auth/academy-user-state.service';
import { HRUserStateService } from './core/services/api/auth/hr-user-state.service';

@Component({
  selector: 'iql-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public readonly _store: Store<State>,
     public languageService: LanguageService,
     private academyUserStateService: AcademyUserStateService,
     private hrUserStateService: HRUserStateService,) {
    this.languageService.setup();
    moment.locale('ru');
    this._store.dispatch(init());
    this.academyUserStateService.publishCurrentAcademyUser();
    this.hrUserStateService.publishCurrentHRUser();
  }
}
