import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { ModalModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableHeaderComponent } from './components/table-v2/table-header/table-header.component';
import { PlanProgressComponent } from './components/table-v2/elements/plan-progress/plan-progress.component';
import { ValueChangeableComponent } from './components/table-v2/elements/value-changebel/value-changeable.component';
import { PgSelectModule } from '@Mesh/@pages/components/select/select.module';
import { ProgressModule } from '@Mesh/@pages/components/progress/progress.module';
import { TableV2Component } from './components/table-v2/table-v2.component';
import { SelectComponent } from './components/select/select.component';
import { TranslateModule } from '@ngx-translate/core';
import { TaskStatusComponent } from './components/table-v2/elements/task-status/task-status.component';
import { TextLinkComponent } from './components/table-v2/elements/text-link/text-link.component';
import { SecurePipe } from '@Mesh/core/pipes/secure.pipe';
import { DatetimePipe } from './pipes/datetime.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { pgSwitchModule } from '@Mesh/@pages/components/switch/switch.module';
import { SliderComponent } from './components/slider/slider.component';
import { pgUploadModule } from '@Mesh/@pages/components/upload/upload.module';
import { CountdownModule } from 'ngx-countdown';
import { BasketDialogComponent } from './components/basket-dialog/basket-dialog.component';
import { MultiTableComponent } from './components/multi-table/multi-table.component';
import { TreeTableComponent } from './components/tree-table/tree-table.component';
import { TableV3Component } from './components/table-v3/table-v3.component';
import { ImagePipe } from '@Mesh/core/pipes/image.pipe';
import { TableHeaderV2Component } from './components/table-v2/table-header/table-headerv2.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { IsActiveMonthDirective } from './directives/is-active-month.directive';
import { ProgressBarComponent } from './components/table-v2/table-header/progress-bar/progress-bar.component';
import { ProgressComponent } from './components/table-v2/table-header/progress/progress.component';
import { RowDetailTemplateDirective } from './components/table-v2/elements/row-detail-template/row-detail-template.directive';
import { TreeWrapperComponent } from './components/tree-wrapper/tree-wrapper.component';
import { RandomGradientModule } from './directives/random-gradient/random-gradient.module';
import { PhonePipe } from '@Mesh/shared/pipes/phone.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { PagesModule } from '@Mesh/@pages/components/pages.module';
import { DisableFormComponent } from '@Mesh/pages/catalog/disable-form/disable-form.component';
import { BadgeLabelComponent } from './components/badge-label/badge-label.component';
import {PercentCoefficientComponent} from '@Mesh/shared/components/percent-coefficient/percent-coefficient.component';
import {CoefficientItemComponent} from '@Mesh/shared/components/coefficient-item/coefficient-item.component';
import {DownloadIconComponent} from '@Mesh/shared/components/download-icon/download-icon.component';
import {MatTreeModule} from '@angular/material/tree';
import { SwitchCheckedModule } from '@Mesh/shared/components/switch/switch-checked.module';

@NgModule({
  imports: [
    CountdownModule,
    TranslateModule,
    CommonModule,
    ObserversModule,
    RouterModule,
    NgxDatatableModule,
    PgSelectModule,
    pgSwitchModule,
    ProgressModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    SwiperModule,
    PagesModule,
    FontAwesomeModule,
    pgUploadModule,
    NgxDaterangepickerMd,
    RandomGradientModule,
    AngularSvgIconModule,
    MatTreeModule,
    SwitchCheckedModule,
  ],
  declarations: [
    TableHeaderComponent,
    TableHeaderV2Component,
    CoefficientItemComponent,
    DownloadIconComponent,
    PercentCoefficientComponent,
    PlanProgressComponent,
    ValueChangeableComponent,
    TableV2Component,
    BadgeLabelComponent,
    SelectComponent,
    TaskStatusComponent,
    TextLinkComponent,
    SecurePipe,
    ImagePipe,
    DatetimePipe,
    NumberFormatPipe,
    SliderComponent,
    BasketDialogComponent,
    MultiTableComponent,
    TreeTableComponent,
    TableV3Component,
    DropdownComponent,
    IsActiveMonthDirective,
    ProgressBarComponent,
    ProgressComponent,
    DisableFormComponent,
    RowDetailTemplateDirective,
    PhonePipe,
    TreeWrapperComponent,
  ],
  exports: [
    TableHeaderComponent,
    TableHeaderV2Component,
    PercentCoefficientComponent,
    DownloadIconComponent,
    CoefficientItemComponent,
    BasketDialogComponent,
    TableV2Component,
    TableV3Component,
    SelectComponent,
    TranslateModule,
    SecurePipe,
    ImagePipe,
    DatetimePipe,
    pgSwitchModule,
    SliderComponent,
    BadgeLabelComponent,
    TaskStatusComponent,
    TextLinkComponent,
    ValueChangeableComponent,
    PlanProgressComponent,
    MultiTableComponent,
    TreeTableComponent,
    DisableFormComponent,
    RowDetailTemplateDirective,
    ReactiveFormsModule,
    PhonePipe,
    TreeWrapperComponent
  ],
  providers: []
})
export class SharedModule {
}
