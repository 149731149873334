import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { User } from '@Mesh/core/models/user';
import { Comment } from '@Mesh/core/models/comment';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';

@Component({
  selector: 'iql-chat-available-dialog-item',
  templateUrl: './chat-available-dialog-item.component.html',
  styleUrls: ['./chat-available-dialog-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatAvailableDialogItemComponent {

  @Output() dialogSelect = new EventEmitter<{ dialog: Comment }>();
  @Output() onSettings = new EventEmitter();
  @ViewChild(ContextMenuComponent, { static: false }) basicMenu: ContextMenuComponent;

  @Input() dialog: Comment;
  @Input() currentUser: User;

  constructor( private chatService: ChatService ) {
  }

  get oneMark(): boolean {
    const {readAt, createdAt} = this.message;
    return !createdAt || !readAt;
  }

  get twoMarks(): boolean {
    const {readAt, createdAt} = this.message;
    return !!(createdAt && readAt);
  }

  get message(): Comment {
    return this.dialog.message;
  }

  get user(): User {
    const {
      replyUser,
      user
    } = this.message;
    return replyUser?.id !== this.currentUser.id ? replyUser || user : user || replyUser;
  }

  get mine(): boolean {
    const {
      user
    } = this.message;
    return !this.message.senderAddressSapId;
  }

  get isTaskDialog(): boolean {
    return this.dialog.type === 'task-comments';
  }

  get isSupportDialog(): boolean {
    return this.dialog.type === 'support';
  }

  attachDialog(): void {
    this.onSettings.emit(false);
    this.chatService.togglePin(this.dialog, this.dialog.type, this.dialog.typeId, !this.dialog.settings.pinned).then((settings) => this.onSettings.emit(settings));
  }

  get onlyImages(): boolean {
    return this.dialog.message.text === '' && this.dialog.message.attached.uploads.filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif'].indexOf(item.ext) !== -1).length > 0;
  }

  noSound(): void {
    this.onSettings.emit(false);
    this.chatService.toggleSound(this.dialog, this.dialog.type, this.dialog.typeId, !this.dialog.settings.muted).then((settings) => this.onSettings.emit(settings));
  }

  remove(): void {
    this.onSettings.emit(false);
    this.chatService.removeDialog(this.dialog, this.dialog.type, this.dialog.typeId).then((settings) => this.onSettings.emit(settings));
  }

}
