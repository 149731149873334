import { VisitJournalEvent, VisitStatus } from '@Mesh/shared/modules/chatHR/models/visit-dialog';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'iql-visit-data-log-item',
  templateUrl: './visit-data-log-item.component.html',
  styleUrls: ['./visit-data-log-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitDataLogItemComponent implements OnInit {

  @Input() item: VisitJournalEvent;

  constructor() { }

  ngOnInit() {
  }

  get statusColor(): string {
    switch (this.item.status_id) {
      case VisitStatus.NEW_VISIT:
        return '#FBC531';
      case VisitStatus.VISIT_TIME_CONFIRMED:
        return '#2ECC71';
      case VisitStatus.REJECTED:
        return '#F64E60';
      case VisitStatus.FAILED_TO_VERIFY:
        return '#F64E60';
      case VisitStatus.CANDIDATE_CONFIRMED_VISIT:
        return '#2ECC71';
      case VisitStatus.CANDIDATE_DID_NOT_SHOW_UP:
        return '#F64E60';
      case VisitStatus.PERFECT_VISIT:
        return '#2ECC71';
      default:
        return '#f8f9fa';
    }
  }

}
