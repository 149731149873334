import { OrderDocInfo, OrderDocInfoDetailed } from '@Mesh/core/models/APImodels/saleplan/orders';
import { createAction, props } from '@ngrx/store';

export const loadOrdersHistories = createAction('[Order] Load Orders Histories', props<{ addressSapId: number, docDateEnd: string, docDateStart: string, status?: number[] }>());
export const loadOrdersHistoriesWithProps = createAction('[Order] Load Orders Histories with props', props<{ addressSapId: number, docDateEnd: string, docDateStart: string }>());
export const ordersHistoriesLoaded = createAction('[Order] Orders Histories Loaded', props<{ ordersHistories: OrderDocInfo[], addressSapId: number }>());
export const ordersHistoriesLoadError = createAction('[Order] Orders Histories Load Error');
export const loadOrderHistory = createAction('[Order] Load Order History ', props<{ docNumber: number }>());
export const orderHistoryLoaded = createAction('[Order] Order History Loaded', props<{ history: OrderDocInfoDetailed[], docNumber: number }>());
export const orderHistoryLoadError = createAction('[Order] Order History Load Error', props<{ docNumber: number }>());
