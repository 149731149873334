import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'iql-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectComponent),
    multi: true
  }]
})
export class CustomSelectComponent implements ControlValueAccessor {
  @Input() showSearch: boolean = false;
  @Input()
  set optionData(options: { [key: string]: string }[]) {
    this.options = options || [];
    if (this.value) {
      this.patchData(this.value);
    }
  }
  @Input() idKey: string = 'id';
  @Input() nameKey: string = 'name';
  @Input() placeholder = '―';
  @Output() outputData: EventEmitter<string> = new EventEmitter<string>();
  @Output() scrolledBottom: EventEmitter<any> = new EventEmitter<any>();

  options: { [key: string]: string }[] = [];
  selectedItem: { [key: string]: string } = null;
  dropdownActive: boolean = false;
  disabled: boolean = false;
  value: string;
  searchQuery: FormControl = new FormControl(null);

  onChange = (value: any) => { };
  onTouched = () => { };

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  toggleHandle(event?: Event): void {
    if (this.disabled) { return; }
    this.dropdownActive = !this.dropdownActive;
  }

  dropdownClosed(): void {
    this.dropdownActive = false;
    this.searchQuery.reset(null, { emitEvent: false });
  }

  select(data: { [key: string]: string }): void {
    this.selectedItem = data;
    this.dropdownClosed();
    this.outputData.emit(data[this.idKey]);
    this.updateValue(data[this.idKey]);
  }

  writeValue(outsideValue: string): void {
    this.value = outsideValue
    if (outsideValue) {
      this.patchData(outsideValue);
    }
    this.updateValue(outsideValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateValue(insideValue: string): void {
    this.onChange(insideValue);
    this.onTouched();
  }

  private patchData(selectedValue: string): void {
    if (this.options.length) {
      const option = this.options.find(item => String(item[this.idKey]) === String(selectedValue));
      if (option) {
        this.selectedItem = option;
      }
    }

  }

  onScroll(): void {
    this.scrolledBottom.emit();
  }
}
