import { ActionReducerMap } from '@ngrx/store';
import * as fromOutlets from './deprecated/outlets.reducer';
import * as fromClients from './deprecated/clients.reducers';
import * as fromAuth from './auth/auth.reducers';
import * as fromBreadcrumb from './breadcrumb/breadcrumb.reducers';
import * as fromTasks from './task/tasks.reducers';
import * as fromApplicationSettings from './startup/applications-settings.reducers';
import * as fromClientsOutletsLookup from './deprecated/clients-outlets-lookup.reducers';
import * as fromOutletsClientsLookup from './deprecated/outlets-clients-lookup.reducers';
import * as fromTasksProgress from './task/task-progress.reducers';
import * as fromPlans from './deprecated/plans.reducers';
import * as fromOrders from './saleplan/orders.reducers';
import * as fromSalePlan from './saleplan/saleplan.reducers';
import * as fromBonus from './saleplan/bonus.reducers';
import * as fromProduct from './saleplan/product.reducer';
import * as fromManufacturer from './deprecated/manufacturer.reducer';
import * as fromNotification from './notification/notification.reducers';
import * as fromDocument from './document/document.reducers';
import * as fromFinance from './finance/finance.reducers';

export const appKey = 'app';

export interface State {
    applicationSettings: fromApplicationSettings.State;
    auth: fromAuth.State;
    breadcrumb: fromBreadcrumb.State;
    // deprecated
    clients: fromClients.State;
    outlets: fromOutlets.State;
    clientsOutletsLookup: fromClientsOutletsLookup.State;
    outletsClientsLookup: fromOutletsClientsLookup.State;
    plans: fromPlans.State;
    manufacturer: fromManufacturer.State;
    // document
    document: fromDocument.State;
    finance: fromFinance.State;
    // tasks
    tasks: fromTasks.State;
    tasksProgress: fromTasksProgress.State;
    // saleplan
    orders: fromOrders.State;
    saleplan: fromSalePlan.State;
    bonus: fromBonus.State;
    product: fromProduct.State;
    notification: fromNotification.State;
}

export const reducers: ActionReducerMap<State> = {
    auth: fromAuth.reducer,
    applicationSettings: fromApplicationSettings.reducer,
    breadcrumb: fromBreadcrumb.reducer,
    // deprecated
    outlets: fromOutlets.reducer,
    plans: fromPlans.reducer,
    clients: fromClients.reducer,
    clientsOutletsLookup: fromClientsOutletsLookup.reducer,
    outletsClientsLookup: fromOutletsClientsLookup.reducer,
    manufacturer: fromManufacturer.reducer,
    // document
    document: fromDocument.reducer,
    finance: fromFinance.reducer,
    // tasks
    tasks: fromTasks.reducer,
    tasksProgress: fromTasksProgress.reducer,
    // saleplan
    orders: fromOrders.reducer,
    saleplan: fromSalePlan.reducer,
    bonus: fromBonus.reducer,
    product: fromProduct.reducer,
    notification: fromNotification.reducer,
};
