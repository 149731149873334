import { OrderHistoryInfo, OrdersHistory } from '@Mesh/core/models/APImodels/saleplan/orders';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import moment from 'moment';
import * as fromActions from '../../actions/saleplan/orders.actions';

export const orderFeatureKey = 'orders';

export interface State extends EntityState<OrderHistoryInfo> {
  selectedId: number;
  ordersState: LoadingStatus;
  addressDictionary: { addressSapId: number, docNumbers: number[] }[];
  dateRange: { startDate: moment.Moment, endDate: moment.Moment };
  addressSapId: number;
  ordersHistories: OrdersHistory;
}

export const adapter: EntityAdapter<OrderHistoryInfo> = createEntityAdapter<OrderHistoryInfo>({
  selectId: order => order.docNumber
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  ordersState: LoadingStatus.NotLoaded,
  addressDictionary: [],
  dateRange: { startDate: moment.utc().startOf('day'), endDate: moment.utc().endOf('day') },
  addressSapId: 0,
  ordersHistories: null
});

const updateAddressDictionary = (addressSapId: number, docNumbers: number[], addressDictionary: { addressSapId: number, docNumbers: number[] }[]) => {
  const storedAddress = addressDictionary.find(d => d.addressSapId === addressSapId);
  if (storedAddress) {
    storedAddress.docNumbers = Array.from(new Set([...docNumbers, ...storedAddress.docNumbers]));
  } else {
    addressDictionary.push({ addressSapId, docNumbers });
  }
  return addressDictionary;
};

const ordersReducer = createReducer(
  initialState,
  on(fromActions.loadOrdersHistories, (state) => {
    return {
      ...state,
      ordersState: LoadingStatus.Loading,
      selectedId: null
    };
  }),
  on(fromActions.loadOrdersHistoriesWithProps, (state, { addressSapId, docDateStart, docDateEnd }) => {
    return {
      ...state,
      ordersState: LoadingStatus.Loading,
      selectedId: null,
      addressSapId,
      dateRange: { startDate: moment.utc(docDateStart), endDate: moment.utc(docDateEnd) }
    };
  }),
  on(fromActions.ordersHistoriesLoaded, (state, { ordersHistories, addressSapId }) => {
    const addressDictionary = updateAddressDictionary(addressSapId, ordersHistories.map(o => o.docNumber), state.addressDictionary);
    return adapter.addMany(ordersHistories, { ...state, ordersState: LoadingStatus.Loaded, addressDictionary, addressSapId });
  }),
  on(fromActions.ordersHistoriesLoadError, (state) => {
    return { ...state, ordersState: LoadingStatus.Error };
  }),
  on(fromActions.loadOrderHistory, (state, { docNumber }) => {
    const newState = { ...state, selectedId: docNumber };
    return adapter.updateOne(
      {
        id: docNumber, changes: {
          ...state.entities[docNumber],
          loadingStatus: state.entities[docNumber].loadingStatus === LoadingStatus.Loaded ? LoadingStatus.Loaded : LoadingStatus.Loading
        }
      },
      { ...newState }
    );
  }),
  on(fromActions.orderHistoryLoaded, (state, { history, docNumber }) => {
    const newState = { ...state, selectedId: docNumber };
    return adapter.updateOne(
      { id: docNumber, changes: { ...state.entities[docNumber], datailedData: history, loadingStatus: LoadingStatus.Loaded } },
      { ...newState }
    );
  }),
  on(fromActions.orderHistoryLoadError, (state, { docNumber }) => {
    const newState = { ...state, selectedId: docNumber };
    return adapter.updateOne(
      { id: docNumber, changes: { ...state.entities[docNumber], loadingStatus: LoadingStatus.Error } },
      { ...newState }
    );
  })
);

export function reducer(state: State | undefined, action: Action) {
  return ordersReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
export const _selectOrdersIds = selectIds;
export const _selectOrdersEntities = selectEntities;
export const _selectAllOrders = selectAll;

export const getSelectedId = (state: State) => state.selectedId;
export const getAddressSapId = (state: State) => state.addressSapId;
export const getDateRange = (state: State) => state.dateRange;
export const getOrdersLoadingStatus = (state: State) => state.ordersState;
export const getOrdersAddressDictionary = (state: State) => state.addressDictionary;
export const getOrdersHistory = (state: State) => state.ordersHistories;
