import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { HRDocumentType } from "./documents-type.enum";
import { ChatHrService } from "../../chat-hr.service";
import { DocumentFilterParams, PersonalDocumentResource } from "./chat-hr-candidat-documents";
import { Subject } from "rxjs";
import { ChatHrCandidatDocumentsService } from "./chat-hr-candidat-documents.service";
import { takeUntil } from "rxjs/operators";
import { VisitDataItem } from "@Mesh/shared/modules/chatHR/models/visit-dialog";

@Component({
  selector: "iql-chat-hr-candidat-documents",
  templateUrl: "./chat-hr-candidat-documents.component.html",
  styleUrls: ["./chat-hr-candidat-documents.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHrCandidatDocumentsComponent implements OnInit, OnDestroy {
  showPreview: boolean = false;
  documentList: PersonalDocumentResource[];
  loading: boolean = false;
  params: DocumentFilterParams = {};

  private ngOnDestroy$: Subject<null> = new Subject<null>();
  visitData: VisitDataItem;
  currentDocument: PersonalDocumentResource;

  constructor(
    private chatHrService: ChatHrService,
    private cdr: ChangeDetectorRef,
    private chatHrCandidatDocumentsService: ChatHrCandidatDocumentsService
  ) {}

  ngOnInit() {
    this.chatHrService
      .onShowShareChat()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((data) => {
        if (data && data.open && data.visitDataItem) {
          this.visitData = data.visitDataItem;
          this.params = {
            user_id: this.visitData.user_id,
            // user_id: 14419,
            type_id: HRDocumentType.PassportFirstPage,
          };
          this.loadDocuments();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  onClosed(): void {
    this.chatHrService.closeVisitData();
  }

  onChangeTab(tab: HRDocumentType): void {
    this.onShowedPreview(false, null);
    this.params = {
      user_id: this.visitData.user_id,
      // user_id: 14419,
      type_id: tab,
    };
    this.documentList = [];
    this.loadDocuments();
  }

  onShowedPreview(showed: boolean, document: PersonalDocumentResource): void {
    this.currentDocument = document;
    this.showPreview = showed;
    this.cdr.markForCheck();
  }

  loadDocuments(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.chatHrCandidatDocumentsService
      .getDocuments(this.params)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((data) => {
        this.documentList = data;
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  onSavedDocument(document: PersonalDocumentResource) {
    this.documentList = this.documentList.map((item) => (item.id === document.id ? document : item));
    this.cdr.markForCheck();
  }
}
