import { RulesEntity } from '@Mesh/core/models/bonus/bonus-configuration';
import { BonusConfiguration } from '../../../../core/models/bonus/bonus-configuration';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ITableType } from '../models/table';
import { IMAGE_URL, IMAGES_URL } from '@Env/environment';
import { SalePlan } from '@Mesh/core/models/APImodels/saleplan/saleplans';
import { ClientsLookup } from '@Mesh/core/models/lookup';
import { Outlet } from '@Mesh/core/models/outlet';
import { OrderHistoryInfo } from '@Mesh/core/models/APImodels/saleplan/orders';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';
import moment, { Moment } from 'moment';
import { AuthService } from '@Mesh/core/services/api/auth/auth.service';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { IShoppingCardItem } from '@Mesh/core/models/APImodels/saleplan/shopping-cart';

@Component({
  selector: 'iql-table-header',
  templateUrl: './table-headerv2.component.html',
  styleUrls: ['./table-headerv2.component.scss']
})
export class TableHeaderV2Component implements OnChanges, OnInit, AfterViewChecked {
  readonly url = IMAGE_URL;
  readonly basePath = IMAGES_URL;

  @Input() shoppingCartItems: IShoppingCardItem[];
  @Input() tableType: ITableType;
  @Input() links: { path: string; params: any; name: string }[] = [];
  @Input() predictBonus: Bonus[];
  @Input() bonusLoadingStatus: LoadingStatus;
  @Input() predictBonusLoadingStatus: LoadingStatus;
  @Input() clients: ClientsLookup[];
  @Input() outlets: Outlet[];
  @Input() activePlanId: number;
  @Input() activeClientId: number;
  @Input() activeOutletId: number;
  @Input() orders: OrderHistoryInfo[];
  @Input() interval: number;
  @Input() currentMonth: Moment;
  @Input() ordersLoadingStatus: LoadingStatus;
  @Output() onButtonClick = new EventEmitter();
  @Output() onBasketEvent = new EventEmitter();
  @Output() onActivePlanSelect = new EventEmitter<Bonus>();
  @Output() onSalePlanEnable = new EventEmitter<{ bonus: Bonus, enableId: RulesEntity }>();
  @Output() changePlanPeriod = new EventEmitter<Moment>();
  @Output() excelFileUploaded = new EventEmitter<File>();

  _plans: SalePlan[];
  _configurations: BonusConfiguration[];
  LoadingStatus = LoadingStatus;

  readonly currentPlanWidth = 3;
  readonly currentMonthsWidth = 5;
  readonly basicMonth = moment.utc().startOf('month');

  TableType = ITableType;
  typeState: boolean;
  basketCount = 0;
  price = 0;
  activePlanState = false;
  activeBonus: Bonus;
  activeClient: ClientsLookup;
  activeOutlet: Outlet;
  _planList: SalePlan[] = [];
  bonusList: Bonus[] = [];
  currentPlanIndex = 0;
  points = 0;
  monthList: { caption: string; date: Moment }[];
  isPreviewMode = true;
  settingsOpen = false;
  communitiesSwiperIndex = 0;
  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    spaceBetween: 20,
    // spaceBetween: 16,
    slidesPerView: 'auto',
    freeMode: true,
    preloadImages: true,
    pagination: false,
    centeredSlides: false
  };
  file?: File;

  @ViewChildren(SwiperDirective) swiperViewes: QueryList<SwiperDirective>;
  @ViewChild(SwiperDirective, { static: false }) swiper: SwiperDirective;
  private _bonus: Bonus[];

  private filterPlanList(): void {
    this.planList = this.plans;
  }

  openCloseSettings(): void {
    this.settingsOpen = !this.settingsOpen;
  }

  get configurations(): BonusConfiguration[] {
    return this._configurations;
  }

  get bonus(): Bonus[] {
    return this._bonus;
  }

  @Input()
  set bonus(value: Bonus[]) {
    this._bonus = value;
    console.log(value);
  }

  @Input()
  set configurations(value: BonusConfiguration[]) {
    this._configurations = value;
  }

  getPredictBonus(bonus: Bonus): Bonus {
    if (this.predictBonus) {
      return this.predictBonus.find((p) => p.planName === bonus.planName);
    }
  }

  getActiveBonus(): Bonus {
    if (this.activePlanId) {
      return this.bonus.find(b => b.planId === this.activePlanId);
    }
  }

  getEnableRule(bonus: Bonus): RulesEntity {
    if (this.configurations) {
      return this.configurations.find(
        (r) =>
          r.planId === bonus.planId
      ).rules.find(rule => {
        return rule.addressSapId === this.activeOutletId &&
          moment(rule.startAt, 'YYYY-MM-DD').startOf('day').isSameOrBefore(
            moment().startOf('month').startOf('day')
          ) &&
          moment(rule.finishAt, 'YYYY-MM-DD').startOf('day').isSameOrAfter(
            moment().endOf('month').startOf('day')
          );
      });
    }
  }

  updateRules(): void {
    // const disables: SalePlanEnable[] = this._enableRules;
    // if (this._plans) {
    //   this._plans.map((p, i) => {
    //     const disable = disables.find(
    //       (b) =>
    //         b.planId === p.planId &&
    //         b.addressSapId === this.activeOutletId &&
    //         moment(b.startAt, 'YYYY-MM-DD').isSameOrAfter(
    //           moment().startOf('month')
    //         ) &&
    //         moment(b.finishAt, 'YYYY-MM-DD').isSameOrBefore(
    //           moment().endOf('month')
    //         )
    //     );
    //     p.disable = disable;
    //     p.disableOn = !!disable;
    //     return p;
    //   });
    //   this.filterPlanList();
    // }
  }

  get planList(): SalePlan[] {
    return this._planList;
  }

  set planList(value: SalePlan[]) {
    this._planList = value;
  }

  get plans(): SalePlan[] {
    return this._plans;
  }

  @Input()
  set plans(value: SalePlan[]) {
    this._plans = value;

    this.updateRules();
  }

  calcPlanColor(bonus: Bonus): number {
    if (bonus) {
      const color = Math.floor(bonus.executedPercent / 10) + 1;
      return color < 11 ? color : 11;
    }
  }

  createMonthList(): void {
    this.changePlanPeriod.emit(moment(this.currentMonth));
    // this.monthList = new Array(this.currentMonthsWidth)
    //   .fill(null)
    //   .map((el, i) => ({
    //     caption: moment(this.currentMonth)
    //       .add(i - 2, 'months')
    //       .format('MMMM'),
    //     date: moment(this.currentMonth).add(i - 2, 'months')
    //   }));
    // console.log('this.changePlanPeriod:', this.changePlanPeriod);
  }

  curMonth(): string {
    return (
      moment(this.currentMonth).startOf('month').format('DD.MM.YYYY') +
      ' - ' +
      moment(this.currentMonth).endOf('month').format('DD.MM.YYYY')
    );
  }

  ngOnInit(): void {
    this.createMonthList();
  }

  ngAfterViewChecked(): void {
    this.swiperViewes.changes.pipe().subscribe(() => {
      this.swiperViewes.forEach((item) => item.update());
    });
  }

  onEnablePlan(value: {bonus: Bonus, enableId: RulesEntity}): void {
    this.onSalePlanEnable.emit({ bonus: value.bonus, enableId: value.enableId });
    // this.plans.map(p => {
    //   if(p.planId === plan.planId) {
    //     if(p.disable) {
    //       delete p.disable;
    //     }
    //     p.disableOn = !p.disableOn;
    //   }
    //   return p;
    // })
    // this.filterPlanList();
  }

  ngOnChanges({
    orders,
    activePlanId,
    activeClientId,
    activeOutletId,
    plans,
    enableRules,
    clients,
    outlets,
    bonus,
    predictBonus,
    interval,
    currentMonth
  }: SimpleChanges): void {
    if (activeClientId) {
      if (this.clients) {
        this.activeClient = this.clients.find(
          (p) => p.clientSapId === activeClientId.currentValue
        );
      }
    }

    if (activeOutletId) {
      if (this.clients) {
        this.activeOutlet = this.outlets.find(
          (p) => p.addressSapId === activeOutletId.currentValue
        );
      }
    }

    // if (plans) {
    //   console.log('plans:', plans);
    //   this.currentPlanIndex = 0;
    //   const planList: SalePlan[] = plans.currentValue;
    //   if (planList && planList.length) {
    //     if (this.activePlan) {
    //       const exist = planList.findIndex(
    //         (el) => el.planId === this.activePlan.planId
    //       );
    //       if (exist === -1) {
    //         this.activePlan = planList[0];
    //       }
    //     } else {
    //       if (this.activePlanId) {
    //         this.activePlan = plans.currentValue.find(
    //           (p) => p.planId === this.activePlanId
    //         );
    //       } else {
    //         this.activePlan = planList[0];
    //       }
    //     }
    //     this.planList = planList;
    //     this.filterPlanList();
    //   }
    // }

    if (activePlanId && (activePlanId.currentValue === 0 || activePlanId.currentValue)) {
      if (activePlanId.currentValue === 0) {
        this.activePlanState = true;
      } else {
        const curBonuses = bonus?.currentValue ?? this.bonus;
        if (curBonuses) {
          this.activeBonus = curBonuses.find(
            (b) => b.planName === activePlanId.currentValue
          );
          this.activePlanId = activePlanId.currentValue;
          this.activePlanState = false;
        }
      }
    }

    if (interval) {
      this.typeState = !this.typeState;
    }

    if (bonus && bonus.currentValue) {
      const bonuses: Bonus[] = bonus.currentValue;
      this.points = bonuses.reduce((sum, b) => sum + (b.currentBonus ?? 0), 0);
      // if (this.plans) {
      //   this.plans = this.plans.map((p) => {
      //     const curBonus = bonuses.find((b) => b.planId === p.planId);
      //     if (curBonus) {
      //       p.bonus = curBonus;
      //       p.progressColor = this.calcPlanColor(p.bonus);
      //     }
      //     return p;
      //   });
      //   this.points = this.bonus.reduce(
      //     (sum, p) => sum + (p.bonus?.currentBonus ?? 0),
      //     0
      //   );
      // }
    }

    // if (predictBonus) {
    //   const predictBonuses: Bonus[] = predictBonus.currentValue;
    //   if (this.plans) {
    //     this.plans = this.plans.map((p, i) => {
    //       const curBonus = predictBonuses?.find((b) => b.planId === p.planId);
    //       p.predictBonus = curBonus;
    //       p.predictColor = p.predictBonus
    //         ? this.calcPlanColor(p.predictBonus)
    //         : 0;
    //       return p;
    //     });
    //   }
    // }

    if (clients?.currentValue) {
      this.activeClient = clients.currentValue.find(
        (p) => p.clientSapId === this.activeClientId
      );
    }

    if (outlets?.currentValue) {
      this.activeOutlet = outlets.currentValue.find(
        (p) => p.addressSapId === this.activeOutletId
      );
    }

    if (orders) {
      const ordersV: OrderHistoryInfo[] = orders.currentValue;
      this.basketCount = ordersV?.reduce((s, o) => s + o.countProduct, 0) ?? 0;
      this.price = ordersV?.reduce((s, o) => s + o.cost, 0) ?? 0;
    }

    if (currentMonth) {
      this.createMonthList();
    }
  }

  onBasketClick(): void {
    this.onBasketEvent.emit();
  }

  onActiveStateChanging(): void {
    this.activePlanState = true;
    if (this.activePlanState) {
      this.filterPlanList();
    }
  }

  onActiveSelect(bonus: Bonus): void {
    this.activePlanState = false;
    this.activeBonus = bonus;
    this.onActivePlanSelect.emit(bonus);
  }

  onPlanMoveForward(): void {
    this.swiper.nextSlide();
  }

  onPlanMoveBackward(): void {
    this.swiper.prevSlide();
  }

  // onPrevMonthsGroup(): void {
  //   this.currentMonth.subtract(1, 'months');
  //   this.createMonthList();
  // }

  // onNextMonthsGroup(): void {
  //   if (moment(this.currentMonth).add(1, 'months') <= this.basicMonth) {
  //     this.currentMonth.add(1, 'months');
  //     this.createMonthList();
  //   }
  // }

  // onSelectMonth(data: Moment) {
  //   console.log(moment(data).toISOString().substr(0, 10));
  //   console.log(moment(this.basicMonth).toISOString().substr(0, 10));
  //   if (
  //     moment(data)
  //       .endOf('month')
  //       .startOf('day')
  //       .isBefore(moment(this.basicMonth).endOf('month').endOf('day'))
  //   ) {
  //     this.currentMonth = moment(data);
  //     this.createMonthList();
  //   }
  // }

  constructor(readonly auth: AuthService, private cdr: ChangeDetectorRef) {
    this.isPreviewMode = auth.isPreviewMode;
  }

  uploadFile(event: any): void {
    const file = event.target.files[0] as File;
    this.excelFileUploaded.emit(file);
    this.file = undefined;
  }
}
