import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HR_URL } from "@Env/environment";
import { DocumentFilterParams, PersonalDocumentResource } from "./chat-hr-candidat-documents";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { objectToParams } from "@Mesh/shared/helpers/object.helpers";

@Injectable({
  providedIn: "root",
})
export class ChatHrCandidatDocumentsService {
  constructor(private readonly http: HttpClient) {}

  getDocuments(criteria: DocumentFilterParams = {}): Observable<Array<PersonalDocumentResource>> {
    const params = objectToParams(criteria);
    return this.http
      .get<Array<PersonalDocumentResource>>(`${HR_URL}/personal-documents`, {
        params,
      })
      .pipe(
        map((res: any) => res.data),
        catchError(() => of([] as Array<PersonalDocumentResource>))
      );
  }

  showDocument(id: number): Observable<PersonalDocumentResource> {
    return this.http
      .get<PersonalDocumentResource>(`${HR_URL}/personal-documents/${id}`)
      .pipe(map((res: any) => res.data));
  }

  updateDocument(id: number, payload: Partial<PersonalDocumentResource>): Observable<PersonalDocumentResource> {
    return this.http
      .patch<PersonalDocumentResource>(`${HR_URL}/personal-documents/${id}`, payload)
      .pipe(map((res: any) => res.data));
  }
}
