import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { ChatHrMainPageTab, TabsKey } from '../../chat-hr';
import { ChatSource } from '../chat-hr-main-page.component';
import { UnreadMessagesCount } from '@Mesh/shared/modules/chatHR/models/unread-messages-count';
import { ChatService } from '@Mesh/shared/modules/chatHR/service/chat.service';
import { Subscription } from 'rxjs';
import { FeathersService } from '@Mesh/shared/modules/chatHR/service/feathers.service';
import { ChatTypeSwitchService } from '@Mesh/shared/modules/chat/chat-type-switch/chat-type-switch.service';
import { ChatType } from '@Mesh/shared/modules/chat/chat-type';
interface TabItem {
  title: string;
  key: TabsKey;
  statusIds: number[];
  source: ChatSource;
}
@Component({
  selector: 'iql-chat-hr-main-page-tabs',
  templateUrl: './chat-hr-main-page-tabs.component.html',
  styleUrls: ['./chat-hr-main-page-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHrMainPageTabsComponent implements OnInit, OnDestroy {
  @Output() changeTab = new EventEmitter<ChatHrMainPageTab>();

  tabsList: TabItem[] = [
    {
      title: 'Открытые резюме',
      key: TabsKey.OPENED,
      statusIds: [],
      source: ChatSource.OPENED_RESUME,
    },
    {
      title: 'Не подтвержденные',
      key: TabsKey.ALL_USERS_CHAT,
      statusIds: [],
      source: ChatSource.USERS_CHAT,
    },
    // {
    //   title: 'Не подтвержденные',
    //   key: 'Unconfirmed',
    //   statusIds: [0, 2, 3],
    //   source: ChatSource.VISIT_CHAT
    // },
    {
      title: 'Подтвержденные',
      key: TabsKey.CONFIRMED,
      statusIds: [1, 4],
      source: ChatSource.VISIT_CHAT,
    },
    {
      title: 'Кандидат не явился',
      key: TabsKey.NO_SHOW,
      statusIds: [5],
      source: ChatSource.VISIT_CHAT,
    },
    {
      title: 'Завершенные',
      key: TabsKey.COMPLETED,
      statusIds: [6],
      source: ChatSource.VISIT_CHAT,
    },
    {
      title: 'Архив',
      key: TabsKey.ARCHIVE,
      statusIds: [],
      source: ChatSource.USERS_CHAT,
    },
  ];
  currentTab = 'Opened';
  subscription: Subscription;

  constructor(
    private chatService: ChatService,
    private feathersService: FeathersService,
    private chatTypeSwitchService: ChatTypeSwitchService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const initialTab = this.tabsList.find((tab) => tab.key === this.currentTab);
    if (initialTab) {
      this.changeTab.emit(initialTab);
    }

    this.subscription = this.feathersService.isAuthChat().subscribe(() => {
      this.chatService.onUnreadMessagesUpdate().subscribe({
        next: (data) => {
          console.log('Обновленные данные о непрочитанных сообщениях:', data);
          this.tabsList = this.mapResponseToTabs(data, this.tabsList);
          this.setTotalUnreadMessages(data);
          this.cdr.markForCheck();
        },
        error: (err) => {
          console.error('Ошибка при получении данных о непрочитанных сообщениях:', err);
        },
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeTab(tab: ChatHrMainPageTab): void {
    this.currentTab = tab.key;
    this.changeTab.emit(tab);
    this.cdr.markForCheck();
  }

  private mapResponseToTabs(response: UnreadMessagesCount, tabsList: TabItem[]): TabItem[] {
    return tabsList.map((tab) => {
      let count = 0;

      switch (tab.key) {
        case TabsKey.ALL_USERS_CHAT: // Не подтвержденные
          count = response.archived_0;
          break;
        case TabsKey.CONFIRMED: // Подтвержденные
          count = response.statusIds_1_4;
          break;
        case TabsKey.NO_SHOW: // Кандидат не явился
          count = response.statusIds_5;
          break;
        case TabsKey.COMPLETED: // Завершенные
          count = response.statusIds_6;
          break;
        case TabsKey.ARCHIVE: // Архив
          count = response.archived_1;
          break;

        default:
          count = 0;
          break;
      }

      return {
        ...tab,
        count: count,
      };
    });
  }

  private setTotalUnreadMessages(data: UnreadMessagesCount): void {
    const totalUnread = Object.entries(data)
      .filter(([key]) => key !== 'archived_1')
      .reduce((accumulator, [, dialog]) => accumulator + dialog, 0);

    this.chatTypeSwitchService.setNotifications(ChatType.RESPONSES_JOB, totalUnread);
  }
}
