import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOrders from '../../reducers/saleplan/orders.reducers';
import { Dictionary } from '@ngrx/entity';
import { pickBy } from 'lodash-es';
import { OrderHistoryInfo } from '@Mesh/core/models/APImodels/saleplan/orders';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import moment from 'moment';

const selectState = createFeatureSelector<fromOrders.State>(fromOrders.orderFeatureKey);
const selectAddressDictionary = createSelector(selectState, fromOrders.getOrdersAddressDictionary);

export const selectOrdersEntities = createSelector(selectState, fromOrders._selectOrdersEntities);
export const selectAllOrders = createSelector(selectState, fromOrders._selectAllOrders);
export const selectOrdersIds = createSelector(selectState, fromOrders._selectOrdersIds);
export const selectOrdersLoadingStatus = createSelector(selectState, fromOrders.getOrdersLoadingStatus);
export const selectOrdersHistory = createSelector(selectState, fromOrders.getOrdersHistory);
export const selectDateRange = createSelector(selectState, fromOrders.getDateRange);
export const selectAddressSapId = createSelector(selectState, fromOrders.getAddressSapId);
export const selectOrderLoadingStatusById = createSelector(
  selectOrdersEntities,
  (orders: Dictionary<OrderHistoryInfo>, props: { docNumber: number }) => {
    let filteredOrders = orders;
    let result: OrderHistoryInfo = null;
    if (!filteredOrders) {
      return result;
    }
    filteredOrders = pickBy(filteredOrders, o => o.docNumber === props.docNumber);
    if (filteredOrders) {
      result = filteredOrders[props.docNumber];
    }
    return result ? result.loadingStatus : LoadingStatus.NotLoaded;
  }
);

export const selectSelectedOrderIid = createSelector(selectState, fromOrders.getSelectedId);

export const selectOrdersByDateAndAddress = createSelector(
  selectAddressDictionary,
  selectAllOrders,
  (addressDictionary: { addressSapId: number, docNumbers: number[] }[],
    orders: OrderHistoryInfo[],
    props: { dateRange: { startDate: moment.Moment, endDate: moment.Moment }, addressSapId: number }
  ) => {
    let result: OrderHistoryInfo[] = [];
    if (!orders) {
      return result;
    }
    const docNumbers = addressDictionary.find(d => d.addressSapId === props.addressSapId)?.docNumbers;
    if (!docNumbers) {
      return result;
    }
    // фильтр по адрессу
    result = orders.filter(o => docNumbers.findIndex(d => d === o.docNumber) !== -1);
    // фильтр по дате
    result = result.filter(o => moment.utc(o.docDate) >= moment.utc(props.dateRange.startDate) && moment.utc(o.docDate) <= moment.utc(props.dateRange.endDate));
    return result ? result : [];
  }
);
export const selectOrdersByDefaultDate = createSelector(
  selectAddressDictionary,
  selectAllOrders,
  selectDateRange,
  selectAddressSapId,
  (addressDictionary: { addressSapId: number, docNumbers: number[] }[],
    orders: OrderHistoryInfo[],
    dateRange: { startDate: moment.Moment, endDate: moment.Moment },
    addressSapId: number
  ) => {
    let result: OrderHistoryInfo[] = [];
    if (!orders) {
      return result;
    }
    const docNumbers = addressDictionary.find(d => d.addressSapId === addressSapId)?.docNumbers;
    if (!docNumbers) {
      return result;
    }
    // фильтр по адрессу
    result = orders.filter(o => docNumbers.findIndex(d => d === o.docNumber) !== -1);
    // фильтр по дате
    result = result.filter(o => moment.utc(o.docDate) >= moment.utc(dateRange.startDate) && moment.utc(o.docDate) <= moment.utc(dateRange.endDate));
    return result ? result : [];
  }
);
