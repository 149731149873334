import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Outlet, OutletData, OutletFilters } from '@Mesh/core/models/outlet';
import { Action, createReducer, on } from '@ngrx/store';
import {
    outletListLoaded,
    populateOutletEntity,
    setOutletFilters,
    setSelectedOutlet,
    setSelectedOutlets,
    setSelectedTaskOutletClient
} from '@Mesh/store/actions/deprecated/outlets.actions';
import { TaskProgress } from '@Mesh/core/models/task';

export const outletFeatureKey = 'outlets';

export interface State extends EntityState<Outlet> {
    selectedId: number;
    selectTaskOutletClient: TaskProgress;
    selectedOutletIds: number[];
    filters: OutletFilters;
    outletData: OutletData;
}

export const adapter: EntityAdapter<Outlet> = createEntityAdapter<Outlet>({
    selectId: outlet => outlet.addressSapId,
    sortComparer: (o1, o2) => o1.street.localeCompare(o2.street)
});

export const initialState: State = adapter.getInitialState({
    selectedId: undefined,
    selectTaskOutletClient: undefined,
    selectedOutletIds: [],
    filters: {
        new: true,
        moderation: true,
        history: false,
        city: undefined,
        region: undefined,
        outletId: undefined
    },
    outletData: null
});

const outletsReducer = createReducer(
    initialState,
  on(populateOutletEntity, (state, { outlets }) => adapter.addAll(outlets, { ...state, selectedId: undefined })),
  on(setSelectedOutlets, (state, { ids }) => {
    return {
      ...state,
      selectedOutletIds: ids
    };
  }),
  on(setSelectedOutlet, (state, { id }) => {
    return {
      ...state,
      selectedId: id
    };
  }),
  on(setSelectedTaskOutletClient, (state, { taskProgress }) => {
    return {
      ...state,
      selectTaskOutletClient: taskProgress
    };
  }),
  on(setOutletFilters, (state, { filters }) => {
    return {
      ...state,
      filters
    };
  }),
  on(outletListLoaded, (state, { outletData }) => {
    return {
      ...state,
      outletData
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
    return outletsReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
export const _selectOutletIds = selectIds;
export const _selectOutletEntities = selectEntities;
export const _selectAllOutlets = selectAll;

export const getOutletData = (state: State) => state.outletData;
