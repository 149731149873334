import { Injectable, Type } from '@angular/core';
import { ChatDynamicService } from '../../../chat-dynamic.service';

@Injectable({
  providedIn: 'root'
})
export class ChatHrOpenedResumeDataService {

  constructor(
    private chatAdService: ChatDynamicService
  ) { }

  showOpenedResumeData(component: Type<any>,data: any) {
    this.chatAdService.createDynamicItem(component, data);
  }

  closeOpenedResumeData(){
    this.chatAdService.removeDynamicItem();
  }
}
