import { Subject } from 'rxjs';
import { Product, Stock } from '@Mesh/core/models/product';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-catalog-unit-modal',
  templateUrl: './catalog-unit-modal.component.html',
  styleUrls: ['./catalog-unit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogUnitModalComponent implements OnInit {
  product: Product;
  currentStock: Stock;
  selectedStock: Stock;
  onClose: Subject<Stock> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  setCurrentStock(stock: Stock) {
    this.currentStock = stock;
    this.selectedStock = stock;
    this.cdr.detectChanges();
  }

  onChoose(stock: Stock) {
    this.onClose.next(stock);
  }

  selectCurrentProduct(product: Product) {
    this.product = product;
    this.cdr.detectChanges();
  }

  close() {
    this.onClose.next(null);
  }

  getListStocks(): Stock[] {
    if (!this.currentStock) {
      return [];
    }
    // return this.product.stock.filter((stock) => stock.distributorName === this.currentStock?.distributorName )

    let answer = [];

    this.product.groupedStock.forEach((group) => {
      group.stock.forEach((stock) => {
        if (stock.id === this.currentStock.id) {
          answer = [...group.stock];
        }
      });
    });

    return answer;
  }
}
