import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { FormsModule } from '@angular/forms';
import { ChatAvailableDialogItemComponent } from './chat-available-dialog-item/chat-available-dialog-item.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ChatCommunityComponent } from './chat-community/chat-community.component';
import { ChatUserRecordComponent } from './chat-users-list/chat-user-record/chat-user-record.component';
import { ChatMainPageComponent } from './chat-main-page/chat-main-page.component';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { UserInfoModule } from '@Mesh/shared/modules/user-info/user-info.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutosizeModule } from 'ngx-autosize';
import { ChatDialogContextMenuComponent } from './chat-dialog/chat-dialog-context-menu/chat-dialog-context-menu.component';
import { ReceivedReadComponent } from './received-read/received-read.component';
import { BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { ChatMessageComponent } from './chat-dialog/chat-message/chat-message.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ChatSearchInputComponent } from './chat-search-input/chat-search-input.component';
import { ChatHeaderComponent } from './chat-header/chat-header.component';
import { ChatUsersListComponent } from './chat-users-list/chat-users-list.component';
import { PreventParentScrollModule } from 'ngx-prevent-parent-scroll';
import { ChatDialogsListComponent } from './chat-dialogs-list/chat-dialogs-list.component';
import { ChatDialogListContextMenuComponent } from './chat-dialog-list-context-menu/chat-dialog-list-context-menu.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { LongPressDirective } from '@Mesh/shared/directives/long-press.directive';
import { ChatAudioComponent } from './chat-audio/chat-audio.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TimesModule } from '@Mesh/shared/modules/times/times.module';
import { SafeModule } from '@Mesh/shared/modules/safe/safe.module';
import { TokenModule } from '@Mesh/shared/modules/token/token.module';
import { TableModule } from 'primeng-lts/table';
import { SharedModule } from '@Mesh/shared/shared.module';
import { ProductRecognitionComponent } from '@Mesh/shared/modules/chat/product-recognition/product-recognition.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProductRecognitionMapComponent } from './product-recognition/product-recognition-map/product-recognition-map.component';
import { ChatDialogTabComponent } from './chat-dialog-tabs/chat-dialog-tab/chat-dialog-tab.component';
import { ChatDialogTabsComponent } from './chat-dialog-tabs/chat-dialog-tabs.component';
import { DialogFilterPipe } from './chat-dialogs-list/dialog-filter.pipe';
import { ChatClientMainPageComponent } from './chat-client-main-page/chat-client-main-page.component';
import { PgSelectModule } from '../../../@pages/components/select/select.module';
import { ChatClientItemComponent } from './chat-client-main-page/chat-client-item/chat-client-item.component';
import { ChatClientOutletItemComponent } from './chat-client-main-page/chat-client-item/chat-client-outlet-item/chat-client-outlet-item.component';
import { OutletLastMessageComponent } from './chat-client-main-page/chat-client-item/chat-client-outlet-item/outlet-last-message/outlet-last-message.component';
import { LoadingModule } from '../../directives/loading/loading.module';
import { GroupByModule } from '../group-by/group-by.module';
import { ChatTrainingMainPageComponent } from './chat-training/chat-training-main-page/chat-training-main-page.component';
import { ChatTrainingComponent } from './chat-training/chat-training.component';
import { ChatTrainingItemComponent } from './chat-training/chat-training-main-page/chat-training-item/chat-training-item.component';
import { ChatMessagesPlaceholderComponent } from './chat-dialog/chat-messages-placeholder/chat-messages-placeholder.component';
import { ChatDialogGroupDatePipe } from './chat-dialog/chat-dialog-group-date.pipe';
import { ChatClientMainPageFilterModule } from './chat-client-main-page/chat-client-main-page-filter/chat-client-main-page-filter.module';
import { ChatTypeSwitchModule } from './chat-type-switch/chat-type-switch.module';
import { ChatHrModule } from './chat-hr/chat-hr.module';
import { ChatTrainingModule } from './chat-training/chat-training.module';
import { ChatHrVisitDataModule } from './chat-hr/chat-hr-visit-data/chat-hr-visit-data.module';
import { WrapperDraggModule } from '@Mesh/shared/directives/wrapper-dragg/wrapper-dragg.module';
import { DynamicModule } from '@Mesh/shared/directives/dynamic/dynamic.module';
import { ChatHrOpenedResumeDataEditComponent } from './chat-hr/chat-hr-opened-resume/chat-hr-opened-resume-data/chat-hr-opened-resume-data-edit/chat-hr-opened-resume-data-edit.component';
import { ChatHrOpenedResumeModule } from './chat-hr/chat-hr-opened-resume/chat-hr-opened-resume.module';

@NgModule({
  imports: [
    PreventParentScrollModule,
    NgxUiLoaderModule,
    TooltipModule.forRoot(),
    NgxDropzoneModule,
    BsDropdownModule,
    AutosizeModule,
    InfiniteScrollModule,
    UserInfoModule,
    CommonModule,
    GalleryModule,
    TimesModule,
    PerfectScrollbarModule,
    LightboxModule,
    SwiperModule,
    TimesModule,
    SafeModule,
    TokenModule,
    FormsModule,
    ContextMenuModule.forRoot(),
    TableModule,
    SharedModule,
    ImageCropperModule,
    PgSelectModule,
    LoadingModule,
    GroupByModule,
    ChatTrainingModule,
    ChatHrModule,
    ChatClientMainPageFilterModule,
    ChatTypeSwitchModule,
    ChatHrVisitDataModule,
    WrapperDraggModule,
    DynamicModule,
    ChatHrOpenedResumeModule
  ],
  declarations: [
    ChatDialogsListComponent,
    ChatUsersListComponent,
    ChatHeaderComponent,
    ChatSearchInputComponent,
    ChatMessageComponent,
    ChatMessagesPlaceholderComponent,
    ReceivedReadComponent,
    ChatDialogContextMenuComponent,
    ChatMainPageComponent,
    ChatDialogComponent,
    ChatComponent,
    ChatUserRecordComponent,
    ChatAvailableDialogItemComponent,
    ChatDialogListContextMenuComponent,
    ChatCommunityComponent,
    LongPressDirective,
    ChatAudioComponent,
    ProductRecognitionComponent,
    ProductRecognitionMapComponent,
    ChatDialogTabsComponent,
    ChatDialogTabComponent,
    DialogFilterPipe,
    ChatDialogGroupDatePipe,
    ChatClientMainPageComponent,
    ChatClientItemComponent,
    ChatClientOutletItemComponent,
    OutletLastMessageComponent,
  ],
  exports: [ChatComponent],
  entryComponents: [ChatHrOpenedResumeDataEditComponent]
})
export class ChatModule {
}
