import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Visit } from '../../../../chatHR/models/visit-dialog';
import moment from 'moment';

@Component({
  selector: 'iql-chat-hr-info-candidate',
  templateUrl: './chat-hr-info-candidate.component.html',
  styleUrls: ['./chat-hr-info-candidate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrInfoCandidateComponent implements OnInit {
  @Input() visit: Visit;
  @Output() closed = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onClosed(event: Event) {
    event.stopPropagation();
    this.closed.emit();
  }
}
