import { BonusConfigCategory, BonusConfiguration } from '@Mesh/core/models/bonus/bonus-configuration';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';
import { ErrorType } from '@Mesh/core/models/APPmodels/errors';
import { createAction, props } from '@ngrx/store';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { BonusOutlets } from '@Mesh/core/models/bonus/bonus-outlets';
import { BonusConfigurationRuleRequest } from '@Mesh/core/models/bonus/bonus-configuration-rule-request';

export const loadBonus = createAction('[Bonus] Load bonus ', props<{ addressSapId: number; planIds: number[] }>());
export const updateBonus = createAction('[Bonus] Update bonus ', props<{ addressSapId: number; date: string }>());
export const bonusLoaded = createAction('[Bonus] Bonus loaded', props<{ bonus: Bonus[] }>());
export const bonusLoadError = createAction('[Bonus] Bonus load error', props<{ error: ErrorType }>());

export const loadPredictBonus = createAction(
  '[Bonus] Load predict bonus ',
  props<{ addressSapId: number; planIds: number[] }>()
);
export const clearPredictBonus = createAction('[Bonus] Clear predict bonus ');
export const predictBonusLoaded = createAction('[Bonus] PredictBonus loaded', props<{ bonus: Bonus[] }>());
export const predictBonusLoadError = createAction('[Bonus] Predict bonus load error', props<{ error: ErrorType }>());

export const salePlanBonusAction = {
  loadPredictBonusClient: createAction('[Bonus] Load client predict', props<{ clientSapId: number }>()),
  loadPredictBonusClientSuccess: createAction('[Bonus] Load client predict success', props<{ data: BonusClient }>()),
  loadPredictBonusClientError: createAction('[Bonus] Load client predict error', props<{ error: ErrorType }>()),

  loadBonusOutlets: createAction('[Bonus] Load outlets bonus', props<{ addressSapId: number }>()),
  loadBonusOutletsSuccess: createAction(
    '[Bonus] Load outlets bonus success',
    props<{ data: { addressSapId: number; bonus: BonusOutlets[] } }>()
  ),
  loadBonusOutletsError: createAction('[Bonus] Load outlets bonus error', props<{ error: ErrorType }>()),

  loadBonusConfiguration: createAction(
    '[Bonus] Load outlets bonus configuration',
    props<{ addressSapId?: number[]; planId?: number[]; strategy?: string }>()
  ),
  loadBonusConfigurationSuccess: createAction(
    '[Bonus] Load outlets bonus configuration success',
    props<{ configurations: BonusConfiguration[] }>()
  ),
  loadBonusConfigurationError: createAction(
    '[Bonus] Load outlets bonus configuration error',
    props<{ error: ErrorType }>()
  ),
  resetBonusConfiguration: createAction('[Bonus] Reset outlets bonus configuration'),

  toggleBonusConfigurationRule: createAction(
    '[Bonus] Enable/Disable rule bonus',
    props<{ request: BonusConfigurationRuleRequest[] }>()
  ),
  toggleBonusConfigurationRuleSuccess: createAction('[Bonus] Enable/Disable bonus rule success'),
  toggleBonusConfigurationRuleError: createAction(
    '[Bonus] Lnable/Disable bonus rule error',
    props<{ error: ErrorType }>()
  ),

  loadPredictBonusOutlets: createAction('[Bonus] Load outlets predict', props<{ addressSapId: number }>()),
  loadPredictBonusOutletsSuccess: createAction(
    '[Bonus] Load outlets predict success',
    props<{ data: { addressSapId: number; bonus: BonusOutlets[] } }>()
  ),
  loadPredictBonusOutletsError: createAction('[Bonus] Load outlets predict error', props<{ error: ErrorType }>()),

  loadBonusConfigCategory: createAction('[Bonus] Load bonus config categories'),
  loadBonusConfigCategorySuccess: createAction(
    '[Bonus] Load bonus config categories success',
    props<{ categories: BonusConfigCategory[] }>()
  ),
  loadBonusConfigCategoryError: createAction(
    '[Bonus] Load bonus config categories error',
    props<{ error: ErrorType }>()
  ),

  loadBonusConfigurationById: createAction('[Bonus] Load outlets bonus configuration by id', props<{ id: number }>()),
  loadBonusConfigurationByIdSuccess: createAction(
    '[Bonus] Load outlets bonus configuration by id success',
    props<{ currentConfiguration: BonusConfiguration }>()
  ),
  loadBonusConfigurationByIdError: createAction(
    '[Bonus] Load outlets bonus configuration by id error',
    props<{ error: ErrorType }>()
  ),
};
