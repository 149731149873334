import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDraggableScroll]'
})
export class DraggableScrollDirective implements OnInit {
  private isDown = false;
  private startX: number;
  private scrollLeft: number;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    // this.el.nativeElement.style.cursor = 'pointer';
    // this.el.nativeElement.style.width = '100%';
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    this.renderer.setStyle(this.el.nativeElement, 'width', '100%');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'auto');
    this.renderer.setStyle(this.el.nativeElement, 'white-space', 'nowrap');
    this.renderer.setStyle(this.el.nativeElement, 'scrollbar-width', 'none');
    this.renderer.setStyle(this.el.nativeElement, '-ms-overflow-style', 'none');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    console.log('onvouseDown')
    this.isDown = true;
    this.startX = event.pageX - this.el.nativeElement.offsetLeft;
    this.scrollLeft = this.el.nativeElement.scrollLeft;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isDown = false;
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.isDown = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDown) return;
    event.preventDefault();
    const x = event.pageX - this.el.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 3;
    this.el.nativeElement.scrollLeft = this.scrollLeft - walk;
  }
}
