import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DocInfoItem, DocumentPayload, PersonalDocumentResource } from "../chat-hr-candidat-documents";
import { ChatHrCandidatDocumentsService } from "../chat-hr-candidat-documents.service";
import { HRDocumentMapper } from "../chat-hr-document-mapper";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { error } from "console";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "iql-candidat-documents-page-item",
  templateUrl: "./candidat-documents-page-item.component.html",
  styleUrls: ["./candidat-documents-page-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidatDocumentsPageItemComponent implements OnInit {
  @Input() pageNumber: number = 1;
  @Input() progress: number = 90;
  @Input() documentResource: PersonalDocumentResource;
  @Output() showedPreview: EventEmitter<boolean> = new EventEmitter();
  @Output() saved: EventEmitter<PersonalDocumentResource> = new EventEmitter();

  docInfoItem: DocInfoItem[] = [];
  form: FormGroup;
  editMode: boolean = false;
  loading: boolean = false;

  private ngOnDestroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private chatHrCandidatDocumentsService: ChatHrCandidatDocumentsService
  ) {}

  ngOnInit(): void {
    if (this.documentResource) {
      this.docInfoItem = HRDocumentMapper.mapPayloadToDocInfo(
        this.documentResource.payload,
        this.documentResource.type_id
      );
    }
    this.form = this.fb.group({
      checked: [false],
      items: this.fb.array(this.docInfoItem.map((item) => this.createItem(item))),
    });

    this.needCheck.patchValue(this.documentResource?.checked);
  }

  onToggleEdit(): void {
    this.editMode = !this.editMode;
    this.showedPreview.emit(this.editMode);
  }

  createItem(item: DocInfoItem): FormGroup {
    return this.fb.group({
      key: [item.key],
      label: [item.label],
      value: [item.value],
      progress: [item.progress],
      isEditing: [item.isEditing],
    });
  }

  get items(): FormArray {
    return this.form.get("items") as FormArray;
  }

  get needCheck(): FormControl {
    return this.form.get("checked") as FormControl;
  }

  onSave(): void {
    const data: DocumentPayload = {} as DocumentPayload;
    this.items.controls.forEach((control) => {
      const key = control.get("key").value;
      const value = control.get("value").value;
      data[key] = value;
    });
    const checked = this.needCheck.value;
    const payload = { ...this.documentResource.payload, ...data };
    this.loading = true;
    this.chatHrCandidatDocumentsService
      .updateDocument(this.documentResource.id, { checked, payload })
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(
        (data) => {
          this.onToggleEdit();
          this.toastr.success("Данные успешно обновленны", "", { timeOut: 2000 });
          this.saved.emit(data);
          this.loading = false;
          this.cdr.markForCheck();
        },
        (error: HttpErrorResponse) => {
          this.toastr.error(error.error?.message?.message || "Ошибка сохранения данных", "Ошибка");
          this.loading = false;
          this.cdr.markForCheck();
        }
      );
  }

  toggleEditInfoRow(index: number): void {
    const control = this.items.at(index);
    control.patchValue({ isEditing: !control.value.isEditing });
  }
}
