import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { OpenedResume, OpenedResumeStatusInfo } from "../chat-hr-opened-resume";
import { ChatHrOpenedResumeApiService } from "../chat-hr-opened-resume.service";
import { ChatHrOpenedResumeDataService } from "../chat-hr-opened-resume-data/chat-hr-opened-resume-data.service";
import { takeUntil } from "rxjs/operators";
import { ChatHrOpenedResumeDataEditComponent } from "../chat-hr-opened-resume-data/chat-hr-opened-resume-data-edit/chat-hr-opened-resume-data-edit.component";
import { UsersSyncService } from "../opened-users-sync.service";
import { PagesToggleService } from "@Mesh/core/services/chat/toggler.service";

@Component({
  selector: "iql-chat-hr-opened-resume-list",
  templateUrl: "./chat-hr-opened-resume-list.component.html",
  styleUrls: ["./chat-hr-opened-resume-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHrOpenedResumeListComponent implements OnInit, OnDestroy {
  @Input() set scrolled(value: boolean) {
    this.loadData();
  }

  @ViewChild("scrollContainer", { static: false }) scrollContainer: ElementRef;

  private ngOndestroy$ = new Subject();
  public resumeList: OpenedResume[] = [];
  currentPage = 1;
  totalPages = 1;
  loading = false;
  statusId: string = "0";

  constructor(
    private openedResumeService: ChatHrOpenedResumeApiService,
    private cdr: ChangeDetectorRef,
    private chatHrOpenedResumeDataService: ChatHrOpenedResumeDataService,
    private usersSyncservice: UsersSyncService,
    private toggler: PagesToggleService
  ) {}

  ngOnInit() {
    this.getUsers(this.statusId, this.currentPage);
    this.usersSyncservice.triggerObservable.subscribe(() => {
      this.updateUsersList();
    });
    this.toggler.quickViewToggle.subscribe((message) => {
      if (message === "closed") {
        this.chatHrOpenedResumeDataService.closeOpenedResumeData();
      }
    });
  }

  ngOnDestroy(): void {
    this.chatHrOpenedResumeDataService.closeOpenedResumeData();
    this.ngOndestroy$.next();
    this.ngOndestroy$.complete();
  }

  updateUsersList() {
    this.resumeList = [];
    this.currentPage = 1;
    this.getUsers(this.statusId, this.currentPage);
  }

  onChangeTab(tab: OpenedResumeStatusInfo) {
    this.statusId = tab.statusId;
    this.resumeList = [];
    this.getUsers(this.statusId, this.currentPage);
  }

  getUsers(statusId: string, page: number) {
    this.loading = true;
    this.openedResumeService
      .getOpenedUsers(statusId, page)
      .pipe(takeUntil(this.ngOndestroy$))
      .subscribe((res) => {
        if (res.data && res.data.length) {
          this.currentPage = page;
          this.totalPages = res.meta.last_page;
          const newData = res.data;
          this.resumeList.push(...newData);
        }
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  onShowResume(data: any) {
    this.chatHrOpenedResumeDataService.showOpenedResumeData(ChatHrOpenedResumeDataEditComponent, data);
  }

  private loadData() {
    if (this.currentPage < this.totalPages && !this.loading) {
      this.getUsers(this.statusId, this.currentPage + 1);
    }
  }

  onScroll() {}
}
