import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraggableScrollDirective } from './draggable-scroll.directive';

@NgModule({
  declarations: [DraggableScrollDirective],
  imports: [
    CommonModule
  ],
  exports: [DraggableScrollDirective]
})
export class DraggableScrollModule { }
