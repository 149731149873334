import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SwitchCheckedComponent } from './switch-checked.component';

@NgModule({
    declarations: [
        SwitchCheckedComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        SwitchCheckedComponent
    ]
})
export class SwitchCheckedModule { }
