import { createAction, props } from '@ngrx/store';
import { IClientsLookup } from '@Mesh/core/models/lookup';

enum ClientsOutletsLookupActionTypes {
    LoadClientsOutletsLookup = '[ClientsOutletsLookup] Load ClientsOutletsLookup',
    LoadClientsOutletsLookupByParam = '[ClientsOutletsLookup] Load ClientsOutletsLookupByParam',
    PopulateClientsOutletsLookup = '[ClientsOutletsLookup] Populate ClientsOutletsLookup Entity'
}

export const getClientsOutletsLookup = createAction(ClientsOutletsLookupActionTypes.LoadClientsOutletsLookup, props<{clientSapId: number}>());
export const getClientsOutletsLookupByParam = createAction(ClientsOutletsLookupActionTypes.LoadClientsOutletsLookupByParam, props<{clientSapIds: number[]}>());
export const populateClientsOutletsLookupEntity = createAction(ClientsOutletsLookupActionTypes.PopulateClientsOutletsLookup, props<{ clientsLookup: IClientsLookup[] }>());
