import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { detailBody } from '../../../../../../@pages/animations/collapse-animation';
import { DialogByClient, TypeDialog, UserDialog } from '../../../chat';
import { User } from '../../../../../../core/models/user';

@Component({
  selector: 'iql-chat-client-outlet-item',
  templateUrl: './chat-client-outlet-item.component.html',
  styleUrls: ['./chat-client-outlet-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [detailBody],
})
export class ChatClientOutletItemComponent implements OnInit {
  @Input() userDialogs: UserDialog[];
  @Input() currentUser: User;
  @Output() openedDialog = new EventEmitter<any>();
  TypeDialog = TypeDialog;

  collapsed: boolean = true;
  toggleType: string;
  constructor(
  ) { }

  ngOnInit() {
  }
  get state(): string {
    return this.collapsed ? 'collapsed' : 'expanded';
  }

  onToggle(type: string): void {
    this.toggleType = type;
    this.collapsed = !this.collapsed;
  }

  get supportDialogs(): UserDialog[] {
    return this.userDialogs && this.userDialogs.filter(item => item.type === TypeDialog.SUPPORT);
  }

  get taskDialogs(): UserDialog[] {
    return this.userDialogs && this.userDialogs.filter(item => item.type === TypeDialog.TASK_COMMENTS);
  }

  get totalUnreadMessage(): number {
    return this.userDialogs && this.userDialogs.reduce((accumulator, dialog) => accumulator + dialog.totalUnread, 0);
  }

  // get totalSupportDialogs(): number {
  //   return this.userDialogs && this.userDialogs.reduce((accumulator, dialog) => accumulator + dialog.totalSupport, 0);
  // }

  onOpenDialog(dialog: UserDialog): void {
    this.openedDialog.emit(dialog)
  }
}
