import { Product } from "./product";

export interface BonusesRecommendedOrdersCriteria {
  addressSapId?: number;
  planId?: number[];
  userId?: number;
  recommendedOrderId?: number;
  predict?: boolean;
  executed?: boolean;
}

export interface Item {
  materialId: number;
  quantity: number;
}

export interface OrderBonusProduct {
  id: number;
  sectorId: string;
  materialId: number;
  planId: number;
}

export interface BonusAvailablePercent {
  percentStep: number;
  multiplier: number;
}

export interface OrderBonusConfig {
  id: number;
  name: string;
  shortName: string;
  image: string;
  description: string;
  items: OrderBonusProduct[];
  availablePercents: BonusAvailablePercent[];
}

export interface RecommendOrderBonus {
  id: number;
  addressSapId: number;
  userId: number;
  expiredAt: Date;
  createdAt: Date;
  nextStepPercent: number;
  nextStepBonus: number;
  currentBonus: number;
  maxBonus: number;
  requiredQuantity: number;
  executedPercent: number;
  executedQuantity: number;
  items: Item[];
  config: OrderBonusConfig;
}

export interface OrderHistoryItemsCriteria {
  pageNumber: number;
  pageSize: number;
  sort: string[];
  materialIds: number[];
  addressSapId: number;
  status: number[];
  startDate: string; // format 2022-10-10
  finishDate: string; // format 2022-11-02
}

export interface OrderHistoryItem {
  erDate: string;
  product: Product;
  quant: number;
  unitId: string;
  amount: number;
  amountQuant: number;
}

export enum OrderStatus {
  inBasket = 0,
  accepted = 1,
  finished = 2,
  denied = 3,
}

export interface DelayedOrderConfig {
  type: string
  identifier: string
  deadlineHours: number
}

export interface DelayedOrderConfigCriteria {
  page: number;
  size: number;
  type: string;
}

export enum DelayedOrderConfigType {
  CLIENT = 'CLIENT',
  ADDRESS = 'ADDRESS',
  SALES_ORG = 'SALES_ORG'
}

export const DelayedOrderConfigTypeMap = {
  [DelayedOrderConfigType.ADDRESS]: {
    title: 'Торговой точки',
    value: DelayedOrderConfigType.ADDRESS
  },
  [DelayedOrderConfigType.CLIENT]: {
    title: 'Клиент',
    value: DelayedOrderConfigType.CLIENT
  },
  [DelayedOrderConfigType.SALES_ORG]: {
    title: 'Сбытова организация',
    value: DelayedOrderConfigType.SALES_ORG
  }
}
export interface DelayedOrderConfigPayload {
  items: DelayedOrderConfig[]
}
