import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { removeEmptyParams } from '../../../../helpers/remove-empty-params';

@Component({
  selector: 'iql-chat-client-main-page-filter',
  templateUrl: './chat-client-main-page-filter.component.html',
  styleUrls: ['./chat-client-main-page-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatClientMainPageFilterComponent implements OnInit, OnDestroy {
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();
  filterForm: FormGroup;
  private ngOnDestroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  initForm(): void {
    this.filterForm = this.fb.group({
      filters: null,
      name: null,
    });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged())
      .subscribe((value) => {
        removeEmptyParams(value);
        this.filterChanged.emit(value);
      });
  }

}
