import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnInit } from '@angular/core';
import { ChatTypeSwitchService } from './chat-type-switch.service';
import { ChatType } from '@Mesh/shared/modules/chat/chat-type';

@Component({
  selector: 'iql-chat-type-switch',
  templateUrl: './chat-type-switch.component.html',
  styleUrls: ['./chat-type-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTypeSwitchComponent implements OnInit {
  @Input() newMessages: { [x: string]: number };

  chatTypeList = [
    {
      key: ChatType.SALES_POINTS,
      title: 'Торговые точки',
    },
    // {
    //   key: ChatType.SALES_AGENTS,
    //   title: 'Торговые агенты',
    // },
    {
      key: ChatType.EMPLOYEE_TRAINING,
      title: 'Обучение сотрудников',
    },
    {
      key: ChatType.RESPONSES_JOB,
      title: 'Отклики на вакансию',
    },
  ];
  currentType = ChatType.SALES_POINTS as string;
  @Output() changedType = new EventEmitter<string>();
  newMessagesIndicator: { [x: string]: number } = {};
  ChatType = ChatType;

  constructor(
    private cdr: ChangeDetectorRef,
    private chatTypeSwitchService: ChatTypeSwitchService
  ) { }

  ngOnInit(): void {
    this.chatTypeSwitchService.onChangeChatType()
      .subscribe(type => this.changeType(type));
    this.chatTypeSwitchService.onChangeNotifications()
      .subscribe(newMessage => {
        this.newMessagesIndicator = newMessage;
        this.cdr.markForCheck();
      });
  }

  onChangeType(type: ChatType): void {
    this.chatTypeSwitchService.setChatType(type);
  }

  private changeType(type: string) {
    this.currentType = type;
    this.cdr.markForCheck();
    this.changedType.emit(status);
  }
}
