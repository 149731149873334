import { createAction, props } from '@ngrx/store';
import { BalanceData } from '@Mesh/core/models/balance';
import { BalanceRequestParams } from '@Mesh/core/services/api/finance/finance.service';
import { FinanceFullData } from '@Mesh/core/models/finance';

enum FinanceActionsEnum {
  loadBalance = '[Finance] load balance',
  loadBalanceSuccess = '[Finance] load balance success',
  loadBalanceError = '[Finance] load balance error',
  loadOutletsBalance  = '[Finance] load outlets balance',
  loadOutletsBalanceSuccess = '[Finance] load outlets balance success',
  loadOutletsBalanceError = '[Finance] load outlets balance error',
  loadFinanceFullData = '[Finance] load finance full data',
  loadFinanceFullDataSuccess = '[Finance] load finance full data success',
  loadFinanceFullDataError = '[Finance] load finance full data error',
}

const loadBalance = createAction(FinanceActionsEnum.loadBalance, props<BalanceRequestParams>());
const loadBalanceSuccess = createAction(FinanceActionsEnum.loadBalanceSuccess, props<{ data: BalanceData }>());
const loadBalanceError = createAction(FinanceActionsEnum.loadBalanceError);

const loadOutletsBalance = createAction(FinanceActionsEnum.loadOutletsBalance, props<BalanceRequestParams>());
const loadOutletsBalanceSuccess = createAction(FinanceActionsEnum.loadOutletsBalanceSuccess, props<{ data: BalanceData }>());
const loadOutletsBalanceError = createAction(FinanceActionsEnum.loadOutletsBalanceError);

const loadFinanceFullData = createAction(FinanceActionsEnum.loadFinanceFullData, props<{ clientSapId: number; page: number;  itemsPerPage: number; startDate: string, finishDate: string }>());
const loadFinanceFullDataSuccess = createAction(FinanceActionsEnum.loadFinanceFullDataSuccess, props<{ data: FinanceFullData[] }>());
const loadFinanceFullDataError = createAction(FinanceActionsEnum.loadFinanceFullDataError);


export const financeAction = {
  loadBalance,
  loadBalanceSuccess,
  loadBalanceError,

  loadOutletsBalance,
  loadOutletsBalanceSuccess,
  loadOutletsBalanceError,

  loadFinanceFullData,
  loadFinanceFullDataSuccess,
  loadFinanceFullDataError,
};
