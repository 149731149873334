import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutosizeModule } from 'ngx-autosize';
import { ChatDialogContextMenuComponent } from './chat-dialog/chat-dialog-context-menu/chat-dialog-context-menu.component';
import { ReceivedReadComponent } from './received-read/received-read.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ChatMessageComponent } from './chat-dialog/chat-message/chat-message.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ChatHeaderComponent } from './chat-header/chat-header.component';
import { PreventParentScrollModule } from 'ngx-prevent-parent-scroll';
import { ContextMenuModule } from 'ngx-contextmenu';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { TimesPipe } from './times.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatAudioComponent } from './chat-audio/chat-audio.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UserInfoModule } from '../user-info/user-info.module';
import { DirectivesModule } from '../../directives';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { LongPressDirective } from './long-press.directive';
import { UserAvatarModule } from './user-avatar/user-avatar.module';
import { AuthImageModule } from './auth-image/auth-image.module';
import { ChatSearchInputComponent } from './chat-search-input/chat-search-input.component';
import { ChatUsersListComponent } from './chat-users-list/chat-users-list.component';
import { ChatUserRecordComponent } from './chat-users-list/chat-user-record/chat-user-record.component';
import { ChatCommunityComponent } from './chat-community/chat-community.component';

@Pipe({name: 'safe'})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@NgModule({
  imports: [
    PreventParentScrollModule,
    NgxUiLoaderModule,
    TooltipModule.forRoot(),
    NgxDropzoneModule,
    BsDropdownModule,
    AutosizeModule,
    InfiniteScrollModule,
    UserInfoModule,
    CommonModule,
    GalleryModule,
    PerfectScrollbarModule,
    LightboxModule,
    SwiperModule,
    FormsModule,
    ContextMenuModule.forRoot(),
    PaginationModule.forRoot(),
    UserAvatarModule,
    AuthImageModule
  ],
  declarations: [
    ChatUsersListComponent,
    ChatSearchInputComponent,
    ChatHeaderComponent,
    ChatMessageComponent,
    ReceivedReadComponent,
    ChatDialogContextMenuComponent,
    ChatDialogComponent,
    TimesPipe,
    SafePipe,
    LongPressDirective,
    ChatAudioComponent,
    ChatUserRecordComponent,
    ChatCommunityComponent
  ],
  exports: [ChatDialogComponent],
  providers: []
})
export class ChatTrainingDialogModule {
}
