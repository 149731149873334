import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { ChatHrService } from "../../chat-hr.service";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ChatHrVisitApiService } from "../chat-hr-visit.service";
import { Education, Gender, Resume } from "@Mesh/core/models/resume";

@Component({
  selector: "iql-chat-hr-visit-data-resume",
  templateUrl: "./chat-hr-visit-data-resume.component.html",
  styleUrls: ["./chat-hr-visit-data-resume.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHrVisitDataResumeComponent implements OnInit, OnDestroy {
  public currentResume: Resume;
  public resumeList: Resume[];
  private ngOnDestroy$ = new Subject<void>();
  public isLoading = true;
  private error;
  public Gender = Gender;
  public Education = Education;

  constructor(
    private chatHrService: ChatHrService,
    private chatHrVisitApiService: ChatHrVisitApiService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.chatHrService.userId$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        filter((userId) => !!userId)
      )
      .subscribe((userId) => {
        this.getResumeList(userId);
      });
  }

  private getResumeList(userId: number) {
    this.chatHrVisitApiService
      .getResume(userId)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe({
        next: (responce) => {
          this.resumeList = responce;
          if (this.resumeList && this.resumeList.length) {
            this.currentResume = this.resumeList[0];
          }
          this.isLoading = false;
          this.cdr.markForCheck();
        },
        error: (err) => {
          console.error("Error fetching resume");
          this.error = err;
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      });
  }

  public onClosed(): void {
    this.chatHrService.closeVisitData();
  }

  public onChangeResume(sourceId: string) {
    this.currentResume = this.resumeList.find(
      (item) => item.source_id === sourceId
    );
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }
}
