import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, share } from 'rxjs/operators';
import { Product } from '../../../core/models/product';
import { Outlet } from '../../../core/models/outlet';

export interface IProductParam {
  clientSapId: number;
  addressSapId: number;
  // pageNumber: number;
  // pageSize: number;
  sort: string;
}

export interface IProductParamCategory extends IProductParam {
  materialName?: string;
  manufacturerNameId?: string;
  categoryNameId?: string;
  brandNameId?: string;
  brandFamilyNameId?: string;
  productTypeNameId?: string;
  materialGroupNameId?: string;
  planId?: number;
  onlyFavorites?: boolean;
}

export interface IProductParamPlan extends IProductParam {
  planId: number;
}

class ProductParam implements IProductParam {
  addressSapId: number = null;
  clientSapId: number = null;
  pageNumber: number = 0;
  pageSize: number = 12;
  sort = 'rank|ASC';

  static empty() {
    return new ProductParam();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTableService {
  readonly changeProductFavorite$ = new BehaviorSubject<Product>(null);
  readonly changeFilterFavorite$ = new BehaviorSubject<boolean>(false);
  readonly activeOutlet$ = new BehaviorSubject<Outlet>(null);
  readonly queryParam = new BehaviorSubject<IProductParam>(ProductParam.empty());
  private readonly queryParam$ = this.queryParam.asObservable();
  private _basketToggle = new Subject();
  basketToggle = this._basketToggle.asObservable();

  readonly queryParamChanged$ = this.queryParam$.pipe(
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    debounceTime(300)
  );

  setQuery(query: IProductParam) {
    this.queryParam.next(query);
  }

  setFavoriteProduct(product: Product): void {
    this.changeProductFavorite$.next(product);
  }

  onChangeFavoriteProduct(): Observable<Product> {
    return this.changeProductFavorite$.pipe(share());
  }

  setActiveOutlet(outlet: Outlet): void {
    this.activeOutlet$.next(outlet);
  }

  onChangeActiveOutlet(): Observable<Outlet> {
    return this.activeOutlet$.pipe(share());
  }

  toggleBacket(toggle: boolean) {
    this._basketToggle.next(toggle);
  }

}
