import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { ChatService } from './chat.service';
import { User } from '@Mesh/core/models/user';
import { ChatDialogService, ChatDialogType } from './chat-dialog/chat-dialog.service';
import { filter, mergeMap, takeUntil, throttleTime } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SPINNER } from 'ngx-ui-loader';
import { UserService } from '@Mesh/core/services/chat/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PagesToggleService } from '../../../core/services/chat/toggler.service';
import { ChatTypeSwitchService } from './chat-type-switch/chat-type-switch.service';
import { TasksService } from '../../../core/services/api/task/tasks.service';
import { ChatHrService } from './chat-hr/chat-hr.service';
import { ChatHrOpenedResumeDataEditComponent } from './chat-hr/chat-hr-opened-resume/chat-hr-opened-resume-data/chat-hr-opened-resume-data-edit/chat-hr-opened-resume-data-edit.component';
import { AdItem, ChatDynamicService } from './chat-dynamic.service';
import { DynamicDirective } from '@Mesh/shared/directives/dynamic/dynamic.directive';
import { ChatType } from '@Mesh/shared/modules/chat/chat-type';


@Component({
  selector: "iql-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
  animations: [
    trigger("productRecognition", [
      state(
        "open",
        style({
          opacity: "*",
          bottom: "15px",
        })
      ),
      state(
        "closed",
        style({
          opacity: 0,
          bottom: "-56px",
        })
      ),
      transition("open => closed", [animate("0.7s")]),
      transition("closed => open", [animate("0.5s")]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {
  u$ = new Subject();
  dialog: {
    type?: string;
    typeId?: any;
    taskId?: any;
    task?: any;
    outlet?: any;
    approve?: any;
    cancel?: any;
    taskStep?: any;
    addressSapId?: number;
    clientSapId?: number;
    stepId?: number;
    sentBy?: number;
  };
  current_user: User;
  userToChat = null;
  search$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  currentUser$;
  search;
  clan$;
  all$;
  spinner = SPINNER;
  newChat;

  private _record: User | any;
  curentTypeChat: string = ChatType.SALES_POINTS;
  ChatTypes = ChatType;
  showViewVist: boolean;
  showViewLog: boolean;
  showViewResume: boolean;
  showViewDocuments: boolean;
  showOpenedUserData: boolean = false;
  @ViewChild(DynamicDirective, { static: true }) adHost!: DynamicDirective;

  constructor(
    public chatService: ChatService,
    private chatDialogService: ChatDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private feathersService: FeathersService,
    private toggler: PagesToggleService,
    private chatTypeSwitchService: ChatTypeSwitchService,
    private chatHrService: ChatHrService,
    private chatAdService: ChatDynamicService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  set searchUsers(value: string) {
    this.search$.next(value);

    this.cdr.detectChanges();
  }

  get record(): User | any {
    return this._record;
  }

  set record(value: User | any) {
    this._record = value;

    this.chatDialogService.record = value;

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.u$.next();
    this.u$.unsubscribe();
  }

  openCreateChatView(): void {
    this.newChat = true;
  }

  ngAfterViewChecked(): void {}

  ngOnInit(): void {
    this.toggler.quickViewToggle.subscribe((message) => {
      if (message === "closed") {
        this.chatService.recognitionSectionToggle = false;
        // this.curentTypeChat = ChatType.SALES_POINTS;
        this.closedDialog();
        this.resetAllVisitData();
      }
    });
    this.currentUser$ = this.feathersService.currentUserSubject;
    this.search$
      .pipe(takeUntil(this.u$), throttleTime(300))
      .subscribe((value) => {
        this.search = value;
      });

    this.chatTypeSwitchService
      .onChangeChatType()
      .pipe(takeUntil(this.u$))
      .subscribe((type) => this.onChangeTypeChat(type));
    this.chatTypeSwitchService
      .onChangeNotifications()
      .pipe(takeUntil(this.u$))
      .subscribe((newMessage) => this.setNewMessage(newMessage));
    this.currentUser$.pipe(takeUntil(this.u$)).subscribe((user) => {
      this.current_user = user;
      this.chatDialogService.current_user = user;
      this.all$ = this.chatService.getChatCommunity("all");
      this.cdr.detectChanges();
    });

    const getDialog = (params): Observable<any[]> => {
      this.dialog = {};
      if (params["action"] === "chat") {
        switch (params["action_type"]) {
          case "task-comments":
            this.dialog = {
              type: params["action_type"],
              taskId: params["task_id"],
              typeId: params["action_id"],
              addressSapId: params["address_sap_id"],
              stepId: params["step_id"],
              clientSapId: params["client_sap_id"],
              sentBy: params["sent_by"],
              approve: params["approve"],
              cancel: params["cancel"],
            };

            break;
          case "support":
            this.dialog = {
              type: params["action_type"],
              typeId: params["action_id"],
              addressSapId: params["address_sap_id"],
              clientSapId: params["client_sap_id"],
              sentBy: params["sent_by"],
            };
            break;
        }
        const observables = [];
        if (this.dialog.sentBy) {
          observables.push(this.chatService.getUser(this.dialog.sentBy));
        } else if (this.dialog.clientSapId) {
          observables.push(
            this.chatService.getUserByClientSapId(this.dialog.clientSapId)
          );
        }
        observables.push(
          this.dialog.type === "task-comments" && this.dialog.taskId
            ? this.chatService.getTaskById(this.dialog.taskId)
            : of(null),
          this.dialog.stepId
            ? this.chatService.getTaskStep(this.dialog.stepId)
            : of(null),
          this.dialog.addressSapId
            ? this.chatService.getOutletByAddressSapId(this.dialog.addressSapId)
            : of(null)
        );
        return forkJoin(observables);
      }
      return of([]);
    };

    this.route.queryParamMap
      .pipe(
        takeUntil(this.u$),
        mergeMap((paramsMap): Observable<any[]> => {
          const params = paramsMap["params"];
          return getDialog(params);
        })
      )
      .subscribe(([user, task, taskStep, outlet]) => {
        console.log("user-from:", user);
        this.dialog.task = task;
        this.dialog.taskStep = taskStep;
        this.dialog.outlet = outlet;
        this.record = user;
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(
        takeUntil(this.u$),
        mergeMap((): Observable<any[]> => {
          const params = {
            ...this.route.snapshot.params,
            ...this.route.snapshot.queryParams,
          };
          return getDialog(params);
        })
      )
      .subscribe(([user, task, taskStep, outlet]) => {
        console.log("user-from:", user);
        this.dialog.task = task;
        this.dialog.taskStep = taskStep;

        this.dialog.outlet = outlet;
        this.record = user;
      });
    this.onChangeShowVistData();
    this.subscribeToAddComponent();
  }

  onBack(): void {
    const queryParams = {
      action: "chat",
      action_type: null,
      action_id: null,
      step_id: null,
      address_sap_id: null,
      client_sap_id: null,
      approve: null,
      cancel: null,
      sent_by: null,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: "merge",
    });
  }

  openChat(record: { dialog?: any; type?: ChatDialogType; info? }): void {
    if (record && record.dialog) {
      const { id: current_user_id } = this.chatService.user,
        { id: reply_user_id = current_user_id } =
          record.dialog.message.replyUser || {};
      this.dialog = record.dialog;
      this.record =
        current_user_id !== reply_user_id
          ? record.dialog.message.replyUser
          : record.dialog.message.user;
    } else {
      this.dialog = null;
      this.record = record;
    }
    this.cdr.detectChanges();
  }

  closeChat(): void {
    this.toggler.toggleQuickView("closed");
  }

  closedDialog(): void {
    this.record = null;
    this.dialog = null;
    this.onBack();
  }

  get recognitionSectionToggle(): boolean {
    return this.chatService.recognitionSectionToggle;
  }

  onChangeTypeChat(type: string): void {
    this.curentTypeChat = type;
  }

  private setNewMessage(data: { [x: string]: number }) {
    const totalUnread = Object.values(data).reduce(
      (accumulator, count) => accumulator + count,
      0
    );
    this.toggler.setHasMessages(totalUnread > 0);
  }

  onChangeShowVistData() {
    this.chatHrService
      .onShowShareChat()
      .pipe(takeUntil(this.u$))
      .subscribe((data) => {
        if (data) {
          this.resetAllVisitData();
          switch (data.entity) {
            case "viewVisit":
              this.showViewVist = data.open;
              this.cdr.markForCheck();
              break;
            case "viewLog":
              this.showViewLog = data.open;
              this.cdr.markForCheck();
              break;
            case "viewResume":
              this.showViewResume = data.open;
              this.cdr.markForCheck();
              break;
            case "viewDocuments":
              this.showViewDocuments = data.open;
              this.cdr.markForCheck();
              break;
            default:
              this.resetAllVisitData();
              break;
          }
        } else {
          this.resetAllVisitData();
        }
      });
  }

  private resetAllVisitData() {
    this.showViewLog = null;
    this.showViewResume = null;
    this.showViewVist = null;
    this.showViewDocuments = null;
    this.cdr.markForCheck();
  }

  subscribeToAddComponent() {
    this.chatAdService.getDynamicItemObservable()
      .pipe(takeUntil(this.u$))
      .subscribe(adItem => {
        this.loadComponent(adItem);
        this.cdr.markForCheck();
      });
  }

  loadComponent(adItem: AdItem | null) {
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    if (adItem) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
      const componentRef = viewContainerRef.createComponent(componentFactory);
      componentRef.instance.data = adItem.data;
    }
  }
}
