export class Region {
  id?: number;
  regionName: string;
  regionCode: number;

  constructor(id?: number, regionName?: string, regionCode?: number) {
    this.id = id;
    this.regionName = regionName;
    this.regionCode = regionCode;
  }
}

export class City {
  id?: number;
  cityName: string;
  regionCode: number;

  constructor(id: number, cityName: string, regionCode: number) {
    this.id = id;
    this.cityName = cityName;
    this.regionCode = regionCode;
  }
}

export interface Criteria {
  size?: number;
  page?: number;
  sort?: string;
  direction?: string;
}

export interface CitiesCriteria extends Criteria {
  cityNamePattern?: string;
  regionCode?: string;
}


export interface RegionCriteria extends Criteria {
  regionNamePattern?: string;
}
