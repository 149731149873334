import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_URL } from '@Env/environment';
import { OrderDocInfo, OrderDocInfoDetailed } from '@Mesh/core/models/APImodels/saleplan/orders';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  readonly connectionString = SALEPLAN_URL;

  constructor(private readonly http: HttpClient) {
  }

  getOrderHistory(data: { addressSapId: number, docDateEnd: string, docDateStart: string, status?: number[] }): Observable<OrderDocInfo[]> {
    let params = new HttpParams();
    if (data.docDateEnd) {
      params = params.append('finishDate', `${data.docDateEnd}`);
    }
    if (data.docDateStart) {
      params = params.append('startDate', `${data.docDateStart}`);
    }
    params = params.append('pageSize', '100');
    data.status?.forEach(status => params = params.append('status', `${status}`));
    return this.http.get<{ content: OrderDocInfo[] }>(`${this.connectionString}/v1/order-history/${data.addressSapId}`, { params }).pipe(pluck('content'));
  }

  getOrderHistoryDetailed(docNumber: number): Observable<OrderDocInfoDetailed[]> {
    return this.http.get<OrderDocInfoDetailed[]>(`${this.connectionString}/v1/order-history/details/${docNumber}`);
  }
}
