import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/task/tasks.actions';
import { IStockInfo, TaskData, TaskInfo, TaskStepStatusResponse } from '@Mesh/core/models/task';
import { LoadingStatus, PostingStatus } from '@Mesh/core/models/external/loadable';
import * as moment from 'moment';
import {IClientsForTask} from '@Mesh/core/models/client';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import {loadAllClientsOutlets} from '../../actions/task/tasks.actions';

export interface State {
    tasks: TaskData;
    currentTask: TaskInfo;
    stepStatuses: TaskStepStatusResponse[];
    clients: IClientsForTask[];
    isUpdating: boolean;
    loading: boolean;
    planNames: { planName: string, plan_id: number }[];
    planStock: IStockInfo;
    taskCreatingStatus: PostingStatus;
    taskLoadingStatus: LoadingStatus;
    stepPostingStatus: PostingStatus;
    predictBonusClient: BonusClient;
    predictBonusClientLoadingStatus: LoadingStatus;
    // stepPostingQueue: {stepId: number, postingStatus: PostingStatus}[];
    // stepQueueLength: number;
}

export const initialState: State = {
    tasks: null,
    currentTask: null,
    planNames: undefined,
    planStock: undefined,
    stepStatuses: [],
    clients: [],
    loading: false,
    isUpdating: false,
    taskCreatingStatus: PostingStatus.Initial,
    taskLoadingStatus: LoadingStatus.NotLoaded,
    stepPostingStatus: PostingStatus.Initial,
    predictBonusClient: null,
    predictBonusClientLoadingStatus: LoadingStatus.NotLoaded,
    // stepPostingQueue: [],
    // stepQueueLength: 0
};

const tasksReducer = createReducer(
    initialState,
    on(fromActions.loadTasks, state => ({ ...state, tasks: null, taskLoadingStatus: LoadingStatus.Loading })),
    on(fromActions.tasksLoaded, (state, { tasks }) => ({ ...state, tasks, taskLoadingStatus: LoadingStatus.Loaded })),
    on(fromActions.tasksLoadError, (state) => ({ ...state, tasks: null, taskLoadingStatus: LoadingStatus.Error })),
    on(fromActions.loadCompletedTasks, state => ({ ...state, tasks: null, taskLoadingStatus: LoadingStatus.Loading })),
    on(fromActions.completedTasksLoaded, (state, { tasks }) => ({ ...state, tasks, taskLoadingStatus: LoadingStatus.Loaded })),
    on(fromActions.completedTasksLoadError, (state) => ({ ...state, tasks: null, taskLoadingStatus: LoadingStatus.Error })),
    on(fromActions.createTask, (state) => ({ ...state, taskCreatingStatus: PostingStatus.Posting })),
    on(fromActions.updateTask, (state) => ({ ...state, taskCreatingStatus: PostingStatus.Posting })),
    on(fromActions.taskUpdated, (state, { task }) => {
        if (state.tasks && state.tasks.content && state.tasks.content.length) {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    content: state.tasks.content.map(t => t.id === task.id ? { ...task, id: task.id, brand: { ...task.brand } } : t)
                },
                taskCreatingStatus: PostingStatus.Posted
            };
        }
        return { ...state, taskCreatingStatus: PostingStatus.Posted };
    }),
    on(fromActions.taskUpdateError, (state) => ({ ...state, taskCreatingStatus: PostingStatus.Error })),
    on(fromActions.setTaskUpdateInit, (state) => ({
        ...state,
        taskCreatingStatus: PostingStatus.Initial,
        stepPostingStatus: PostingStatus.Initial
    })),

    on(fromActions.taskPatched, (state, { task, id }) => {
        if (state.tasks && state.tasks.content && state.tasks.content.length) {
            return {
                ...state,
                tasks: { ...state.tasks, content: state.tasks.content.map(t => t.id === id ? { ...t, ...task } : t) }
            };
        }
        return { ...state };
    }),

    on(fromActions.loadAllClientsOutlets, (state) => {
        return { ...state, loading: true };
    }),

    on(fromActions.loadAllClientsOutletsLoaded, (state, { data, reset}) => {
        if (state.clients) {
            return {
                ...state,
                clients: reset ? data : state.clients.concat(data),
                loading: false
            };
        }
        return { ...state, loading: false };
    }),
    on(fromActions.taskCreated, (state, { task }) => {
        if (state.tasks && state.tasks.content && state.tasks.content.length) {
            return {
                ...state,
                tasks: { ...state.tasks, content: [...state.tasks.content, task] },
                taskCreatingStatus: PostingStatus.Posted
            };
        }
        if (state.tasks) {
            return {
                ...state,
                tasks: { ...state.tasks, content: [task] },
                taskCreatingStatus: PostingStatus.Posted
            };
        }
        return { ...state, taskCreatingStatus: PostingStatus.Posted };
    }),
    on(fromActions.createSteps, (state) => {
        return { ...state, stepPostingStatus: PostingStatus.Posting };
    }),
    on(fromActions.stepUpdateError, (state, error) => {
        // if (state.stepQueueLength > 0 && state.stepQueueLength < state.stepPostingQueue.length) {
        //     state.stepPostingQueue.push({stepId: error.id, postingStatus: PostingStatus.Error})
        // }
        return { ...state, stepPostingStatus: PostingStatus.Error };
    }),
    on(fromActions.stepsCreated, (state, { steps, taskId }) => {
        // if (state.stepQueueLength > 0 && state.stepQueueLength < state.stepPostingQueue.length) {
        //     state.stepPostingQueue.push({stepId: error.id, postingStatus: PostingStatus.Posted})
        // }
        return {
            ...state,
            tasks: {
                ...state.tasks,
            },
            stepPostingStatus: PostingStatus.Posted
        };
    }),
    on(fromActions.taskLoaded, (state, { task }) => {
        return {
            ...state,
            currentTask: { ...task },
            steps: []
        };
    }),
    on(fromActions.clearTask, (state) => {
        return {
            ...state,
            currentTask: undefined,
            steps: []
        };
    }),
    on(fromActions.planNamesLoaded, (state, { planNames }) => {
        return {
            ...state,
            planNames
        };
    }),
    on(fromActions.stepStatusesLoaded, (state, { steps }) => {
        let resultSteps = state.stepStatuses.slice();
        for (const step of steps) {
            const stepIndex = state.stepStatuses.findIndex((s: TaskStepStatusResponse) => s.addressSapId === step.addressSapId && s.clientSapId === step.clientSapId && s.taskId === step.taskId);
            if (stepIndex === -1) {
                resultSteps = [...resultSteps, step];
            }
            resultSteps[stepIndex] = step;
        }

        return {
            ...state,
            stepStatuses: resultSteps
        };
    }),
    on(fromActions.planStockLoaded, (state, { stockInfo, productsInfo }) => {
        const products = [];

        for (const s of stockInfo) {
            const product = productsInfo.find(p => p.materialId === s.materialId);

            if (!product) {
                continue;
            }

            products.push({
                materialId: product.materialId,
                imageUrl: product.url || product.productInfo?.imageUrl,
                name: product.materialName || product.productInfo?.name,
                quantity: s.quantity,
                unit: 'шт.'
            });
        }

        const planStock: IStockInfo = {
            planName: 'Plan Name',
            date: moment.utc(stockInfo[0]?.registerAt).format('L'),
            productsInfo: products
        };

        return {
            ...state,
            planStock
        };
    }),
    on(fromActions.clearPlanStock, (state) => {
        return {
            ...state,
            planStock: undefined
        };
    }),
  on(fromActions.taskBonusAction.loadPredictBonusClient, (state) => ({ ...state, predictBonusClient: null, predictBonusClientLoadingStatus: LoadingStatus.Loading })),
  on(fromActions.taskBonusAction.loadPredictBonusClientSuccess, (state, { data }) => ({ ...state, predictBonusClient:  data, predictBonusClientLoadingStatus: LoadingStatus.Loaded })),
  on(fromActions.taskBonusAction.loadPredictBonusClientError, (state, { error }) => ({ ...state, bonus: [], predictBonusClientLoadingStatus: LoadingStatus.Error })),
);

export function reducer(state: State | undefined, action: Action) {
    return tasksReducer(state, action);
}

export const getTasks = (state: State) => state.tasks;
export const getCurrentTask = (state: State) => state.currentTask;
export const getTasksClients = (state: State) => state.clients;
export const tasksLoading = (state: State) => state.loading;
export const getTasksLoadingStatus = (state: State) => state.taskLoadingStatus;
export const getTaskPostingStatus = (state: State) => state.taskCreatingStatus;
export const getStepsPostingStatus = (state: State) => state.stepPostingStatus;
export const getStepStatuses = (state: State) => state.stepStatuses;
export const getIsUpdating = (state: State) => state.isUpdating;
export const getPlanNames = (state: State) => state.planNames;
export const getPlanStock = (state: State) => state.planStock;
export const getPredictBonusClient = (state: State) => state.predictBonusClient;
export const getPredictBonusClientLoadingStatus = (state: State) => state.predictBonusClientLoadingStatus;
