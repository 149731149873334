import { Injectable } from '@angular/core';
import { FeathersService } from './feathers.service';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class ChatLikesService {
  constructor(private feathers: FeathersService) {
  }

  get service() {
    return this.feathers.service('message-likes');
  }

  toggleLike(type: string, typeId: number, messageId: number): any {
    return this.service.create({ type, typeId, messageId  });
  }

}
