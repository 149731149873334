import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ChatHrService } from '../../chat-hr.service';
import { Observable, Subject } from 'rxjs';
import { filter, share, switchMap, tap } from 'rxjs/operators';
import { ChatHrVisitApiService } from '../chat-hr-visit.service';
import { TaskPaymentRecord, UserMoneyRecord, VisitDataItem, VisitJournalEvent } from '@Mesh/shared/modules/chatHR/models/visit-dialog';
import { LogTabsKey } from './visit-data-log-tabs/visit-data-log-tabs.component';

@Component({
  selector: 'iql-chat-hr-visit-data-log',
  templateUrl: './chat-hr-visit-data-log.component.html',
  styleUrls: ['./chat-hr-visit-data-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrVisitDataLogComponent implements OnInit {
  private ngOnDestroy$: Subject<null> = new Subject<null>();
  visit: VisitDataItem;
  isLoading: boolean;
  messageList$: Observable<VisitJournalEvent[]> = null;
  taskPaymentList$: Observable<TaskPaymentRecord[]> = null;
  userMoneyList$: Observable<UserMoneyRecord[]> = null;
  viewMode: LogTabsKey = LogTabsKey.EVENTS;
  LogTabsKey = LogTabsKey;

  constructor(
    private chatHrService: ChatHrService,
    private visitService: ChatHrVisitApiService
  ) { }

  ngOnInit() {
    const sharedChatStream$ = this.chatHrService.onShowShareChat().pipe(
      filter((data) => data.entity === 'viewLog' && data.open === true),
      tap(data => {
        this.visit = data.visitDataItem
      }),
      share()
    );
    this.messageList$ = this.createStreamFromService(sharedChatStream$,
      () => this.visitService.getVisitJournal(this.visit.id));
    this.taskPaymentList$ = this.createStreamFromService(sharedChatStream$,
      () => this.visitService.getTaskPaymentsByUserId(this.visit.user.id));
    this.userMoneyList$ = this.createStreamFromService(sharedChatStream$,
      () => this.visitService.getUserMoneyByUserId(this.visit.user.id));
  }

  private createStreamFromService(stream$, serviceMethod: () => Observable<any>): Observable<any> {
    return stream$.pipe(
      switchMap(() => serviceMethod()),
    );
  }

  onClosed(): void {
    this.chatHrService.closeVisitData();
  }

  onChangeTab(tabKey: LogTabsKey) {
    this.viewMode = tabKey;
  }
}
