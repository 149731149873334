import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/deprecated/clients.actions';
import { Client, ClientInfo, ClientsData } from '@Mesh/core/models/client';
import { generateToSet, Loadable, LoadingStatus } from '@Mesh/core/models/external/loadable';

export interface State {
    clients: Loadable<Array<{clientSapId: string; name: string}>>;
    clientsData: ClientsData;
    loadingStatus: LoadingStatus;
    currentPage: number;
    currentClient: Client;
    clientInfo: ClientInfo;
    bonusClient: {clientSapId: number, account: number};
}

export const initialState: State = {
    clients: new Loadable([], LoadingStatus.NotLoaded),
    clientsData: null,
    loadingStatus: LoadingStatus.NotLoaded,
    currentPage: 0,
    currentClient: null,
    clientInfo: null,
    bonusClient: null,
};

const clientsReducer = createReducer(
    initialState,
    on(fromActions.loadClientsByParams, (state, query) => (
        {
            ...state,
            clients: (state.clients.loadingStatus === LoadingStatus.NotLoaded
                || state.clients.loadingStatus === LoadingStatus.Error
                || state.clients.query !== query) ?
                new Loadable(query?.cityName && !query?.page ? [] : state.clients.value, LoadingStatus.Loading, query) :
                state.clients
        })
    ),
    on(fromActions.clientsByParamsLoaded, (state, { clients }) => {
        return {
            ...state,
            clients: {...state.clients, value: generateToSet(state.clients.value.concat(clients), 'clientSapId'), loadingStatus: LoadingStatus.Loaded}
        };
    }),
    on(fromActions.clientsDataLoaded, (state, { clientsData }) => {
        return {...state, clientsData};
    }),
    on(fromActions.currentClientLoaded, (state, { client }) => ({ ...state, currentClient: client })),
    on(fromActions.clientInfoLoaded, (state, { clientInfo }) => ({ ...state, clientInfo })),
    on(fromActions.clientInfoCreated, (state, { clientInfo }) => ({ ...state, clientInfo })),
    on(fromActions.clientInfoUpdated, (state, { clientInfo }) => ({ ...state, clientInfo })),
    on(fromActions.loadBonusClientSuccess, (state, {data}) => ({...state, bonusClient: data})),
);

export function reducer(state: State | undefined, action: Action) {
    return clientsReducer(state, action);
}

export const getClients = (state: State) => state.clients.value;
export const getClientsData = (state: State) => state.clientsData;
export const getClientsLoadingStatus = (state: State) => state.loadingStatus;
export const getCurrentPage = (state: State) => state.currentPage;
export const getCurrentClient = (state: State) => state.currentClient;
export const getClientInfo = (state: State) => state.clientInfo;
export const getBonusClient = (state: State) => state.bonusClient;
