import { Subject } from 'rxjs';
import { Product, Stock } from '@Mesh/core/models/product';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-catalog-distributor-modal',
  templateUrl: './catalog-distributor-modal.component.html',
  styleUrls: ['./catalog-distributor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogDistributorModalComponent implements OnInit {
  product: Product;
  currentStock: Stock;
  selectedStock: Stock;
  onClose: Subject<Stock> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  setCurrentStock(stock: Stock): void {
    this.currentStock = stock;
    this.selectedStock = stock;
    this.cdr.detectChanges();
  }

  onChoose(stock: Stock): void {
    this.onClose.next(stock);
  }

  selectCurrentProduct(product: Product): void {
    this.product = product;
    this.cdr.detectChanges();
  }

  close(): void {
    this.onClose.next(null);
  }

  ngOnInit(): void {}

  getListStocks(): Stock[] {
    // let stocks: any = []
    // this.product.stock.forEach(stock => {
    //   if (stock.unit === this.currentStock?.unit) {
    //     stocks.push(stock)
    //     // distributors[stock.distributorName] = { ndsPrice: stock.ndsPrice };
    //   }
    // });
    // return this.product.stock.filter((stock) => stock.unit === this.currentStock?.unit)
    // return this.product.stock

    if (!this.currentStock) return [];

    let groupedStock = [];
    this.product.groupedStock.forEach((group) => {
      // let stocks = group.stock.filter((stock) => stock.unit === this.currentStock?.unit)
      // groupedStock = [...groupedStock, ...stocks]
      // groupedStock.push(group.stock[0]);
      group.stock.forEach((stock) => {
        if (stock.unit === this.currentStock.unit) {
          groupedStock.push(stock);
        }
      });
    });
    return groupedStock;
  }
}
