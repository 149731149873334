import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { USER_URL } from '@Env/environment';
import { Observable } from 'rxjs';
import { DocumentsData } from '@Mesh/core/models/document';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  readonly baseUrl = `${USER_URL}/document`;

  constructor(private http: HttpClient) {
  }

  getDocuments(param: DocRequestParams): Observable<DocumentsData> {
    let params = new HttpParams();

    if (param.size) {
      params = params.append('size', `${param.size}`);
    }
    if (param.pageNumber) {
      params = params.append('page', `${param.pageNumber}`);
    }
    if (param.sort) {
      params = params.append('sort', `${param.sort}`);
    }
    if (param.direction) {
      params = params.append('direction', `${param.direction}`);
    }
    if (param.documentType) {
      params = params.append('documentType', `${param.documentType}`);
    }
    if (param.clientSapId) {
      params = params.append('clientSapId', `${param.clientSapId}`);
    }
    if (param.startDate) {
      params = params.append('startDate', `${param.startDate}`);
    }
    if (param.endDate) {
      params = params.append('endDate', `${param.endDate}`);
    }
    return this.http.get<DocumentsData>(`${this.baseUrl}`, {params});
  }
}

export interface DocRequestParams {
  size?: number;
  pageNumber?: number;
  sort?: string;
  direction?: string;
  documentType?: string[];
  clientSapId?: number;
  startDate?: string;
  endDate?: string;
}
