import { Deserializable } from './deserializable';
import { User } from '@Mesh/core/models/user';
import { ChatUserInfo } from './chat-user-info';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ChatMessageQuote } from '@Mesh/shared/modules/chat/models/chat-message-quote';
import { E } from '@angular/cdk/keycodes';
import { ProductRecognition } from '../../shared/modules/chat/models/recognition';
export interface TaskStep {
  cost: number;
  description: string;
  id: number;
  load: boolean;
  imageUrl: string;
  name: string;
  step: number;
  sended: boolean;
  success: boolean;
  notPassed: boolean;
  status;
  taskId: number;
  time: string;
  videoUrl: string;
  additionalType?: string;
  recommendedOrderConfigId?: number;
  type?: {
    description: string;
    goldCost: number;
    ironCost: number;
    name: string;
    silverCost: number;
    title: string;
    uncategoryCost: number;
  };
  stepSuccess?: {
    id: number;
    recognitionResult: RecognitionResult;
    productRecognition?: ProductRecognition;
  };
}

export class RecognitionResult {
  id: number;
  images: RecognitionResultImage[];
}
export class RecognitionResultImage {
  id: number;
  imagePositions: RecognitionResultImagePosition[];
  markedUpImage: string;
  originalImage: string;
}

export class RecognitionResultImagePosition {
  autoMarking: boolean;
  confidence: number;
  image: string;
  materialId: number;
  name: string;
  xmax: number;
  xmin: number;
  ymax: number;
  ymin: number;
}
export class Comment implements Deserializable {
  id: number;
  answer?: string;
  text: string;
  user: ChatUserInfo;
  replyUser: ChatUserInfo;
  replyMessageId: number;
  type: string | null;
  typeId?: number | null;
  addressSapId?: number | null;
  senderAddressSapId?: number | null;
  readAt?: Date | null;
  editedAt?: Date | null;
  deletedAt?: Date | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  userSubject: BehaviorSubject<User>;
  replyUserSubject: BehaviorSubject<User>;
  participants?: number[];
  totalUnread?: number;
  isCanceledTask?: any;
  isApprovedTask?: any;
  isStepInfo?: boolean;
  isTicketInfo?: boolean;
  // link?: string;
  // teacher?: ChatUserInfo;
  message?: Comment;
  attached: {
    messages: ChatMessageQuote[];
    uploads: any[];
  };
  ticket?: {
    id: number;
    status: string;
    createdAt: string;
  };
  task?: {
    id: number;
    name: string;
    description: string;
    cost: number;
    imageUrl: number;
    active: boolean;
    step: TaskStep[];
    // link?: string;
    // teacher?: ChatUserInfo;
  };
  taskStep: TaskStep;
  module?: {
    id: number;
    name: string;
    image: string;
    teacherId: number;
    type?: string;
    // link?: string;
    // teacher?: ChatUserInfo;
  };
  settings?: {
    muted: 0,
    pinned: 0,
    blocked: 0,
    deletedAt: null,
  };
  replies: {
    total: number,
    limit: number,
    skip: number,
    data: Comment[]
  };
  likes: number;
  liked: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (this.user) {
      this.user = new ChatUserInfo().deserialize(this.user);
      const tempUser = new User().deserialize({
        id: input.user.id
      });
      this.userSubject = new BehaviorSubject<User>(tempUser);
    }
    if (this.replyUser) {
      const tempReplyUser = new User().deserialize({
      id: (input.replyUser || {}).id || input.user.id
      });
      this.replyUserSubject = new BehaviorSubject<User>(tempReplyUser);
    }
    this.createdAt = new Date(input.createdAt || Date.now());
    this.updatedAt = new Date(input.updatedAt || Date.now());
    return this;
  }

  get createdAtFromNow(): string {
    if (this.createdAt) {
      return moment(this.createdAt).fromNow();
    }
  }
}
