import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectDropdownComponent } from './multi-select-dropdown.component';
import { FormsModule } from '@angular/forms';
import { ClickOutsideDirective } from './click-outside.directive';



@NgModule({
  declarations: [
    MultiSelectDropdownComponent,
    ClickOutsideDirective
  ],
  exports: [MultiSelectDropdownComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
})
export class MultiSelectDropdownModule { }
