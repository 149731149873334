import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef, AfterViewChecked, AfterViewInit, NgZone
} from '@angular/core';
import { Comment } from '../../models/comment';
import { User } from '../../models/user';
import { ChatService } from '../../service/chat.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { ChatUserInfo } from '../../models/chat-user-info';
import { FORWARDED_MESSAGE } from '../chat-dialog.component';
import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModuleTypes } from '../chat-dialog.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnInit, AfterViewInit, AfterViewChecked {
  FORWARDED_MESSAGE = FORWARDED_MESSAGE;
  private _message: Comment;
  @Input() mine: boolean;
  @Input() isEditing: boolean;
  @ViewChild(ContextMenuComponent, { static: false }) contextMenu: ContextMenuComponent;

  images;
  videos = [];
  otherFiles;
  imagesQuote;
  videosQuote;
  imagesLoadedCount = 0;
  @Output() edit = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() forward = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() quote = new EventEmitter();
  @Output() quoteClick = new EventEmitter();
  @Output() imagesLoaded = new EventEmitter();
  @Output() loaded = new EventEmitter();

  @ViewChild('basicMenu', { static: false }) public otherMenu: ContextMenuComponent;
  @ViewChild('triggerMenu', { static: false }) public triggerMenu: ElementRef<HTMLElement>;
  @ViewChild('element', { static: false }) public element: ElementRef<HTMLElement>;
  @ViewChild('video', { static: false }) public video: ElementRef<HTMLVideoElement>;
  galleryId;

  get edited() {
    const { editedAt } = this._message;
    return editedAt;
  }

  get message(): Comment {
    return this._message;
  }

  ngAfterViewInit(): void {
    this.loaded.emit(this.message);
    this.imagesLoaded.emit(this.message.id);

    this.zone.runOutsideAngular(() => {
      if (this.video && this.video.nativeElement) {
        this.imageLoaded();
        this.video.nativeElement.src = '' + this.video.nativeElement.src;
      }
      if (this.message && this.images) {

        const loadImages = [];

        // this.images.map(item => loadImages.push(this.authImagePipe.transform(item.original)));

        // Promise.all(loadImages).then(urls => {
        const lightboxGalleryRef = this.gallery.ref('lightbox' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const items = this.images.map((item, index) =>
          new ImageItem({ src: item.original, thumb: item.url })
        );
        lightboxGalleryRef.load(items);
        // });

        // 3. Load the items into the lightbox
      }
      if (this.message && this.imagesQuote) {

        // const loadImages = [];

        // this.imagesQuote.map(item => loadImages.push(this.authImagePipe.transform(item.original)));

        // Promise.all(loadImages).then(urls => {
        //   console.log(urls);
        const lightboxGalleryRef = this.gallery.ref('lightbox-qoute' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });
        const items = this.imagesQuote.map((item, index) =>
          new ImageItem({ src: item.original, thumb: item.url })
        );
        lightboxGalleryRef.load(items);
        // });

        // 3. Load the items into the lightbox
      }
    });
  }

  get imagesCount() {
    return (this.images ? this.images.length : 0) + (this.imagesQuote ? this.imagesQuote.length : 0) + (this.videos ? this.videos.length : 0);
  }

  imageLoaded() {
    this.imagesLoadedCount++;
    if (this.imagesLoadedCount === this.imagesCount) {
      this.imagesLoaded.emit(this.message.id);
    }
  }

  get empty() {
    return this.message?.attached?.uploads?.length === 0 && (this.message.text === '' || this.message.text === FORWARDED_MESSAGE || this.message.text === '[Пересланное сообщение]') && this.message?.attached?.messages?.length === 0;
  }

  ngAfterViewChecked(): void {
  }

  @Input()
  set message(value: Comment) {
    // console.log(value, 'message');
    this._message = value;
    const imageExts = ['svg', 'jpeg', 'jpg', 'png', 'gif'];
    const videoExts = ['avi', 'mp4', 'webm', 'mov', 'ogg', '3gp', 'wmv', 'qt'];
    const audioExts = ['weba', 'wav', 'pgg'];
    this.images = this._message?.attached?.uploads?.filter(item => [...imageExts].indexOf(item.ext) !== -1);
    console.log('value.id:', this._message.attached?.messages[0]?.message?.attached?.uploads);
    this.imagesQuote = this._message.attached?.messages[0]?.message?.attached?.uploads?.filter(item => [...imageExts].indexOf(item.ext) !== -1);
    this.videosQuote = this._message.attached?.messages[0]?.message?.attached?.uploads?.filter(item => [...videoExts].indexOf(item.ext) !== -1);

    this.videos = this._message?.attached?.uploads?.filter(item => [...videoExts].indexOf(item.ext) !== -1);
    this.otherFiles = this._message?.attached?.uploads?.filter(item => [...videoExts, ...imageExts, ...audioExts].indexOf(item.ext) === -1);

    this.cdr.detectChanges();
  }

  editMessage() {
    this.edit.emit(this._message);
  }

  removeMessage() {
    this.remove.emit(this._message);
  }

  forwardMessage() {
    this.forward.emit(this._message);
  }

  copyMessage() {
    if (this._message.text) {
      const inp = document.createElement('input');
      document.body.appendChild(inp);
      inp.value = this._message.text;
      inp.select();
      document.execCommand('copy', false);
      inp.remove();
    }
  }

  quoteMessage() {
    this.quote.emit(this._message);
  }

  showContextMenu($event: MouseEvent | any, right) {
    console.log('press');
    const event = { ...$event };
    const el: HTMLElement = this.element.nativeElement;
    const clientRect = el.getBoundingClientRect();
    let posx = $event.pageX, posy = $event.pageY;
    if ($event.changedTouches) {
      posx = $event.changedTouches[0].pageX;
      posy = $event.changedTouches[0].pageY;
    }
    if ($event.targetTouches) {
      posx = $event.targetTouches[0].pageX;
      posy = $event.targetTouches[0].pageY;
    }
    console.log('menu:', $event);
    event.clientX = posx;
    event.clientY = posy;
    this.contextMenuService.show.next({
      contextMenu: this.otherMenu,
      event: event,
      item: $event.target
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  log(message) {
    // console.log(message);
  }

  constructor(
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    public domSanitizer: DomSanitizer,
    private zone: NgZone,
    public gallery: Gallery,
  ) {
  }

  ngOnInit() {
    this.imagesLoadedCount = 0;
  }

  openMenu($event) {
    const el: HTMLElement = this.triggerMenu.nativeElement;
    console.log($event.clientX);
  }

  get audios() {
    return this._message?.attached?.uploads?.reverse().filter(item => ['weba', 'wav'].indexOf(item.ext) !== -1);
  }

  get files() {
    return this._message?.attached?.uploads?.reverse().filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif', 'webm', 'weba'].indexOf(item.ext) === -1);
  }

  get hasContent() {
    return (this.message.text && this.message.text !== '' && this.message.text !== FORWARDED_MESSAGE && this.message.text !== '[Пересланное сообщение]') || (this.files && this.files.length > 0) || (this.audios && this.audios.length > 0);
  }

  openChatModal(i) {
  }

  get oneMark() {
    const { readAt, createdAt } = this._message;
    return !createdAt || !readAt;
  }

  get twoMarks() {
    const { readAt, createdAt } = this._message;
    return createdAt && readAt;
  }


  getName(user: User | ChatUserInfo) {
    if (user.patronymic) {
      return `${user.surname} ${user.name?.charAt(0).toUpperCase()}. ${user.patronymic?.charAt(0).toUpperCase()}.`;
    } else {
      return `${user.surname} ${user.name?.charAt(0).toUpperCase()}.`
    }

  }

  get isMessageFromChat() {
    /*if (this.message.attached?.messages?.length > 0) {
      for (const message of this.message.attached.messages) {
        if (message?.message?.user.id !== this.message.replyUser?.id &&
          message?.message?.user.id !== this.message.user?.id
        ) {
          return false;
        }
      }
    }*/
    return true;
  }

  get isTaskMessage() {
    return this.message.type === 'task-comments';
  }

  get isModuleMessage() {
    return ModuleTypes.indexOf(this.message.type) !== -1;
  }

  toggleLike() {
    if (this._message.liked) {
      this._message.liked = false;
      this._message.likes--;
    } else {
      this._message.liked = true;
      this._message.likes++;
    }
    this.cdr.detectChanges();
    this.chatService.toggleLike(this._message.type, this._message.typeId, this._message.id);
  }

  onClickTask(task_id: number) {
    // this.moduleService.taskStart.emit({task_id});
  }
}
