import { User } from '../models/user';
import { ChatUserInfo } from '../models/chat-user-info';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ChatMessageQuote } from '../models/chat-message-quote';
import { Deserializable } from './deserializable';

export class Comment implements Deserializable {
  id: number;
  text: string;
  user: ChatUserInfo;
  replyUser: ChatUserInfo;
  replyMessageId: number;
  type: string | null;
  typeId?: number | null;
  readAt?: Date | null;
  editedAt?: Date | null;
  deletedAt?: Date | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  // userSubject: BehaviorSubject<User>;
  // replyUserSubject: BehaviorSubject<User>;
  participants?: number[];
  totalUnread?: number;
  // link?: string;
  // teacher?: ChatUserInfo;
  message?: Comment;
  attached: {
    messages: ChatMessageQuote[];
    uploads: any[];
  };
  task?: {
    id: number;
    name: string;
    description: string
    goblet: string;
    teacherId: number;
    type?: string;
    // link?: string;
    // teacher?: ChatUserInfo;
  };
  module?: {
    id: number;
    name: string;
    image: string;
    teacherId: number;
    type?: string;
    // link?: string;
    // teacher?: ChatUserInfo;
  };
  settings?: {
    muted: number,
    pinned: number,
    blocked: number,
    deletedAt: boolean,
    archived: number,
  };
  replies: {
    total: number,
    limit: number,
    skip: number,
    data: Comment[]
  };

  likes: number;
  liked: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (this.user) {
      this.user = new ChatUserInfo().deserialize(this.user);
      const tempUser = new User().deserialize({
        id: input.user.id
      });
      // this.userSubject = new BehaviorSubject<User>(tempUser);
    }
    if (this.replyUser) {
      const tempReplyUser = new User().deserialize({
      id: (input.replyUser || {}).id || input.user.id
      });
      // this.replyUserSubject = new BehaviorSubject<User>(tempReplyUser);
    }
    this.createdAt = new Date(input.createdAt || Date.now());
    this.updatedAt = new Date(input.updatedAt || Date.now());
    return this;
  }

  get createdAtFromNow() {
    if (this.createdAt) {
      return moment(this.createdAt).fromNow();
    }
  }
}
