import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHeaderComponent implements OnInit {

  @Input() name: string;
  @Input() icon: string;
  @Input() status: string;
  @Input() nameClickable: boolean;
  private _sticky: boolean;
  @Output() onBack = new EventEmitter;
  @Output() onNameClick = new EventEmitter;


  get sticky(): boolean {
    return this._sticky;
  }

  @Input()
  set sticky(value: boolean) {
    this._sticky = value;
    console.log(value);
    this.cdr.detectChanges();
  }

  nameClick() {
    this.onNameClick.emit();
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

}
