import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[iqlWrapperDragg]'
})
export class WrapperDraggDirective implements AfterViewInit {
  private dragHandle: HTMLElement;
  private startX: number;
  private startWidth: number;
  private lastClientX: number;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
      this.dragHandle = this.el.nativeElement.querySelector('.drag-handle');
      this.renderer.setStyle(this.el.nativeElement, 'transition', 'width 0.5s ease-in-out');
      if (this.dragHandle) {
        this.renderer.listen(this.dragHandle, 'mousedown', this.onMousedown.bind(this));
      }
  }

  onMousedown(event: MouseEvent) {
    event.preventDefault();

    this.startX = event.clientX;
    this.startWidth = this.el.nativeElement.offsetWidth;
    this.lastClientX = event.clientX;
    this.renderer.setStyle(this.el.nativeElement, 'transition', 'none');

    const onMouseMove = (e: MouseEvent) => {
      const dx = e.clientX - this.startX;
      let newWidth = this.startWidth - dx;

      this.lastClientX = e.clientX;
      this.renderer.setStyle(this.el.nativeElement, 'width', `${newWidth}px`);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      this.renderer.setStyle(this.el.nativeElement, 'transition', 'width 0.5s ease-in-out');
      const endWidth = this.el.nativeElement.offsetWidth;
      const maxWidth = window.innerWidth;
      if (this.lastClientX < event.clientX) {
        this.renderer.setStyle(this.el.nativeElement, 'width', `${maxWidth}px`);
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'width', '600px');
      }
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }
}