import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChatHrService } from '../../chat-hr.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { VisitDataItem, VisitStatusMap } from '@Mesh/shared/modules/chatHR/models/visit-dialog';
import { ChatHrVisitApiService } from '../chat-hr-visit.service';
import moment from 'moment';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iql-chat-hr-visit-data-edit',
  templateUrl: './chat-hr-visit-data-edit.component.html',
  styleUrls: ['./chat-hr-visit-data-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrVisitDataEditComponent implements OnInit, OnDestroy {
  private _visitData: VisitDataItem;

  get visitData(): VisitDataItem {
    return this._visitData;
  }

  public visitForm: FormGroup;
  public userInfo: FormGroup;
  public bsConfig: Partial<BsDatepickerConfig>;
  public statuses: any[];
  public spinner = SPINNER;
  public isUserInfoLoading = true;
  private ngOnDestroy$ = new Subject<null>();
  public userRequestError: string | undefined;

  constructor(
    private fb: FormBuilder,
    private chatHrService: ChatHrService,
    private chatHrVisitService: ChatHrVisitApiService,
    private loaderService: NgxUiLoaderService,
  ) {
    this.initForm();
    this.getStatusses();
  }

  ngOnInit() {
    this.configureBsConfig();
    this.setupChatShareListener();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  private initForm(): void {
    this.visitForm = this.fb.group({
      patronymic: [{ value: null, disabled: true }],
      surname: [{ value: null, disabled: true }],
      name: [{ value: null, disabled: true }],
      phone: [{ value: null, disabled: true }],
      date: [null],
      status: [null],
      address: [{ value: null, disabled: true }],
      city: [{ value: null, disabled: true }],
      positionName: [{ value: null, disabled: true }]
    });
  }

  set visitData(data: VisitDataItem) {
    this._visitData = data;
    if (data) {
      this.visitForm.patchValue({
        name: data.user?.name,
        patronymic: data.user?.patronymic,
        surname: data.user?.surname,
        phone: data.user?.phone,
        date: this.getFormattedDate(data.visit_date),
        status: data.status_id.toString(),
        address: data.vacancy?.address,
        city: data.vacancy?.city.name,
        positionName: data.vacancy?.name
      });
    }
  }

  private configureBsConfig() {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-app',
        locale: 'ru',
        dateInputFormat: 'DD.MM.YYYY HH:mm',
        showWeekNumbers: false
      }
    );
  }

  private setupChatShareListener() {
    this.chatHrService.onShowShareChat()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(data => {
        if (data && data.visitDataItem) {
          this.visitData = data.visitDataItem;
        }
      });
  }

  private getFormattedDate(dateString: string): Date {
    if (!dateString) {
      return null;
    }
    return moment(dateString).toDate();
  }

  public isControlInvalid(controlName: string): boolean {
    if (!this.visitForm) {
      return;
    }
    const control = this.visitForm.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  public onClosed(): void {
    this.chatHrService.closeVisitData();
  }

  public onSubmit() {
    this.loaderService.startLoader('edit-visit');
    let visitDate = this.visitForm.controls['date'].value;
    const expectedFormat = /^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}$/;
    if (!expectedFormat.test(visitDate)) {
      visitDate = moment(visitDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS') + 'Z';
    }
    const payload = {
      vacancy_id: this.visitData.vacancy_id,
      visit_date: visitDate,
      status_id: this.visitForm.controls['status'].value
    }

    this.chatHrVisitService.updateVisit(this.visitData.id, payload)
      .subscribe(
        (response) => {
          this.visitData = response.data;
          this.chatHrService.setPartialVisitData({
            visitDataItem: response.data
          });
          this.loaderService.stopLoader('edit-visit');
          this.onClosed();
        },
        (error) => {
          console.error('Error updating visit:', error);
          this.loaderService.stopLoader('edit-visit');
        }
      );
  }

  getStatusses() {
    this.statuses = Object.entries(VisitStatusMap)
      .filter(([key, value]) => key !== 'UNKNOWN')
      .map(([key, value]) => ({
        type: value.type,
        title: value.title,
        color: value.color,
        fullname: value.fullname
      }));
  }

}

