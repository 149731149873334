import { Pipe, PipeTransform } from '@angular/core';
import { IListItem } from '../../../../core/services/chat/messages.service';

@Pipe({
  name: 'dialogFilter'
})
export class DialogFilterPipe implements PipeTransform {

  transform(value: IListItem[], type: string, property: string): IListItem[] {
    if (value && value.length === 0 || !type) {
      return value;
    }

    return (value || []).filter(item => item.type === type);
  }
}

