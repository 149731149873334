import { Injectable, isDevMode } from '@angular/core';

import * as feathersRx from 'feathers-reactive/dist/feathers-reactive';
import * as io from 'socket.io-client';

import { BehaviorSubject, Observable, from } from 'rxjs';
import { delay, filter, map, retryWhen, take, tap } from 'rxjs/operators';

import feathers from '@feathersjs/client';
import { AcademyUserStateService } from '../../../../core/services/api/auth/academy-user-state.service';
import { ACADEMY_CHAT_URL } from '../../../../../environments/environment';

/**
 * Simple wrapper for feathers
 */
@Injectable({ providedIn: 'root' })
export class FeathersService {
  private _feathers; // init socket.io
  private _socket;   // init feathers
  private _currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private academyUserStateService: AcademyUserStateService) {
    this._feathers = feathers();
    this._socket = io(ACADEMY_CHAT_URL, { transports: ['websocket'], timeout: 5000, forceNew: true });
    this.connect();
    this.academyUserStateService.onChangeToken()
      .subscribe(token => {
        tap(val => console.log(val, 'auth')),
        from(this.authenticate(token)).pipe(
          take(1),
          retryWhen(errors =>
            errors.pipe(
              tap(val => console.log(val)),
              delay(1000)
            )
          ),
        ).subscribe((data: any) => {
          if (data) {
            this._currentUserSubject.next(data);
          }
        });
      });
  }

  get currentUserSubject(): BehaviorSubject<any> {
    return this._currentUserSubject;
  }

  set currentUserSubject(value: BehaviorSubject<any>) {
    this._currentUserSubject = value;
  }

  isAuthChat(): Observable<boolean> {
    return this.currentUserSubject.pipe(
      filter(user => Object.keys(user || {}).length !== 0),
      map(data => !!data));
  }

  public connect() {
    if (ACADEMY_CHAT_URL) {
      this._feathers
        .configure(feathers.socketio(this._socket, { timeout: 5000 }))  // add socket.io plugin
        .configure(feathers.authentication({         // add authentication plugin
          storageKey: 'academy_token',
          scheme: 'Bearer'
        }))
        .configure(feathersRx({                      // add feathers-reactive plugin
          idField: 'id'
        }));
    }
  }

  // expose services
  public service(name: string) {
    return this._feathers.service(name);
  }

  // expose authentication
  public authenticate(token) {
    return this._feathers.authenticate({
      strategy: 'jwt',
      accessToken: token
    });
  }

  // expose logout
  public logout() {
    // @ts-ignore
    if (isDevMode()) {
      return this._feathers.logout();
    }
  }
}
