import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, flatMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ChatDialogService, ChatDialogType, ITypingUser, ResponseType } from './chat-dialog.service';
import { User } from '@Mesh/core/models/user';
import { Comment } from '@Mesh/core/models/comment';
import { ChatMessageQuote } from '../models/chat-message-quote';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import * as RecordRTC from 'recordrtc';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { MessagesService } from '@Mesh/core/services/chat/messages.service';
import { SoundService } from '@Mesh/core/services/chat/sound.service';
import { UserService } from '@Mesh/core/services/chat/user.service';
import { IStockInfo, TaskStepResultPayload } from '@Mesh/core/models/task';
import { Store } from '@ngrx/store';
import { State } from '@Mesh/store/reducers';
import { selectPlanStock } from '@Mesh/store/selectors/task/tasks.selectors';
import { TasksService } from '../../../../core/services/api/task/tasks.service';

declare var StereoAudioRecorder: any;
export const FORWARDED_MESSAGE = `«Пересланное сообщение»`;

@Component({
  selector: 'iql-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatDialogComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @Output('onBack') onBack = new EventEmitter();
  @Output('onForward') onForward = new EventEmitter();
  u$ = new Subject;

  @ViewChild(NgxDropzoneComponent, { static: false }) dropzone: NgxDropzoneComponent;
  @ViewChildren('chatList', { read: ElementRef }) chatListRefs: QueryList<ElementRef>;

  _scrollNew = 0;
  firstNotLoaded = false;
  stream;
  record$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dialog$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: -1
  };
  planStocks: IStockInfo;
  realDialog: any;

  cancel = false;
  approve = false;
  error = false;
  ticket = false;
  _searchQuery: string = null;
  communitiesSwiperIndex = 0;
  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    // spaceBetween: 16,
    slidesPerView: 'auto',
    freeMode: true,
    preloadImages: true,
    pagination: false,
    centeredSlides: false
  };
  guild$;
  alliance$;
  clan$;
  all$;
  scrolledToBottom = false;
  loading;
  recording;
  files: File[] = [];

  @ViewChildren(SwiperDirective) swiperViewes: QueryList<SwiperDirective>;
  @ViewChild(SwiperDirective, { static: false }) swiper: SwiperDirective;

  spinner = SPINNER;
  LOADER_ID = 'chat-dialog-loader';
  current_user: User;
  messages$;
  dialogs$;
  dialogs;
  taskDialogs;
  chatDialogs;
  messages: any[];
  lastIndex;
  page = 1;
  loadOnScroll = false;
  loadImage = false;
  loadMessagesCount = {};
  @ViewChildren('messages') messagesQuery: QueryList<any>;
  @ViewChild(PerfectScrollbarDirective, { static: false }) messagesWrapper: PerfectScrollbarDirective;
  @ViewChild('messagesWrapper', { static: false }) infiniteScrollEl: ElementRef;
  @ViewChild(InfiniteScrollDirective, { static: false }) infiniteScroll: InfiniteScrollDirective;
  @ViewChild('messageInput', { static: false }) messageInput: ElementRef;
  @ViewChild('chatHistoryWrapper', { static: false }) chatHistoryWrapper: ElementRef;
  _stepId;
  recorder;

  userMessage = '';
  editingMessage: Comment = null;
  quotingMessage: Comment = null;
  forwardingMessage: Comment = null;
  dzIsHidden = true;
  first = true;
  hide = true;
  typingUsers$;
  allTypingUsers: ITypingUser[] = [];
  currentTypingUsers: ITypingUser[] = [];
  removeUploads: any[] = [];

  typingReplyUser: ITypingUser;
  timeoutTyping: ReturnType<typeof setTimeout>;
  typing: boolean = false;

  private _stickyHeader = false;
  private _record: User | { type: ChatDialogType, info: any, messages: any[] };
  private _dialog: { type: string, typeId: any, task?: any, module?: any, approve?: any, cancel?: any };
  loadingStepActions: boolean;
  closedTicket: boolean;
  taskMessage: any;

  constructor(private chatDialogService: ChatDialogService,
    private messagesService: MessagesService,
    private chatService: ChatService,
    private userService: UserService,
    private tasksService: TasksService,
    private cdr: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private soundService: SoundService,
    private feathersService: FeathersService,
    private zone: NgZone,
    private router: Router,
    private store: Store<State>) {
  }

  get scrollNew(): number {
    return this._scrollNew;
  }

  set scrollNew(value: number) {
    if (this.isUserNearBottom() || value === 0) {
      this._scrollNew = value;
    }
  }

  @Input()
  set record(value: User | any) {
    this._record = value;
    this.record$.next(value);
    this.chatDialogService.update$.next(value);
  }

  get record(): User | any {
    return this._record;
  }

  @Input()
  set dialog(value: any) {
    this._dialog = value;
    this.realDialog = this._dialog;
    this.dialog$.next(value);
  }

  get dialog(): any {
    return this._dialog;
  }

  get stickyHeader(): boolean {
    return this._stickyHeader;
  }

  get searchQuery(): string {
    return this._searchQuery;
  }

  set searchQuery(value: string) {
    this._searchQuery = value;
  }

  get stepId(): any {
    return this._stepId;
  }

  set stepId(value: any) {
    this._stepId = value;
    this.chatDialogService.dialog.stepId = value;
  }

  set stickyHeader(value: boolean) {
    this._stickyHeader = value;
    //this.cdr.detectChanges();
  }

  get chatStatus(): string {
    switch (this.chatType) {
      case 'user':
        return (this.chatRecord as any)?.status?.online ? 'Онлайн' : 'Офлайн';
      case 'guild':
        return 'Твоя гильдия';
      case 'alliance':
        return 'Твой клан';
      case 'clan':
        return 'Твой альянс';
      default:
        return '';
    }
  }

  typingSubject = new Subject<string>();

  onKeyUp(event: KeyboardEvent): void {
    this.error = false;
    if (!this.typing) {
      this.typing = true;
      this.emitTypingEvent()
      const timeout = setTimeout(() => {
        this.typing = false;
        clearTimeout(timeout);
      }, 500);
    }
  }

  private emitTypingEvent(): void {
    if (this.dialog && this.current_user) {
      const typingData: ITypingUser = {
        type: this.dialog.type,
        typeId: this.dialog.typeId,
        replyUserId: this.current_user.id,
        fullname: this.current_user.name,
      };
      if (typingData.type === 'support') {
        delete typingData.typeId;
      }
      this.chatDialogService.setTypingStatus(typingData);
    }
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      if (this.messageInput && this.messageInput.nativeElement && !this.isTouchDevice()) {
        this.messageInput.nativeElement.focus();
      }
    });
    this.swiperViewes.changes.pipe(
      takeUntil(this.u$)
    ).subscribe(() => {
      this.swiperViewes.forEach(item => item.update());
      //this.swiper.update();
    });
    // this.scrollWithDelay();
    // this.messagesQuery.changes.pipe(first()).subscribe(this.scrollWithDelay.bind(this));

    // this.typingSubject
    //   .pipe(takeUntil(this.u$),
    //     delay(500))
    //   .subscribe(textValue => this.emitTypingEvent(textValue));
  }

  get isEdge(): boolean {
    return navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
  }

  get audios(): File[] {
    return this.files.filter(file => file.name.indexOf('audio') !== -1);
  }

  get others(): File[] {
    return this.files.filter(file => file.name.indexOf('audio') === -1);
  }

  get isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  getExt(file: File): string {
    return file.name.split('.').pop();
  }

  getUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  async startAudioRecord(): Promise<any> {
    navigator.mediaDevices.getUserMedia({
      audio: true
    }).then(stream => {
      this.stream = stream;
    });
    if (this.stream) {
      const options: any = {
        type: 'audio',
        mimeType: 'audio/wav',
        numberOfAudioChannels: this.isEdge ? 1 : 2,
        checkForInactiveTracks: true,
        bufferSize: 16384
      };
      if (this.isSafari || this.isEdge) {
        options.recorderType = RecordRTC.StereoAudioRecorder;
      }

      if (navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
        options.sampleRate = 48000; // or 44100 or remove this line for default
      }

      if (this.isSafari) {
        options.sampleRate = 44100;
        options.bufferSize = 4096;
        options.numberOfAudioChannels = 2;
      }

      if (this.recorder) {
        this.recorder.destroy();
        this.recorder = null;
      }
      this.recorder = new RecordRTC.RecordRTCPromisesHandler(this.stream, options);
      await this.recorder.startRecording();
      this.recording = true;
      this.cdr.detectChanges();
    }
  }

  trackByMessageId(index: number, message: any) {
    return message.id
  }

  async stopAudioRecord(): Promise<any> {
    if (this.recorder) {
      await this.recorder.stopRecording();
      const blob = await this.recorder.getBlob();
      if (this.recorder) {
        this.recorder.destroy();
        this.recorder = null;
      }
      blob.name = `audio${this.files.length + 1}`;
      this.files.push(blob);
      this.recording = false;
      this.cdr.detectChanges();
    }
  }

  scrollWithDelay(): void {
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  imagesLoaded(id: number): void {
    this.loadMessagesCount[id] = true;
    if (this.messages[this.messages.length - 1].id === id && this.isUserNearBottom()) {
      if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
        this.scrollToBottom();
      }
    }
    if (this.first) {
      if (Object.keys(this.loadMessagesCount).length === this.messages.length) {
        if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
          this.scrollToBottom();
          this.first = false;
        } else {
          this.firstNotLoaded = true;
        }
      }
    }

    if (this.scrollNew > 0 && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      this.scrollNew--;
    }
    /*if (this.lastIndex && this.chatListRefs && this.lastIndex !== (this.chatListRefs.length - 1) && this.chatListRefs.toArray()[this.lastIndex]) {
      this.scrollToIndex(this.lastIndex);
      this.lastIndex = null;
      this.loadOnScroll = false;
    }*/
  }

  scrollToBottom(): void {
    try {
      // this.messagesWrapper.nativeElement.scrollIntoView(true);
      if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
        this.zone.runOutsideAngular(() => {
          //this.chatListRefs.last.nativeElement.scrollIntoView({block: 'end', behavior: 'auto', inline: 'start'});
        });
        this.hide = false;
        this.cdr.detectChanges();
      }
      /*const el = this.messagesWrapper.nativeElement as Element;
      el.scrollTop = el.scrollHeight;*/
      // window.scrollTo(0, 1);
    } catch (err) {
    }
  }

  isMobileSafari(): boolean {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  edit(message: Comment): void {
    this.editingMessage = message;
    this.quotingMessage = null;
    this.userMessage = message.text;
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.check();
  }

  remove(message: Comment): void {
    this.ngxService.startLoader(this.LOADER_ID);
    this.chatDialogService.removeMessage(message)
      .then(() => this.ngxService.stopLoader(this.LOADER_ID));
  }

  forward(message: Comment): void {
    this.forwardingMessage = message;
    this.check();
  }

  forwardTo(record: User | { type: ChatDialogType, info: any, messages: any[] }, dialog: Comment): void {

    // let id = null,
    //   [mine_id, their_id] = dialog?.participants || [];
    //id = typeof their_id !== 'undefined' ? their_id : user.id;

    if (!this.forwardingMessage) {
      return;
    }

    const text = FORWARDED_MESSAGE;

    /*if (this.forwardingMessage.text === FORWARDED_MESSAGE) {
      text = this.forwardingMessage.attached.messages[0].message.text;
    }*/

    const type = 'users-chat';
    this.chatDialogService.forwardMessage({
      text,
      record,
      dialog,
      type,
      attached: {
        messages: [this.forwardingMessage],
        uploads: []
      }
    }).pipe(
      first()
    ).subscribe(() => {
      this.forwardingMessage = null;
      this.ngxService.stopLoader(this.LOADER_ID);
      //this.chatService.updateDialogs.emit();
      this.onForward.emit(record);
      this.check();
    });
  }

  onTouch($event: any): void {
    this.zone.runOutsideAngular(() => {
      if (this.messageInput) {
        this.messageInput.nativeElement.blur();
      }
    });
  }

  quote(message: Comment): void {
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.quotingMessage = message;
    this.editingMessage = null;
    this.userMessage = '';
    this.check();
  }

  scrollToQuoted(quote: ChatMessageQuote): void {
    this.scrollIntoViewIfNeeded(quote.linkId);
  }

  private scrollIntoViewIfNeeded(messageId: number): void {
    setTimeout(() => {
      if (this.chatListRefs && this.chatListRefs.length && messageId) {
        const targetOption = this.chatListRefs.find((m) => +m.nativeElement.id === messageId);
        if (targetOption) {
          (targetOption.nativeElement as any).scrollIntoView(false);
        }
      }
    });
  }

  get chatRecord(): any {
    // if (this.record.hasOwnProperty('type')) {
    //   // @ts-ignore
    //   return this.record.info;
    // } else {
    return this.record;
    // }
  }

  scrollToIndex(index: number): boolean {
    if (this.chatListRefs) {
      const arrChatList = this.chatListRefs.toArray();
      if (index === (arrChatList.length - 1)) {
        return false;
      }
      if (arrChatList[index]) {
        this.zone.runOutsideAngular(() => {
          //this.messagesWrapper.nativeElement.scrollTop = arrChatList[index].nativeElement.offsetTop;
        });
        return true;
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.scrollNew > 0 && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      this.scrollNew--;
    }
    if (this.firstNotLoaded && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      this.firstNotLoaded = false;
    }
    if (this.lastIndex && this.chatListRefs && this.messagesWrapper) {
      if (this.scrollToIndex(this.lastIndex)) {
        this.lastIndex = null;
        this.loadOnScroll = false;
      }
    }
  }

  private isUserNearBottom(): boolean {
    /* if (this.messageInput) {
       const threshold = 150;
       const position = this.messagesWrapper.directiveRef.nativeElement.scrollTop;
       const height = this.messagesWrapper.nativeElement.scrollHeight - this.messagesWrapper.nativeElement.offsetHeight;
       return (position / height) > 0.85;
     }*/
    return true;
  }

  onScroll($event: any): void {
    if (this.isTouchDevice()) {
      this.zone.runOutsideAngular(() => {
        // this.messageInput.nativeElement.blur();
      });
    }
  }

  onScrollTop($event: any): void {
    // rework this func
    this.scrolledToBottom = false;
    if (!this.loadOnScroll) {
      this.loadOnScroll = true;
      this.chatDialogService.getMessages({ page: this.page++ });
    }
  }

  get chatType(): 'user' | 'guild' | 'clan' | 'alliance' {
    // @ts-ignore
    return this.record.type ? this.record.type : 'user';
  }

  nameClick(): void {
  }

  ngOnDestroy(): void {
    this.u$.next();
    this.u$.unsubscribe();
  }

  trackMessagesBy(i: number, id: number, user: User, replyUser: User): string {
    return `${id}-from-${user.id}-to-${replyUser ? replyUser.id : i}`;
  }

  cancelTask(message: any = null): void {
    this.approve = false;
    this.cancel = true;
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.taskMessage = message;
    this.editingMessage = null;
    this.sendMessage(null);
  }

  closeTicket(): void {
    this.closedTicket = true;
    this.userMessage = 'Закрываю обращение';
    this.approve = true;
    this.ticket = true;
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.editingMessage = null;
    // this.sendMessage(null);
  }

  closeClosedTicketMessage(): void {
    this.closedTicket = false;
    this.userMessage = '';
  }

  approveTask(message: any): void {
    this.cancel = false;
    this.approve = true;
    this.acceptTaskResult(message);
  }

  ngOnInit(): void {
    // tslint:disable-next-line: no-unused-expression
    async () => {
      const notifications = await this.chatDialogService.findNotifications();
    };
    this.lastIndex = null;
    this.loadOnScroll = false;
    this.scrollNew = 0;
    this.first = true;

    this.loading = true;
    this.hide = true;
    this.cdr.detectChanges();

    this.messagesService.subjects['users-chat/list']
      .pipe(takeUntil(this.u$))
      .subscribe((dialogs) => {
        // this.ngxService.stopLoader('messages-loader');
        this.zone.run(() => {
          this.dialogs = dialogs;
          this.chatDialogs = this.dialogs?.data;
          this.cdr.markForCheck();
        });
      });

    this.feathersService.currentUserSubject.pipe(
      takeUntil(this.u$),
      first()
    ).subscribe(user => {
      this.current_user = user;

      // this.all$ = this.chatService.getChatCommunity('all');

      this.typingUsers$ = this.chatDialogService.activateTypingStatus();
      this.typingUsers$.pipe(takeUntil(this.u$)).subscribe(({ type, data }) => {
        if (type === ResponseType.TYPING) {
          if (data.replyUserId !== this.current_user.id) {
            if (data.type === 'support') {
              this.setTypingReplyUser(data);
            } else {
              if (data.typeId === this.dialog?.typeId) {
                this.setTypingReplyUser(data);
              }
            }
          }
        }
      });

      const filterFunc = ((value) => !!value);

      this.chatDialogService.update$.pipe(
        takeUntil(this.u$),
        tap(() => {
          if (this.messagesWrapper) {
            this.messagesWrapper.update();
            this.messagesWrapper.scrollToTop();
          }
        }),
        filter(filterFunc),
        flatMap((): Observable<any> => {
          return this.dialog$;
        }),
        flatMap((): Observable<any> => {
          return this.chatDialogService.activateChat({
            record: this.record,
            current_user: this.current_user,
            dialog: this.dialog
          });
        }),
        withLatestFrom(this.store.select(selectPlanStock))
      ).subscribe(([{ messages, type, lastIndex }, planStock]) => {
        this.planStocks = planStock;
        this.loadOnScroll = false;
        if (messages) {
          this.loadMessagesCount = {};
          this.messages = [...messages];
          this.loading = false;
          if (messages && messages.length === 0) {
            this.hide = false;
          }
          if (messages && messages.length > 0) {
            this.realDialog = {
              ...this._dialog,
              ...this.messages[0],
              message: this.messages[0]
            };
            this.chatDialogService.dialog = this.realDialog;
          }
          if (type === ResponseType.CREATED || type === ResponseType.REMOVED || type === ResponseType.PATCHED) {
            this.scrollNew = 2;
          }

          if (lastIndex) {
            this.lastIndex = lastIndex;
          }
          this.cdr.detectChanges();

        }
      });
    });
  }

  filterTypingUsers(): void {
    const checkDialog = (type, typeId) => (type === 'support' ? true : typeId === this.dialog?.typeId);
    const filteredByChat = this.allTypingUsers.filter(({ type, typeId }) => checkDialog(type, typeId));
    this.currentTypingUsers = filteredByChat.splice(0, 3);
  }

  private setTypingReplyUser(user: ITypingUser): void {
    this.typingReplyUser = user;
    this.cdr.detectChanges();
    clearTimeout(this.timeoutTyping);
    this.timeoutTyping = setTimeout(() => {
      this.typingReplyUser = null;
      this.cdr.detectChanges();
    }, 1500);
  }

  messageLoaded(message: any): void {
    //this.loading = false;
    //this.ngxService.stopLoader('messages-loader');
  }

  check(): void {
    this.cdr.markForCheck();
  }

  isTouchDevice(): boolean {
    return 'ontouchstart' in document.documentElement;
  }

  addToRemoveUpload(upload: any, id: number): void {
    if (this.editingMessage) {
      this.editingMessage.attached.uploads.splice(id, 1);
      this.removeUploads.push(upload);
    }
  }

  removeUpload(): void {
    if (this.editingMessage) {
      //this.editingMessage.attached.uploads.splice(id, 1);
      this.removeUploads.forEach(upload => {
        if (upload.id && upload.typeId) {
          this.chatDialogService.removeUpload(upload);
        } else {
          this.files.splice(this.files.indexOf(upload), 1);
        }
      });
    }
  }

  closeEditingMode(): void {
    this.editingMessage = null;
    this.userMessage = '';
  }

  sendMessage($event: any): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (
      (!this.userMessage && this.files.length === 0 && !this.editingMessage)
      ||
      (!this.userMessage && this.files.length === 0 && this.editingMessage && this.editingMessage.attached?.uploads?.length === 0)
    ) {
      this.error = true;
      this.cdr.detectChanges();
      return;
    }

    // this.ngxService.startLoader(this.LOADER_ID);

    this.zone.runOutsideAngular(() => {
      if (this.messageInput) {
        this.messageInput.nativeElement.blur();
      }
    });

    this.first = true;

    switch (true) {
      case !!this.editingMessage:
        // tslint:disable-next-line: no-unused-expression
        const msg = this.editingMessage;
        const msgId = this.editingMessage.id;
        if (msg.text !== this.userMessage || this.files.length || this.removeUploads.length > 0) {
          this.removeUpload();
          this.chatDialogService.editMessage({
            ...msg,
            text: this.userMessage
          }).then((response) => {
            const tempFiles = [...this.files];
            const imgFiles = tempFiles.filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif'].indexOf(item.name.split('.').pop()) !== -1);
            this.files = [];

            if (tempFiles.length) {
              /*const placeholders = [];
              imgFiles.forEach(item => {
                const url = URL.createObjectURL(item);
                placeholders.push({
                  name: item.name,
                  ext: item.name.split('.').pop(),
                  url,
                  original: url,
                });
              });
              this.chatDialogService.updateUploads(msgId, placeholders);*/

              this.chatDialogService.uploadMedia({ uploads: tempFiles, type: this.dialog?.type }).subscribe(uploads => {
                if (uploads.length > 0) {
                  const uploadsList = [...uploads];
                  this.chatDialogService.service.patch(msg.id, { text: msg.text, attached: { uploads: uploadsList } }).then(() => {
                    //this.scrollNew = 2;
                  });
                }
                //this.scrollToBottom();
              });
            }
            // this.ngxService.stopLoader(this.LOADER_ID);
            /*this.zone.runOutsideAngular(() => {
              this.messageInput.nativeElement.blur();
            });*/
            this.check();
          });
        }
        this.editingMessage = null;
        this.userMessage = '';
        break;

      default:
        const attached = {
          messages: this.quotingMessage ? [this.quotingMessage] : [],
          uploads: this.files,
        };
        // console.log('dialog:', this.dialog);
        this.chatDialogService.sendMessage({
          text: this.userMessage,
          type: this.dialog?.type,
          attached,
          canceled: this.cancel,
          approved: this.approve
        }).pipe(
          first()
        ).subscribe(async (response) => {
          if (this.cancel) {
            this.cancelTaskResult(this.taskMessage, this.userMessage);
          }

          this.userMessage = '';
          if (this.ticket) {
            this.dialog.ticket.status = 'done';
          }

          this.cancel = false;
          this.approve = false;
          this.quotingMessage = null;
          this.error = false;
          this.files = [];
          this.check();
          this.scrollWithDelay();
        });

        break;
    }

    // this.chatHistoryWrapper.nativeElement.scrollTop = this.chatHistoryWrapper.nativeElement.scrollHeight;
  }

  onSelect(event: any): void {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: File): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onReadMessages(): void {
    if (this.messages && this.messages.length) {
      const readedMessages = this.messages.filter(message => !message.readAt && !!message.senderAddressSapId);
      readedMessages.forEach(message =>
        this.chatDialogService.markIsReadMessage(message));
    }
  }

  get isTaskChat(): boolean {
    return this.realDialog?.type === 'task-comments';
  }

  get isSupportChat(): boolean {
    return this.realDialog?.type === 'support';
  }

  acceptTaskResult(message: any): void {
    this.loadingStepActions = true;
    const payload: TaskStepResultPayload = {
      taskOutletClientId: message.taskStep.taskOutletClientId,
      stepId: message.taskStep.id,
      comment: 'Принято'
    };
    this.tasksService.acceptStepResult(payload)
      .pipe(takeUntil(this.u$))
      .subscribe(() => this.taskMessage = null);
  }

  cancelTaskResult(message: any, comment: string): void {
    this.loadingStepActions = true;
    const payload: TaskStepResultPayload = {
      taskOutletClientId: message.taskStep.taskOutletClientId,
      stepId: message.taskStep.id,
      comment
    };
    this.tasksService.cancelStepResult(payload)
      .pipe(takeUntil(this.u$))
      .subscribe(() => this.taskMessage = null);
  }
}
