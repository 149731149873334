import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { DelayedOrderHistory } from '../../../../../core/models/delayed-order';
;

@Component({
  selector: 'app-delayed-order-history',
  templateUrl: './delayed-order-history.component.html',
  styleUrls: ['./delayed-order-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayedOrderHistoryComponent implements OnInit {
  @Input() orderHistories: DelayedOrderHistory[];
  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  onClosedModal() {
    this.closed.emit();
  }
}
