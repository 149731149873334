import { BonusConfigCategory, BonusConfiguration } from '@Mesh/core/models/bonus/bonus-configuration';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/saleplan/bonus.actions';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { BonusOutlets } from '@Mesh/core/models/bonus/bonus-outlets';

export interface State {
  bonus: Bonus[];
  bonusLoadingStatus: LoadingStatus;
  predictBonus: Bonus[];
  currentConfiguration: BonusConfiguration;
  configurations: BonusConfiguration[];
  categories: BonusConfigCategory[];
  predictBonusLoadingStatus: LoadingStatus;
  predictBonusClient: BonusClient;
  predictBonusClientLoadingStatus: LoadingStatus;
  bonusOutlets: { addressSapId: number; bonus: BonusOutlets[] };
  bonusOutletsLoadingStatus: LoadingStatus;
  predictBonusOutlets: { addressSapId: number; bonus: BonusOutlets[] };
  predictBonusOutletsLoadingStatus: LoadingStatus;
}

export const initialState: State = {
  bonus: [],
  bonusLoadingStatus: LoadingStatus.NotLoaded,
  predictBonus: [],
  currentConfiguration: null,
  configurations: [],
  categories: [],
  predictBonusLoadingStatus: LoadingStatus.NotLoaded,
  predictBonusClient: null,
  predictBonusClientLoadingStatus: LoadingStatus.NotLoaded,
  bonusOutlets: null,
  bonusOutletsLoadingStatus: LoadingStatus.NotLoaded,
  predictBonusOutlets: null,
  predictBonusOutletsLoadingStatus: LoadingStatus.NotLoaded,
};

const bonusReducer = createReducer(
  initialState,
  on(fromActions.loadBonus, fromActions.updateBonus, (state) => ({
    ...state,
    bonus: [],
    bonusLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.bonusLoaded, (state, { bonus }) => ({ ...state, bonus, bonusLoadingStatus: LoadingStatus.Loaded })),
  on(fromActions.bonusLoadError, (state, { error }) => ({
    ...state,
    bonus: [],
    bonusLoadingStatus: LoadingStatus.Error,
  })),
  on(fromActions.loadPredictBonus, (state) => ({
    ...state,
    predictBonus: [],
    predictBonusLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.clearPredictBonus, (state) => ({
    ...state,
    predictBonus: [],
    predictBonusLoadingStatus: LoadingStatus.NotLoaded,
  })),
  on(fromActions.predictBonusLoaded, (state, { bonus }) => ({
    ...state,
    predictBonus: bonus,
    predictBonusLoadingStatus: LoadingStatus.Loaded,
  })),
  on(fromActions.predictBonusLoadError, (state, { error }) => ({
    ...state,
    predictBonus: [],
    predictBonusLoadingStatus: LoadingStatus.Error,
  })),

  on(fromActions.salePlanBonusAction.loadPredictBonusClient, (state) => ({
    ...state,
    predictBonusClient: null,
    predictBonusClientLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.salePlanBonusAction.loadPredictBonusClientSuccess, (state, { data }) => ({
    ...state,
    predictBonusClient: data,
    predictBonusClientLoadingStatus: LoadingStatus.Loaded,
  })),
  on(fromActions.salePlanBonusAction.loadPredictBonusClientError, (state, { error }) => ({
    ...state,
    bonus: [],
    predictBonusClientLoadingStatus: LoadingStatus.Error,
  })),

  on(fromActions.salePlanBonusAction.loadBonusOutlets, (state) => ({
    ...state,
    bonusOutlets: null,
    bonusOutletsLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.salePlanBonusAction.loadBonusOutletsSuccess, (state, { data }) => ({
    ...state,
    bonusOutlets: data,
    bonusOutletsLoadingStatus: LoadingStatus.Loaded,
  })),
  on(fromActions.salePlanBonusAction.loadBonusOutletsError, (state, { error }) => ({
    ...state,
    bonus: [],
    bonusOutletsLoadingStatus: LoadingStatus.Error,
  })),

  on(fromActions.salePlanBonusAction.loadBonusConfiguration, (state) => ({
    ...state,
    configurations: null,
    bonusOutletsLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.salePlanBonusAction.loadBonusConfigurationSuccess, (state, { configurations }) => ({
    ...state,
    configurations,
    bonusOutletsLoadingStatus: LoadingStatus.Loaded,
  })),
  on(fromActions.salePlanBonusAction.loadBonusConfigurationError, (state, { error }) => ({
    ...state,
    configurations: [],
    bonusOutletsLoadingStatus: LoadingStatus.Error,
  })),
  on(fromActions.salePlanBonusAction.resetBonusConfiguration, (state) => ({
    ...state,
    configurations: null,
    bonusOutletsLoadingStatus: LoadingStatus.NotLoaded,
  })),

  on(fromActions.salePlanBonusAction.loadPredictBonusOutlets, (state) => ({
    ...state,
    predictBonusOutlets: null,
    predictBonusOutletsLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.salePlanBonusAction.loadPredictBonusOutletsSuccess, (state, { data }) => ({
    ...state,
    predictBonusOutlets: data,
    predictBonusOutletsLoadingStatus: LoadingStatus.Loaded,
  })),
  on(fromActions.salePlanBonusAction.loadPredictBonusOutletsError, (state, { error }) => ({
    ...state,
    bonus: [],
    predictBonusOutletsLoadingStatus: LoadingStatus.Error,
  })),

  on(fromActions.salePlanBonusAction.loadBonusConfigCategory, (state) => ({
    ...state,
    configurations: null,
    bonusOutletsLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.salePlanBonusAction.loadBonusConfigCategorySuccess, (state, { categories }) => ({
    ...state,
    categories,
    bonusOutletsLoadingStatus: LoadingStatus.Loaded,
  })),
  on(fromActions.salePlanBonusAction.loadBonusConfigCategoryError, (state, { error }) => ({
    ...state,
    categories: [],
    bonusOutletsLoadingStatus: LoadingStatus.Error,
  })),

  on(fromActions.salePlanBonusAction.loadBonusConfigurationById, (state) => ({
    ...state,
    currentConfiguration: null,
    bonusOutletsLoadingStatus: LoadingStatus.Loading,
  })),
  on(fromActions.salePlanBonusAction.loadBonusConfigurationByIdSuccess, (state, { currentConfiguration }) => ({
    ...state,
    currentConfiguration,
    bonusOutletsLoadingStatus: LoadingStatus.Loaded,
  })),
  on(fromActions.salePlanBonusAction.loadBonusConfigurationByIdError, (state, { error }) => ({
    ...state,
    currentConfiguration: null,
    bonusOutletsLoadingStatus: LoadingStatus.Error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return bonusReducer(state, action);
}

export const getBonus = (state: State) => state.bonus;
export const getBonusLoadingStatus = (state: State) => state.bonusLoadingStatus;
export const getPredictBonus = (state: State) => state.predictBonus;
export const getCurrentConfiguration = (state: State) => state.currentConfiguration;
export const getBonusConfigurations = (state: State) => state.configurations;
export const getPredictBonusLoadingStatus = (state: State) => state.predictBonusLoadingStatus;
export const getPredictBonusClient = (state: State) => state.predictBonusClient;
export const getPredictBonusClientLoadingStatus = (state: State) => state.predictBonusClientLoadingStatus;
export const getBonusOutlets = (state: State) => state.bonusOutlets;
export const getBonusOutletsLoadingStatus = (state: State) => state.bonusOutletsLoadingStatus;
export const getPredictBonusOutlets = (state: State) => state.predictBonusOutlets;
export const getPredictBonusOutletsLoadingStatus = (state: State) => state.predictBonusOutletsLoadingStatus;
export const getBonusConfigCategory = (state: State) => state.categories;
