import { Injectable } from '@angular/core';
import { PartialObserver, BehaviorSubject, Subscription, Observable, of } from 'rxjs';
import { Paginated } from '@feathersjs/feathers';
import { map, catchError, startWith } from 'rxjs/operators';

import { FeathersService } from './feathers.service';
import { Message } from '../models/message';


export class PageParams {
  page?: number;
  skip?: number;
  take?: number;
}

export interface IListItem {
  id: number;
  label?: string;
  message: Message;
  participants?: number[];
  task?: {
    id: number;
    name: string;
    description?: string;
    goblet: string | null;
    teacherId?: number | null;
  } | null;
  total?: number;
  totalUnread?: number;
  type?: string;
  typeId?: number | null;
}

export interface IMessagesList {
  total: any;
  data: IListItem[];
}

export interface IMessages {
  total: number;
  data: Message[];
}

interface CommonParams {
  type?: string;
  typeId?: number | null;
  participant?: number;
  $like?: string;
  asList?: boolean;
}

interface MessageParams extends CommonParams {
  id?: number;
  text: string;
  replyUserId?: number;
}

/**
 *  Abstraction layer for data management
 *  Technically this isn't needed for feathers-chat,
 *  but you will need it for more complex tasks.
 */

@Injectable({providedIn: 'root'})
export class MessagesService {

  /* tslint:disable: curly */
  types = {
    chats: ['tasks-comments', 'chats', 'all'],
    messages: ['alliance', 'clan', 'guild', 'article', 'book', 'course', 'exam', 'video', 'clubs', 'modules']
  };

  subjects = {
    getObs: function (label) {
      return this[label].asObservable();
    },
    'users-chat/list': new BehaviorSubject<any>({total: 0, page: 1, data: null})
  };

  constructor(private feathers: FeathersService) {
  }

  async find(path, params, watchLabel) {
    this.subjects[watchLabel] = new BehaviorSubject<any>([]);
    const result = await this.feathers.service(path).find({query: params});
    if (result) {
      if (path.includes('list')) result.data.map(item => item.message = new Message().deserialize(item.message));
      else result.data.map(item => new Message().deserialize(item));
      this.subjects[watchLabel].next(result.data.reverse());
    }
  }

  toggleSound(dialog, muted) {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    chatsList.data = chatsList.data.map(item => {
      if (item.id === dialog.id) {
        item.settings.muted = muted;
      }
      return item;
    });
    this.subjects[label].next(chatsList);
  }

  togglePinned(dialog, pinned) {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    chatsList.data = chatsList.data.map(item => {
      if (item.id === dialog.id) {
        item.settings.pinned = pinned;
      }
      return item;
    });
    const pinnedChats = chatsList.data.filter(item => item.settings.pinned);
    const notPinnedChats = chatsList.data.filter(item => !item.settings.pinned);
    pinnedChats.sort((item1, item2) => {
      if (item1.id > item2.id) return -1;
      if (item1.id < item2.id) return 1;
    });
    notPinnedChats.sort((item1, item2) => {
      if (item1.id > item2.id) return -1;
      if (item1.id < item2.id) return 1;
    });
    chatsList.data = [...pinnedChats, ...notPinnedChats];
    console.log(chatsList.data);
    this.subjects[label].next(chatsList);
  }

  removeDialog(dialog) {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    chatsList.data = chatsList.data.filter(item => item.id !== dialog.id);
    this.subjects[label].next(chatsList);
  }

  dialogService() {
    return this.feathers.service('users-chat/list');
  }

  async findChats(params) {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    /*const service = this.feathers.service(label);
    service.on('patched', async patchedChat => {
      const chats = {total: this.subjects[label].getValue().total, data: []};
      let count = 0;
      for (const chat of this.subjects[label].getValue().data) {
        if (chat.type === patchedChat.type && chat.typeId === patchedChat.typeId) {
          chats.data.push({...patchedChat});
        } else {
          count++;
          chats.data.push(chat);
        }
      }
      this.subjects[label].next(chats);
    });*/
    const result = await this.feathers.service(label).find({query: params, paginate: false});
    if (result) {
      const chats = {total: chatsList.total, page: 0, data: []};
      result.data.map(item => item.message = new Message().deserialize(item.message));
      /*chats.data = [...chatsList.data];
      result.data.forEach((item) => {
        const foundIndex = chats.data.findIndex((chat) => {
          return item.id === chat.id;
        });
        if (foundIndex === -1) {
          chats.data.push(item);
        } else {
          chats.data.splice(foundIndex, 1, item);
        }
      });*/
      //const pages = Math.ceil(result.total / params.$limit);
      //chats.page = (params.$skip / params.$limit) + 1;
      console.log(chatsList.page, chats.page, result.total, params);
      chats.data = [...result.data];
      /*if (chatsList.page === chats.page || !chatsList.page) {
        chats.data = [...result.data];
      } else if (chatsList.page > chats.page) {
        result.data.forEach((item) => {
          const foundIndex = chatsList.data.findIndex((chat) => {
            return item.id === chat.id;
          });
          if (foundIndex === -1) {
            chats.data.push(item);
          }
        });
        chats.data = [...chats.data, ...chatsList.data];
      } else if (chatsList.page < chats.page) {
        result.data.forEach((item) => {
          chats.data = [...chatsList.data];
          const foundIndex = chats.data.findIndex((chat) => {
            return item.id === chat.id;
          });
          if (foundIndex === -1) {
            chats.data.push(item);
          }
        });
      }*/
      chats.total = result.total;
      this.subjects[label].next(chats);
    }
  }

  async create(path, msg) {
    let data: any = {attached: msg.attached, text: msg.text};
    if (path.includes('chat')) {
      const {typeId, replyUserId} = msg;
      if (msg.type !== 'task-comments') {
        data = {...data, replyUserId};
      } else {
        data = {...data, type: 'task-comments', typeId};
      }
    }
    const createdMsg = await this.feathers.service(path).create(data);
    if (createdMsg) {
      const {type, typeId} = createdMsg;
      let label = '';
      if (!typeId || type === 'task-comments') label = typeId ? `users-chat/task-comments/${typeId}` : `users-chat/${type}`;
      else label = `messages/${type}/${typeId}`;
      const watchChat: any[] = [...this.subjects[label].getValue()];
      watchChat.push(createdMsg);
      const chatsList = await this.feathers.service('users-chat/list').find({query: {type: 'all'}});
      this.subjects['users-chat/list'].next(chatsList);
      this.subjects[label].next(watchChat);
    }
    return createdMsg;
  }

  async remove(path, id, watchLabel) {
    const removedMsg = await this.feathers.service(path).remove(id);
    const watchChat: any[] = [...this.subjects[watchLabel].getValue()];
    watchChat.forEach((item, index) => {
      if (item.id === id) {
        watchChat.splice(index, 1);
      }
    });
    const chatsList = await this.feathers.service('users-chat/list').find({query: {type: 'all'}});
    this.subjects['users-chat/list'].next(chatsList);
    this.subjects[watchLabel].next(watchChat);
    return removedMsg;
  }

  getServiceName(type = '') {
    return this.types.messages.includes(type) ? 'messages' : 'users-chat';
  }

  getPath(params: CommonParams) {
    const {asList = false, type = '', typeId = null} = params;
    const service: { name?: string; params?: any; path?: string } = {};
    service.name = this.getServiceName(type);
    service.path = service.name;
    if (service.name === 'messages') {
      service.path = `${service.path}/${type}/${typeId}`;
      service.params = {route: {type, typeId}};
    }
    return service;
  }

  prepareParams(dataParams, asList = false) {
    const {page = 0, take = 10, type = '', typeId = null, participant = 0, text, $like} = dataParams;
    const service = this.getPath({asList, type, typeId});
    const params: any = {$like, $limit: take, $skip: page * take, text};
    if (service.name !== 'messages') {
      service.params = {type, typeId, participant, ...params};
    }
    if (type === 'all') service.path = 'users-chat/list';
    const query = {...params, $sort: {createdAt: -1}};
    return {service, query};
  }

  findMessages(service: any, queryParams) {
    const params = {query: queryParams};
    return (this.feathers.service(service.path).watch().find({query: service.params})
        .pipe(
            startWith({total: 0, data: []}),
            catchError(err => {
              console.error(`${service.path} return error: ${err.message}`);
              return of([]);
            }),
            map(({total, data}) => {
              return {
                total, data: data.map(item => new Message().deserialize(item))
              };
            })
        ));
  }

  messages$(
      commonParams: CommonParams = {},
      pagerParams: PageParams = {}
  ): Observable<IMessages> {
    const {page = 0, take = 10, skip = page * take} = pagerParams;
    const {type = '', typeId = null, participant = 0, $like} = commonParams;
    const app = this.prepareParams({...commonParams, ...pagerParams}, false);
    return this.findMessages(app.service, app.query);
  }

  messagesList$(
      commonParams: CommonParams = {},
      pagerParams: PageParams = {}
  ): Observable<IMessagesList> {
    const {page = 0, take = 10, skip = page * take} = pagerParams;
    const {type = 'all', typeId = null, $like} = commonParams;
    const app = this.prepareParams({...commonParams, ...pagerParams}, true);
    return this.findMessages(app.service, app.query);
  }

  validText(text) {
    if (!text.trim()) throw new Error('Не хватает текста сообщения');
  }

  addMessage(newMessage: MessageParams) {
    const {text, type = '', typeId = null, replyUserId} = newMessage;
    this.validText(text);
    const app = this.prepareParams({...newMessage});
    return this.feathers.service(app.service.path).create(app.query);
  }

  editMessage(data: MessageParams) {
    const {id, text, type = '', typeId = null} = data;
    this.validText(text);
    const service = this.getPath({type, typeId});
    return this.feathers
        .service(service.path)
        .patch(id, {
          text
        });
  }

  deleteMessage({id, type = '', typeId = null}) {
    const service = this.getPath({type, typeId});
    return this.feathers
        .service(service.path)
        .remove(id);
  }

}
