import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProducts from '../../reducers/saleplan/product.reducer';

export const getProductsState = createFeatureSelector<fromProducts.State>('product');

export const getProducts = createSelector(
	getProductsState,
	fromProducts.getProducts
);
export const selectProductLoadingStatus = createSelector(
	getProductsState,
	fromProducts.getProductLoadingStatus
);
export const getProductsWithOrders = createSelector(
	getProductsState,
	fromProducts.getProductsWithOrders
);
export const selectProductsByIds = createSelector(
	getProductsState,
	fromProducts.getProductsByIds
  );