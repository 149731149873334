import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ChatService } from '../../../chatAcademy/service/chat.service';
import { ChatDialogService } from '../../../chatAcademy/chat-dialog/chat-dialog.service';
import { PagesToggleService } from '../../../../../core/services/chat/toggler.service';
import { Observable, Subject } from 'rxjs';
import { DialogUserParams } from '../../../chatAcademy/models/chat';
import { Comment } from '../../../chatAcademy/models/comment';
import { User } from '../../../chatAcademy/models/user';
import { ChatTypeSwitchService } from '../../chat-type-switch/chat-type-switch.service';
import { FeathersService } from '../../../chatAcademy/service/feathers.service';
import { ChatType } from '../../chat-type';
@Component({
  selector: 'iql-chat-training-main-page',
  templateUrl: './chat-training-main-page.component.html',
  styleUrls: ['./chat-training-main-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTrainingMainPageComponent implements OnInit, OnDestroy {
  @Input() currentUser: User;
  @Output() openedDialog = new EventEmitter<any>();
  @Output() changedType = new EventEmitter<string>();
  trainingChatList: Comment[];
  loading: boolean = false;
  private ngOnDestroy$: Subject<null> = new Subject<null>();
  params: DialogUserParams = {
    $limit: 20,
    $skip: 0,
    type: 'all'
  };

  constructor(
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private chatDialogService: ChatDialogService,
    private toggleService: PagesToggleService,
    private chatTypeSwitchService: ChatTypeSwitchService,
    private feathersService: FeathersService,
  ) { }

  ngOnInit(): void {
    this.feathersService.isAuthChat()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(() =>
        this.getTrainingChatList());
    this.chatDialogService.messagesSubject
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(({ type, data }) => {
        if (type === 'created') {
          this.updateChats();
        }
      });
    this.toggleService.quickViewToggle
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(message => {
        if (message === 'closed') {
          this.updateChats();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  getTrainingChatList(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.chatService.getUsersDialogs(this.params)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(listDialogs => {
        this.trainingChatList = this.trainingChatList ? [...this.trainingChatList, ...listDialogs.data] : listDialogs.data;
        this.setUnreadMessage();
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  onScroll(): void {
    this.params = {
      $limit: 20,
      $skip: this.trainingChatList ? this.trainingChatList.length : 0,
      type: 'all'
    };
    this.getTrainingChatList();
  }

  onOpenDialog(dialog: Comment): void {
    this.openedDialog.emit({ dialog })
  }

  updateChats(): void {
    this.trainingChatList = [];
    this.getTrainingChatList();
  }

  onFilterChanged(data: { name?: string }): void {
    if (data && data.name) {
      this.params = {
        type: 'all',
        $limit: 20,
        $skip: 0,
        $like: data.name,
      };
    } else {
      this.params = {
        type: 'all',
        $limit: 20,
        $skip: 0,
      };
    }
    this.updateChats();
  }

  onChangeTypeChat(type: string): void {
    this.changedType.emit(type);
  }

  private setUnreadMessage(): void {
    if (this.trainingChatList && this.trainingChatList.length) {
      const totalUnread = this.trainingChatList.reduce((accumulator, dialog) => accumulator + dialog.totalUnread, 0);
      this.chatTypeSwitchService.setNotifications(ChatType.EMPLOYEE_TRAINING, totalUnread);
    }
  }
}