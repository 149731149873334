import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';


enum Communities {
  'clan' = 'Клан',
  'alliance' = 'Альянс',
  'guild' = 'Гильдия',
  'shop' = 'Магазин',
}

enum CommunitiesRe {
  'clan' = 'клане',
  'alliance' = 'альянсе',
  'guild' = 'гильдие',
  'shop' = 'магазине',
}

@Component({
  selector: 'app-chat-community',
  templateUrl: './chat-community.component.html',
  styleUrls: ['./chat-community.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatCommunityComponent implements OnInit {

  @Input() community;
  @Input() user;
  @Input() type;

  get name() {
    if (!this.type) return '';
    return Communities[this.type];
  }

  get inCommunity() {
    if (this.user && this.user[this.type]) {
      return this.user[this.type].id;
    }
  }

  get namere() {
    if (!this.type) return '';
    return CommunitiesRe[this.type];
  }

  get firstMessage() {
    return this.community?.messages[0] || {};
  }

  get secondMessage() {
    return this.community?.messages[1] || {};
  }

  constructor() {
  }

  ngOnInit() {
    console.log(this.community);
  }

}
