import { NotificationEffects } from './notification/notification.effects';
import { ApplicationSettingsEffects } from './startup/application-settings.effects';
import { TasksEffects } from './task/tasks.effects';
import { AuthEffects } from './auth/auth.effects';
import { ClientsEffects } from './deprecated/clients.effects';
import { OutletsEffects } from './deprecated/outlets.effects';
import { ChatEffects } from '@Mesh/store/effects/chat/chat.effects';
import { PlansEffects } from './deprecated/plans.effects';
import { OrdersEffects } from './saleplan/orders.effects';
import { SalePlanEffects } from './saleplan/saleplan.effects';
import { ClientsOutletsLookupEffects } from './deprecated/clients-outlets-lookup.effects';
import { BonusEffects } from './saleplan/bonus.effects';
import { ProductEffects } from './saleplan/product.effects';
import { TaskProgressEffects } from './task/task-progress.effect';
import { ManufacturerEffects } from './deprecated/manufacturer.effects';
import { DocumentEffects } from '@Mesh/store/effects/document/document.effects';
import { FinanceEffects } from '@Mesh/store/effects/finance/finance.effects';

export const effects = [
    TasksEffects,
    ApplicationSettingsEffects,
    AuthEffects,
    ClientsEffects,
    OutletsEffects,
    ChatEffects,
    TaskProgressEffects,
    PlansEffects,
    ClientsOutletsLookupEffects,
    OrdersEffects,
    SalePlanEffects,
    BonusEffects,
    ProductEffects,
    ManufacturerEffects,
    DocumentEffects,
    NotificationEffects,
    FinanceEffects,
];
