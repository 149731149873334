import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, share } from "rxjs/operators";
import { VisitDataItem } from "../../chatHR/models/visit-dialog";

export interface ShowVisitData {
  visitDataItem: VisitDataItem;
  entity: ShowVisitDataEntity;
  open: boolean;
}

export type ShowVisitDataEntity = "viewVisit" | "viewResume" | "viewLog" | "viewDocuments";

@Injectable({
  providedIn: "root",
})
export class ChatHrService {
  private showVisitData$ = new BehaviorSubject<ShowVisitData | null>(null);
  public userId$: Observable<number | undefined>;

  constructor() {
    this.userId$ = this.showVisitData$.pipe(map((visitData) => visitData?.visitDataItem?.user_id));
  }

  onShowShareChat(): Observable<ShowVisitData> {
    return this.showVisitData$.pipe(share());
  }

  setShowVisitData(state: ShowVisitData): void {
    this.showVisitData$.next(state);
  }

  setPartialVisitData(update: Partial<ShowVisitData>): void {
    const currentState = this.showVisitData$.getValue();
    const newState = { ...currentState, ...update };
    this.showVisitData$.next(newState);
  }

  closeVisitData(): void {
    const currentState = this.showVisitData$.getValue();
    if (currentState) {
      this.showVisitData$.next({ ...currentState, open: false });
    }
  }
}
