import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../../actions/saleplan/orders.actions';
import { catchError, concatMap, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { OrdersService } from '@Mesh/core/services/api/saleplan/orders.service';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { selectOrderLoadingStatusById } from '../../selectors/saleplan/orders.selector';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';

@Injectable()
export class OrdersEffects {
  constructor(private _actions$: Actions, private ordersService: OrdersService, private readonly _store: Store<State>) {
  }

  ordersHistoriesLoading$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadOrdersHistories, fromActions.loadOrdersHistoriesWithProps),
      concatMap(params => this.ordersService.getOrderHistory(params).pipe(
        map(ordersHistories => fromActions.ordersHistoriesLoaded({ ordersHistories, addressSapId: params.addressSapId })),
        catchError(() => [fromActions.ordersHistoriesLoadError()])
      ))
    )
  );

  orderHistoryLoading$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadOrderHistory),
      pluck('docNumber'),
      mergeMap(docNumber =>
        this._store.pipe(
          select(selectOrderLoadingStatusById, { docNumber }),
          switchMap(orderLoadingStatus => {
            if (orderLoadingStatus === LoadingStatus.NotLoaded || orderLoadingStatus === LoadingStatus.Loading) {
              return this.ordersService.getOrderHistoryDetailed(docNumber).pipe(
                map(history => fromActions.orderHistoryLoaded({ history, docNumber })),
                catchError(() => [fromActions.orderHistoryLoadError({ docNumber })])
              );
            }
            return [];
          })
        )
      )
    )
  );
}
