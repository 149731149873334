import { state, style, transition, animate, trigger } from '@angular/animations';

export const detailBody = trigger('detailBody', [
    state(
        'collapsed',
        style({
            overflow: 'hidden',
            visibility: 'hidden',
            height: 0,
        })
    ),
    state(
        'expanded',
        style({
            overflow: 'hidden',
            visibility: 'visible',
        })
    ),
    transition('collapsed => expanded', animate('100ms ease-in')),
    transition('expanded => collapsed', animate('100ms ease-out')),
]);
