import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/saleplan/product.actions';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import { Manufacturer } from '@Mesh/core/models/manufacturer';
import { Product, ProductsData } from '@Mesh/core/models/product';

export interface State {
  products: ProductsData;
  productsForShoppingCart: ProductsData;
  productsWithOrders: Product[];
  productLoadingStatus: LoadingStatus;
  manufacturers: Manufacturer[];
  productsByIds: Product[];
}

export const initialState: State = {
  products: null,
  productsForShoppingCart: null,
  productsWithOrders: [],
  productLoadingStatus: LoadingStatus.NotLoaded,
  manufacturers: [],
  productsByIds: [],
};

const productReducer = createReducer(
  initialState,
  on(fromActions.loadProducts, (state) => ({ ...state, productLoadingStatus: LoadingStatus.Loading })),
  on(fromActions.productsLoaded, (state, { products }) => {
    if (products?.number === 0) {
      return { ...state, products, productLoadingStatus: LoadingStatus.Loaded };
    }
    const newProducts = products?.content;
    return newProducts && state.products
      ? {
          ...state,
          productLoadingStatus: LoadingStatus.Loaded,
          products: { ...state.products, content: state.products.content.concat(newProducts), number: products.number },
        }
      : { ...state, productLoadingStatus: LoadingStatus.Loaded, products };
  }),
  on(fromActions.productsForShoppingCartLoaded, (state, { products }) => {
    return { ...state, productsForShoppingCart: products, number: products.number };
  }),
  on(fromActions.loadProductsWithOrders, (state) => ({ ...state, productsWithOrders: null })),
  on(fromActions.productsWithOrdersLoaded, (state, { products }) => ({ ...state, productsWithOrders: products })),
  on(fromActions.clearProducts, (state) => ({
    ...state,
    products: { ...initialState.products },
    productsWithOrders: { ...initialState.productsWithOrders },
  })),
  // on(fromActions.productInfoCreated, (state, { productInfo, id }) => {
  //   if (state.products && state.products.content && state.products.content.length) {
  //       return {...state, products: {
  //               ...state.products,
  //               content: state.products.content.map(p => p.materialId === productInfo.materialId ? {...p, productInfo: {...productInfo, id}} : p)
  //           }
  //       }
  //   }
  //   return {...state}
  // }),
  // on(fromActions.productInfoPatched, (state, { productInfo }) => {
  //   if (state.products && state.products.content && state.products.content.length) {
  //       return {...state, products: {
  //               ...state.products,
  //               content: state.products.content.map(p => p.materialId === productInfo.materialId ? {...p, productInfo: {...p.productInfo, active: productInfo.active}} : p)
  //           }
  //       }
  //   }
  //   return {...state}
  // }),
  // on(fromActions.productInfoCreated, (state, { productInfo, id }) => {
  //     if (state.products && state.products.content && state.products.content.length) {
  //         return {...state, products: {
  //                 ...state.products,
  //                 content: state.products.content.map(p => p.materialId === productInfo.materialId ? {...p, productInfo: {...productInfo, id}} : p)
  //             }
  //         }
  //     }
  //     return {...state}
  // }),
  // on(fromActions.productInfoUpdated, (state, { productInfo }) => {
  //     if (state.products && state.products.content && state.products.content.length) {
  //         return {...state, products: {
  //                 ...state.products,
  //                 content: state.products.content.map(p => p.materialId === productInfo.materialId ? {...p, productInfo} : p)
  //             }
  //         }
  //     }
  //     return {...state}
  // }),
  on(fromActions.loadProductsByProductIdsSuccess, (state, { data }) => {
    console.log('loadProductsByProductIdsSuccess', data);
    return {
      ...state,
      productsByIds: data.content,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return productReducer(state, action);
}

export const getProducts = (state: State) => state.products;
export const getProductLoadingStatus = (state: State) => state.productLoadingStatus;
export const getManufacturers = (state: State) => state.manufacturers;
export const getProductsWithOrders = (state: State) => state.productsWithOrders;
export const getProductsByIds = (state: State) => state.productsByIds;