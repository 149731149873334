import { User } from "./user";

export class ChatMessage {
    id: number;
    text: string;
    userId: number;
    replyUserId: number;
    type: string;
    typeId: number;
    readed: boolean;
    createdAt: string; //2019-12-29T21:06:24.326Z,
    updatedAt: string; //2019-12-29T21:06:24.326Z,
    user: User;
    replyUser: User;
    attached: any;
    imageLoading: boolean;

    constructor(o) {
        Object.assign(this, o);
    }
}
