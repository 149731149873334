import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, forwardRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ChatHrMainPageFiltersUserSearchService } from './chat-hr-main-page-filters-user-search.service';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { User } from '@Mesh/shared/modules/chatHR/models/user';

@Component({
  selector: 'iql-chat-hr-main-page-filters-user-search',
  templateUrl: './chat-hr-main-page-filters-user-search.component.html',
  styleUrls: ['./chat-hr-main-page-filters-user-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChatHrMainPageFiltersUserSearchComponent),
      multi: true
    }
  ],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('0.4s', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('0.4s', style({ opacity: 0, transform: 'translateY(-20px)' })),
      ]),
    ]),
  ],
})
export class ChatHrMainPageFiltersUserSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public searchControl = new FormControl('');
  public searchResults: Array<User> = [];
  private ngOnDestroy$ = new Subject<null>();
  private currentPage$ = new BehaviorSubject<number>(1);
  public isListLoading = false;
  private onChange: Function = () => {};
  private onTouched: Function = () => {};

  constructor(
    private userSearchService: ChatHrMainPageFiltersUserSearchService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => {
        this.isListLoading = true;
        this.onChange(null);
        this.searchResults = [];
        this.currentPage$.next(1);
        this.cdr.markForCheck();
      }),
      switchMap((text) => {
        const cleanedText= text.trim();
        if (!cleanedText || cleanedText.length < 2) {
          return of([]);
        }
        return this.queryResults(cleanedText);
      }),
      takeUntil(this.ngOnDestroy$)
    ).subscribe(
      (res) => {
        this.searchResults = res;
        this.isListLoading = false;
        this.cdr.markForCheck();
      }
    );

    this.currentPage$.pipe(
      distinctUntilChanged(),
      filter(page => page !== 1),
      switchMap(page => this.queryResults(this.searchControl.value.trim(), page)),
      takeUntil(this.ngOnDestroy$)
    ).subscribe(
      (res) => {
        this.searchResults = [...this.searchResults, ...res];
        this.isListLoading = false;
        this.cdr.markForCheck();
      }
    );
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private queryResults(cleanedText: string, page: number = this.currentPage$.value): Observable<User[]> {
    const isNumeric = /^\d+$/.test(cleanedText.replace(/\s+/g, ''));
    if (isNumeric) {
      return this.userSearchService.searchUserByPhone(cleanedText, page);
    } else {
      return this.userSearchService.searchUser(cleanedText, page);
    }
  }

  public selectUser(user: User): void {
    const fullName = `${user.surname ?? ''} ${user.name ?? ''} ${user.patronymic ?? ''}`.trim();
    this.searchControl.setValue(fullName, { emitEvent: false });
    this.searchResults = [];
    const userId = user.id;
    this.onChange(userId);
    this.cdr.markForCheck();
  }

  public loadData(event: Event) {
    this.currentPage$.next(this.currentPage$.value + 1);
    this.isListLoading = true;
  }

  public trackByFn(index: number, element: any) {
    return element.id;
  }

  public onClosedInfo() {
    this.searchResults = [];
    this.cdr.markForCheck();
  }
}
