import { ChatLikesService } from './chat-likes.service';
import { ChatSettingsService } from './chat-settings.service';
import { FeathersService } from './feathers.service';
import { MessagesService } from './messages.service';
import { SoundService, Sounds } from './sound.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { ChatMessage } from '../../chatHR/models/chat-message';
import { User } from '../../chatHR/models/user';
import { DialogUserParams } from '../../chatHR/models/chat';
import { HRUserStateService } from '../../../../core/services/api/auth/hr-user-state.service';
import { VisitDialog, VisitDialogParams } from '../models/visit-dialog';
import { UnreadMessagesCount } from '@Mesh/shared/modules/chatHR/models/unread-messages-count';


export class ChatPager {
  page?: number;
  skip?: number;
  take?: number;
}
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  updateDialogs = new EventEmitter();
  userChatSubscription$: Subject<Observable<ChatMessage[]>> = new Subject;

  constructor(
    private feathers: FeathersService,
    private academyUserStateService: HRUserStateService,
    private messagesService: MessagesService,
    private chatSettings: ChatSettingsService,
    private chatLikesService: ChatLikesService,
    private soundService: SoundService,
  ) {

  }
  user: User;

  getUser(userId) {
    return this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
      .service('users')
      .watch({
        idField: 'id',
        listStrategy: 'never'
      })
      .get(userId);

  }

  getTaskUser(id: number) {
    return this.feathers
      .service('task-user')
      .get(id);
  }

  getModule(type: string, id: number) {
    return this.feathers
      .service('users-chat/module/' + type)
      .get(id);
  }

  getUsers({
    page = 0,
    omit_ids = [],
    searchQuery = null
  }) {
    let limit = 20;

    let query = {
      /* $sort: {
        name: 1
      }, */
      // $select: ['id', 'name', 'patronymic', 'positionId', 'birthPlace', 'avatarId', 'surname'],
      // readed: false
      $limit: limit,
      $skip: page * limit,
      name: {
        $ne: ""
      },
      /* id: {
        $nin: omit_ids,
      }, */
      /* dismissalDate: {
        $in: [ null ]
      } */

    };
    if (searchQuery) {
      query['$like'] = searchQuery;
    }

    return this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
      .service('users')
      .watch({
        idField: 'id',
        listStrategy: 'never'
      })
      .find({
        query
      });

  }

  getUsersDialogs(params: DialogUserParams): Observable<{ total: number; data: any[] }> {
    console.log('params',params)
    return this.feathers.service('users-chat/list')
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({ query: params })
      .pipe(
        catchError(err => {
          console.error(`users-chat/list return error: ${err.message}`);
          return of({ total: 0, data: [] });
        }),
        map(({ total, data }) => ({ total, data })
        ));
  }


  getChatCommunity({ type, action_id }): Observable<any> {
    return new Observable(observer => {
      let community;
      this.feathers.service('messages/:type/:typeId').on('created', msg => {
        if (community && community.type === type && community.info.id === msg.typeId) {
          community.messages.pop();
          community.messages.unshift(msg);
          observer.next(community = {
            ...community
          });
        }
      });

      this.academyUserStateService.onChangeUser()
        .pipe(switchMap(user => {
          this.user = user;
          if (type === 'visits') {
            if (!action_id) {
              return of(null);
            }
          } else if (!this.user[type]) {
            return of(null);
          }
          return this.getCommunity({
            type,
            type_id: type === 'visits' ? action_id : user[type].id,
            limit: 2
          }).pipe(
            map(messages => ({
              type,
              info: type === 'visits' ? { id: action_id } : user[type],
              messages
            }),
              first(),
              // catchError(err => of(null))
            )
          );
        })).subscribe(_community => {
          community = _community;
          observer.next(community);
        });
    });
  }

  getCommunity({ type, type_id, page = 0, limit = 100 }: any) {
    return this.feathers
      .service(`messages/${type}/${type_id}`)
      .watch({
        idField: 'id',
        listStrategy: 'never'
      })
      .find({
        query: {
          $limit: limit,
          $skip: page * limit,
          // participant: user_id
          $sort: {
            createdAt: -1
          }
        }
      }).pipe(
        map(({ data }) => data)
      );
  }

  getAvailableDialogs({
    page = 0,
    take = 10,
    skip = 0
  }: ChatPager = {}): Observable<{ total: number, data: any[] }> {
    const $limit = take;
    const $skip = skip;
    this.messagesService.findChats({ type: 'all', $limit, $skip });
    return this.messagesService.subjects.getObs('users-chat/list');
  }

  togglePin(dialog, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.togglePin(dialog, type, typeId, flag);
  }

  toggleSound(dialog, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.toggleSound(dialog, type, typeId, flag);
  }

  removeDialog(dialog, type: string, typeId: number): any {
    return this.chatSettings.removeDialog(dialog, type, typeId);
  }

  toggleLike(type: string, typeId: number, messageId: number) {
    this.soundService.play(Sounds.CHAT_MESSAGE_LIKED);
    this.chatLikesService.toggleLike(type, typeId, messageId);
  }

  archiveDialog(type: string, typeId: number): Promise<any> {
    return this.chatSettings.archiveDialog(type, typeId);
  }

  unArchiveDialog(type: string): Promise<any> {
    return this.chatSettings.unArchiveDialog(type);
  }

  getVisitDialogsList(query: VisitDialogParams): Observable<{ total: number; data: VisitDialog[] }> {
    return this.feathers.service('messages/visits/list')
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({ query })
      .pipe(
        catchError(err => {
          console.error(`messages/visits/list return error: ${err.message}`);
          return of({ total: 0, data: [] });
        }),
        map(({ total, data }) => ({ total, data })
        ));
  }

  onUnreadMessagesUpdate(): Observable<UnreadMessagesCount> {
    return new Observable<UnreadMessagesCount>(observer => {
      const service = this.feathers.service('counter-unread-dialogs');

      const handler = (response: { unread_dialogs: UnreadMessagesCount }) => {
        observer.next(response.unread_dialogs);
      };

      service.on('patched', handler);

      // Cразу получить текущее состояние
      service.watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find()
      .subscribe({
        next: (response: { unread_dialogs: UnreadMessagesCount }) => {
          observer.next(response.unread_dialogs);
        },
        error: (err: any) => {
          observer.error(err);
        }
      });

      return () => {
        service.removeListener('patched', handler);
      };
    }).pipe(
      shareReplay(1)
    );
  }
}
