import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Comment } from '../../../../chatHR/models/comment';
import { User } from '../../../../chatHR/models/user';
import moment from 'moment';

@Component({
  selector: 'iql-chat-hr-item',
  templateUrl: './chat-hr-item.component.html',
  styleUrls: ['./chat-hr-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrItemComponent implements OnInit {
  @Input() chatDialog: Comment;
  @Input() currentUser: User;
  @Output() openedDialog = new EventEmitter<any>();
  @Output() toggleArchiveDialog = new EventEmitter<void>();

  isArchived = false;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  get oneMark() {
    let { readAt, createdAt } = this.message;
    return !createdAt || !readAt;
  }

  get twoMarks() {
    let { readAt, createdAt } = this.message;
    return createdAt && readAt;
  }

  get message() {
    return this.chatDialog.message ? this.chatDialog.message : {} as Comment ;
  }

  get user() {
    let { replyUser, user } = this.message;
    return replyUser?.id !== this.currentUser.id ? replyUser || user : user || replyUser;
  }

  get mine() {
    let {
      user
    } = this.message;
    return user?.id === this.currentUser.id;
  }

  get isTaskChatDialog() {
    return this.chatDialog.type === 'task-comments';
  }

  get onlyImages() {
    return this.message.text && this.message.text === '' && this.chatDialog.message.attached.uploads.filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif'].indexOf(item.ext) !== -1).length > 0;
  }

  get formattedDateOrTime(): string {
    const currentDate = moment();
    const date = moment(this.chatDialog?.message?.createdAt);

    if (currentDate.isSame(date, 'day')) {
      return date.format('HH:mm');
    } else {
      return date.format('DD.MM.YYYY');
    }
  }

  onToggleArchive(event: Event) {
    event.stopPropagation();
    this.toggleArchiveDialog.emit()
  }


  onOpenDialog(): void {
    this.openedDialog.emit()
  }
}