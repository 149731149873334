import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatClientMainPageFilterModule } from '../chat-client-main-page/chat-client-main-page-filter/chat-client-main-page-filter.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChatTypeSwitchModule } from '../chat-type-switch/chat-type-switch.module';
import { LoadingModule } from '../../../directives/loading/loading.module';
import { ChatTrainingItemComponent } from './chat-training-main-page/chat-training-item/chat-training-item.component';
import { ChatTrainingMainPageComponent } from './chat-training-main-page/chat-training-main-page.component';
import { ChatTrainingComponent } from './chat-training.component';
import { ChatTrainingDialogModule } from '../../chatAcademy/chat.module';



@NgModule({
  declarations: [
    ChatTrainingComponent,
    ChatTrainingMainPageComponent,
    ChatTrainingItemComponent
  ],
  exports: [
    ChatTrainingComponent,
  ],
  imports: [
    CommonModule,
    ChatClientMainPageFilterModule,
    ChatTypeSwitchModule,
    InfiniteScrollModule,
    LoadingModule,
    ChatTrainingDialogModule
  ]
})
export class ChatTrainingModule { }
