import {PipeTransform, Pipe} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AuthenticationService } from '@Mesh/core/services';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    // private auth: AuthenticationService, // our service that provides us with the authorization token
  ) {}

  transform(src: string): Observable<string> {
    /*const token = this.auth.token;
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});*/
    try {
      return of(src);
    } catch {
      return of('assets/fallback.png');
    }
  }
}
