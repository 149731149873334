import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, share } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { User } from '../../../../shared/modules/chatAcademy/models/user';
import { AcademyAuth } from '../../../models/academy-auth';

@Injectable({
  providedIn: 'root'
})
export class AcademyUserStateService {
  protected academyUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  protected academyUserToken$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private authService: AuthService
  ) {
  }

  onChangeToken(): Observable<any> {
    return this.academyUserToken$
      .pipe(
        filter(value => !!value),
        share());
  }

  onChangeUser(): Observable<User> {
    return this.academyUser$
      .pipe(share());
  }

  publishCurrentAcademyUser(): void {
    this.authService.tokenChange()
      .pipe(
        concatMap(token => {
          if (token) {
            return this.authService.getTokenAcademy(token)
              .pipe(catchError((err) => of({ data: null, token: null })));
          } else {
            return of({ data: null, token: null });
          }
        }),
        catchError((err) => of({ data: null, token: null })))
      .subscribe((tokenUser: AcademyAuth) => {
        if (tokenUser) {
          this.academyUser$.next(tokenUser.data)
          this.academyUserToken$.next(tokenUser.token);
        }
      });
  }
}
