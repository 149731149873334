import { salePlanBonusAction } from '@Mesh/store/actions/saleplan/bonus.actions';
import { State } from '../../../store/reducers/saleplan/bonus.reducers';
import { BonusConfigurationRuleRequest } from '@Mesh/core/models/bonus/bonus-configuration-rule-request';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SalePlanService } from '@Mesh/core/services/api/saleplan/saleplan.service';
import * as moment from 'moment';
import { ExcelParseService } from '@Mesh/core/services/excel/excel-parse.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'iql-disable-form',
  templateUrl: './disable-form.component.html',
  styleUrls: ['./disable-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisableFormComponent implements OnInit, OnChanges {
  @Input() file?: File;
  @Output() close = new EventEmitter();

  options: { id: string, name: string }[];

  form = new FormGroup({
    addressSapId: new FormControl('', Validators.required),
    planId: new FormControl('', Validators.required),
    startAt: new FormControl('', Validators.required),
    finishAt: new FormControl('', Validators.required)
  });

  get controls(): any {
    return this.form.controls;
  }

  arrayBuffer: any;
  jsonFromFile?: unknown[];

  constructor(
    private salePlanService: SalePlanService,
    readonly _store: Store<State>,
    private cdr: ChangeDetectorRef,
    private excelParseService: ExcelParseService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file?.currentValue) {
      this.setOptions();
    }
  }

  submit(): void {
    if (this.form.invalid || !this.file || !this.jsonFromFile) {
      return;
    }
    const res: BonusConfigurationRuleRequest[] = [];

    const addressSapIdName = this.controls.addressSapId.value;
    const planIdName = this.controls.planId.value;
    const startAtName = this.controls.startAt.value;
    const finishAtName = this.controls.finishAt.value;

    this.jsonFromFile.forEach((row) => {
      res.push({
        addressSapId: Number(row[addressSapIdName]),
        planId: Number(row[planIdName]),
        startAt: moment(row[startAtName], 'DD.MM.YYYY').format('YYYY-MM-DD'),
        finishAt: moment(row[finishAtName], 'DD.MM.YYYY').format('YYYY-MM-DD'),
        enable: true,
      });
    });
    this._store.dispatch(salePlanBonusAction.toggleBonusConfigurationRule({ request: res}));
    this.closeModal();
  }

  closeModal(): void {
    this.close.emit();
  }

  setOptions(): void {
    if (this.file) {
      this.excelParseService.transformExcelToJson(this.file).subscribe((json) => {
        this.jsonFromFile = json;
        this.options = Object.keys(json[0]).map((el) => ({id: el, name: el}));
        this.cdr.detectChanges();
      });
    }
  }

  changed(value: string | number, key: string): void {
    this.controls[key]?.patchValue(value);
  }
}
