import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Visit, VisitDataItem, VisitDialog } from "../../models/visit-dialog";
import {
  ChatHrService,
  ShowVisitDataEntity,
} from "../../../chat/chat-hr/chat-hr.service";
import { ChatUserInfo } from "../../models/chat-user-info";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "iql-dialog-visit-header",
  templateUrl: "./dialog-visit-header.component.html",
  styleUrls: ["./dialog-visit-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogVisitHeaderComponent implements OnInit, OnDestroy {
  @Input() visitDialog: VisitDialog;
  @Input() userData: ChatUserInfo;
  @Input() visitDataItem: VisitDataItem | null;
  @Output() back = new EventEmitter<any>();

  isLoadingVisit: boolean = true;
  hiddenEntities: Set<ShowVisitDataEntity> = new Set();

  buttons = [
    {
      entity: "viewVisit" as ShowVisitDataEntity,
      label: "Визит",
      icon: "icon-edit",
    },
    {
      entity: "viewLog" as ShowVisitDataEntity,
      label: "Журнал событий",
      icon: "icon-text",
    },
    {
      entity: "viewResume" as ShowVisitDataEntity,
      label: "Резюме",
      icon: "icon-file",
    },
    {
      entity: "viewDocuments" as ShowVisitDataEntity,
      label: "Документы",
      icon: "icon-view-documents",
    },
  ];
  private ngOnDestroy$: Subject<null> = new Subject<null>();

  constructor(
    private chatHrService: ChatHrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.chatHrService
      .onShowShareChat()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((state) => {
        if (state) {
          this.visitDataItem = state.visitDataItem;
          if (!state.open) {
            this.hiddenEntities.clear();
          }
          this.cdr.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.unsubscribe();
  }

  get dialogUser():
    | { user: Partial<ChatUserInfo>; vacancy?: any; visitDate?: string }
    | Visit {
    let userObj: {
      user: Partial<ChatUserInfo>;
      vacancy?: any;
      visitDate?: string;
    } = { user: this.userData };

    if (this.visitDataItem) {
      if (this.visitDataItem.user) {
        userObj.user = {
          name: this.visitDataItem.user.name,
          surname: this.visitDataItem.user.surname,
          patronymic: this.visitDataItem.user.patronymic,
        };
      }
      userObj.visitDate = this.visitDataItem.visit_date;
      userObj.vacancy = {
        city: { name: this.visitDataItem.vacancy?.city?.name || "Москва" },
        address: this.visitDataItem.vacancy?.address,
        name: this.visitDataItem.vacancy?.name,
      };
      return userObj;
    }
    if (this.visitDialog) {
      return this.visitDialog.visit;
    }
    return userObj;
  }

  onBack(event: Event): void {
    this.back.emit(event);
    this.chatHrService.setShowVisitData(null);
  }

  private handleView(entity: ShowVisitDataEntity): void {
    if (!this.visitDataItem) {
      return;
    }
    this.chatHrService.setShowVisitData({
      visitDataItem: this.visitDataItem,
      entity: entity,
      open: true,
    });
    this.resetHiddenEntities(entity);
  }

  private resetHiddenEntities(entity: ShowVisitDataEntity): void {
    this.hiddenEntities.clear();
    this.hiddenEntities.add(entity);
  }

  onView(entity: ShowVisitDataEntity): void {
    this.handleView(entity);
  }

  isButtonHidden(entity: ShowVisitDataEntity): boolean {
    return this.hiddenEntities.has(entity);
  }
}
