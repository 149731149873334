export interface OpenedResume {
  id: number;
  academy_id: number | null;
  name: string;
  surname: string;
  patronymic: string;
  money: number;
  points: number;
  score: number;
  email: string | null;
  phone: string;
  bio: string | null;
  sex: string | null;
  birth_place: string | null;
  cloth_size: string | null;
  foot_size: string | null;
  birthday_date: string | null;
  employment_date: string | null;
  login: string | null;
  rank: string | null;
  rank_shop: string | null;
  rank_guild: string | null;
  rank_clan: string | null;
  rank_alliance: string | null;
  avatar: string | null;
  emodji: string | null;
  division_id: number | null;
  position_id: number;
  position_name: string;
  total: number;
  must_change_password: boolean;
  vkontakte: string | null;
  facebook: string | null;
  instagram: string | null;
  iqretail_id: number | null;
  is_head: boolean;
  is_checked: boolean;
  is_rejected: boolean;
  active: boolean;
  external_id: string | null;
  city_id: number;
  city_name: string | null;
  status_id: number;
  status_name: string;
  refusalReasons: Reason[];
  resume_count: number;
  is_fulltime: number;
  is_parttime: number;
  comment: string | null;
  city: City;
  district: string | null;
}

export type OpenedResumeUpdatePayload = Partial<Pick<OpenedResume, 'status_id' | 'refusalReasons' | 'comment'>>;

export interface City {
  id: number;
  name: string | null;
}

export enum OpenedResumeStatus {
  NEW = 0,
  RECALL = 1,
  CONFIRMED = 2,
  REJECTED = 3,
  ARCHIVE = 4
}

export interface OpenedResumeStatusInfo {
  type: OpenedResumeStatus;
  color: string;
  title: string;
  label: string;
  statusId: string;
}

export const OpenedResumeStatusMap: { [key in OpenedResumeStatus]: OpenedResumeStatusInfo } = {
  [OpenedResumeStatus.NEW]: {
    type: OpenedResumeStatus.NEW,
    color: '#00D0B3',
    title: 'Новый',
    label: 'Новые',
    statusId: '0'
  },
  [OpenedResumeStatus.RECALL]: {
    type: OpenedResumeStatus.RECALL,
    color: '#FBC531',
    title: 'Перезвонить',
    label: 'Ожидающие звонка',
    statusId: '1'
  },
  [OpenedResumeStatus.CONFIRMED]: {
    type: OpenedResumeStatus.CONFIRMED,
    color: '#2ECC71',
    title: 'Подтверждено',
    label: 'Подтвержденные',
    statusId: '2'
  },
  [OpenedResumeStatus.REJECTED]: {
    type: OpenedResumeStatus.REJECTED,
    color: '#F64E60',
    title: 'Отказано',
    label: 'Отклоненные',
    statusId: '3'
  },
  [OpenedResumeStatus.ARCHIVE]: {
    type: OpenedResumeStatus.ARCHIVE,
    color: '#F64E60',
    title: 'Архив',
    label: 'Архив',
    statusId: '4'
  }
}
export interface Reason {
  id: number;
  name: string
}