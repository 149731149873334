import {
    ChangeDetectorRef,
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener,
    forwardRef, Inject, Optional,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
// tslint:disable: no-use-before-declare
const UI_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SwitchCheckedComponent),
    multi: true,
};

@Component({
    selector: 'iql-switch-checked',
    templateUrl: './switch-checked.component.html',
    styleUrls: ['./switch-checked.component.scss'],
    providers: [UI_SWITCH_CONTROL_VALUE_ACCESSOR],
})
export class SwitchCheckedComponent implements ControlValueAccessor {
    @Input()
    set checked(v: boolean) {
        this._checked = v !== false;
    }

    get checked() {
        return this._checked;
    }

    @Input()
    set disabled(v: boolean) {
        this._disabled = v !== false;
    }

    get disabled() {
        return this._disabled;
    }

    @Input()
    set reverse(v: boolean) {
        this._reverse = v !== false;
    }

    get reverse() {
        return this._reverse;
    }

    @Output() modelChange = new EventEmitter<boolean>();
    @Output() changeEvent = new EventEmitter<MouseEvent>();
    @Output() valueChange = new EventEmitter<boolean>();

    private _checked: boolean;
    private _disabled: boolean;
    private _reverse: boolean;
    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }

    @HostListener('click', ['$event'])
    onToggle(event: MouseEvent): void {
        if (this.disabled) {
            return;
        }
        this.checked = !this.checked;

        // Component events
        this.modelChange.emit(this.checked);
        this.valueChange.emit(this.checked);
        this.changeEvent.emit(event);

        // value accessor callbacks
        this.onChangeCallback(this.checked);
        this.onTouchedCallback(this.checked);
        this.cdr.markForCheck();
    }

    writeValue(obj: any): void {
        if (obj !== this.checked) {
            this.checked = !!obj;
        }
        if (this.cdr) {
            this.cdr.markForCheck();
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private onTouchedCallback = (v: any) => { };
    private onChangeCallback = (v: any) => { };
}
