import { createAction, props } from '@ngrx/store';
import { IUserInfo } from '@Mesh/core/models/user';
import { LoginInfo } from '@Mesh/core/models/loginInfo';

export const init = createAction('[Auth] initialization of App');
export const getToken = createAction('[Auth] get token', props<{ params: LoginInfo }>());
export const getTokenSuccess = createAction('[Auth] get token success', props<{ userInfo: IUserInfo }>());
export const setTokenSuccess = createAction('[Auth] set token success', props<{ userInfo: IUserInfo }>());
export const refreshToken = createAction('[Auth] refresh token');
export const refreshTokenSuccess = createAction('[Auth] refresh token success', props<{ userInfo: IUserInfo }>());
export const Logout = createAction('[Auth] Logout');
export const LogoutSucces = createAction('[Auth] Logout success');
export const ResetUserInfo = createAction('[Auth] Reset user info');