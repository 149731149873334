import { ProductsFilter } from '@Mesh/core/models/internal/products-filter';
import { Product, ProductInfo, ProductsData } from '@Mesh/core/models/product';
import { createAction, props } from '@ngrx/store';

export const loadProductsWithOrders = createAction('[Product] Load Products With Orders', props<ProductsFilter>());
export const productsWithOrdersLoaded = createAction('[Product] Products With Orders loaded', props<{ products: Product[] }>());
export const loadProducts = createAction('[Product] Load Products', props<ProductsFilter>());
export const loadProductsForGoal = createAction('[Product] Load Products For Goal', props<{planIds: number[], outletId: number, page: number}>());
export const loadProductsForShoppingCart = createAction('[Product] Load Products For Shopping Cart', props<{outletId: number, materialIds: number[]}>());
export const productsLoaded = createAction('[Product] Products loaded', props<{ products: ProductsData }>());
export const productsForShoppingCartLoaded = createAction('[Product] Products For Shopping Cart loaded', props<{ products: ProductsData }>());

export const clearProducts = createAction('[Product] Clear Products');

export const loadProductInfo = createAction('[Product] load Product info', props<{ materialId: number }>());
export const productInfoLoaded = createAction('[Product] Product info Loaded', props<{ productInfo: ProductInfo[] }>());
export const createProductInfo = createAction('[Product] Create Product info', props<{ productInfo: ProductInfo, materialId: number }>());
export const productInfoCreated = createAction('[Product] Product info created', props<{ productInfo: ProductInfo, materialId: number }>());
export const patchProductInfo = createAction('[Product] Patch Product info', props<{ productInfo: Partial<ProductInfo>, materialId: number }>());
export const productInfoPatched = createAction('[Product] Product info patched', props<{ productInfo: Partial<ProductInfo>, materialId: number }>());

export const loadProductsByProductIds = createAction('[Product] Load Products By Product Ids', props<{ materialIds: number[], addressSapId: number }>());
export const loadProductsByProductIdsSuccess = createAction('[Product] Load Products By Product Ids Success', props<{ data: ProductsData  }>());
export const loadProductsByProductIdsFailure = createAction('[Product] Load Products By Product Ids Failure', props<{ error: any }>());