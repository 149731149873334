import { ACADEMY_URL, HR_URL } from '@Env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TaskPaymentRecord, UserMoneyRecord, VisitCity, VisitDataItem, VisitJournalEvent } from '../../../chatHR/models/visit-dialog';
import { User } from '@Mesh/shared/modules/chatHR/models/user';
import { Resume } from '@Mesh/core/models/resume';

interface UpdateVisitPayload {
  vacancy_id: number,
  visit_date: string,
  status_id: number
}

@Injectable({
  providedIn: 'root'
})
export class ChatHrVisitApiService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  getVisitCities(): Observable<Array<VisitCity>> {
    return this.http.get<Array<VisitCity>>(`${HR_URL}/visits/adm/cities`)
      .pipe(
        map((res: any) => res.data),
        catchError(() => of([] as Array<VisitCity>))
      );
  }

  getVisitsByVisitId(id: number): Observable<VisitDataItem> {
    return this.http.get<VisitDataItem>(`${HR_URL}/visits/adm/${id}`)
    .pipe(
      map((res: any) => res.data),
      catchError((err) => {
        console.error('Error visit Data', err);
        return throwError(new Error('Ошибка получения информации о визите'));
      })
    );
  }

  getVisitsByUserId(user_id: number): Observable<any> {
    const params = new HttpParams().set('user_id', user_id.toString());
    return this.http.get<any>(`${HR_URL}/visits/adm`, { params: params });
  }

  updateVisit(visitId: number, payload: UpdateVisitPayload): Observable<{ data: VisitDataItem }> {
    return this.http.patch<{ data: VisitDataItem }>(`${HR_URL}/visits/adm/${visitId}/update`, payload);
  }

  getVisitJournal(visitId: number): Observable<VisitJournalEvent[]> {
    return this.http.get<VisitJournalEvent[]>(`${HR_URL}/visits/adm/${visitId}/journal`)
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error fetching visit events journal', err);
          return of([] as Array<VisitJournalEvent>);
        })
      );
  }

  getUserMoneyByUserId(id: number): Observable<UserMoneyRecord[]> {
    return this.http.get<UserMoneyRecord[]>(`${HR_URL}/user-money/user/${id}`)
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error fetching user money', err);
          return of([] as Array<UserMoneyRecord>);
        })
      )
  }

  getTaskPaymentsByUserId(id: number): Observable<TaskPaymentRecord[]> {
    return this.http.get<TaskPaymentRecord[]>(`${HR_URL}/task-payment/user/${id}`)
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error fetching tasks payment', err);
          return of([] as Array<TaskPaymentRecord>);
        })
      )
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${HR_URL}/user/${userId}`)
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error fetching user info', err);
          return throwError(new Error('Ошибка получения информации соискателя'));
        })
      )
  }

  changeUserCheckedMode(userId: number, value: boolean) {
    const payload = { is_checked: value ? 1 : 0 }
    return this.http.post<User>(`${HR_URL}/admin/user/set-is-checked/${userId}`, payload)
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error of changing user status', err);
          return throwError(new Error('Ошибка смены статуса'));
        })
      )
  }

  changeUserRejectedMode(userId: number, value: boolean) {
    const payload = { is_rejected: value ? 1 : 0 }
    return this.http.post<User>(`${HR_URL}/admin/user/set-is-rejected/${userId}`, payload)
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error of changing user status', err);
          return throwError(new Error('Ошибка смены статуса'));
        })
      )
  }

  getResume(userId: number): Observable<Resume[]> {
    const params = new HttpParams().set('user_id', userId.toString());
    return this.http.get<Resume[]>(`${HR_URL}/resume`, { params: params })
      .pipe(
        map((res: any) => res.data),
        catchError((err) => {
          console.error('Error fetching user resume', err);
          return throwError(new Error('Ошибка получения резюме'));
        })
      )
  }

}
