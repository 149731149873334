import { TaskPaymentRecord } from '@Mesh/shared/modules/chatHR/models/visit-dialog';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'iql-visit-data-task-payment-item',
  templateUrl: './visit-data-task-payment-item.component.html',
  styleUrls: ['./visit-data-task-payment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitDataTaskPaymentItemComponent {
  @Input() item: TaskPaymentRecord;

}
