import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTransform'
})
export class DateTransformPipe implements PipeTransform {
  transform(value: string): string {

    const date = new Date(value);
    const formattedDate = new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).format(date);

    const result = formattedDate.replace(
      /(\d{2})\.(\d{2})\.(\d{4}), (\d{2}):(\d{2})/,
      '$4:$5 / $2.$1.$3'
    );

    return result;
  }
}
