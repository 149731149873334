import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { SecondarySidebarComponent } from './secondary-sidebar/secondary-sidebar.component';
import { QuickviewService } from './quickview/quickview.service';
import { ModalModule, TypeaheadModule } from 'ngx-bootstrap';
import { ParallaxDirective } from './parallax/parallax.directive';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormGroupDefaultDirective } from './forms/form-group-default.directive';
import { ViewDirective } from './view/view.directive';
import { pgCollapseModule } from './collapse/collapse.module';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ContainerComponent } from './container/container.component';
import { PageContainer } from './container/page-container.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { MenuAltComponent } from './menu/menu-alt.component';
import { MenuIconComponent } from './menu/menu-icon.component';
import { ListItemComponent } from './list-view/list-item/list-item.component';
import { ListViewContainerComponent } from './list-view/list-view-container/list-view-container.component';
import { pgRetinaDirective } from './retina/retina.directive';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FilterPipe } from '../pipes/filter.pipe';
import { PgSelectModule } from './select/select.module';
import { ProgressModule } from './progress/progress.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ShowComponent } from './show/show.component';
import { ButtonToggleComponent } from './button-toggle/button-toggle.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        ObserversModule,
        TypeaheadModule.forRoot(),
        PerfectScrollbarModule,
        RouterModule,
        NgxDatatableModule,
        PgSelectModule,
        ProgressModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
    ],
    declarations: [
        SidebarComponent,
        SecondarySidebarComponent,
        ParallaxDirective,
        BreadcrumbComponent,
        FormGroupDefaultDirective,
        ViewDirective,
        ContainerComponent,
        PageContainer,
        MenuComponent,
        MenuAltComponent,
        MenuIconComponent,
        ListItemComponent,
        ListViewContainerComponent,
        pgRetinaDirective,
        FilterPipe,
        ShowComponent,
        ButtonToggleComponent
    ],
    exports: [
        SidebarComponent,
        SecondarySidebarComponent,
        ParallaxDirective,
        BreadcrumbComponent,
        FormGroupDefaultDirective,
        ViewDirective,
        pgCollapseModule,
        ContainerComponent,
        PageContainer,
        MenuComponent,
        MenuAltComponent,
        MenuIconComponent,
        ListItemComponent,
        ListViewContainerComponent,
        pgRetinaDirective,
        ShowComponent,
        ButtonToggleComponent
    ],
    providers: [
        QuickviewService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class PagesModule {
}
