import { Component, OnInit, ChangeDetectionStrategy, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = (): void => { };

@Component({
  selector: 'iql-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectDropdownComponent),
    multi: true,
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectDropdownComponent implements ControlValueAccessor {
  @Input() inputWidth: string;
  @Input() idKey: string = 'id';
  @Input() nameKey: string = 'name';
  @Input() onlyInput: boolean;
  @Input() hideScroll: boolean;
  @Input() hideNoData: boolean;
  @Input() isDoubleColumn: boolean;
  @Input() isMultiSelect: boolean;
  @Input() isDisabled: boolean;
  @Input() placeholder = '―';
  @Input() showRemove: boolean = true;

  @Input()
  set optionData(options: { [key: string]: string }[]) {
    this.options = options || [];
  }

  inputValue = '';
  selectedItems: { [key: string]: string } = {};

  @Output()
  outputData: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();

  options: { [key: string]: string }[] = [];

  opened: boolean;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  toggleCollapse(): void {
    if (!this.onlyInput) {
      this.opened = !this.opened;
    }
  }

  select(data: { [key: string]: string }): void {
    if (this.selectedItems[data[this.idKey]]) {
      delete this.selectedItems[data[this.idKey]];
      this.getInputValue();
      this.selectData();
      return;
    }
    if (this.isMultiSelect) {
      this.selectedItems[data[this.idKey]] = data[this.nameKey];
    } else {
      this.selectedItems = {
        [data[this.idKey]]: data[this.nameKey]
      };
    }
    this.getInputValue();
    this.selectData();
  }

  close(): void {
    this.opened = false;
  }

  getInputValue(): void {
    this.inputValue = Object.values(this.selectedItems).join(', ');
  }

  delete(): void {
    this.inputValue = '';
    if (this.onlyInput) {
      this.outputData.emit(this.inputValue);
      this.updateValue(this.inputValue);
    } else {
      this.selectedItems = {};
      if (this.isMultiSelect) {
        this.outputData.emit([]);
        this.updateValue([]);
      } else {
        this.outputData.emit(null);
        this.updateValue(null);
      }
    }
  }

  onModelChange(inputValue: string): void {
    if (this.onlyInput) {
      this.outputData.emit(inputValue);
      this.updateValue(inputValue);
    }
  }

  selectData(): void {
    const data = Object.keys(this.selectedItems);
    if (this.isMultiSelect) {
      this.outputData.emit(data);
      this.updateValue(data);
      return;
    }
    this.outputData.emit(data[0]);
    this.updateValue(data[0]);
    this.close();
  }

  writeValue(outsideValue: string | string[]): void {
    if (outsideValue) {
      this.patchData(outsideValue);
    }
    this.updateValue(outsideValue);
  }
  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  updateValue(insideValue: string | string[]): void {
    this.onChangeCallback(insideValue); // уведомить Forms API
    this.onTouchedCallback();
  }

  private patchData(selectedValue: string | string[]): void {
    if (!Array.isArray(selectedValue)) {
      const option = this.options.find(item => item[this.idKey] === selectedValue);
      if (!!option) {
        this.selectedItems = {
          [option[this.idKey]]: option[this.nameKey]
        };
        this.getInputValue();
      }
    } else {
      selectedValue
        .forEach(item => {
          const option = this.options.find(
            (opt) => opt[this.idKey] === item
          );
          if (option) {
            this.selectedItems[option[this.idKey]] = option[this.nameKey];
          }
        });
      this.getInputValue();
    }
  }
}

