import { User } from "@Mesh/shared/modules/chatHR/models/user";

export interface Resume {
  id: number;
  user_id: number;
  title: string;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  age: string;
  birth_day: string | null;
  relocation_areas: any[];
  source_id: string;
  source_name: string;
  status: string;
  education: Education;
  gender: Gender;
  salary: number;
  salary_currency_id: string;
  photo_url: string;
  download_pdf: string;
  portfolio_url: string;
  phone: string;
  email: string;
  total_experience: number;
  skills_additional: string;
  relocations: any[];
  employments: Employment[];
  schedules: Schedule[];
  skill_sets: SkillSet[];
  driver_license_types: DriverLicenseType[];
  educations: any[];
  languages: Language[];
  user: User;
}

export interface Employment {
  id: number;
  dictionary_type: string;
  code: string;
  value: string;
}

export interface Schedule {
  id: number;
  dictionary_type: string;
  code: string;
  value: string;
}

export interface SkillSet {
  id: number;
  dictionary_type: string;
  code: string;
  value: string;
}

export interface DriverLicenseType {
  id: number;
  dictionary_type: string;
  code: string;
  value: string;
}

export interface Language {
  id: number;
  code: string;
  name: string;
  level_id: string;
  level_name: string;
}

export enum Gender {
  male = 'мужской',
  female = 'женский'
}

export enum Education {
  primary = 'primary',
  secondary = 'secondary',
  special_secondary = 'средне-специальное',
  higher = 'высшее'
}

