import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
// import { Comment } from '@Mesh/core/models/comment';
import { Message } from '@Mesh/core/models/message';
import { HttpClient } from '@angular/common/http';

// import { type } from 'os';

export class PageParams {
  page?: number;
  skip?: number;
  take?: number;
}

export interface IListItem {
  id: number;
  label?: string;
  message: Message;
  participants?: number[];
  task?: any;
  brand?: any;
  outlet?: any;
  total?: number;
  totalUnread?: number;
  type?: string;
  typeId?: number | null;
}

export interface IMessagesList {
  total: any;
  data: IListItem[];
}

export interface IMessages {
  total: number;
  data: Message[];
}

interface CommonParams {
  type?: string;
  typeId?: number | null;
  participant?: number;
  $like?: string;
  asList?: boolean;
}

interface MessageParams extends CommonParams {
  id?: number;
  text: string;
  replyUserId?: number;
}

/**
 *  Abstraction layer for data management
 *  Technically this isn't needed for feathers-chat,
 *  but you will need it for more complex tasks.
 */

@Injectable({ providedIn: 'root' })
export class MessagesService {

  /* tslint:disable: curly */
  types = {
    chats: ['tasks-comments', 'chats', 'all'],
    messages: ['alliance', 'clan', 'guild', 'article', 'book', 'course', 'exam', 'video', 'clubs', 'modules']
  };

  subjects = {
    getObs: function (label) {
      return this[label].asObservable();
    },
    'users-chat/list': new BehaviorSubject<any>({ total: 0, page: 1, data: null })
  };

  constructor(private feathers: FeathersService, private http: HttpClient) {
  }

  async find(path, params, watchLabel) {
    this.subjects[watchLabel] = new BehaviorSubject<any>([]);
    const result = await this.feathers.service(path).find({ query: params });
    if (result) {
      if (path.includes('list')) {
        result.data.map(item => item.message = new Message().deserialize(item.message));
      } else {
        result.data.map(item => new Message().deserialize(item));
      }
      this.subjects[watchLabel].next(result.data.reverse());
    }
  }

  toggleSound(dialog: any, muted: any): any {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    chatsList.data = chatsList.data.map(item => {
      if (item.id === dialog.id) {
        item.settings.muted = muted;
      }
      return item;
    });
    this.subjects[label].next(chatsList);
  }

  togglePinned(dialog: any, pinned: any): any {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    chatsList.data = chatsList.data.map(item => {
      if (item.id === dialog.id) {
        item.settings.pinned = pinned;
      }
      return item;
    });
    const pinnedChats = chatsList.data.filter(item => item.settings.pinned);
    const notPinnedChats = chatsList.data.filter(item => !item.settings.pinned);
    pinnedChats.sort((item1, item2) => {
      if (item1.id > item2.id) {
        return -1;
      }
      if (item1.id < item2.id) {
        return 1;
      }
    });
    notPinnedChats.sort((item1, item2) => {
      if (item1.id > item2.id) {
        return -1;
      }
      if (item1.id < item2.id) {
        return 1;
      }
    });
    chatsList.data = [...pinnedChats, ...notPinnedChats];
    //chatsList.data);
    this.subjects[label].next(chatsList);
  }

  removeDialog(dialog: any): void {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    chatsList.data = chatsList.data.filter(item => item.id !== dialog.id);
    this.subjects[label].next(chatsList);
  }

  dialogService(): any {
    return this.feathers.service('users-chat/list');
  }

  async findChats(params: any): Promise<any> {
    const label = 'users-chat/list';
    const chatsList = this.subjects[label].getValue();
    const result = await this.feathers.service(label).find({ query: params });
    if (result) {
      const chats = { total: chatsList.total, page: 0, data: [] };
      result.data.map(item => item.message = new Message().deserialize(item.message));
      chats.data = chatsList.data ? [...chatsList.data, ...result.data] : [...result.data];
      chats.total = result.total;
      this.subjects[label].next(chats);
    }
  }

  resetChatList(): void {
    const chats = { total: 0, page: 0, data: null };
    const label = 'users-chat/list';
    this.subjects[label].next(chats);
  }

  async create(path: any, msg: any): Promise<any> {
    let data: any = { attached: msg.attached, text: msg.text };
    if (path.includes('chat')) {
      const { typeId, replyUserId } = msg;
      if (msg.type !== 'task-comments') {
        data = { ...data, replyUserId };
      } else {
        data = { ...data, type: 'task-comments', typeId };
      }
    }
    const createdMsg = await this.feathers.service(path).create(data);
    if (createdMsg) {
      const { type, typeId } = createdMsg;
      let label = '';
      if (!typeId || type === 'task-comments') {
        label = typeId ? `users-chat/task-comments/${typeId}` : `users-chat/${type}`;
      } else {
        label = `messages/${type}/${typeId}`;
      }
      const watchChat: any[] = [...this.subjects[label].getValue()];
      watchChat.push(createdMsg);
      // const chatsList = await this.feathers.service('users-chat/list').find({ query: { type: 'all' } });
      // this.subjects['users-chat/list'].next(chatsList);
      this.subjects[label].next(watchChat);
    }
    return createdMsg;
  }

  async remove(path: any, id: number, watchLabel: any): Promise<any> {
    const removedMsg = await this.feathers.service(path).remove(id);
    const watchChat: any[] = [...this.subjects[watchLabel].getValue()];
    watchChat.forEach((item, index) => {
      if (item.id === id) {
        watchChat.splice(index, 1);
      }
    });
    // const chatsList = await this.feathers.service('users-chat/list').find({ query: { type: 'all' } });
    // this.subjects['users-chat/list'].next(chatsList);
    this.subjects[watchLabel].next(watchChat);
    return removedMsg;
  }

  openChat(): any {

  }

}
