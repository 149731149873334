import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';
import { API_URL, USER_URL } from '@Env/environment';
import { AuthService } from '../auth/auth.service';
import { Outlet, OutletData } from '../../../models/outlet';
import { Manufacturer } from '../../../models/manufacturer';
import {IResponse} from '@Mesh/core/models/IResponse';
import {IClientsForTask} from '@Mesh/core/models/client';
import {IClientsQueryParams} from '@Mesh/core/models/query-params';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private readonly http: HttpClient, private readonly authService: AuthService) {
  }

  readonly connectionString = API_URL;
  readonly newConnectionString = USER_URL;

  getImage(url: string): Observable<Blob> {
    return this.http.get(
      `${url}?token=${this.authService.accessToken}`,
      { responseType: 'blob' });
  }

  getClientsForOutlets(data: Partial<IClientsQueryParams>): Observable<IResponse<IClientsForTask[]>> {
    const options: Partial<IClientsQueryParams> = data;
    options.sort = 'clientName';
    options.direction = 'asc';
    return this.http.get<IResponse<IClientsForTask[]>>(`${this.newConnectionString}/client`, { params: <HttpParams>options });
  }

  getOutletList(clientSapId?: number, pageNumber?: number): Observable<OutletData> {
    let params = new HttpParams();
    params = params.append('extendedFormat', `true`);

    if (clientSapId) {
      params = params.append('clientSapId', `${clientSapId}`);
    }
    if (pageNumber) {
      params = params.append('page', `${pageNumber}`);
    }
    return this.http.get<OutletData>(`${USER_URL}/address`, {
      params: params,
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  getOutlets(clientSapId?: number, pageSize?: number): Observable<Outlet[]> {
    let params = new HttpParams();
    params = params.append('extendedFormat', `true`);

    if (clientSapId) {
      params = params.append('clientSapId', `${clientSapId}`);
    }

    if (pageSize) {
      params = params.append('size', `${pageSize}`);
    }
    return this.http.get<Outlet[]>(`${USER_URL}/address`, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(pluck('content'));
  }

  getCategoryManufacturer(categoryName: string): Observable<Manufacturer[]> {
    const queryString = '/category/manufacturer';
    let params = new HttpParams();
    params = params.append('categoryName', `${categoryName}`);
    return this.http.get<Array<Manufacturer>>(`${this.connectionString}${queryString}`, { params })
      .pipe(map(c => c.filter(cat => cat?.name.length !== 0)));
  }
}
