import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {CLOUD_UPLOAD_URL, IMAGES_URL} from '@Env/environment';
import {UploadFileResponse} from '@Mesh/core/models/upload-file-response';
import {UploadChangeParam} from '@Mesh/@pages/components/upload/interface';

@Component({
  selector: 'iql-download-icon',
  templateUrl: './download-icon.component.html',
  styleUrls: ['./download-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadIconComponent {
  @Input()
  public labelText: string;

  @Input()
  public labelWidth: string;

  @Input()
  public inputWidth: string;

  @Input()
  public uploadedFile: UploadFileResponse;

  @Output()
  downloadIcon: EventEmitter<string> = new EventEmitter<string>();
  
  public uploadImageUrl = CLOUD_UPLOAD_URL;
  public IMAGES_URL = IMAGES_URL;
  
  constructor(private cdr: ChangeDetectorRef) { }

  uploadIcon(event: UploadChangeParam): void {
    const response = event.fileList[event.fileList.length - 1].response;
    if (response) {
      this.uploadedFile = response;
      this.cdr.detectChanges();
      this.downloadIcon.emit(this.uploadedFile.relativeUrl);
    }
  }
}
