
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ChatHrVisitApiService } from '../../chat-hr-visit.service';
import { User } from '@Mesh/shared/modules/chatHR/models/user';

@Component({
  selector: 'iql-user-data-edit',
  templateUrl: './user-data-edit.component.html',
  styleUrls: ['./user-data-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataEditComponent implements OnInit, OnDestroy {
  @Input() userId: number;

  private ngOnDestroy$ = new Subject<null>();
  isCheckedUser: boolean = false;
  isRejectedUser: boolean = false;
  userRequestError: string | null = null;
  public isUserInfoLoading: boolean = true;
  private currentUser: User;

  constructor(
    private chatHrVisitService: ChatHrVisitApiService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.fetchUserById(this.userId);
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  private fetchUserById(userId: number) {
    this.chatHrVisitService.getUserById(userId)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe({
        next: user => {
          this.currentUser = user;
          this.updateInputsValue();
          this.cdr.markForCheck();
        },
        error: (error: Error) => {
          this.userRequestError = error.message;
          console.error('error', error);
          this.cdr.markForCheck();
        }
      });
  }

  onCheckedValueChange(newValue: boolean): void {
    this.isUserInfoLoading = true;
    this.cdr.markForCheck();
    this.chatHrVisitService.changeUserCheckedMode(this.userId, newValue)
      .pipe(
        catchError((error: Error) => this.handleError(error)),
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe((user) => {
        this.currentUser = user;
        this.updateInputsValue();
      });
  }


  onRejectedValueChange(newValue: boolean): void {
    this.isUserInfoLoading = true;
    this.cdr.markForCheck();
    this.chatHrVisitService.changeUserRejectedMode(this.userId, newValue)
      .pipe(
        catchError((error: Error) => this.handleError(error)),
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe((user) => {
        this.currentUser = user;
        this.updateInputsValue();
      });
  }

  private updateInputsValue() {
    this.isCheckedUser = this.currentUser.is_checked;
    this.isRejectedUser = this.currentUser.is_rejected;
    this.isUserInfoLoading = false;
    this.cdr.markForCheck();
  }

  private handleError(error: Error) {
    console.error('error', error);
    this.updateInputsValue();
    this.userRequestError = error.message;
    this.isUserInfoLoading = false;
    this.cdr.markForCheck();
    return of([])
  }

}

