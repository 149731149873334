import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { User } from '../../chatAcademy/models/user';
import { ChatDialogService, ChatDialogType } from '../../chatHR/chat-dialog/chat-dialog.service';
import { HRUserStateService } from '../../../../core/services/api/auth/hr-user-state.service';

@Component({
  selector: 'iql-chat-hr',
  templateUrl: './chat-hr.component.html',
  styleUrls: ['./chat-hr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrComponent implements OnInit {
  @Output() changedType = new EventEmitter<string>();
  record: { dialog?: any; type?: ChatDialogType; info?: any; };
  dialog: any;
  currentUser: User;

  constructor(
    private chatDialogService: ChatDialogService,
    private cdr: ChangeDetectorRef,
    private hrUserStateService: HRUserStateService
  ) { }

  ngOnInit() {
    this.hrUserStateService.onChangeUser().
      subscribe(user => {
        this.currentUser = user;
        this.chatDialogService.current_user = user;
        this.cdr.markForCheck();
      })
  }

  onChangeTypeChat(type: string): void {
    this.changedType.emit(type);
  }

  openChat(record: { dialog?: any, type?: ChatDialogType, info?: any}): void {
    if (record.type) {
      this.record = record;
    } else {
      let { id: current_user_id } = this.currentUser,
        { id: reply_user_id = current_user_id } = record.dialog.message.replyUser || {};
      this.record = current_user_id !== reply_user_id ? record.dialog.message.replyUser : record.dialog.message.user;
      this.dialog = record.dialog;
    }
    this.cdr.detectChanges();
  }

  closedDialog(): void {
    this.record = null;
    this.dialog = null;
  }
}
