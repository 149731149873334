import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { OpenedResumeStatus, OpenedResumeStatusInfo, OpenedResumeStatusMap } from '../../chat-hr-opened-resume';

@Component({
  selector: 'iql-chat-hr-opened-resume-tabs',
  templateUrl: './chat-hr-opened-resume-tabs.component.html',
  styleUrls: ['./chat-hr-opened-resume-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHrOpenedResumeTabsComponent implements OnInit {

  openedResumeTabList: OpenedResumeStatusInfo[];
  currentTab = OpenedResumeStatusMap[OpenedResumeStatus.NEW];
  @Output() changeTab: EventEmitter<OpenedResumeStatusInfo> = new EventEmitter()

  constructor() { }

  ngOnInit() {
    this.openedResumeTabList = this.setTabList();
  }

  onChangeTab(tab: OpenedResumeStatusInfo) {
    this.currentTab = OpenedResumeStatusMap[tab.type];
    this.changeTab.emit(tab);
  }

  private setTabList() {
    return Object.values(OpenedResumeStatusMap)
  }

}
