export function removeEmptyParams(obj: { [x: string]: any; }): void {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        removeEmptyParams(obj[key]);
      } else if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key] === false) {
        delete obj[key];
      }
      if (Array.isArray(obj[key])) {
        if (!obj[key].length) {
          delete obj[key];
        } else {
          obj[key] = obj[key].filter(i => !!i);
          if (!obj[key].length) {
            delete obj[key];
          }
        }
      }
    });
  }